import React, { FC, useCallback, useState } from 'react'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SxProps, Theme, styled } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'

interface FormikObjectProps {
    enableReinitialize?: boolean
    children?: any
    initialValues: any
    validationSchema?: any
    onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any
    innerRef?: React.Ref<FormikProps<FormikValues>>
    submitOnEnter?: boolean
    recaptcha?: boolean
    sx?: SxProps<Theme>
}

const FormComponent: FC<FormikObjectProps> = ({
    children,
    initialValues,
    validationSchema,
    onSubmit,
    innerRef,
    enableReinitialize,
    submitOnEnter = false,
    recaptcha = false,
    sx,
}) => {
    const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>()
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    const baseSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
        if (recaptchaToken) {
            values.recaptchaToken = recaptchaToken
            setRefreshReCaptcha((a) => !a)
        }

        onSubmit(values, formikHelpers)
    }

    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token)
    }, [])

    return (
        <>
            <Formik
                enableReinitialize={enableReinitialize}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={baseSubmit}
                innerRef={innerRef}
            >
                {({ handleSubmit }) => {
                    return (
                        <StyledForm
                            sx={sx}
                            onKeyDown={(e) => {
                                if (submitOnEnter && e.key === 'Enter') {
                                    handleSubmit()
                                }
                            }}
                        >
                            {children}
                        </StyledForm>
                    )
                }}
            </Formik>
            {recaptcha && process.env.REACT_APP_SITE_KEY && (
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
                    <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
                </GoogleReCaptchaProvider>
            )}
        </>
    )
}

const StyledForm = styled(Form)(() => ({}))

export default FormComponent
