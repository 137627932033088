import { useState } from 'react'
import { styled } from '@mui/material'
import { EvtFilterWrapper } from '../../components/EvtFilterWrapper'
import { Download, Resend, View } from '../../components/OrderAndProductActions'
import OrderAndProductActionsMobile from '../../components/OrderAndProductActionsMobile'
import { OrderProductCta } from '../../components/OrderAndProductCta'
import { ProgressWithLabel } from '../../components/ProgressWithLabel'
import TableEmptyContent from '../../components/TableEmptyContent'
import EvtBox from '../../elements/EvtBox'
import { EvtButton } from '../../elements/EvtButton'
import EvtDateRangePicker, { DateFilterProps } from '../../elements/EvtDateRangePicker'
import EvtGrid from '../../elements/EvtGrid'
import EvtSearchField from '../../elements/EvtSearchField'
import EvtTableDetail, { AlignType, Column } from '../../elements/EvtTableDetail'
import useDeviceType from '../../hooks/useDeviceType'
import { useEvtNavigateSearch } from '../../hooks/useEvtNavigate'
import { useDigitalOrders } from '../../query/orders'
import { OrderProduct } from '../../types/orders/Order'
import productUrls from '../../urls/productUrls'
import { EVT } from '../../utils/evt'

const init = { pageSize: 10, query: '', dateRange: { fromDate: null, toDate: null } } as Filter

const DigitalOrder = () => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const navigate = useEvtNavigateSearch()

    const [page, setPage] = useState<number>(1)
    const [filter, setFilter] = useState<Filter>(init)

    const { data: ordersResponse, isLoading } = useDigitalOrders({
        page: page,
        pageSize: filter.pageSize,
        query: filter.query,
        fromDate: filter.dateRange.fromDate?.toDate(),
        toDate: filter.dateRange.toDate?.toDate(),
    })

    const maxLength =
        ordersResponse?.orderProducts?.items?.reduce((p, c) => {
            return Math.max(p, c.quantity.toString().length)
        }, 0) || 0

    return (
        <>
            <EvtFilterWrapper
                initialValue={init}
                onSubmit={(filter) => {
                    setPage(1)
                    setFilter(filter)
                }}
            >
                {({ filterState, handleClear, setFieldValue, submit }) => (
                    <EvtGrid container spacing={6} mb="40px">
                        <EvtGrid item xs={12} md={3}>
                            <EvtSearchField
                                fullWidth
                                label="Product Name"
                                value={filterState.query}
                                onChange={(value) => setFieldValue('query', value)}
                                onClear={() => handleClear('query')}
                                onEnterToSearch={submit}
                            />
                        </EvtGrid>
                        <EvtGrid item xs={12} md={7}>
                            <EvtDateRangePicker
                                dateFilter={filterState.dateRange}
                                onChange={(date) => setFieldValue('dateRange', date)}
                                onClear={(date) => handleClear('dateRange', date)}
                                onEnterToSearch={submit}
                            />
                        </EvtGrid>
                        <EvtGrid item xs={4} md={2}>
                            <EvtButton
                                fullWidth
                                variant="contained"
                                sx={{ height: '100%', minHeight: '56px' }}
                                onClick={submit}
                            >
                                Search
                            </EvtButton>
                        </EvtGrid>
                    </EvtGrid>
                )}
            </EvtFilterWrapper>
            <EvtTableDetail
                isLoading={isLoading}
                columns={isDesktop ? orderColumns(maxLength) : orderColumnsMobile(navigate)}
                pagination={ordersResponse?.orderProducts}
                onPageChange={setPage}
                skeletonRows={filter.pageSize}
                emptyNode={
                    <TableEmptyContent
                        text="No orders to show"
                        btnTxt="Purchase products"
                        btnLinkTo={productUrls.pages.index}
                    />
                }
            />
        </>
    )
}

export default DigitalOrder

interface Filter {
    pageSize: number
    query: string
    dateRange: DateFilterProps
}

const ImageStyles = styled('img')(() => ({
    height: '60px',
}))

export const orderColumns = (maxLength: number) => {
    const retVal: Array<Column<OrderProduct>> = [
        {
            title: '',
            descriptor: (item) => <ImageStyles alt={item.name} src={item.cardImageUrl} />,
        },
        { title: 'Order', descriptor: (o) => o.orderNumber, align: AlignType.Center },
        { title: 'Order Date', descriptor: (o) => EVT.displayDate(o.created) || '-', align: AlignType.Center },
        { title: 'Product Name', descriptor: (item) => item.name },
        { title: 'Price', descriptor: (o) => EVT.toCurrency(o.price), align: AlignType.Center },
        { title: 'Quantity', descriptor: (item) => item.quantity, align: AlignType.Center },
        { title: 'Total', descriptor: (o) => EVT.toCurrency(o.price * o.quantity), align: AlignType.Center },
        {
            title: 'Quantity Assigned/Total',
            descriptor: (o) => (
                <ProgressWithLabel current={o.totalSent || 0} total={o.quantity} maxNumberLength={maxLength} />
            ),
            align: AlignType.Center,
        },
        {
            title: 'Actions',
            descriptor: (o) => (
                <EvtBox display="flex" justifyContent="center" gap="10px">
                    {(o.totalSent || 0) > 0 && <Download orderId={o.orderId} />}
                    <View orderId={o.orderId} />
                    <Resend disabled={(o.totalSent || 0) <= 0} orderNumber={o.orderNumber} orderProductId={o.id} />
                </EvtBox>
            ),
            align: AlignType.Center,
        },
        {
            title: '',
            descriptor: (o) => <OrderProductCta orderProduct={o} />,
            align: AlignType.Center,
        },
    ]

    return retVal
}

export const orderColumnsMobile = (navigate: any) => {
    const retVal: Array<Column<OrderProduct>> = [
        { title: 'Product Name', descriptor: (item) => item.name },
        { title: 'Total', descriptor: (o) => EVT.toCurrency(o.price * o.quantity), align: AlignType.Center },
        {
            title: '',
            descriptor: (o) => (
                <OrderAndProductActionsMobile
                    listItems={[
                        <OrderProductCta orderProduct={o} isMobile />,
                        (o.totalSent || 0) > 0 ? <Download orderId={o.orderId} /> : null,
                        <View orderId={o.orderId} />,
                        <Resend disabled={!(o.totalSent && o.totalSent > 0)} orderNumber={o.orderNumber} />,
                    ]}
                />
            ),
            align: AlignType.Center,
        },
    ]

    return retVal
}
