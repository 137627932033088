import React from 'react'

function useLocalStorage<T>(key: string, initialValue: T) {
    const [storedValue, setStoredValue] = React.useState(() => {
        return getLocalStorageItem(key, initialValue)
    })

    const setValue = (value: T) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)
            setLocalStorageItem(key, valueToStore)
        } catch (error) {
            console.error(error)
        }
    }

    return [storedValue, setValue]
}

export const getLocalStorageItem = <T>(key: string, initialValue: T) => {
    try {
        const item = window.localStorage.getItem(key)
        return (item ? JSON.parse(item) : initialValue) as T
    } catch (error) {
        console.error(error)
        return initialValue
    }
}

export const setLocalStorageItem = <T>(key: string, value: T) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
        console.error(error)
    }
}

export default useLocalStorage
