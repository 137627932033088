import React, { useState } from 'react'

const SetStateContext = React.createContext((state: boolean) => {})
const GetLoaderStateContext = React.createContext<number>(0)

const LoaderProvider = (props: { children: React.ReactNode }) => {
    const [loaderCount, setLoaderCount] = useState<number>(0)
    const setLoaderLoading = (loading: boolean) => {
        setLoaderCount((s) => (loading ? s + 1 : s - 1))
    }

    return (
        <SetStateContext.Provider value={setLoaderLoading}>
            <GetLoaderStateContext.Provider value={loaderCount}>{props.children}</GetLoaderStateContext.Provider>
        </SetStateContext.Provider>
    )
}

const useLoader = () => {
    const context = React.useContext(SetStateContext)
    if (context === undefined) {
        throw new Error('useLoader must be used within a LoaderProvider')
    }

    return context
}

export { LoaderProvider, useLoader, GetLoaderStateContext }
