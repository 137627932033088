import { FC, useEffect, useState } from 'react'
import { FormikProps, FormikValues, useFormikContext } from 'formik'
import FormComponent from '../../components/FormComponent'
import GoogleMapInput from '../../components/GoogleMapInput'
import { Countries, States, Titles, isAu } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtButton, EvtSubmitButton } from '../../elements/EvtButton'
import { EvtFormCheckbox } from '../../elements/EvtFormCheckbox'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtFormSelect from '../../elements/EvtFormSelect'
import EvtGrid from '../../elements/EvtGrid'
import EvtLink from '../../elements/EvtLink'
import { EvtLoader } from '../../elements/EvtLoader'
import EvtPostcodeFormInput from '../../elements/EvtPostcodeFormInput'
import { EvtTitleTypography } from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useModal } from '../../providers/modals/GlobalModalProvider'
import { useUpdateUser, useUserProfile } from '../../query/users'
import { UserDetail } from '../../types/users/User'
import { EVT } from '../../utils/evt'
import { evtValidator } from '../../utils/validation'
import PasswordUpdateForm from './PasswordUpdateForm'

interface Props {
    buttons?: React.ReactNode
    innerRef?: React.Ref<FormikProps<FormikValues>>
    onSuccess?: (values: UserDetail) => void
}

const INITIAL_FORM_STATE: UserDetail = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    addressLine2: '',
    suburb: '',
    state: States[0].value,
    country: Countries[0].value,
    companyName: '',
    postCode: '',
    title: Titles[0].value,
    optin: true,
    city: '',
}

const FORM_VALIDATION = evtValidator(INITIAL_FORM_STATE)

const Profile: FC<Props> = () => {
    const { data: userProfile, isLoading } = useUserProfile()
    const { mutate: updateUser } = useUpdateUser()
    const { openModal } = useModal()
    const [formValues, setFormValues] = useState<UserDetail>(INITIAL_FORM_STATE)
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)

    useEffect(() => {
        setFormValues({ ...INITIAL_FORM_STATE, ...userProfile })
    }, [userProfile])

    const resetForm = () => {
        setFormValues(INITIAL_FORM_STATE)
    }

    const onSubmit = (values: UserDetail) => {
        updateUser(values)
    }

    const showPasswordResetModal = () => {
        openModal({
            content: <PasswordUpdateForm />,
        })
    }

    return (
        <EvtLoader loading={isLoading}>
            {userProfile && (
                <FormComponent
                    enableReinitialize
                    initialValues={formValues}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={onSubmit}
                >
                    <EvtBox
                        className="profile-form"
                        width={{
                            xs: '90%',
                            md: '60%',
                            lg: '50%',
                            xl: '40%',
                        }}
                        sx={containerStyle}
                    >
                        <EvtTitleTypography paddingTop="30px">Profile</EvtTitleTypography>

                        <EvtGrid container spacing={5}>
                            <EvtGrid item xs={12} display="flex" justifyContent="flex-end" pr="15px">
                                <EvtLink
                                    sx={{ cursor: 'pointer', color: (t) => t.palette.text.primary }}
                                    onClick={showPasswordResetModal}
                                >
                                    Manage Password
                                </EvtLink>
                            </EvtGrid>
                            <GridSelectField name="title" label="Titles" options={Titles} />
                            <GridInputField name="firstName" label="First Name" />

                            <GridInputField name="lastName" label="Last Name" />
                            <GridInputField name="email" label="Email" />

                            <GridInputField name="phoneNumber" label="Phone Number" />
                            <GridInputField name="companyName" label="Company Name" />

                            <EvtGrid item xs={12}>
                                <GoogleMapInput
                                    label="address"
                                    properties={{
                                        postcode: 'postCode',
                                    }}
                                />
                            </EvtGrid>

                            <GridInputField name="suburb" label="Suburb" />
                            <EvtGrid item xs={isMobile ? 12 : 6}>
                                <EvtPostcodeFormInput name="postCode" label="Postcode" />
                            </EvtGrid>

                            {isAu ? (
                                <GridSelectField name="state" label="State" options={States} />
                            ) : (
                                <GridInputField name="city" label="City" />
                            )}

                            <GridSelectField name="country" label="Country" options={Countries} />

                            <EvtGrid item xs={12}>
                                <EvtFormCheckbox
                                    name="optin"
                                    description="I wish to receive conmunications from Event Corporate"
                                />
                            </EvtGrid>

                            <EvtGrid item xs={6}>
                                <EvtSubmitButton
                                    variant="contained"
                                    fullWidth
                                    sx={{ height: '50px', borderRadius: '25px' }}
                                >
                                    Update
                                </EvtSubmitButton>
                            </EvtGrid>
                            <EvtGrid item xs={6}>
                                <ResetButton onClick={resetForm} />
                            </EvtGrid>
                        </EvtGrid>
                    </EvtBox>
                </FormComponent>
            )}
        </EvtLoader>
    )
}

export default Profile

interface GridInputFieldProps {
    size?: number
    name: string
    label: string
}

const GridInputField: FC<GridInputFieldProps> = ({ size = 6, name, label }) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    let props = {
        label,
        name,
        isRoundBorder: true,
    }

    return (
        <EvtGrid item xs={isMobile ? 12 : size}>
            <EvtFormInputField {...props} />
        </EvtGrid>
    )
}

interface GridSelectFieldProps {
    size?: number
    name: string
    label: string
    options: Array<{ value: string; text: string }>
}

const GridSelectField: FC<GridSelectFieldProps> = ({ size = 6, name, label, options }) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    let props = {
        label,
        name,
        isRoundBorder: true,
    }
    return (
        <EvtGrid item xs={isMobile ? 12 : size}>
            <EvtFormSelect options={options} {...props} fullWidth />
        </EvtGrid>
    )
}

const ResetButton: FC<{ onClick: () => void }> = ({ onClick }) => {
    const { resetForm } = useFormikContext()
    return (
        <EvtButton
            variant="contained"
            fullWidth
            onClick={() => {
                resetForm()
                onClick()
            }}
            sx={{ height: '50px', borderRadius: '25px' }}
        >
            Reset
        </EvtButton>
    )
}

const containerStyle = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    gap: '40px',
    pb: '50px',
})
