import { FC } from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DownloadIcon from '@mui/icons-material/Download'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Theme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import EvtLink from '../elements/EvtLink'
import { EvtLinkTo } from '../elements/EvtLinkTo'
import EvtTooltip from '../elements/EvtTooltip'
import ResendIcon from '../icons/ResendIcon'
import { useOrderDownloadEvouchers } from '../query/orders'
import { digitalHubUrls, pages } from '../urls/digitalHubUrls'
import eVoucherUrls from '../urls/eVoucherUrls'
import orderUrls from '../urls/orderUrls'

export const Edit: FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <EvtTooltip title="Update Receiver">
            <BorderColorIcon sx={editStyles} onClick={onClick} />
        </EvtTooltip>
    )
}

export const ViewEvoucher: FC<{ id: string }> = ({ id }) => {
    return (
        <EvtLinkTo to={eVoucherUrls.pages.evoucher} toId={id} isOpenInNew>
            <EvtTooltip title="View Evoucher">
                <VisibilityIcon sx={viewStyles} />
            </EvtTooltip>
        </EvtLinkTo>
    )
}

export const View: FC<{ orderId: string }> = ({ orderId }) => {
    return (
        <EvtLinkTo to={orderUrls.pages.details} toId={orderId}>
            <EvtTooltip title="View Order">
                <VisibilityIcon sx={viewStyles} />
            </EvtTooltip>
        </EvtLinkTo>
    )
}

export const Download: FC<{ orderId: string }> = ({ orderId }) => {
    const { mutate: downloadEvouchers } = useOrderDownloadEvouchers()
    return (
        <EvtTooltip title="Download">
            <DownloadIcon sx={viewStyles} onClick={() => downloadEvouchers({ id: orderId })} />
        </EvtTooltip>
    )
}

export const Resend: FC<{
    disabled: boolean
    orderNumber?: string
    orderProductId?: string
    onResend?: () => void
}> = ({ disabled, orderNumber, orderProductId, onResend }) => {
    return onResend ? (
        <EvtLink title="Resend">
            <ResendIconWrapper disabled={disabled} onResend={onResend} />
        </EvtLink>
    ) : !disabled ? (
        <EvtLinkTo
            title="Resend"
            to={digitalHubUrls.pages.index}
            toId={pages.resend.url}
            search={{ orderNumber: orderNumber || '', orderProductId: orderProductId || '' }}
        >
            <ResendIconWrapper disabled={disabled} />
        </EvtLinkTo>
    ) : (
        <EvtLink title="Resend">
            <ResendIconWrapper disabled={disabled} />
        </EvtLink>
    )
}

const ResendIconWrapper: FC<{ disabled: boolean; onResend?: () => void }> = ({ disabled, onResend }) => {
    return (
        <EvtBox sx={{ pt: '4px', height: '25px' }}>
            <ResendIcon sx={(t) => resendStyles(t, disabled)} onClick={onResend} />
        </EvtBox>
    )
}

const editStyles = (theme: Theme) => ({
    fontSize: '20px',
    cursor: 'pointer',
    color: `${theme.palette.table.actionIcon} !important`,
})

const viewStyles = (theme: Theme) => ({
    color: `${theme.palette.table.actionIcon} !important`,
})

const resendStyles = (theme: Theme, disabled: boolean) => ({
    fontSize: '25px',
    cursor: disabled ? 'default' : 'pointer',
    color: `${disabled ? theme.palette.text.disabled : theme.palette.table.actionIcon} !important`,
})
