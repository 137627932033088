import React, { FC, ReactNode, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MenuProps, styled } from '@mui/material'
import { EvtButton } from '../elements/EvtButton'
import EvtMenu from '../elements/EvtMenu'
import EvtMenuItem from '../elements/EvtMenuItem'

interface Props {
    listItems: Array<ReactNode>
}

const OrderAndProductActionsMobile: FC<Props> = ({ listItems }) => {
    const menuId = 'evt-action-mobile-menu'
    const buttonId = 'evt-action-mobile-button'
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <EvtButton
                id={buttonId}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Actions
            </EvtButton>
            <StyledMenu
                id={menuId}
                MenuListProps={{
                    'aria-labelledby': buttonId,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {listItems
                    .filter((i) => i)
                    .map((item, index) => (
                        <EvtMenuItem
                            key={`action-list-${index}`}
                            onClick={handleClose}
                            disableRipple
                            divider={index < listItems.length - 1}
                        >
                            {item}
                        </EvtMenuItem>
                    ))}
            </StyledMenu>
        </div>
    )
}

export default OrderAndProductActionsMobile

const StyledMenu = styled((props: MenuProps) => (
    <EvtMenu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 120,
        '& .MuiMenu-list': {
            backgroundColor: theme.palette.actionMenu.bgColor,
            '> li': {
                justifyContent: 'center',
            },
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
        },
    },
}))
