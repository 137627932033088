import { FC } from 'react'
import { styled } from '@mui/material'
import { PaymentStatus } from '../consts'
import { EvtButtonPill } from '../elements/EvtButton'
import { EvtLinkTo } from '../elements/EvtLinkTo'
import EvtTypography from '../elements/EvtTypography'
import { Order, OrderProduct } from '../types/orders/Order'
import orderUrls from '../urls/orderUrls'

export const OrderCta: FC<{ order: Order; isMobile?: boolean }> = ({ order, isMobile }) => {
    const Buttons = () => {
        if (order.canAssign) {
            return isMobile ? <AssignItem orderId={order.orderId} /> : <AssignButton orderId={order.orderId} />
        }

        if (order.paymentStatus === PaymentStatus.awaitingPayment) {
            return isMobile ? <PayNowItem orderId={order.orderId} /> : <PayNowButton orderId={order.orderId} />
        }

        if (!order.hasElectronic) {
            return isMobile ? <>Paid</> : <EvtButtonPill sx={{ minWidth: '100px' }}>Paid</EvtButtonPill>
        }

        if (order.totalSent && order.totalSent === order.totalSendQuantity) {
            return isMobile ? <>Assigned</> : <AssignedButton />
        }

        return <></>
    }

    return <Buttons />
}

export const OrderProductCta: FC<{ orderProduct: OrderProduct; isMobile?: boolean }> = ({ orderProduct, isMobile }) => {
    const Buttons = () => {
        if (!orderProduct.paidDate) {
            return isMobile ? (
                <PayNowItem orderId={orderProduct.orderId} />
            ) : (
                <PayNowButton orderId={orderProduct.orderId} />
            )
        }

        if (!orderProduct.isElectronic) {
            return <></>
        }

        if ((orderProduct.totalSent || 0) < orderProduct.quantity) {
            return isMobile ? (
                <AssignItem orderId={orderProduct.orderId} />
            ) : (
                <AssignButton orderId={orderProduct.orderId} orderProductId={orderProduct.id} />
            )
        }

        if ((orderProduct.totalSent || 0) === orderProduct.quantity) {
            return isMobile ? <>Assigned</> : <AssignedButton />
        }

        return <></>
    }

    return <Buttons />
}

interface ButtonProps {
    orderId?: string
}

const AssignButton: FC<ButtonProps & { orderProductId?: string }> = ({ orderId, orderProductId }) => {
    return (
        <EvtButtonPill
            to={orderUrls.pages.assign}
            toId={orderId}
            search={{ orderProductId: orderProductId || '' }}
            sx={{ minWidth: '100px' }}
        >
            Assign
        </EvtButtonPill>
    )
}
const PayNowButton: FC<ButtonProps> = ({ orderId }) => {
    return (
        <StyledLink href={`${orderUrls.api.invoice.url}/${orderId}`} target="_blank" download>
            <EvtButtonPill color="error" sx={{ minWidth: '100px' }}>
                Pay Now
            </EvtButtonPill>
        </StyledLink>
    )
}
const AssignedButton = () => {
    return (
        <EvtButtonPill color="success" sx={{ minWidth: '100px' }}>
            Assigned
        </EvtButtonPill>
    )
}

const StyledLink = styled('a')(({ theme }) => ({
    ':visited': { color: theme.palette.text.primary },
    textDecoration: 'none',
}))

const AssignItem: FC<{ orderId: string | undefined }> = ({ orderId }) => (
    <EvtLinkTo to={orderUrls.pages.assign} toId={orderId}>
        <EvtTypography>Assign</EvtTypography>
    </EvtLinkTo>
)

const PayNowItem: FC<{ orderId: string | undefined }> = ({ orderId }) => (
    <StyledLink href={`${orderUrls.api.invoice.url}/${orderId}`} target="_blank" download>
        <EvtTypography>Pay Now</EvtTypography>
    </StyledLink>
)
