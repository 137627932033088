import * as yup from 'yup'
import { isAu } from '../consts'

export function evtValidator<T>(obj: T | string[], overrides?: object): object {
    const shape: Record<string, any> = {}
    let keys = []
    if (Array.isArray(obj)) {
        keys = obj
    } else {
        // @ts-ignore
        keys = Object.keys(obj)
    }

    keys.forEach((k) => {
        shape[k] = defaults[k]
    })

    if (overrides) {
        Object.assign(shape, overrides)
    }

    return yup.object().shape(shape)
}

const phoneRegExp = /^(?:(61))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
const maxEmailLength = 60

export const defaults: Record<string, any> = {
    email: yup
        .string()
        .max(maxEmailLength, `Email must not exceed ${maxEmailLength} characters`)
        .email('Invalid email')
        .required('Required'),
    confirmEmail: yup
        .string()
        .email('Invalid email')
        .required('Required')
        .oneOf([yup.ref('email'), null], 'Email must match'),
    password: yup
        .string()
        .required('Required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
    confirmPassword: yup
        .string()
        .required('Required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    username: yup.string().required('Required'),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phone: yup.string().required('Required').matches(phoneRegExp, 'Please input valid phone number'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Please input valid phone number'),
    contactNumber: yup.string().matches(phoneRegExp, 'Please input valid phone number'),
    address: yup.string().required('Required'),
    address1: yup.string().required('Required'),
    addressLine2: yup.string(),
    city: !isAu ? yup.string().required('Required') : yup.string(),
    state: isAu ? yup.string().required('Required') : yup.string(),
    suburb: yup.string().required('Required'),
    postcode: yup
        .string()
        .required('Required')
        .matches(/^[0-9]{4}$/, 'Postcode is not valid'),
    postCode: yup
        .string()
        .required('Required')
        .matches(/^[0-9]{4}$/, 'Postcode is not valid'),
    country: yup.string().required('Required'),
    arrivealDate: yup.date().required('Required'),
    departureDate: yup.date().required('Required'),
    message: yup.string(),
    termsOfService: yup
        .boolean()
        .oneOf([true], 'The terms and conditions must be accepted')
        .required('The terms and conditions must be accepted'),
}
