import { FC } from 'react'
import { Theme } from '@mui/material'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtGrid from '../../elements/EvtGrid'
import { EvtLoader } from '../../elements/EvtLoader'
import EvtTypography, { EvtTitleTypography } from '../../elements/EvtTypography'
import { Image, useImages } from '../../query/images'
import userUrls from './../../urls/userUrls'

const JoinSection = () => {
    const { data: images, isLoading } = useImages()
    const whyJoinImgs = images?.home.whyJoin.images
    return (
        <EvtBox>
            <EvtTitleTypography mb="40px">WHY JOIN EVENT CORPORATE</EvtTitleTypography>
            <EvtLoader loading={isLoading}>
                <>
                    <EvtGrid container spacing="40px">
                        {whyJoinImgs?.map((image, index) => (
                            <EvtGrid
                                key={image.imageUrl}
                                item
                                sm={12}
                                md={4}
                                sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                            >
                                <ImageItem image={image} />
                            </EvtGrid>
                        ))}
                    </EvtGrid>
                    <EvtButtonPill to={userUrls.pages.login} sx={{ padding: '15px 35px', mt: '40px' }}>
                        Login / Join Now
                    </EvtButtonPill>
                </>
            </EvtLoader>
        </EvtBox>
    )
}

export default JoinSection

const ImageItem: FC<{ image: Image }> = ({ image }) => {
    const { Title, Content, imageUrl } = image
    return (
        <EvtBox sx={imageItemStyle}>
            <img alt={Title || 'why join image'} src={imageUrl} />
            {Title && <EvtTypography fontSize="18px">{Title}:</EvtTypography>}
            {Content && <EvtTypography fontSize="12px">{Content}</EvtTypography>}
        </EvtBox>
    )
}

const imageItemStyle = (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '300px',
    gap: '40px',
    img: {
        width: 'auto',
        height: '120px',
    },
})
