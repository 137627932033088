import { config } from '../config'
import { API, createPageUrl } from '../urls'

const eVoucherUrls = {
    api: {
        retrieve: {
            url: config.baseUrl + '/api/evoucher/retrieve',
            requiresAuth: false,
            method: 'GET',
            queryKey: 'GET_EVOUCHER',
        } as API,
    },
    pages: {
        details: createPageUrl({
            url: '/evoucher/*',
            text: 'eVoucher Details',
        }),
        evoucher: createPageUrl({
            url: '/evoucher/:id',
            text: 'Evoucher',
        }),
    },
}

export default eVoucherUrls
