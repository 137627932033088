import React, { FC, ReactNode, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import StarsIcon from '@mui/icons-material/Stars'
import { Autocomplete, SelectChangeEvent, Theme, useTheme } from '@mui/material'
import { Enumerable } from 'sharp-collections'
import EvtBox from '../elements/EvtBox'
import { EvtButtonPill } from '../elements/EvtButton'
import EvtCustomSelect from '../elements/EvtCustomSelect'
import { EvtInputField } from '../elements/EvtInputField'
import EvtTypography from '../elements/EvtTypography'
import { useCart } from '../providers/CartProvider'
import { Product } from '../types/products/Product'
import { EVT } from '../utils/evt'

interface Props {
    product: Product
    type?: 'card' | 'page'
    mobile?: boolean
}

const MobileFontSize = '13px'
const DesktopFontSize = '15px'

const AddToCartSection: FC<Props> = ({ product, type = 'card', mobile }) => {
    const { addToCart } = useCart()
    product.prices = product.prices.sort((a, b) => a - b)
    const { minimumQuantity, maximumQuantity, defaultPrice, prices } = product

    const thresholds = product.thresholds || [{ unitPrice: defaultPrice || prices[0] }]
    const threshEnum = Enumerable.from(thresholds)
    const lowestThreshold = threshEnum.orderBy((e) => e.limit).firstOrDefault()
    const qtyOptions = EVT.range(minimumQuantity || 1, maximumQuantity + 1).map((i) => `${i}`)

    product.price = lowestThreshold?.limit === 1 ? lowestThreshold.unitPrice : defaultPrice || prices[0]

    const [price, setPrice] = useState(product.price)
    const [qty, setQty] = useState<number | null>(minimumQuantity || 1)

    const handleAddToCart = () => {
        product = { ...product, price }
        addToCart(product, qty || minimumQuantity)
        setQty(minimumQuantity || 1)
    }

    return (
        <>
            <EvtBox sx={qtyPriceDirectionStyle(type)}>
                <PriceField
                    qty={qty}
                    price={price}
                    prices={prices}
                    type={type}
                    mobile={mobile}
                    product={product}
                    minimumQuantity={minimumQuantity}
                    onChange={(price) => {
                        setPrice(price)
                    }}
                />
                <QuantityField
                    qty={qty}
                    options={qtyOptions}
                    maximumQuantity={maximumQuantity}
                    minimumQuantity={minimumQuantity}
                    setQty={setQty}
                    mobile={mobile}
                />
            </EvtBox>

            <EvtBox>
                <EvtButtonPill fullWidth sx={addToCartStyle} onClick={handleAddToCart}>
                    Add to cart
                    <AddIcon sx={{ paddingLeft: '8px', fontWeight: 'bold', fontSize: '28px' }} />
                </EvtButtonPill>
            </EvtBox>
        </>
    )
}

export default AddToCartSection

const QuantityField: FC<{
    qty: number | null
    options: Array<string>
    minimumQuantity: number
    maximumQuantity: number
    setQty: (qty: number | null) => void
    mobile?: boolean
}> = ({ qty, options, minimumQuantity, maximumQuantity, setQty, mobile }) => {
    const theme = useTheme()
    const [open, setOpen] = useState(false)

    return (
        <Autocomplete
            fullWidth
            freeSolo
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            filterOptions={(t) => t}
            options={options}
            getOptionLabel={(option) => option || ''}
            value={qty ? qty.toString() : ''}
            onBlur={() => {
                if (!qty) setQty(minimumQuantity || 1)
            }}
            disableClearable
            renderInput={(params) => {
                const InputProps = { ...params.InputProps }
                InputProps.endAdornment = (
                    <EvtBox className="evt-autoselect-icon">
                        <KeyboardArrowDownIcon />
                    </EvtBox>
                )
                return (
                    <EvtBox
                        onClick={(e) => {
                            const target: any = e.target
                            if (target.localName !== 'input') {
                                setOpen((s) => !s)
                            }
                        }}
                    >
                        <EvtInputField
                            isRoundBorder
                            value={qty ? qty.toString() : ''}
                            {...params}
                            InputProps={InputProps}
                            type="tel"
                            bgColor={theme.palette.productCard.bgColor}
                        />
                    </EvtBox>
                )
            }}
            sx={(t) => qtyStyle(t, mobile)}
            onInputChange={(e: React.SyntheticEvent, qtyString: string, reason: string) => {
                if (qtyString) {
                    let val = parseInt(qtyString)
                    if (val < minimumQuantity || val < 1 || !val) {
                        val = minimumQuantity || 1
                    }

                    if (val > maximumQuantity) {
                        val = maximumQuantity
                    }
                    if (!isNaN(val)) {
                        setQty(val)
                    }
                } else if (reason === 'input') {
                    // only clear it if deleting from the input
                    setQty(null)
                }
            }}
        />
    )
}

interface PriceFieldProps {
    product: Product
    qty: number | null
    price: number
    prices: Array<number>
    type: 'card' | 'page'
    mobile: boolean | undefined
    minimumQuantity: number
    onChange: (price: number) => void
}

const PriceField: FC<PriceFieldProps> = ({ product, price, prices, qty, onChange, type, mobile, minimumQuantity }) => {
    const theme = useTheme()
    const { defaultPrice } = product
    const thresholds = product.thresholds || [{ unitPrice: defaultPrice || prices[0] }]
    const priceOptions = prices.map((price) => ({ value: price, text: `$${price}` }))
    const originalPrice = defaultPrice || prices[0]
    let label: string | ReactNode = 'Discounted'
    const threshEnum = Enumerable.from(thresholds)
    const threshold = threshEnum
        .where((e) => e.limit <= (qty || minimumQuantity))
        .orderByDescending((t) => t.limit)
        .firstOrDefault()

    if (threshold && (qty || minimumQuantity) >= threshold.limit) {
        if (qty === 1) {
            label = <StarsIcon sx={{ color: theme.palette.discountedPrice, fontSize: '40px' }} />
        }
        label = <StarsIcon sx={{ color: theme.palette.discountedPrice, fontSize: '40px' }} />
        price = threshold.unitPrice
    }

    return (
        <>
            {priceOptions.length > 1 ? (
                <EvtCustomSelect
                    sx={(t) => priceStyle(t, type, mobile)}
                    fullWidth
                    options={priceOptions}
                    value={price}
                    isRoundBorder
                    onChange={(e: SelectChangeEvent<unknown>) => {
                        onChange(e.target.value as number)
                    }}
                />
            ) : type === 'card' ? (
                <CardFixedPriceField price={price} originalPrice={originalPrice} mobile={mobile} label={label} />
            ) : (
                <PageFixedPriceField price={price} originalPrice={originalPrice} />
            )}
        </>
    )
}

interface PriceFieldProsp {
    price: number
    originalPrice: number
    mobile?: boolean
    label?: string | ReactNode
}

const CardFixedPriceField: FC<PriceFieldProsp> = ({ price, originalPrice, mobile, label }) => {
    return (
        <EvtBox sx={(t) => priceFieldCardStyle(t, mobile)}>
            {price !== originalPrice && (
                <>
                    <EvtTypography className="discount-price-label">{label}</EvtTypography>
                    <EvtTypography className="original-price">{EVT.toCurrency(originalPrice)}</EvtTypography>
                </>
            )}
            <EvtTypography className="discounted-price" sx={(t) => priceTextStyle(t, price !== originalPrice)}>
                {EVT.toCurrency(price)}
            </EvtTypography>
        </EvtBox>
    )
}

const PageFixedPriceField: FC<PriceFieldProsp> = ({ price, originalPrice }) => {
    return (
        <EvtBox sx={priceFieldPageStyle}>
            {price !== originalPrice && (
                <EvtTypography className="original-price">{EVT.toCurrency(originalPrice)}</EvtTypography>
            )}
            <EvtTypography sx={(t) => priceTextStyle(t, price !== originalPrice)} fontSize="30px">
                {EVT.toCurrency(price)}
            </EvtTypography>
        </EvtBox>
    )
}

const priceFieldPageStyle = () => ({
    width: '100%',
    height: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    '.original-price': {
        fontSize: '20px',
        textDecoration: 'line-through',
    },
})

const priceFieldCardStyle = (theme: Theme, mobile: boolean | undefined) => ({
    width: '100%',
    height: mobile ? '40px' : '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    position: 'relative',
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: '35px',
    '.discounted-price': {
        fontSize: DesktopFontSize,
    },
    '.original-price': {
        textDecoration: 'line-through',
        fontSize: '13px',
    },
    '.discount-price-label': {
        position: 'absolute',
        top: '-10px',
        left: '15px',
        fontSize: mobile ? MobileFontSize : '12px',
        backgroundColor: theme.palette.productCard.bgColor,
        height: '24px',
        width: '30px',
        svg: {
            fontSize: mobile ? 'large' : 'x-large',
        },
    },
})

const addToCartStyle = (theme: Theme) => ({
    borderRadius: '30px',
    height: '50px',
    paddingX: 'auto',
})

const qtyPriceDirectionStyle = (type: 'card' | 'page') => {
    return {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        flexDirection: type === 'page' ? 'column' : 'row',
        paddingBottom: type === 'page' ? '0px' : '15px',
        '> .MuiFormControl-root': {
            alignItems: 'center',
        },
    }
}

const priceTextStyle = (theme: Theme, isDiscounted: boolean) => ({
    color: isDiscounted ? theme.palette.discountedPrice : theme.palette.text.primary,
})

const priceStyle = (theme: Theme, type: 'card' | 'page', mobile: boolean | undefined) => {
    const cssObject: {
        height?: string
        paddingRight?: string
        width?: string
        fontSize?: string
    } = type === 'card' ? {} : { width: '120px' }

    // @ts-ignore
    cssObject['.MuiInputBase-input'] = { paddingY: '11px' }
    cssObject.fontSize = DesktopFontSize
    if (mobile) {
        cssObject.height = '40px'
        cssObject.paddingRight = '10px'
        cssObject.fontSize = MobileFontSize
        // @ts-ignore
        cssObject['.evt-select-icon '] = {
            width: '40px',
        }
        // @ts-ignore
        cssObject['.MuiInputBase-input'] = {
            paddingRight: '40px !important',
        }
    }

    return cssObject
}

const qtyStyle = (theme: Theme, mobile?: boolean) => ({
    '.MuiInputBase-root': {
        height: mobile ? '40px' : '45px',
        paddingY: 0,
        input: { textAlign: 'center' },
        paddingRight: '45px !important',
    },
    '.Mui-focused': {
        '.evt-autoselect-icon': {
            borderLeft: `2px solid ${theme.palette.border.default} !important`,
        },
    },
    '.evt-autoselect-icon': {
        position: 'absolute',
        borderLeft: `1px solid ${theme.palette.border.default}`,
        width: '50px',
        height: 'calc(100% - 1px)',
        right: 0,
        top: 0,
        WebkitTransform: 'unset',
        transform: 'unset',
        cursor: 'pointer',
        zIndex: '0',
        svg: {
            height: '100%',
        },
    },
    position: 'relative',
})
