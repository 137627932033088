import { FC } from 'react'
import { Theme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import EvtTypography from '../elements/EvtTypography'
import { useSettings } from '../query/settings'
import { Order } from '../types/orders/Order'
import { EVT } from '../utils/evt'

const OrderExtraInfoBox: FC<{ order: Order | undefined }> = ({ order }) => {
    const { data: settings } = useSettings()

    return (
        <>
            {order && (
                <EvtBox sx={extraInfoBoxStyle}>
                    {order.billingAddress && (
                        <InfoItem
                            title="Receipt To"
                            contents={[
                                `${order.billingAddress?.nameSummary}`,
                                `${order.billingAddress?.companyName}`,
                                `${order.billingAddress?.address1} ${order.billingAddress?.subStatePCSummary}`,
                            ]}
                        />
                    )}
                    {order.shippingAddress && (
                        <InfoItem
                            title="Shipping To"
                            contents={[
                                `${order.shippingAddress?.nameSummary}`,
                                `${order.shippingAddress?.companyName}`,
                                `${order.shippingAddress?.address1} ${order.shippingAddress?.subStatePCSummary}`,
                            ]}
                        />
                    )}

                    <InfoItem
                        title="Tax Invoice"
                        contents={[
                            `${process.env.REACT_APP_ABN_NAME} ${settings?.abn}`,
                            `Receipt Date ${EVT.displayDate(order.created)}`,
                            `Receipt Number ${order.orderNumber}`,
                        ]}
                    />
                </EvtBox>
            )}
        </>
    )
}

export default OrderExtraInfoBox

const InfoItem: FC<{ title: string; contents: Array<string> }> = ({ title, contents }) => {
    return (
        <EvtBox sx={infoItemStyle}>
            <EvtTypography className="title">{title}:</EvtTypography>
            {contents
                .filter((c) => c)
                .map((c, i) => (
                    <EvtTypography key={i} className="content">
                        {c}
                    </EvtTypography>
                ))}
        </EvtBox>
    )
}

const infoItemStyle = (theme: Theme) => ({
    textAlign: 'left',
    '.title': {
        fontSize: '15px',
        fontWeight: 'bold',
        pb: '5px',
        color: theme.palette.summary.text,
    },
    '.content': {
        fontSize: '12px',
        color: theme.palette.summary.text,
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        '.title': { fontSize: '12px' },
        '.content': { fontSize: '10px' },
    },
})

const extraInfoBoxStyle = (theme: Theme) => ({
    borderRadius: '10px',
    border: `1px solid ${theme.palette.border.default}`,
    mt: '20px',
    padding: '20px 30px',
    display: 'flex',
    justifyContent: 'space-around',
    gap: '15px',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        flexDirection: 'column',
    },
})
