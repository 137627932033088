import React, { FC, useContext } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Enumerable } from 'sharp-collections'
import EvtAvatar from '../../elements/EvtAvatar'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtIconButton from '../../elements/EvtIconButton'
import EvtTable from '../../elements/EvtTable'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import AusPostIcon from '../../icons/AusPostIcon'
import { GetCartStateContext, useCart } from '../../providers/CartProvider'
import { OrderCreateProdcutProps, OrderCreateRequestProps, useOrderCreate } from '../../query/orders'
import { Product } from '../../types/products/Product'
import cartUrls from '../../urls/cartUrls'
import checkoutUrls from '../../urls/checkoutUrls'
import productUrls from '../../urls/productUrls'
import { EVT } from '../../utils/evt'
import MenuUI from '../MenuUI'
import QtyInputField from '../QtyInputField'

interface Props {
    anchorEl: null | HTMLElement
    menuId: string
    isMenuOpen: boolean
    handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void
    forceMenuClose: () => void
}

const CartMenu: FC<Props> = ({ anchorEl, menuId, isMenuOpen, handleMenuClose, forceMenuClose }) => {
    const cart = useContext(GetCartStateContext)
    const theme = useTheme()
    const { removeFromCart } = useCart()
    const navigate = useEvtNavigate()
    const { mutate: createOrder } = useOrderCreate()
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)

    const handleCheckout = () => {
        const reqeustProducts: OrderCreateRequestProps = {
            products: cart.items.map(
                (item) =>
                    ({
                        id: item.id,
                        name: item.name,
                        quantity: item.quantity,
                        price: getProductPrice(item),
                    } as OrderCreateProdcutProps),
            ),
        }
        forceMenuClose()
        createOrder(reqeustProducts, {
            onSuccess: (res) => {
                navigate(checkoutUrls.pages.checkout, res.id)
            },
        })
    }

    const rows = cart.items.map((item) => {
        const product_name = isMobile ? (
            <EvtBox>
                <EvtTypography className="product-name">{item.name}</EvtTypography>
                {item.isPhysical && <AusPostIcon sx={{ width: '35px' }} />}
            </EvtBox>
        ) : (
            <EvtTypography className="product-name">{item.name}</EvtTypography>
        )

        const row = [
            <EvtAvatar alt={item.name} src={item.cardImageUrl} />,
            product_name,
            <QtyInputField product={item} />,
            <Price product={item} />,
            <EvtIconButton onClick={() => removeFromCart(item)}>
                <DeleteOutlineIcon sx={{ color: (theme) => theme.palette.text.primary }} />
            </EvtIconButton>,
        ]
        if (isMobile) {
            row.shift()
        }
        return row
    })

    const header = isMobile ? ['Product', 'Qty', 'Price', ''] : ['Product', '', 'Qty', 'Price', '']

    return (
        <MenuUI
            anchorEl={anchorEl}
            id={menuId}
            BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.5)' } }}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{ padding: '20px' }}
        >
            <EvtBox sx={containerStyle}>
                {rows.length > 0 ? (
                    <>
                        <EvtTypography>Shopping Cart</EvtTypography>
                        <EvtTable
                            header={header}
                            rows={rows}
                            paddingSize="small"
                            sx={isMobile ? mobileTableStyles : desktopTableStyles}
                        />
                        <EvtBox className="button-wrapper" width="100%">
                            <EvtButtonPill
                                variant="outlined"
                                to={cartUrls.pages.cart}
                                sx={{
                                    ...buttonStyle(),
                                    width: '100%',
                                    borderColor: theme.palette.btn.pill.border,
                                    color: theme.palette.text.primary,
                                }}
                                onClick={() => {
                                    forceMenuClose()
                                }}
                            >
                                View&nbsp;Cart
                            </EvtButtonPill>
                            <EvtButtonPill sx={buttonStyle} onClick={handleCheckout}>
                                Checkout
                            </EvtButtonPill>
                        </EvtBox>
                    </>
                ) : (
                    <EmptyCart forceMenuClose={forceMenuClose} />
                )}
            </EvtBox>
        </MenuUI>
    )
}

export default CartMenu

const tableSharedStyles = (theme: Theme) => ({
    th: {
        backgroundColor: `${theme.palette.menu.headerBg} !important`,
        color: `${theme.palette.menu.headerTxt} !important`,
        fontWeight: 'bold',
    },
    'th:nth-of-type(2)': {
        textAlign: 'center',
    },
    'th:nth-of-type(3)': {
        textAlign: 'center',
    },
    '.product-name': {
        fontSize: 'small',
    },
})

const desktopTableStyles = (theme: Theme) => ({
    ...tableSharedStyles(theme),
    tr: {
        td: {
            padding: '20px 8px',
        },
        'td:nth-of-type(2)': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '100px',
            maxWidth: '160px',
        },
        'td:nth-of-type(3)': { width: '100px', textAlign: 'center' },
    },
})

const mobileTableStyles = (theme: Theme) => ({
    ...tableSharedStyles(theme),
    tr: {
        td: {
            padding: '20px 4px',
        },
        'td:nth-of-type(1)': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '100px',
            maxWidth: '160px',
        },
        'td:nth-of-type(2)': {
            width: '100px',
            textAlign: 'center',
            '>div': { minWidth: '80px', '.qty-plus': { marginLeft: '4px' } },
        },
        'td:nth-of-type(3)': { textAlign: 'center' },
        'td:nth-of-type(4)': { button: { padding: 0 } },
    },
})

const Price: FC<{ product: Product }> = ({ product }) => {
    const price = getProductPrice(product)
    return <EvtTypography>{EVT.toCurrency(price)}</EvtTypography>
}

const EmptyCart: FC<{ forceMenuClose: () => void }> = ({ forceMenuClose }) => {
    const navigate = useEvtNavigate()
    return (
        <>
            <EvtTypography>Cart is empty, please select some procuts.</EvtTypography>
            <EvtButtonPill
                sx={{ ...buttonStyle(), width: '60%' }}
                onClick={() => {
                    navigate(productUrls.pages.index)
                    forceMenuClose()
                }}
            >
                Shopping
            </EvtButtonPill>
        </>
    )
}

const buttonStyle = () => ({
    padding: '10px',
    fontWeight: 'bold',
})

const containerStyle = () => ({
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '200px',
    padding: '20px',
    '.button-wrapper': {
        display: 'flex',
        gap: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        ' > *': {
            width: '40%',
        },
    },
})

const getProductPrice = (product: Product) => {
    const threshEnum = Enumerable.from(product.thresholds || [])
    if (threshEnum.any()) {
        const threshold = threshEnum
            .where((e) => e.limit <= product.quantity)
            .orderByDescending((p) => p.limit)
            .firstOrDefault()
        if (threshold) {
            return threshold.unitPrice
        }
    }

    return product.price
}
