import jwt_decode from 'jwt-decode'
import userUrls from '../urls/userUrls'
import { EVT } from './evt'
import { storage } from './storage'

export const token_key = '_evt_user'

export const evtuser = {
    get: async <LoggedInUser>() => {
        return new Promise<LoggedInUser | null>((res, rej) => {
            evtuser
                .getKey()
                .then((jwt) => {
                    if (jwt) {
                        const retVal = jwt_decode<LoggedInUser>(jwt)
                        res(retVal)
                    } else {
                        res(null)
                    }
                })
                .catch(() => res(null))
        })
    },

    getKey: async () => {
        return new Promise<string | null>((res, rej) => {
            let jwt = storage.get(token_key)
            if (!jwt) {
                EVT.get<UserLoginResponse>(userUrls.api.refresh)
                    .then((r) => {
                        if (r.access_token) {
                            storage.set(token_key, r.access_token, 30)
                            res(r.access_token)
                        }
                    })
                    .catch(() => {
                        res(null)
                    })
            } else {
                res(jwt)
            }
        })
    },

    logout: () => {
        return EVT.get(userUrls.api.logout).finally(() => storage.remove(token_key))
    },
}

export type UserLoginResponse = {
    access_token: string
}

export type UserLoginRequest = {
    username: string
    password: string
}
