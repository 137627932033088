import { useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Theme, useTheme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import EvtFade from '../elements/EvtFade'
import EvtIconButton from '../elements/EvtIconButton'
import { GetMessageAlertStateContext, useMessageAlert } from '../providers/MessageAlertProvider'

const MessageAlert = () => {
    const theme = useTheme()
    const messageAlert = useMessageAlert()
    const message = useContext(GetMessageAlertStateContext)

    const onClose = () => {
        messageAlert(undefined)
    }

    return (
        <>
            <EvtBox
                className="evt-alert-message-wrapper"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    zIndex: 9999,
                    top: theme.navbar.topNav.topNavHeight,
                }}
            >
                <EvtFade in={message !== undefined}>
                    <Alert
                        variant="filled"
                        severity={message?.severity || 'success'}
                        sx={(t) => alertStyle(t, message !== undefined)}
                        action={
                            <EvtIconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                                <CloseIcon fontSize="inherit" />
                            </EvtIconButton>
                        }
                    >
                        {message?.content}
                    </Alert>
                </EvtFade>
            </EvtBox>
        </>
    )
}

export default MessageAlert

const alertStyle = (theme: Theme, isShow: boolean) => ({
    width: 'fit-content',
    marginTop: '5px',
    display: isShow ? 'flex' : 'none',
})
