import { FC, ReactNode } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Theme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import EvtIconButton from '../elements/EvtIconButton'

interface Props {
    items: Array<ReactNode>
    renderDotsOutside?: boolean
    infinite?: boolean
    itemWidth?: number
    itemGap?: number
    parentWidthRatio?: number
    isArrowOutside?: boolean
    itemPaddingBottom?: boolean
}

// parentWidthRatio is for the parent width percentage, because responsive porp is based on window size

const CarouselUI: FC<Props> = ({
    infinite = true,
    renderDotsOutside = false,
    items,
    itemWidth = 0,
    itemGap = 0,
    parentWidthRatio = 1,
    isArrowOutside = true,
    itemPaddingBottom = false,
}) => {
    const isItemFullWidth = itemWidth > 0 ? false : true
    const itemUnitSize = itemWidth + itemGap

    const calcBreakPointSize = (ratio: number) => {
        return (ratio * itemUnitSize) / parentWidthRatio + 70
    }
    let responsiveSettings = {} as { [key: string]: any }
    const numOfItems = items?.length || 0

    if (isItemFullWidth || numOfItems === 1) {
        responsiveSettings = {
            desktop: {
                breakpoint: { max: 5000, min: 0 },
                items: 1,
            },
        }
    } else {
        let ratio = 0
        const maxWindowWidth = 5000 / parentWidthRatio

        while (ratio * itemUnitSize <= maxWindowWidth) {
            if (ratio === numOfItems) {
                responsiveSettings[`width_last`] = {
                    breakpoint: {
                        max: maxWindowWidth + 71,
                        min: calcBreakPointSize(ratio),
                    },
                    items: numOfItems,
                }
                break
            }
            responsiveSettings[`width_${ratio}`] = {
                breakpoint: {
                    max: calcBreakPointSize(ratio + 1),
                    min: calcBreakPointSize(ratio),
                },
                items: ratio < numOfItems ? ratio : numOfItems,
            }
            ratio++
        }
    }

    return items.length > 0 ? (
        <EvtBox className="carousel-wrapper" sx={(t) => carouselWapperStyle(t, renderDotsOutside, isArrowOutside)}>
            <Carousel
                ssr
                responsive={responsiveSettings}
                dotListClass="carousel-dot-list"
                infinite={infinite}
                renderDotsOutside={renderDotsOutside}
                showDots={true}
                //@ts-ignore
                customButtonGroup={<ButtonGroup isArrowOutside={isArrowOutside} />}
                renderButtonGroupOutside={true}
                arrows={false}
            >
                {items.map((item, index) => (
                    <EvtBox
                        className="carousel-item-wrapper"
                        key={index}
                        sx={(t) => carouselItemWrapper(t, itemPaddingBottom)}
                    >
                        {item}
                    </EvtBox>
                ))}
            </Carousel>
        </EvtBox>
    ) : (
        <></>
    )
}

export default CarouselUI

const carouselWapperStyle = (theme: Theme, renderDotsOutside: boolean, isArrowOutside: boolean) => ({
    position: 'relative',
    '.carousel-dot-list': {
        position: renderDotsOutside ? 'relative' : 'absolute',
        top: renderDotsOutside ? '16px' : 'unset',
        bottom: renderDotsOutside ? 'unset' : '16px',
        gap: theme.spacing(5),
        flexWrap: 'wrap',
        button: {
            width: theme.spacing(10),
            height: theme.spacing(1),
            borderRadius: theme.spacing(1),
            border: 'none',
            marginRight: 0,
            backgroundColor: theme.palette.carouse.indicator.primary,
        },
        '.react-multi-carousel-dot--active': {
            button: {
                backgroundColor: theme.palette.carouse.indicator.secondary,
            },
        },
    },
    '.react-multi-carousel-list': {
        width: isArrowOutside ? 'calc(100% - 46px)' : '100%',
        left: isArrowOutside ? '23px' : '0',
    },
})

// @ts-ignore
const ButtonGroup = ({ next, previous, goToSlide, isArrowOutside, ...rest }) => {
    const {
        carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest
    const isHideLeftArrow = currentSlide === 0 || slidesToShow >= totalItems
    const isHideRightArrow = totalItems - slidesToShow === currentSlide

    return (
        <EvtBox sx={(t) => arrowWrapperStyle(t, isHideLeftArrow, isHideRightArrow, isArrowOutside)}>
            <EvtIconButton className="arrow-button left-arrow" onClick={previous}>
                <ArrowBackIosIcon
                    sx={{
                        transform: 'translateX(4px)',
                        fontSize: 'large',
                    }}
                />
            </EvtIconButton>
            <EvtIconButton className="arrow-button right-arrow" onClick={next}>
                <ArrowForwardIosIcon
                    sx={{
                        fontSize: 'large',
                    }}
                />
            </EvtIconButton>
        </EvtBox>
    )
}

const arrowWrapperStyle = (
    theme: Theme,
    isHideLeftArrow: boolean,
    isHideRightArrow: boolean,
    isArrowOutside: boolean,
) => ({
    display: 'flex',
    position: 'absolute',
    // 22px half of arrow button height
    // 20px half of bottom padding height
    top: 'calc(50% - 22px - 20px)',
    width: 'calc(100% - 13px)',
    justifyContent: 'space-between',
    marginLeft: '7px',
    '.arrow-button': {
        background: theme.palette.carousel.navBtn.bgColor,
        border: '1px solid #999999',
        minWidth: `${theme.carousel.arrowBtn.width}px`,
        minHeight: `${theme.carousel.arrowBtn.width}px`,
    },
    '.left-arrow': {
        visibility: isHideLeftArrow ? 'hidden' : 'unset',
        left: isArrowOutside ? '0' : '4%',
    },
    '.right-arrow': {
        visibility: isHideRightArrow ? 'hidden' : 'unset',
        right: isArrowOutside ? '0' : '4%',
    },
})

const carouselItemWrapper = (theme: Theme, itemPaddingBottom: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    paddingBottom: itemPaddingBottom ? '10px' : '0',
    width: 'calc(100% - 1px)',
})
