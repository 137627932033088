import { Theme, useTheme } from '@mui/material'
import * as yup from 'yup'
import FormComponent from '../../components/FormComponent'
import EvtBox from '../../elements/EvtBox'
import { EvtSubmitButton } from '../../elements/EvtButton'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtLink from '../../elements/EvtLink'
import EvtTypography from '../../elements/EvtTypography'
import { useAlert } from '../../providers/modals/AlertModalProvider'
import { useModal } from '../../providers/modals/GlobalModalProvider'
import { useForgottenPassword, useLoginUser } from '../../query/users'
import { evtValidator } from '../../utils/validation'

interface FormProps {
    username: string
    password: string
}

const INITIAL_FORM_STATE: FormProps = {
    username: '',
    password: '',
}

const FORM_VALIDATION = evtValidator(INITIAL_FORM_STATE, { password: yup.string().required('Required') })
const SignInForm = () => {
    const alert = useAlert()
    const { mutate: loginUser } = useLoginUser((error, qc, request: FormProps) => {
        if (error.response.data.errorCode === 1001) {
            alert({
                message: error.response.data.message,
                confirm: true,
                title: 'Alert',
                okButtonText: 'Yes',
                cancelButtonText: 'No',
                onOk: () => {
                    loginUser({
                        ...request,
                        forceCorporate: true,
                    })
                },
            })

            return true
        }
    })
    const { openModal } = useModal()

    const onSubmit = (values: FormProps) => {
        loginUser(values)
    }
    const showForgotPassword = () => {
        openModal({
            content: <ForgotPasswordForm />,
        })
    }

    return (
        <>
            <FormComponent
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={onSubmit}
                submitOnEnter
            >
                <EvtBox sx={wrapperStyle}>
                    <EvtTypography fontSize="25px">Sign In</EvtTypography>
                    <EvtFormInputField label="Username" name="username" isRoundBorder />
                    <EvtFormInputField label="Password" name="password" isRoundBorder type="password" />
                    <EvtSubmitButton variant="contained" fullWidth sx={{ borderRadius: '35px', height: '56px' }}>
                        Sign In
                    </EvtSubmitButton>
                    <EvtBox display="flex" alignItems="center" justifyContent="flex-end" width="100%">
                        <EvtLink sx={{ cursor: 'pointer' }} onClick={showForgotPassword}>
                            <EvtTypography fontSize="0.9rem">Forgot password?</EvtTypography>
                        </EvtLink>
                    </EvtBox>
                </EvtBox>
            </FormComponent>
        </>
    )
}

export default SignInForm

const ForgotPasswordForm = () => {
    const theme = useTheme()
    const alert = useAlert()
    const { forceClose } = useModal()
    const { mutate: forgottenPassword } = useForgottenPassword(() => {
        alert({
            message: 'If the details provided match, you will receive an email with reset instructions',
            onOk: () => {
                forceClose()
            },
        })
    })

    const onSubmit = (values: { email: string }) => {
        forgottenPassword({ forgottenEmail: values.email })
    }

    return (
        <FormComponent initialValues={{ email: '' }} validationSchema={evtValidator({ email: '' })} onSubmit={onSubmit}>
            <EvtBox display="flex" flexDirection="column" alignItems="center" gap="30px">
                <EvtTypography align="center">Please enter your email to reveive a password reset email</EvtTypography>
                <EvtFormInputField
                    label="Email"
                    name="email"
                    bgColor={theme.palette.modal.inputBgColor}
                    isRoundBorder
                />

                <EvtSubmitButton size="large" variant="contained" sx={{ borderRadius: '25px' }}>
                    Send
                </EvtSubmitButton>
            </EvtBox>
        </FormComponent>
    )
}

const wrapperStyle = (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    gap: '20px',
    width: '30%',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: '40%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: '50%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: '80%',
    },
})
