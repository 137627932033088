import { useContext } from 'react'
import EvtBox from '../elements/EvtBox'
import EvtTypography from '../elements/EvtTypography'
import { AnimationMaskValue, GetAnimationMaskStateContext } from '../providers/AnimationMaskProvider'
import { CrossAnimation, TickAnimation } from './lottie/TickAnimation'

const AnimationMask = () => {
    const animation = useContext(GetAnimationMaskStateContext)
    let actualAnimation = animation?.item
    if (actualAnimation === AnimationMaskValue.error) {
        actualAnimation = <CrossAnimation width={50} height={50} />
    } else if (actualAnimation === AnimationMaskValue.success) {
        actualAnimation = <TickAnimation width={50} height={50} />
    }

    return (
        <>
            {actualAnimation && (
                <EvtBox sx={spinnerStyle}>
                    {actualAnimation}
                    {animation?.text && <EvtTypography>{animation.text}</EvtTypography>}
                </EvtBox>
            )}
        </>
    )
}

export default AnimationMask

const spinnerStyle = () => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.6)',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '9999',
})
