import { createSearchParams, useNavigate } from 'react-router-dom'
import { hubPages } from '../consts'
import { PageURL } from '../urls'

export function useEvtNavigate() {
    const navigate = useNavigate()
    return (url: PageURL, id?: string) => {
        navigate(id ? url.idUrl(id) : url.url)
        window.scrollTo(0, 0)
    }
}

export function useEvtNavigateSearch() {
    const navigate = useNavigate()
    return (params: navigateParams) => {
        let pathname = params.id ? params.url.idUrl(params.id) : params.url.url

        if (params.search) {
            // @ts-ignore
            pathname += `${createSearchParams(params.search)}`
        }
        navigate(pathname)
    }
}

interface navigateParams {
    url: PageURL
    id?: string
    search?: { [key: string]: string | number }
    state?: any
}

export type HubPage = hubPages
