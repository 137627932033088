export interface API {
    url: string
    requiresAuth?: boolean
    method?: string
    roles?: Array<string>
    queryKey?: string
}

export class PageURL {
    public url: string = '/'
    public text?: string
    roles?: string[]
    idUrl(id: number | string | undefined) {
        return this.url.replace(':id', id?.toString() || '')
    }
}

export const createPageUrl = (obj: any): PageURL => {
    var retVal = new PageURL()
    Object.assign(retVal, obj)
    return retVal
}
