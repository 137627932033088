import { useContext } from 'react'
import EvtBox from '../elements/EvtBox'
import { EvtLoader } from '../elements/EvtLoader'
import { GetLoaderStateContext } from '../providers/LoaderProvider'

const LoaderSpinner = () => {
    const loaderCounts = useContext(GetLoaderStateContext)
    return (
        <>
            {loaderCounts > 0 && (
                <EvtBox sx={spinnerStyle}>
                    <EvtLoader loading={true}>
                        <></>
                    </EvtLoader>
                </EvtBox>
            )}
        </>
    )
}

export default LoaderSpinner

const spinnerStyle = () => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.6)',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '9999',
})
