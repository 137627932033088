import { FC, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Theme } from '@mui/material'
import StepperHeader from '../../components/StepperHeader'
import EvtBox from '../../elements/EvtBox'
import EvtGrid from '../../elements/EvtGrid'
import { EvtTitleTypography } from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { eVoucherImage } from '../../query/images'
import { AssignOrderRequest, useAssignOrder, useOrder } from '../../query/orders'
import { Order, OrderProduct } from '../../types/orders/Order'
import orderUrls from '../../urls/orderUrls'
import { EVT } from '../../utils/evt'
import AssignAddMessage, { Message, defaultMessage } from './AssignAddMessage'
import AssignRecipient, { Recipient } from './AssignRecipient'
import AssignSelectImage from './AssignSelectImage'
import AssignSelectProduct from './AssignSelectProduct'
import AssignSend from './AssignSend'
import MessagePreview from './MessagePreview'
import { RecipientCSV } from './assignTypes/AssignAddCSV'
import { RecipientManual } from './assignTypes/AssignManual'
import { RecipientMyself } from './assignTypes/AssignMyself'

export interface STEP {
    text: string
    index: number
    id: string
}
export interface RecipientSectionDataProps {
    csv: RecipientCSV
    manual: RecipientManual
    myself: RecipientMyself
}
const STEP_SELECT_PRODUCT: STEP = { text: 'Select Product', index: 0, id: 'product-section' }
const STEP_SELECT_IMAGE: STEP = { text: 'Select Image', index: 1, id: 'image-section' }
const STEP_ADD_MESSAGE: STEP = { text: 'Add Message', index: 2, id: 'message-section' }
const STEP_ASSIGN_RECIPIENT: STEP = { text: 'Assign Recipients', index: 3, id: 'recipient-section' }
const STEP_SEND_PRODUCT: STEP = { text: 'Send Product', index: 4, id: 'send-section' }
const STEPS = [STEP_SELECT_PRODUCT.text, STEP_SELECT_IMAGE.text, STEP_ADD_MESSAGE.text, STEP_ASSIGN_RECIPIENT.text]

const Assign = () => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const navigate = useEvtNavigate()
    const [searchParams] = useSearchParams()
    const orderProductId = searchParams.get('orderProductId')

    const { id } = useParams()
    const { data: order } = useOrder(id)
    const { mutate: assignOrder } = useAssignOrder()
    const [activeStep, setActiveStep] = useState(STEP_SELECT_PRODUCT.index)
    const [selectProduct, setSelectedProduct] = useState<OrderProduct | null>(null)
    const [selectImage, setSelectImage] = useState<eVoucherImage | null>(null)
    const [message, setMessage] = useState<Message>(defaultMessage)
    const [recipientSectionData, setRecipientSectionData] = useState<RecipientSectionDataProps>({
        csv: { value: { file: null, recipients: [] }, isActive: false },
        manual: { value: [], isActive: false },
        myself: { value: [], isActive: false },
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (order && orderProductId) {
            setSelectedProduct(order.products.filter((p) => p.id === orderProductId)[0])
            setActiveStep(STEP_SELECT_IMAGE.index)
        }
    }, [order, orderProductId])

    const handleBack = (step: number) => {
        recipientSectionData.csv.isActive = false
        recipientSectionData.manual.isActive = false
        recipientSectionData.myself.isActive = false
        setRecipientSectionData(recipientSectionData)
        if (activeStep === STEP_SEND_PRODUCT.index) {
            STEPS.pop()
        }
        setActiveStep(step)
    }

    const handleNext = (value: any) => {
        let element: HTMLElement | undefined
        switch (activeStep) {
            case STEP_SELECT_PRODUCT.index: {
                setSelectedProduct(value)
                element = document.getElementById(STEP_SELECT_IMAGE.id) as HTMLElement
                break
            }
            case STEP_SELECT_IMAGE.index: {
                setSelectImage(value)
                element = document.getElementById(STEP_ADD_MESSAGE.id) as HTMLElement
                break
            }
            case STEP_ADD_MESSAGE.index: {
                setMessage(value)
                element = document.getElementById(STEP_ASSIGN_RECIPIENT.id) as HTMLElement
                break
            }
            case STEP_ASSIGN_RECIPIENT.index: {
                const data = value as RecipientSectionDataProps
                setRecipientSectionData(data)

                if (data.csv.isActive || data.manual.isActive) {
                    STEPS.push(STEP_SEND_PRODUCT.text)
                    element = document.getElementById(STEP_SEND_PRODUCT.id) as HTMLElement
                } else {
                    handleSend(data.myself.value)
                }
                break
            }
        }

        element && element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        setActiveStep(activeStep + 1)
    }

    const handleChange = (value: any) => {
        switch (activeStep) {
            case STEP_SELECT_PRODUCT.index: {
                setSelectedProduct(value)
                break
            }
            case STEP_SELECT_IMAGE.index: {
                setSelectImage(value)
                break
            }
            case STEP_ADD_MESSAGE.index: {
                setMessage(value)
                break
            }
            case STEP_ASSIGN_RECIPIENT.index: {
                setRecipientSectionData(value)
                break
            }
        }
    }

    const handleSend = (recipients: Array<Recipient>) => {
        const assignOrderReqeust: AssignOrderRequest = {
            recipients: recipients,
            orderProductId: selectProduct?.id || '',
            from: message?.from,
            greeting: message?.greeting,
            message: message?.message,
            bannerImageUrl: selectImage?.imageUrl,
        }
        assignOrder(assignOrderReqeust, {
            onSuccess: (o) => navigate(orderUrls.pages.details, o.orderId),
        })
    }

    return (
        <EvtBox>
            <StepperHeader stepNames={STEPS} activeStepIndex={activeStep} />
            <EvtBox sx={containerStyle}>
                <EvtTitleTypography sx={{ paddingBottom: '40px' }}>Assign Product</EvtTitleTypography>
                {isDesktop ? (
                    <AssignDesktop
                        activeStep={activeStep}
                        message={message}
                        order={order}
                        selectProduct={selectProduct}
                        selectImage={selectImage}
                        recipientSectionData={recipientSectionData}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSend={handleSend}
                        handleChange={handleChange}
                    />
                ) : (
                    <AssignMobile
                        activeStep={activeStep}
                        message={message}
                        order={order}
                        selectProduct={selectProduct}
                        selectImage={selectImage}
                        recipientSectionData={recipientSectionData}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSend={handleSend}
                        handleChange={handleChange}
                    />
                )}
            </EvtBox>
        </EvtBox>
    )
}

export default Assign

interface AssignProps {
    activeStep: number
    message: Message
    order: Order | undefined
    selectProduct: OrderProduct | null
    selectImage: eVoucherImage | null
    recipientSectionData: RecipientSectionDataProps
    handleNext: (value: any) => void
    handleBack: (step: number) => void
    handleSend: (recipients: Array<Recipient>) => void
    handleChange: (value: any) => void
}

const AssignDesktop: FC<AssignProps> = ({
    activeStep,
    message,
    order,
    selectProduct,
    selectImage,
    recipientSectionData,
    handleNext,
    handleBack,
    handleSend,
    handleChange,
}) => {
    return (
        <EvtBox display="flex" flexDirection="column" gap="80px" pb="40px">
            <EvtGrid container spacing="60px" sx={{ scrollBehavior: 'smooth' }}>
                <EvtGrid item lg={7} xl={8} display="flex" flexDirection="column" gap="80px">
                    <AssignSelectProduct
                        selectProduct={selectProduct}
                        order={order}
                        activeStepIndex={activeStep}
                        productStep={STEP_SELECT_PRODUCT}
                        onNext={handleNext}
                        onBack={() => handleBack(STEP_SELECT_PRODUCT.index)}
                        onChange={handleChange}
                    />
                    <AssignSelectImage
                        activeStepIndex={activeStep}
                        imageStep={STEP_SELECT_IMAGE}
                        image={selectImage}
                        onNext={handleNext}
                        onBack={() => handleBack(STEP_SELECT_IMAGE.index)}
                        onChange={handleChange}
                    />
                    <AssignAddMessage
                        updatedFormValues={message}
                        activeStepIndex={activeStep}
                        messageStep={STEP_ADD_MESSAGE}
                        onNext={handleNext}
                        onBack={() => handleBack(STEP_ADD_MESSAGE.index)}
                        onChange={handleChange}
                    />
                    <AssignRecipient
                        recipientSectionData={recipientSectionData}
                        remaining={(selectProduct?.quantity || 0) - (selectProduct?.totalSent || 0)}
                        activeStepIndex={activeStep}
                        recipientStep={STEP_ASSIGN_RECIPIENT}
                        onNext={handleNext}
                        onBack={() => handleBack(STEP_ASSIGN_RECIPIENT.index)}
                        onChange={handleChange}
                    />

                    {(recipientSectionData.csv.isActive || recipientSectionData.manual.isActive) && (
                        <AssignSend
                            product={selectProduct}
                            recipientSectionData={recipientSectionData}
                            activeStepIndex={activeStep}
                            sendStep={STEP_SEND_PRODUCT}
                            onNext={handleSend}
                            onBack={() => handleBack(STEP_SEND_PRODUCT.index)}
                        />
                    )}
                </EvtGrid>

                <EvtGrid item lg={5} xl={4}>
                    <MessagePreview message={message} product={selectProduct} image={selectImage} />
                </EvtGrid>
            </EvtGrid>
        </EvtBox>
    )
}

const AssignMobile: FC<AssignProps> = ({
    activeStep,
    message,
    order,
    selectProduct,
    selectImage,
    recipientSectionData,
    handleNext,
    handleBack,
    handleSend,
    handleChange,
}) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    return (
        <EvtBox display="flex" flexDirection="column" gap="60px" pb="40px" pt={isMobile ? '40px' : '10px'}>
            <AssignSelectProduct
                selectProduct={selectProduct}
                order={order}
                activeStepIndex={activeStep}
                productStep={STEP_SELECT_PRODUCT}
                onNext={handleNext}
                onBack={() => handleBack(STEP_SELECT_PRODUCT.index)}
                onChange={handleChange}
            />
            <AssignSelectImage
                activeStepIndex={activeStep}
                imageStep={STEP_SELECT_IMAGE}
                image={selectImage}
                onNext={handleNext}
                onBack={() => handleBack(STEP_SELECT_IMAGE.index)}
                onChange={handleChange}
            />
            <AssignAddMessage
                updatedFormValues={message}
                activeStepIndex={activeStep}
                messageStep={STEP_ADD_MESSAGE}
                onNext={handleNext}
                onBack={() => handleBack(STEP_ADD_MESSAGE.index)}
                onChange={handleChange}
            />
            <AssignRecipient
                recipientSectionData={recipientSectionData}
                remaining={(selectProduct?.quantity || 0) - (selectProduct?.totalSent || 0)}
                activeStepIndex={activeStep}
                recipientStep={STEP_ASSIGN_RECIPIENT}
                onNext={handleNext}
                onBack={() => handleBack(STEP_ASSIGN_RECIPIENT.index)}
                onChange={handleChange}
            />
            {(recipientSectionData.csv.isActive || recipientSectionData.manual.isActive) && (
                <AssignSend
                    product={selectProduct}
                    recipientSectionData={recipientSectionData}
                    activeStepIndex={activeStep}
                    sendStep={STEP_SEND_PRODUCT}
                    onNext={handleSend}
                    onBack={() => handleBack(STEP_SEND_PRODUCT.index)}
                />
            )}

            <EvtBox display="flex" justifyContent="center">
                <MessagePreview message={message} product={selectProduct} image={selectImage} />
            </EvtBox>
        </EvtBox>
    )
}

const containerStyle = (theme: Theme) => ({
    paddingBottom: '30px',
    [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        paddingTop: '100px',
        paddingLeft: '100px',
        paddingRight: '100px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
        paddingTop: '100px',
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        paddingTop: '150px',
    },
})
