import { FC } from 'react'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import { Theme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import { EvtButton } from '../elements/EvtButton'
import EvtDivider from '../elements/EvtDivider'
import EvtTypography, { EvtTitleTypography } from '../elements/EvtTypography'
import { EvoucherItem } from '../query/eVoucher'
import { EVT } from '../utils/evt'

interface Props {
    description?: string
    name?: string | undefined
    youHaveReceived?: string | undefined
    sender: string | undefined
    greeting: string | undefined
    message: string | undefined
    bannerImageUrl: string | undefined
    items: Array<EvoucherItem> | undefined
    isShowButton?: boolean
}

const EVoucherTemplate: FC<Props> = ({
    description,
    name,
    sender,
    greeting,
    message,
    bannerImageUrl,
    items,
    isShowButton = true,
    youHaveReceived,
}) => {
    return (
        <EvtBox sx={mainStyles} className="evt-evoucher-template">
            <EvtBox className="header">
                <EvtTitleTypography color="white">EVENT | CORPORATE</EvtTitleTypography>
            </EvtBox>

            <EvtBox sx={{ width: '100%', height: '200px' }}>
                {bannerImageUrl ? (
                    <img
                        src={bannerImageUrl}
                        alt="preview"
                        loading="lazy"
                        style={{ width: '100%', height: '100%', objectFit: 'fill' }}
                    />
                ) : (
                    <EvtTypography>Please select an image</EvtTypography>
                )}
            </EvtBox>

            <EvtBox className="receiver-name-wrapper">
                <EvtBox className="name-wrapper">
                    <EvtTypography className="greeting">{greeting}</EvtTypography>
                    {name && <EvtTypography className="name">{name}</EvtTypography>}
                </EvtBox>
                <EvtBox className="message-wrapper">
                    <EvtTypography className="message">{message}</EvtTypography>
                </EvtBox>
                {youHaveReceived && (
                    <EvtBox className="addtional-info">
                        <EvtTypography fontSize="15px" className="title">
                            You have received
                        </EvtTypography>
                        <EvtBox className="info">{EVT.html(youHaveReceived)}</EvtBox>
                    </EvtBox>
                )}
            </EvtBox>

            {items?.map((item, index) => (
                <VoucherItem item={item} sender={sender} key={index} />
            ))}

            <EvtBox className="more-info">
                <RedeemInfo />

                <EvtBox className="description">
                    {description ? (
                        EVT.html(description)
                    ) : (
                        <EvtTypography color="inherit">Terms & Conditions</EvtTypography>
                    )}
                    {isShowButton && (
                        <EvtButton
                            sx={{ mt: '20px' }}
                            className="book-btn"
                            variant="contained"
                            onClick={() => {
                                window.open(process.env.REACT_APP_CINEMA_URL, '_blank')
                            }}
                        >
                            Book Tickets Now
                        </EvtButton>
                    )}
                </EvtBox>
            </EvtBox>

            <EvtBox className="footer">
                <EvtTypography fontSize="12px">{process.env.REACT_APP_CINEMA_NAME}</EvtTypography>
                <EvtTypography fontSize="15px">EVENT</EvtTypography>
            </EvtBox>
        </EvtBox>
    )
}

export default EVoucherTemplate

const VoucherItem: FC<{ item: EvoucherItem; sender: string | undefined }> = ({ item, sender }) => (
    <EvtBox sx={(t) => voucherItemStyles(t)}>
        <EvtBox className="qr-code-wrapper">
            <img
                src={
                    item.barcodeUrl
                        ? item.barcodeUrl
                        : 'https://cdn.eventcinemas.com.au/cdn/resources/contentupload/giftshop/corporate/qr/60c14a43fb4745795b3b358868517e79.png'
                }
                alt="QR Code"
            />
            <EvtTypography>{item.barcode || '********'}</EvtTypography>
        </EvtBox>

        <EvtBox className="content-wrapper">
            <EvtTypography fontSize="18px" fontWeight="600">
                {item.productName}
            </EvtTypography>
            <EvtTypography fontSize="18px" fontWeight="600">
                From {sender}
            </EvtTypography>
            <EvtTypography fontSize="13px">
                Expiry: {item.expiry ? EVT.format(item.expiry, 'DD/MM/YYYY') : 'DD/MM/YYYY'}
            </EvtTypography>
            <EvtTypography fontSize="15px">
                PIN: <span className="pin">{item.pin || '*****'}</span>
            </EvtTypography>
        </EvtBox>
    </EvtBox>
)

const RedeemInfo = () => (
    <EvtBox sx={redeemInfoStyles}>
        <EvtTypography className="title">How to redeem your voucher</EvtTypography>

        <EvtBox className="content-wrapper">
            <EvtBox className="content-item">
                <LocalPrintshopIcon />
                <EvtTypography>Print this eVoucher and take it with you to the Cinema</EvtTypography>
            </EvtBox>

            <EvtBox className="divier">
                <EvtDivider orientation="vertical" />
            </EvtBox>

            <EvtBox className="content-item">
                <PhoneIphoneIcon />
                <EvtTypography>Save this to you Smartphone and show it at the Cinema to be scanned</EvtTypography>
            </EvtBox>
        </EvtBox>
    </EvtBox>
)

const mainStyles = (theme: Theme) => {
    const {
        palette,
        palette: { evoucherTemplate: evoTheme },
    } = theme

    return {
        width: '500px',
        backgroundColor: evoTheme.bgColor,
        borderRadius: '20px 20px 0 0',
        display: 'flex',
        flexDirection: 'column',
        border: `solid 1px ${palette.border.secondary}`,
        '.header': {
            borderRadius: '20px 20px 0 0',
            bgcolor: evoTheme.headerBg,
            paddingY: '10px',
            '> p': {
                fontSize: '25px',
            },
        },
        '.receiver-name-wrapper': {
            padding: '15px',
            borderBottom: `1px dashed ${palette.border.primary}`,

            '.addtional-info': {
                margin: '10px 0',
                '.title': {
                    fontSize: '18px',
                    fontWeight: 600,
                    color: `${evoTheme.txt.primary} !important`,
                },
                '.info': {
                    ul: {
                        whiteSpace: 'unset !important',
                        textAlign: 'left',
                        color: `${evoTheme.txt.primary} !important`,
                    },
                },
            },
            '.name-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                '> p': {
                    color: evoTheme.txt.primary,
                },
                '> .greeting': {
                    fontSize: '20px',
                },
                '> .name': {
                    fontSize: '25px',
                    fontWeight: '600',
                },
            },
            '.message-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                '> p': {
                    color: evoTheme.txt.primary,
                },
                '> .message': {
                    padding: '10px 10px',
                    fontSize: '15px',
                },
            },
        },
        '.more-info': {
            padding: '30px 20px',
            '.description': {
                fontSize: '11px',
                padding: '20px 10px',
                color: evoTheme.txt.secondary,
            },
            '.book-btn': { padding: '15px 35px', borderRadius: '30px' },
        },
        '.footer': {
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `solid 1px ${palette.border.primary}`,
            '> p': {
                color: evoTheme.txt.primary,
            },
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: '400px',
            '.receiver-name-wrapper': {
                '.name-wrapper': {
                    '> .greeting': {
                        fontSize: '17px',
                    },
                    '> .name': {
                        fontSize: '21px',
                    },
                },
            },
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: '300px',
            '.more-info': {
                padding: '30px 10px',
                '.description': {
                    padding: '20px 10px',
                },
            },
        },
    }
}

const voucherItemStyles = (theme: Theme) => {
    const {
        palette,
        palette: { evoucherTemplate: evoTheme },
    } = theme
    return {
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        borderBottom: `1px dashed ${palette.border.primary}`,
        '.qr-code-wrapper': {
            img: {
                width: '200px',
            },
            '> p': {
                color: evoTheme.txt.secondary,
                fontWeight: '600',
            },
        },
        '.content-wrapper': {
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            pt: '50px',
            '> p': {
                color: evoTheme.txt.primary,
            },
            '.pin': {
                color: palette.success.main,
            },
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            gap: '10px',
            '.qr-code-wrapper': {
                img: {
                    width: '150px',
                },
            },
            '.content-wrapper': {
                pt: '10px',
            },
        },
    }
}

const redeemInfoStyles = (theme: Theme) => {
    const {
        palette,
        palette: { evoucherTemplate: evoTheme },
    } = theme

    return {
        border: `solid 1px ${palette.border.primary}`,
        borderRadius: '10px',
        padding: '20px',
        '.title': {
            fontSize: '25px',
            color: evoTheme.txt.secondary,
        },
        '.content-wrapper': {
            display: 'flex',
            pt: '20px',
            '.content-item': {
                width: '48%',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                '> svg': { color: evoTheme.txt.secondary },
                '> p': { fontSize: '10px', textAlign: 'left', color: evoTheme.txt.secondary },
            },
            '.divier': {
                width: '4%',
                display: 'flex',
                justifyContent: 'center',
                '> hr': {
                    height: '100%',
                    borderColor: palette.border.default,
                },
            },
        },
    }
}
