import { FC } from 'react'
import { LinearProgress, useTheme } from '@mui/material'
import CircularProgress, { CircularProgressProps, circularProgressClasses } from '@mui/material/CircularProgress'
import EvtBox from '../elements/EvtBox'
import EvtTypography from '../elements/EvtTypography'

interface Props {
    percentage?: number
    current?: number
    total?: number
    maxNumberLength: number
}

const sizes = [40, 50, 60, 70]

export const ProgressWithLabel: FC<Props & CircularProgressProps> = ({
    percentage,
    current,
    total,
    maxNumberLength,
    ...props
}) => {
    const theme = useTheme()
    let display = ''
    if (percentage) {
        display = `${Math.round(percentage)}%`
    } else if (current !== undefined && total !== undefined) {
        display = `${current}/${total}`
        percentage = (100 * (current || 0)) / total
    }

    return (
        <EvtBox sx={outerBoxStyle}>
            {maxNumberLength > 3 ? (
                <EvtBox sx={{ width: 70, textAlign: 'center' }}>
                    <EvtTypography variant="caption">{display}</EvtTypography>
                    <LinearProgress variant="determinate" value={percentage} />
                </EvtBox>
            ) : (
                <>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: theme.palette.info.light,
                            zIndex: '1',
                        }}
                        thickness={4}
                        value={percentage}
                        size={sizes[maxNumberLength]}
                        {...props}
                    />

                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: theme.palette.process.bar,
                            position: 'absolute',
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        thickness={4}
                        value={100}
                        size={sizes[maxNumberLength]}
                        {...props}
                    />
                    <EvtBox sx={innerBoxStyle}>
                        <EvtTypography variant="caption" className="progress-text">
                            {display}
                        </EvtTypography>
                    </EvtBox>
                </>
            )}
        </EvtBox>
    )
}

const outerBoxStyle = { position: 'relative', display: 'inline-flex' }

const innerBoxStyle = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
