import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import FormComponent from '../../components/FormComponent'
import GoogleMapInput from '../../components/GoogleMapInput'
import StepContainerUI, { StepContinueButtonSubmit } from '../../components/StepContainerUI'
import { Countries, States, Titles, isAu } from '../../consts'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtFormSelect from '../../elements/EvtFormSelect'
import EvtGrid from '../../elements/EvtGrid'
import EvtPostcodeFormInput from '../../elements/EvtPostcodeFormInput'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { Address } from '../../types/orders/OrderCheckoutResponse'
import { EVT } from '../../utils/evt'
import { evtValidator } from '../../utils/validation'

interface Props {
    id: string
    steps: Array<{ text: string; step: number }>
    myStep: number
    activeStep: number
    shippingAddress: Address | undefined
    onNext: (values: Address) => void
    onBack: (step: number) => void
}

const INITAL_FORM_VALUES = {
    title: Titles[0].value,
    firstName: '',
    lastName: '',
    companyName: '',
    address1: '',
    address2: '',
    suburb: '',
    state: States[0].value,
    postcode: '',
    country: Countries[0].value,
    mobile: '',
}

export const ShippingAddressForm: FC<Props> = ({ id, steps, myStep, activeStep, shippingAddress, onNext, onBack }) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    const isDesktop = EVT.isDesktop(deviceType)
    const index = steps.findIndex((s) => s.step === myStep)
    const isActive = activeStep === myStep
    const isDone = activeStep > myStep
    const title = steps[index].text
    const nextTitle = steps[myStep + 1].text

    const formValidation = evtValidator(INITAL_FORM_VALUES)
    const [formValues, setFormValues] = useState<Address>(INITAL_FORM_VALUES)

    useEffect(() => {
        setFormValues(shippingAddress || INITAL_FORM_VALUES)
    }, [shippingAddress])

    return (
        <StepContainerUI
            id={id}
            index={index}
            title={title}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={isMobile ? 450 : isDesktop ? 350 : 400}
            onBack={() => onBack(myStep)}
        >
            {isDone && shippingAddress && <AddressDescription address={shippingAddress} />}
            <FormComponent
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={formValidation}
                onSubmit={onNext}
            >
                {isActive && (
                    <EvtGrid container spacing={5}>
                        <>
                            <>
                                <GridSelectField size={2} label="Title" name="title" options={Titles} />
                                <GridInputField size={5} label="First Name" name="firstName" />
                                <GridInputField size={5} label="Last Name" name="lastName" />
                            </>
                            <GridInputField size={6} label="Company Name" name="companyName" />
                            <GridInputField size={6} label="Phone number" name="mobile" />
                            <EvtGrid item xs={12}>
                                <GoogleMapInput
                                    label="Address"
                                    properties={{
                                        address: 'address1',
                                    }}
                                />
                            </EvtGrid>
                            <>
                                <GridInputField size={4} label="Suburb" name="suburb" />
                                {isAu ? (
                                    <GridSelectField size={4} label="State" name="state" options={States} />
                                ) : (
                                    <GridInputField size={4} label="City" name="city" />
                                )}
                                <GridSelectField size={4} label="Country" name="country" options={Countries} />
                            </>
                            <EvtGrid item xs={12} sm={3}>
                                <EvtPostcodeFormInput name="postcode" label="Postcode" />
                            </EvtGrid>
                            <br />
                        </>
                        <StepContinueButtonSubmit text={`Countinue to ${nextTitle}`} />
                    </EvtGrid>
                )}
            </FormComponent>
        </StepContainerUI>
    )
}

interface GridInputFieldProps {
    size: number
    name: string
    label: string
}

interface GridSelectFieldProps {
    size: number
    name: string
    label: string
    options: Array<{ value: string; text: string }>
}

const GridInputField: FC<GridInputFieldProps> = ({ size, name, label }) => {
    return (
        <EvtGrid item xs={12} sm={size}>
            <EvtFormInputField label={label} name={name} isRoundBorder />
        </EvtGrid>
    )
}

const GridSelectField: FC<GridSelectFieldProps> = ({ size, name, label, options }) => {
    return (
        <EvtGrid item xs={12} sm={size}>
            <EvtFormSelect options={options} name={name} label={label} fullWidth isRoundBorder isCustom={false} />
        </EvtGrid>
    )
}

const AddressDescription: FC<{ address: Address | undefined }> = ({ address }) => {
    const theme = useTheme()
    const textColor = theme.palette.stepContainerUI.border
    if (!address) return <></>
    const addy = [address.address1, address.suburb, `${address.state} ${address.country} ${address.postcode}`]
    const bob = addy.filter((a) => a).join(', ')
    return (
        <>
            <EvtTypography color={textColor.default}>{bob}</EvtTypography>
        </>
    )
}
