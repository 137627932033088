import React, { FC } from 'react'
import EvtBox from '../elements/EvtBox'
import { EvtButtonPill } from '../elements/EvtButton'
import EvtDivider from '../elements/EvtDivider'
import EvtTypography from '../elements/EvtTypography'
import { PageURL } from '../urls'

interface Props {
    text: string
    btnTxt: string
    btnLinkTo: PageURL
}

const TableEmptyContent: FC<Props> = ({ text, btnTxt, btnLinkTo }) => {
    return (
        <EvtBox>
            <EvtTypography paddingY="50px" sx={{ color: (theme) => theme.palette.text.secondary }}>
                {text}
            </EvtTypography>
            <EvtDivider sx={{ width: '100%', mb: '40px' }} />
            <EvtButtonPill sx={{ paddingX: '25px', paddingY: '15px' }} to={btnLinkTo}>
                {btnTxt}
            </EvtButtonPill>
        </EvtBox>
    )
}

export default TableEmptyContent
