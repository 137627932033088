import React, { FC, ReactNode, useState } from 'react'
import { Theme } from '@mui/material'
import { CrossAnimation, TickAnimation } from '../../components/lottie/TickAnimation'
import EvtBox from '../../elements/EvtBox'
import EvtDivider from '../../elements/EvtDivider'
import EvtTypography from '../../elements/EvtTypography'
import { EVT } from '../../utils/evt'

const SetStateContext = React.createContext((state: AlertModalOpenOptions | undefined | string) => {})
const GetAlertModalStateContext = React.createContext<AlertModalOpenOptions | undefined | string>('')

const AlertModalProvider = (props: { children: React.ReactNode }) => {
    const [modalSettings, setModalSettings] = useState<AlertModalOpenOptions | undefined | string>()

    return (
        <SetStateContext.Provider value={setModalSettings}>
            <GetAlertModalStateContext.Provider value={modalSettings}>
                {props.children}
            </GetAlertModalStateContext.Provider>
        </SetStateContext.Provider>
    )
}

const useAlert = () => {
    const context = React.useContext(SetStateContext)
    if (context === undefined) {
        throw new Error('useAlert must be used within a AlertModalProvider')
    }

    return context
}

const useSuccessAlert = () => {
    const alert = useAlert()

    return (message: string | AlertModalOpenOptions) => {
        let options: AlertModalOpenOptions = {
            message: '',
        }

        if (typeof message === 'string') {
            options.message = message
        } else if (typeof message === 'object') {
            Object.assign(options, message)
        }

        const title = options.title || 'Success'
        if (typeof title === 'string') {
            options.title = (
                <EvtTypography fontSize="20px" fontWeight="bold">
                    {title}
                </EvtTypography>
            )
        }

        options.message = <AlertModalBody animation={<TickAnimation />} message={options.message} />
        alert(options)
    }
}

const useErrorAlert = () => {
    const alert = useAlert()

    return (message: string | AlertModalOpenOptions) => {
        let options: AlertModalOpenOptions = {
            message: '',
        }

        if (typeof message === 'string') {
            options.message = message
        } else if (typeof message === 'object') {
            Object.assign(options, message)
        }

        const title = options.title || 'Notice'
        if (typeof title === 'string') {
            options.title = (
                <EvtTypography fontSize="20px" fontWeight="bold" color="error">
                    {title}
                </EvtTypography>
            )
        }

        options.message = <AlertModalBody animation={<CrossAnimation />} message={options.message} />
        alert(options)
    }
}

export { AlertModalProvider, GetAlertModalStateContext, useAlert, useErrorAlert, useSuccessAlert }

export type AlertModalOpenOptions = {
    message: string | React.ReactNode
    title?: string | React.ReactNode
    confirm?: boolean
    onClose?: () => void
    onOk?: () => void
    onCancel?: () => void
    okButtonText?: string
    cancelButtonText?: string
}

interface AlertModalBodyProps {
    message: ReactNode
    animation: ReactNode
}

const AlertModalBody: FC<AlertModalBodyProps> = ({ message, animation }) => {
    return (
        <EvtBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            maxWidth="300px"
            paddingX="10px"
            minWidth="200px"
        >
            <EvtDivider
                sx={{ width: '100%', marginBottom: '20px', borderColor: (t) => t.palette.modal.dividerColor }}
            />
            {animation}
            <EvtBox
                textAlign="center"
                pt="20px"
                sx={customHtmlWrapper}
                className={typeof message === 'string' ? 'custom-html' : ''}
            >
                {typeof message === 'string' ? EVT.html(message) : message}
            </EvtBox>
        </EvtBox>
    )
}

const customHtmlWrapper = (theme: Theme) => ({
    '&.custom-html': {
        a: {
            color: theme.palette.text.primary,
            textDecorationColor: 'rgba(25, 118, 210, 0.4) !important',
        },
    },
})
