import { useEffect, useState } from 'react'
import $ from 'jquery'

export default function useWindowResize() {
    const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
        width: $(document).width() as number,
        height: $(document).height() as number,
    })

    useEffect(() => {
        const onResize = () => {
            setWindowSize({ width: $(document).width() as number, height: $(document).height() as number })
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return windowSize
}
