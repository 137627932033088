import { useEffect } from 'react'
import { QueryClient, UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { useSuccessAlert } from '../providers/modals/AlertModalProvider'
import {
    Join,
    LoggedInUser,
    PasswordChangeRequest,
    PasswordRestRequest,
    UserDetail,
    UserLogin,
} from '../types/users/User'
import userUrls from '../urls/userUrls'
import { UserLoginResponse, evtuser, token_key } from '../utils/evtuser'
import { storage } from '../utils/storage'
import { EvtErrorResponse, useEvtGetWithQuery, useEvtPostQuery, useEvtQuery } from './evtQuery'
import { useSettings } from './settings'

const fiveMinsInMs = 1000 * 60 * 1
const GET_USER_KEY = `GET_USER`
export function useUser(): UseQueryResult<LoggedInUser | null> {
    return useQuery<LoggedInUser | null>(GET_USER_KEY, () => evtuser.get(), {
        cacheTime: fiveMinsInMs,
    })
}

export function usePasswordRestTokenCheck(token: string | undefined, settings: { onOk?: () => void }) {
    return useEvtGetWithQuery<{ email: string; firstName: string; token: string }>(
        userUrls.api.passwordResetTokenCheck,
        { token },
        settings,
    )
}

export function usePasswordRest() {
    return useEvtPostQuery<PasswordRestRequest, any>(userUrls.api.passwordReset, {
        onSuccess: (res, qc) => {
            const retVal = jwt_decode<LoggedInUser>(res.access_token)
            qc.setQueryData(GET_USER_KEY, retVal)
            storage.set(token_key, res.access_token, 30)
        },
        skipCache: true,
    })
}

export function usePasswordChange() {
    return useEvtPostQuery<PasswordChangeRequest, any>(userUrls.api.passwordChange, {
        onSuccess: (res, qc) => {
            const retVal = jwt_decode<LoggedInUser>(res.access_token)
            qc.setQueryData(GET_USER_KEY, retVal)
            storage.set(token_key, res.access_token, 30)
        },
        skipCache: true,
    })
}

export function useForgottenPassword(onSuccess: () => void) {
    return useEvtPostQuery<{ forgottenEmail: string }, UserLoginResponse>(userUrls.api.forgottenPassword, {
        onSuccess: (res, qc) => {
            onSuccess?.()
        },
        skipCache: true,
    })
}

export function useLoginUser(
    onError: (error: EvtErrorResponse, queryClient: QueryClient, request: any) => void | boolean,
) {
    const navigate = useNavigate()
    return useEvtPostQuery<UserLogin, UserLoginResponse>(userUrls.api.login, {
        onSuccess: (res, qc) => {
            const retVal = jwt_decode<LoggedInUser>(res.access_token)
            qc.setQueryData(GET_USER_KEY, retVal)
            storage.set(token_key, res.access_token, 30)
            removeOldCarts()
            const prev = storage.get('_evt_postloginurl')
            storage.remove('_evt_postloginurl')
            if (prev && window.location.pathname !== prev) {
                navigate(prev)
            }
        },
        onError: onError,
    })
}

export function useViewAs(token: string | undefined) {
    const navigate = useNavigate()
    const qc = useQueryClient()
    const { data: res } = useEvtGetWithQuery<UserLoginResponse>(userUrls.api.viewAs, { token })
    useEffect(() => {
        if (res) {
            const retVal = jwt_decode<LoggedInUser>(res.access_token)
            qc.setQueryData(GET_USER_KEY, retVal)
            storage.set(token_key, res.access_token, 30)
            removeOldCarts()
            navigate('/')
        }
    }, [res, navigate, qc])
}

export function useUserProfile() {
    return useEvtQuery<UserDetail>(userUrls.api.profile)
}

export function useLogoutUser() {
    const queryClient = useQueryClient()
    return useMutation((): Promise<unknown> => evtuser.logout(), {
        onSuccess: () => {
            queryClient.clear()
            removeOldCarts()
            window.location.reload()
        },
    })
}

export function useJoinUser() {
    const success = useSuccessAlert()
    const navigate = useNavigate()
    const { data: settings } = useSettings()

    return useEvtPostQuery<Join, UserLoginResponse>(userUrls.api.join, {
        onSuccess: (res, qc) => {
            const retVal = jwt_decode<LoggedInUser>(res.access_token)
            qc.setQueryData(GET_USER_KEY, retVal)
            storage.set(token_key, res.access_token, 30)
            const prev = storage.get('_evt_postloginurl')
            storage.remove('_evt_postloginurl')
            removeOldCarts()
            if (prev && window.location.pathname !== prev) {
                navigate(prev)
            }

            const defaultMessage = (
                <div>
                    Your Event | Corporate account has been created. <br />A member of the team will be in contact soon
                    via email to confirm and finalise the setup of your account
                </div>
            )
            success({
                message: settings?.contentCopy.corporate_User_JoinMessage || defaultMessage,
            })
        },
        skipCache: true,
    })
}

export function useUpdateUser() {
    const alert = useSuccessAlert()
    return useEvtPostQuery<UserDetail, UserLoginResponse>(userUrls.api.update, {
        onSuccess: (res, qc) => {
            const retVal = jwt_decode<LoggedInUser>(res.access_token)
            qc.setQueryData(GET_USER_KEY, retVal)
            storage.set(token_key, res.access_token, 30)
            qc.removeQueries(userUrls.api.profile.queryKey)
            alert('Profile Updated Successfully')
        },
        skipCache: true,
    })
}

const removeOldCarts = () => {
    storage.removeAllMatching('_evt_cart_')
    storage.removeAllMatching('_evt_theme_')
}
