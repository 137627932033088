import { FC, ReactNode } from 'react'
import { MenuProps, styled } from '@mui/material'
import EvtMenu from '../elements/EvtMenu'

interface Props {
    header?: string | ReactNode
    footer?: string | ReactNode
}

const MenuUI: FC<Props & MenuProps> = ({ children, ...props }) => {
    const { header, footer } = props
    return (
        <EvtMenuStyles {...props}>
            {header && <MenuHeaderStyles>{header}</MenuHeaderStyles>}
            {children}
            {footer && <MenuFooterStyles>{footer}</MenuFooterStyles>}
        </EvtMenuStyles>
    )
}

export default MenuUI

const EvtMenuStyles = styled(EvtMenu)(({ theme }) => ({
    '.MuiList-root': {
        backgroundColor: theme.palette.menu.bgColor,
    },
}))

const MenuHeaderStyles = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
}))

const MenuFooterStyles = styled('div')(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
}))
