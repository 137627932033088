import React, { FC, useEffect, useState } from 'react'
import { SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material'
import $ from 'jquery'
import FileUploader from '../../components/FIleUploader'
import { ImageItem, ImageItemSkeleton } from '../../components/ImageItem'
import EvtBox from '../../elements/EvtBox'
import { EvtButton } from '../../elements/EvtButton'
import EvtDivider from '../../elements/EvtDivider'
import EvtGrid from '../../elements/EvtGrid'
import EvtImageList from '../../elements/EvtImageList'
import EvtPagination from '../../elements/EvtPagination'
import { EvtSelect } from '../../elements/EvtSelect'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import useWindowResize from '../../hooks/useWindowResize'
import { eVoucherImage, useEvoucherImages, useImageDelete } from '../../query/images'
import { EVT } from '../../utils/evt'

const DigitalImage = () => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const [selectedImage, setSeletedImage] = useState<eVoucherImage | null>(null)
    const [filter, setFilter] = useState('All')
    const { data: imageList, isLoading: isImageLoading } = useEvoucherImages()
    const { mutate: deleteImage } = useImageDelete()

    const categoryOptions = [
        { value: 'All', text: 'All' },
        { value: 'userImages', text: 'Your Images' },
    ]
    imageList?.evoucherImages?.forEach((image) => {
        if (!categoryOptions.some((e: { value: string; text: string }) => e.value === image.Category)) {
            categoryOptions.push({ value: image?.Category || '', text: image?.Category || '' })
        }
    })

    let filteredImages: Array<eVoucherImage> = []
    if (imageList) {
        if (imageList.userEvoucherImages) {
            if (filter === 'userImages' || filter === 'All') filteredImages = [...imageList.userEvoucherImages]
        }
        if (imageList.evoucherImages) {
            filteredImages = [
                ...filteredImages,
                ...imageList.evoucherImages.filter((image) => image.Category === filter || filter === 'All'),
            ]
        }
    }

    const handleFilter = (e: SelectChangeEvent<unknown>) => {
        const category = e.target.value as string
        setFilter(category)
    }
    const handleImageClick = (image: eVoucherImage) => {
        if (selectedImage?.imageUrl === image.imageUrl) {
            setSeletedImage(null)
        } else {
            setSeletedImage(image)
        }
    }
    const handleImageDelete = () => {
        if (selectedImage) {
            deleteImage({ id: selectedImage.id })
            setSeletedImage(null)
        }
    }

    return (
        <EvtBox display="flex" flexDirection="column">
            <EvtGrid container mb="40px">
                <EvtGrid item xs={12}>
                    <EvtSelect
                        options={categoryOptions}
                        label="Category"
                        fullWidth
                        defaultValue="All"
                        onChange={handleFilter}
                    />
                </EvtGrid>
            </EvtGrid>
            {isDesktop ? (
                <DigitalImageDesktop
                    filter={filter}
                    isImageLoading={isImageLoading}
                    filteredImages={filteredImages}
                    selectedImage={selectedImage}
                    handleImageClick={handleImageClick}
                    handleImageDelete={handleImageDelete}
                />
            ) : (
                <DigitalImageMobile
                    filter={filter}
                    isImageLoading={isImageLoading}
                    filteredImages={filteredImages}
                    selectedImage={selectedImage}
                    handleImageClick={handleImageClick}
                    handleImageDelete={handleImageDelete}
                />
            )}
        </EvtBox>
    )
}

export default DigitalImage

interface DigitalImageProps {
    filter: string
    isImageLoading: boolean
    filteredImages: Array<eVoucherImage>
    selectedImage: eVoucherImage | null
    handleImageClick: (image: eVoucherImage) => void
    handleImageDelete?: () => void
}

const DigitalImageDesktop: FC<DigitalImageProps> = ({
    filter,
    isImageLoading,
    filteredImages,
    selectedImage,
    handleImageClick,
    handleImageDelete,
}) => {
    return (
        <>
            <EvtBox display="flex" alignItems="flex-start">
                <ImageListSection
                    filter={filter}
                    isImageLoading={isImageLoading}
                    filteredImages={filteredImages}
                    selectedImage={selectedImage}
                    handleImageClick={handleImageClick}
                />

                <EvtBox display="flex" alignItems="center" className="image-divider-wrapper">
                    <EvtDivider
                        sx={{
                            marginX: '20px',
                            borderColor: (theme) => theme.palette.border.secondary,
                            height: '250px',
                        }}
                        orientation="vertical"
                    >
                        <EvtTypography>Or</EvtTypography>
                    </EvtDivider>
                    <FileUploader />
                </EvtBox>
            </EvtBox>
            {filteredImages.length > 0 && (
                <EvtBox display="flex" alignItems="flex-start">
                    <EvtButton
                        variant="contained"
                        color="error"
                        disabled={selectedImage === null}
                        onClick={handleImageDelete}
                    >
                        Delete
                    </EvtButton>
                </EvtBox>
            )}
        </>
    )
}

const DigitalImageMobile: FC<DigitalImageProps> = ({
    filter,
    isImageLoading,
    filteredImages,
    selectedImage,
    handleImageClick,
    handleImageDelete,
}) => {
    const theme = useTheme()
    const isDonw_660 = useMediaQuery(theme.breakpoints.down(660))
    return (
        <>
            <EvtBox display="flex" justifyContent={isDonw_660 ? 'center' : 'space-between'} alignItems="flex-start">
                <ImageListSection
                    filter={filter}
                    isImageLoading={isImageLoading}
                    filteredImages={filteredImages}
                    selectedImage={selectedImage}
                    handleImageClick={handleImageClick}
                />
            </EvtBox>
            {filteredImages.length > 0 && (
                <EvtButton
                    variant="contained"
                    color="error"
                    disabled={selectedImage === null}
                    onClick={handleImageDelete}
                    sx={{ width: '100px', mt: '20px' }}
                >
                    Delete
                </EvtButton>
            )}

            <EvtDivider
                sx={{
                    borderColor: (theme) => theme.palette.border.secondary,
                    paddingY: '20px',
                }}
            >
                <EvtTypography>Or</EvtTypography>
            </EvtDivider>
            <EvtBox display="flex" justifyContent="center">
                <FileUploader />
            </EvtBox>
        </>
    )
}

const ImageListSection: FC<DigitalImageProps> = ({
    filter,
    isImageLoading,
    filteredImages,
    selectedImage,
    handleImageClick,
}) => {
    const theme = useTheme()
    const windowSize = useWindowResize()

    const isDown_1400 = useMediaQuery(theme.breakpoints.down(1400))
    const isDown_1200 = useMediaQuery(theme.breakpoints.down(1200))
    const isDown_770 = useMediaQuery(theme.breakpoints.down(770))
    const isDonw_800 = useMediaQuery(theme.breakpoints.down(800))
    const isDonw_660 = useMediaQuery(theme.breakpoints.down(660))
    let colSize = 4
    if (isDown_1400) colSize = 3
    if (isDonw_800) colSize = 2
    if (isDonw_660) colSize = 1

    const drawerWidth = isDown_770 === true ? 0 : theme.navbar.drawer.closeWidthNum
    const wrapperWidth = ((windowSize.width as number) - drawerWidth) * 0.8
    const divider_uploader_width = isDown_1200 === true ? 0 : $('.image-divider-wrapper').width() || 317.672

    const imageListWidth = wrapperWidth - divider_uploader_width
    const ImageItemWidth = (imageListWidth - colSize * 20 + 20) / colSize
    const rowNum = 4

    const [page, setPage] = useState(1)
    const total = filteredImages.length
    const count = Math.ceil(total / (colSize * rowNum))
    const indexStart = (page - 1) * colSize * rowNum
    const indexEnd = page * colSize * rowNum

    const imagesToShowList = filteredImages.slice(indexStart, indexEnd)
    // width / height
    const ImageRatio = 340 / 131
    const rowHeight = Math.max(ImageItemWidth / ImageRatio + 20, 120)
    const height = rowHeight * Math.ceil(imagesToShowList.length / colSize)

    useEffect(() => {
        setPage(1)
    }, [colSize, filter])

    return (
        <EvtBox display="flex" flexDirection="column" alignItems="center">
            {imagesToShowList.length > 0 ? (
                <EvtImageList
                    sx={{ margin: '0', height: `${height}px` }}
                    cols={colSize}
                    rowHeight={theme.image.height}
                    gap={20}
                >
                    {isImageLoading &&
                        EVT.range(0, colSize).map((row, rowIndex) => <ImageItemSkeleton key={rowIndex} />)}

                    {imagesToShowList.map((image) => (
                        <ImageItem
                            key={image.id}
                            image={image}
                            height={`${rowHeight - 20}px`}
                            width={`${ImageItemWidth}px`}
                            isSelectForDelete={true}
                            onClick={handleImageClick}
                            isactive={selectedImage?.id === image.id}
                        />
                    ))}
                </EvtImageList>
            ) : (
                <EvtBox
                    width={imageListWidth}
                    height={250}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    No uploaded images
                </EvtBox>
            )}

            {count > 1 && (
                <EvtPagination
                    count={count}
                    page={page}
                    sx={{ pt: '8px' }}
                    onChange={(event: React.ChangeEvent<unknown>, page: number) => setPage(page)}
                />
            )}
        </EvtBox>
    )
}
