import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

const SetModalStateContext = React.createContext<GlobalModalValue>({
    forceClose: () => {},
    openModal: () => {},
})
const GetModalStateContext = React.createContext<GlobalModalOpenOptions | undefined>(undefined)
const GlobalModalProvider = (props: { children: React.ReactNode }) => {
    const [modalSettings, setModalSettings] = useState<GlobalModalOpenOptions | undefined>()

    const openModal = useCallback(
        (settings: GlobalModalOpenOptions) => {
            setModalSettings(settings)
        },
        [setModalSettings],
    )

    const forceClose = useCallback(() => {
        setModalSettings(undefined)
    }, [setModalSettings])

    const value = useMemo(() => {
        return {
            openModal,
            forceClose,
        }
    }, [openModal, forceClose])

    return (
        <SetModalStateContext.Provider value={value}>
            <GetModalStateContext.Provider value={modalSettings}>{props.children}</GetModalStateContext.Provider>
        </SetModalStateContext.Provider>
    )
}

const useModal = () => {
    const context = React.useContext(SetModalStateContext)
    if (context === undefined) {
        throw new Error('useGlobal must be used within a GlobalModalProvider')
    }

    return context
}

export { GetModalStateContext, GlobalModalProvider, useModal }

export type GlobalModalOpenOptions = {
    content: React.ReactNode
    buttons?: React.ReactNode
    title?: string
    onClose?: (e: MouseEvent<HTMLButtonElement> | undefined) => void
    onOk?: (e: MouseEvent<HTMLButtonElement> | undefined) => void
    onCancel?: (e: MouseEvent<HTMLButtonElement> | undefined) => void
}

type GlobalModalValue = {
    openModal: (options: GlobalModalOpenOptions) => void
    forceClose: () => void
}
