import React, { FC, KeyboardEvent } from 'react'
import { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment, { Moment } from 'moment'
import EvtBox from './EvtBox'
import EvtTypography from './EvtTypography'

export interface DateFilterProps {
    fromDate: Moment | null
    toDate: Moment | null
}

interface Props {
    dateFilter: DateFilterProps
    onEnterToSearch: () => void
    onChange: (dateFilter: DateFilterProps) => void
    onClear: (dateFilter: DateFilterProps) => void
}

const EvtBoxStyles = styled(EvtBox)(({ theme }) => ({
    '.MuiTextField-root': {
        width: 'calc(50% - 20px)',
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            width: '100%',
        },
    },
}))

const EvtDateRangePicker: FC<Props> = ({ dateFilter, onChange, onClear, onEnterToSearch }) => {
    const handleDatePickerChange = (dateFilter: DateFilterProps) => {
        onChange(dateFilter)
    }
    const handleClear = (props: string) => {
        // @ts-ignore
        dateFilter[props] = null
        onClear(dateFilter)
    }
    const onEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onEnterToSearch()
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <EvtBoxStyles sx={dateWrapperStyle}>
                <CustomDatePicker
                    label="Start Date"
                    value={dateFilter.fromDate}
                    maxDate={dateFilter.toDate ? moment(dateFilter.toDate).subtract(1, 'day') : undefined}
                    handleValueChange={(newValue) => {
                        handleDatePickerChange({ ...dateFilter, fromDate: newValue || null })
                    }}
                    handleKeyDown={onEnter}
                    handleClear={() => handleClear('fromDate')}
                />
                <EvtTypography sx={{ width: '40px' }}>To</EvtTypography>
                <CustomDatePicker
                    label="End Date"
                    value={dateFilter.toDate}
                    minDate={dateFilter.fromDate ? moment(dateFilter.fromDate).add(1, 'day') : undefined}
                    handleValueChange={(newValue) => {
                        handleDatePickerChange({ ...dateFilter, toDate: newValue || null })
                    }}
                    handleKeyDown={onEnter}
                    handleClear={() => handleClear('toDate')}
                />
            </EvtBoxStyles>
        </LocalizationProvider>
    )
}

export default EvtDateRangePicker

interface CustomeDatePickerProps {
    label: string
    value: Moment | null
    minDate?: Moment | undefined
    maxDate?: Moment | undefined
    handleValueChange: (date: Moment | null) => void
    handleKeyDown: (e: KeyboardEvent) => void
    handleClear: () => void
}

const CustomDatePicker: FC<CustomeDatePickerProps> = ({
    label,
    value,
    minDate,
    maxDate,
    handleValueChange,
    handleKeyDown,
    handleClear,
}) => (
    <DatePicker
        format="DD/MM/YYYY"
        sx={datePaperProps}
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        value={value}
        onChange={handleValueChange}
        slotProps={{
            field: { clearable: true, onClear: handleClear },
            textField: {
                onKeyDown: handleKeyDown,
            },
        }}
    ></DatePicker>
)

const dateWrapperStyle = (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
    },
})

const datePaperProps = (theme: Theme) => ({
    '.MuiInputBase-root:hover': {
        fieldset: {
            borderWidth: '2px',
            borderColor: theme.palette.input.border,
        },
    },
    fieldset: {
        borderColor: theme.palette.input.border,
    },
})
