import { FC } from 'react'
import { TextFieldProps } from '@mui/material'
import { useFormikContext } from 'formik'
import { EvtFormInputField } from './EvtFormInputField'

export interface EvtPostcodeFormInputProps {
    name: string
    label: string
}

const EvtPostcodeFormInput: FC<EvtPostcodeFormInputProps & TextFieldProps> = ({ name, label }) => {
    const { values } = useFormikContext()
    return (
        <EvtFormInputField
            name={name}
            label={label}
            type="number"
            isRoundBorder={true}
            sx={{
                'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },

                'input[type=number]': {
                    MozAppearance: 'textfield',
                },
            }}
            // @ts-ignore
            value={values[name]}
        />
    )
}
export default EvtPostcodeFormInput
