import { useState } from 'react'
import { Theme } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { PageBanner } from '../../components/PageBanner'
import ProductCard from '../../components/ProductCard'
import ScrollCategoryTab from '../../components/ScrollCategoryTab'
import EvtBox from '../../elements/EvtBox'
import { EvtButton } from '../../elements/EvtButton'
import EvtGrid from '../../elements/EvtGrid'
import { EvtLoader } from '../../elements/EvtLoader'
import EvtSearchField from '../../elements/EvtSearchField'
import EvtTypography from '../../elements/EvtTypography'
import { useProducts } from '../../query/products'
import productUrls from '../../urls/productUrls'

const TypeAllOption = { value: -1, text: 'All' }

const Products = () => {
    const { data: productRes, isLoading: isLoadProducts } = useProducts()
    const [searchValue, setSearchValue] = useState<string>('')

    const [filter, setFilter] = useState<{ type: number; search: string }>({
        type: TypeAllOption.value,
        search: '',
    })

    let products = productRes?.products || []
    const searcher = new FuzzySearch(products || [], ['name'])

    const productTypes = productRes?.productTypes.map((pt) => ({ value: pt.id, text: pt.name })) || []
    const options: Array<{ value: number; text: string }> = [TypeAllOption, ...productTypes]

    products = searcher
        .search(filter.search)
        .filter((p) => p.productTypeId === filter.type || filter.type === TypeAllOption.value)
    const isHasShortDes = products.some((p) => p.shortDescription)

    const handleSearchValueChange = (value: string) => {
        setSearchValue(value)
    }

    const handleFilterTypeChange = (value: number) => {
        setFilter({ search: '', type: value })
        setSearchValue('')
    }
    const handleFilterSearchChange = () => {
        setFilter({ ...filter, search: searchValue })
    }
    const handleClear = () => {
        setFilter({ ...filter, search: '' })
        handleSearchValueChange('')
    }

    return (
        <EvtBox width="100%" pb="50px">
            <PageBanner page={productUrls.pages.index} text={'Products'} />

            <EvtBox sx={containerStyles}>
                <ScrollCategoryTab
                    options={options}
                    selectedCategory={filter.type}
                    onClick={(value) => handleFilterTypeChange(value as number)}
                />

                <EvtGrid container className="search-section">
                    <EvtGrid item xs={8} sm={9} lg={10} className="search-input-grid">
                        <EvtSearchField
                            fullWidth
                            value={searchValue}
                            onChange={(value) => handleSearchValueChange(value as string)}
                            onEnterToSearch={handleFilterSearchChange}
                            onClear={handleClear}
                        />
                    </EvtGrid>
                    <EvtGrid item xs={4} sm={3} lg={2}>
                        <EvtButton
                            fullWidth
                            variant="contained"
                            sx={{ height: '100%' }}
                            onClick={handleFilterSearchChange}
                        >
                            Search
                        </EvtButton>
                    </EvtGrid>
                </EvtGrid>

                <EvtGrid container item xs={12} justifyContent="center" gap="50px" className="products-section">
                    <EvtLoader loading={isLoadProducts}>
                        {products.map((product) => (
                            <ProductCard product={product} key={product.id} isHasShortDes={isHasShortDes} />
                        ))}
                        {products.length === 0 && <EvtTypography>No Products</EvtTypography>}
                    </EvtLoader>
                </EvtGrid>
            </EvtBox>
        </EvtBox>
    )
}

export default Products

const containerStyles = (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '25px',
    width: '66.7%',
    margin: 'auto',
    pt: '40px',
    pb: '10px',
    '.search-section .search-input-grid': {
        pr: '20px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: '100%',
        '.products-section': {
            paddingX: '20px',
        },
        '.search-section': {
            margin: 'auto',
            width: '90%',
        },
    },
})
