import { config } from '../config'
import { API, createPageUrl } from '../urls'

const homeUrls = {
    api: {
        settings: {
            url: config.baseUrl + '/api/home/settings',
            requiresAuth: false,
            method: 'GET',
            queryKey: 'GET_HOME_SETTINGS',
        } as API,
        contactUs: {
            url: config.baseUrl + '/api/home/contactus',
            requiresAuth: false,
            method: 'POST',
            queryKey: 'CONTACT_US',
        } as API,
    },
    pages: {
        index: createPageUrl({
            url: '/',
            text: 'Home',
        }),
        terms: createPageUrl({
            url: '/terms',
            text: 'Terms & Conditions',
        }),
        privacy: createPageUrl({
            url: '/privacy',
            text: 'Privacy Policy',
        }),
        about: createPageUrl({
            url: '/about',
            text: 'About',
        }),
        faq: createPageUrl({
            url: '/faq',
            text: 'Frequently Asked Questions',
        }),
        contactUs: createPageUrl({
            url: '/contactus',
            text: 'Contact Us',
        }),
    },
}

export default homeUrls
