import { FC, ReactNode, useContext } from 'react'
import { Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { theme } from '../consts'
import EvtBox from '../elements/EvtBox'
import { EvtButtonPill } from '../elements/EvtButton'
import EvtSkeleton from '../elements/EvtSkeleton'
import { GetThemeStateContext } from '../providers/CustomThemeProvider'
import { PromoTile } from '../query/promotiles'
import { EVT } from '../utils/evt'
import CarouselUI from './CarouselUI'

export enum PromoTileImagePosition {
    Left,
    Right,
}

const outerBoxHeight = {
    desktop: 450,
    tablet: 750,
    mobile: 850,
}
const imageHeightInTablet = 250

export const PromoTileCarousel: FC<{
    tiles: Array<PromoTile> | undefined
    imagePosition?: PromoTileImagePosition
    loading?: boolean
}> = ({ tiles, imagePosition = PromoTileImagePosition.Left, loading }) => {
    const items: Array<ReactNode> =
        tiles?.map((t) => <PromoTileElement tile={t} key={t.bannerUrl} imagePosition={imagePosition} />) || []

    return (
        <>
            {loading ? (
                <SkeletonTile imagePosition={imagePosition} />
            ) : (
                <CarouselUI items={items} itemGap={30} renderDotsOutside={true} infinite={true} />
            )}
        </>
    )
}

const PromoTileElement: FC<{ tile: PromoTile; imagePosition: PromoTileImagePosition }> = ({ tile, imagePosition }) => {
    return (
        <EvtBox className="promo-container" sx={(t) => outerBoxStyle(t, imagePosition)}>
            <EvtBox sx={(t) => imageBoxStyle(t, tile)}></EvtBox>
            <EvtBox sx={(t) => contentBoxStyle(t, imagePosition)}>
                <EvtBox sx={contentStyle}>{EVT.html(tile.content)}</EvtBox>
                <EvtBox sx={buttonWrapperStyle}>
                    {tile.button1Text && tile.button1Url && (
                        <CustomButton text={tile.button1Text} url={tile.button1Url} />
                    )}

                    {tile.button2Text && tile.button2Url && (
                        <CustomButton text={tile.button2Text} url={tile.button2Url} />
                    )}
                </EvtBox>
                <Lines imagePosition={imagePosition} />
            </EvtBox>
        </EvtBox>
    )
}

const CustomButton: FC<{ text: string; url: string }> = ({ text, url }) => {
    const isLight = useContext(GetThemeStateContext).theme === theme.LIGHT
    const onClick = (url: string | undefined) => {
        if (url) {
            window.open(url, '_blank')
        }
    }
    return (
        <EvtButtonPill variant={isLight ? 'contained' : 'outlined'} sx={buttonStyle} onClick={() => onClick(url)}>
            {text}
        </EvtButtonPill>
    )
}

const SkeletonTile: FC<{ imagePosition: PromoTileImagePosition }> = ({ imagePosition }) => {
    return (
        <EvtBox sx={(t) => outerBoxStyle(t, imagePosition)}>
            <EvtSkeleton variant="rectangular" sx={imageBoxStyle}></EvtSkeleton>
            <EvtBox sx={(t) => contentBoxStyle(t, imagePosition)}>
                <EvtSkeleton></EvtSkeleton>
                <EvtSkeleton></EvtSkeleton>
            </EvtBox>
        </EvtBox>
    )
}

const outerBoxStyle = (theme: Theme, imagePosition: PromoTileImagePosition) => ({
    width: '100%',
    margin: 'auto',
    height: outerBoxHeight.desktop,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: imagePosition === PromoTileImagePosition.Left ? 'row' : 'row-reverse',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        height: outerBoxHeight.tablet,
        flexDirection: 'column',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        height: outerBoxHeight.mobile,
    },
})

const imageBoxStyle = (theme: Theme, tile?: PromoTile) => ({
    flexBasis: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: outerBoxHeight.desktop,
    backgroundColor: theme.palette.promoCarousel.imgBg,
    backgroundImage: tile ? `url(${tile.bannerUrl})` : 'none',
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        height: imageHeightInTablet,
    },
})

const Lines: FC<{ imagePosition: PromoTileImagePosition }> = ({ imagePosition }) => {
    return (
        <EvtBox>
            <EvtBox sx={(t) => lineStyle(t, imagePosition, 10)} />
            <EvtBox sx={(t) => lineStyle(t, imagePosition, 40)} />
            <EvtBox sx={(t) => lineStyle(t, imagePosition, 70)} />
        </EvtBox>
    )
}

const lineStyle = (
    theme: Theme,
    imagePosition: PromoTileImagePosition,
    translateX: number,
): SystemStyleObject<Theme> => {
    const lineCss =
        imagePosition === PromoTileImagePosition.Left
            ? { right: '-70px', transform: `translateY(0px) translateX(${translateX}px) rotate(120deg)` }
            : { left: '-150px', transform: `translateY(0px) translateX(${translateX}px) rotate(240deg)` }
    return {
        ...lineCss,
        width: '250px',
        height: '10px',
        position: 'absolute',
        bottom: '70px',
        backgroundColor: theme.palette.line.bgColor,
    }
}

const contentBoxStyle = (theme: Theme, imagePosition: PromoTileImagePosition) => {
    return {
        position: 'relative',
        borderRadius: imagePosition === PromoTileImagePosition.Left ? '0 20px 20px 0' : '20px 0 0 20px',
        borderTop: `1px solid ${theme.palette.border.default}`,
        borderRight:
            imagePosition === PromoTileImagePosition.Left ? `1px solid ${theme.palette.border.default}` : 'none',
        borderLeft:
            imagePosition === PromoTileImagePosition.Right ? `1px solid ${theme.palette.border.default}` : 'none',
        borderBottom: `1px solid ${theme.palette.border.default}`,
        flexBasis: '50%',
        padding: '20px 50px',
        boxSizing: 'border-box',
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        minHeight: outerBoxHeight.desktop,
        [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
            width: '100%',
            borderTop: 'none',
            borderLeft: `1px solid ${theme.palette.border.default}`,
            borderRight: `1px solid ${theme.palette.border.default}`,
            borderRadius: '0 0 20px 20px',
            padding: '20px',
            minHeight: outerBoxHeight.tablet - imageHeightInTablet,
        },

        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            minHeight: outerBoxHeight.mobile - imageHeightInTablet,
        },
    }
}

const contentStyle = (theme: Theme) => ({
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    width: '100%',
    textAlign: 'left',
    '& p, li': {
        fontSize: '13px',
    },
    '.image-wrapper': {
        marginBottom: 0,
    },
    '.title': {
        marginTop: '4px',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    '.details, .subtitle': {
        color: theme.palette.promoTile.text,
    },
    ul: {
        listStyle: '"- "',
        li: {
            marginY: '10px',
        },
        paddingInlineStart: '10px',
    },

    '.experience-logo': {
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: '100% !important',
        },
    },
})

const buttonWrapperStyle = (theme: Theme) => ({
    gap: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        flexDirection: 'column',
    },
})

const buttonStyle = (theme: Theme) => ({
    padding: '15px 35px',
    borderColor: theme.palette.border.default,
    '&:hover': {
        backgroundColor: theme.palette.info.main,
    },
    zIndex: 10,
})
