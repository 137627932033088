import { FC, ReactNode } from 'react'
import { CheckboxProps, FormControlLabel, useTheme } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import EvtBox from './EvtBox'
import { EvtCheckbox } from './EvtCheckbox'
import EvtFormControl from './EvtFormControl'
import EvtFormHelperText from './EvtFormHelperText'

interface Props {
    name: string
    description: ReactNode | string
}

export const EvtFormCheckbox: FC<Props & CheckboxProps> = ({ name, description, ...props }) => {
    const theme = useTheme()
    const [field, meta] = useField(name)
    const { values } = useFormikContext<any>()

    let error = false
    const configTextfield = {
        ...field,
        ...props,
    }

    if (meta && meta.touched && meta.error) {
        error = true
        configTextfield.sx = {
            ...configTextfield.sx,
            '> svg': { color: theme.palette.error.main },
        }
    }

    return (
        <EvtFormControl fullWidth>
            <EvtBox display="flex" alignItems="flex-start">
                <FormControlLabel
                    control={<EvtCheckbox {...configTextfield} checked={values[name]} />}
                    label={description}
                    sx={{ ml: '0px' }}
                />
            </EvtBox>
            {error && (
                <EvtFormHelperText error={error} sx={{ ml: '24px' }}>
                    {meta.error}
                </EvtFormHelperText>
            )}
        </EvtFormControl>
    )
}
