import React, { ChangeEvent, FC } from 'react'
import { TextFieldProps } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import { isAu } from '../consts'
import { EvtFormInputField } from '../elements/EvtFormInputField'
import { EvtInputFieldProps } from '../elements/EvtInputField'

interface AddressComponent {
    long_name: string
    short_name: string
    types: Array<string>
}

interface PlaceDetailsType {
    place_id: string
    address_components: Array<AddressComponent>
}

interface Props {
    label: string
    properties?: {
        address?: string
        postcode?: string
        state?: string
        suburb?: string
        country?: string
        city?: string
    }
}

const ADDRESS_DEFAULT = 'address'
const POSTCODE_DEFAULT = 'postcode'
const SUBURB_DEFAULT = 'suburb'
const STATE_DEFAULT = 'state'
const COUNTRY_DEFAULT = 'country'
const CITY_DEFAULT = 'city'

const GoogleMapInput: FC<Props & TextFieldProps & EvtInputFieldProps> = ({
    defaultValue,
    properties,
    label,
    ...props
}) => {
    const {
        address = ADDRESS_DEFAULT,
        postcode = POSTCODE_DEFAULT,
        suburb = SUBURB_DEFAULT,
        state = STATE_DEFAULT,
        city = CITY_DEFAULT,
        country = COUNTRY_DEFAULT,
    } = properties || {}

    const [field, meta] = useField(address)
    const { setFieldValue } = useFormikContext()

    const configTextfield = {
        ...field,
        ...props,
        error: false,
    }

    if (meta && meta.touched && meta.error) {
        configTextfield.error = true
    }

    const onAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value
        // @ts-ignore
        const autocomplete = new google.maps.places.Autocomplete(e.target, {
            componentRestrictions: { country: isAu ? 'au' : 'nz' },
        })

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()
            setFormAddressValue(place)
        })

        setFieldValue(address, inputValue)
        setFieldValue(postcode, '')
        setFieldValue(suburb, '')
        setFieldValue(state, '')
    }

    const setFormAddressValue = (placeDetails: PlaceDetailsType) => {
        const components: Array<AddressComponent> = placeDetails.address_components
        let addressValue = ''

        components.forEach((c) => {
            const types = c.types
            if (types.includes('postal_code')) {
                setFieldValue(postcode, c.long_name)
            } else if (types.includes('administrative_area_level_1')) {
                isAu ? setFieldValue(state, c.short_name) : setFieldValue(city, c.long_name)
            } else if (types.includes('locality')) {
                setFieldValue(suburb, c.long_name)
            } else if (types.includes('country')) {
                setFieldValue(country, c.long_name)
            }
            if (types.includes('subpremise')) {
                addressValue += c.long_name + '/'
            }
            if (types.includes('street_number') || types.includes('route')) {
                addressValue += c.long_name + ' '
            }
        })
        setFieldValue(address, addressValue.trim())
    }

    return <EvtFormInputField label={label} name={address} isRoundBorder onChange={onAddressChange} />
}

export default GoogleMapInput
