import { FC } from 'react'
import { CircularProgress, CircularProgressProps } from '@mui/material'

interface Props {
    children: React.ReactNode
    loading: boolean
}

export const EvtLoader: FC<CircularProgressProps & Props> = ({ children, loading, ...props }) => {
    return loading ? <CircularProgress {...props} /> : <>{children}</>
}
