import { createPageUrl } from '../urls'

const cartUrls = {
    pages: {
        cart: createPageUrl({
            url: '/cart',
            text: 'Cart',
        }),
    },
}

export default cartUrls
