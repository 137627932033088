import { QueryClient, UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { queryCacheTime } from '../consts'
import { useEvtNavigate } from '../hooks/useEvtNavigate'
import { AnimationMaskValue, useAnimationMask } from '../providers/AnimationMaskProvider'
import { useLoader } from '../providers/LoaderProvider'
import { useErrorAlert } from '../providers/modals/AlertModalProvider'
import { API } from '../urls'
import userUrls from '../urls/userUrls'
import { EVT } from '../utils/evt'
import { token_key } from '../utils/evtuser'
import { storage } from '../utils/storage'

export function useEvtQuery<T>(evtUrl: API, settings?: any): UseQueryResult<T> {
    const alert = useHandleError()
    const handle401 = useHandle401Error()
    return useQuery<T>(evtUrl.queryKey || evtUrl.url, () => EVT.get<T>(evtUrl, settings), {
        onError: (error) => {
            const errorResponse: EvtErrorResponse = error as EvtErrorResponse
            handle401(errorResponse)
            alert(errorResponse)
        },
    })
}

export function useEvtGetWithQuery<T>(evtUrl: API, data: any, settings?: any): UseQueryResult<T> {
    settings = settings || {}
    settings.params = data
    const alert = useHandleError()
    const handle401 = useHandle401Error()
    return useQuery<T>([evtUrl.queryKey || evtUrl.url, data], () => EVT.get<T>(evtUrl, settings), {
        onError: (error) => {
            const errorResponse: EvtErrorResponse = error as EvtErrorResponse
            handle401(errorResponse)
            alert(errorResponse, settings.onOk)
        },
        enabled: Object.entries(data).filter(([k, v], i) => v === 0 || !!v).length > 0,
        cacheTime: typeof settings.cacheTime === 'number' ? settings.cacheTime : queryCacheTime,
    })
}

export function useEvtPostQuery<TRequest, TResponse>(
    evtUrl: API,
    settings: EvtPostQueryRequest<TResponse>,
    postSettings?: any,
) {
    const animationMask = useAnimationMask()
    const queryClient = useQueryClient()
    const alert = useHandleError()
    const loader = useLoader()
    const handle401 = useHandle401Error()

    const { mutate, isLoading, isSuccess } = useMutation(
        (data: TRequest): Promise<TResponse> => EVT.post(evtUrl, data, postSettings),
        {
            onMutate: () => loader(true),
        },
    )

    return {
        mutate: (request: TRequest, inlineSettings?: EvtPostQueryRequestInline<TResponse>) =>
            mutate(request, {
                onError: (e, request) => {
                    const error = e as EvtErrorResponse
                    loader(false)
                    handle401(error)
                    const func = () => {
                        let handled = settings?.onError?.(error, queryClient, request)
                        if (handled !== true) {
                            handled = inlineSettings?.onError?.(error, queryClient, request)
                        }

                        if (handled !== true) {
                            alert(error)
                        }
                    }

                    if (settings.successAnimationText) {
                        animationMask({
                            item: AnimationMaskValue.error,
                            text: error?.response?.data?.message || error?.message,
                        })
                        setTimeout(() => {
                            func()
                            animationMask(null)
                        }, 1500)
                    } else {
                        func()
                    }
                },
                onSuccess: (res) => {
                    loader(false)
                    if (settings.setQueryKeyData) {
                        const obj = settings.setQueryKeyData(res)
                        queryClient.setQueryData([evtUrl.queryKey || evtUrl.url, obj], res)
                    } else if (!settings.skipCache) {
                        queryClient.setQueryData([evtUrl.queryKey || evtUrl.url], res)
                    }

                    const func = () => {
                        settings.onSuccess?.(res, queryClient)

                        inlineSettings?.onSuccess?.(res, queryClient)
                    }

                    if (settings.successAnimationText) {
                        animationMask({ item: AnimationMaskValue.success, text: settings.successAnimationText })
                        setTimeout(() => {
                            func()
                            animationMask(null)
                        }, 1500)
                    } else {
                        func()
                    }
                },
            }),
        isLoading,
        isSuccess,
    }
}

export interface EvtPostQueryRequest<TResponse> extends EvtPostQueryRequestInline<TResponse> {
    setQueryKeyData?: (data: TResponse) => any
    skipCache?: boolean
    successAnimationText?: string
}

export interface EvtPostQueryRequestInline<TResponse> {
    onSuccess?: (data: TResponse, queryClient: QueryClient) => Promise<unknown> | void
    onError?: (data: EvtErrorResponse, queryClient: QueryClient, request: any) => Promise<unknown> | void | boolean
}

export interface EvtErrorResponse {
    message: string
    response: {
        status: number
        data: {
            errorCode?: number
            message: string
        }
    }
}

const useHandle401Error = () => {
    const navigate = useEvtNavigate()
    const queryClient = useQueryClient()

    return (error: EvtErrorResponse) => {
        if (error.response.status === 401) {
            storage.remove(token_key)
            queryClient.clear()
            storage.set('_evt_postloginurl', window.location.pathname, 10)
            navigate(userUrls.pages.login)
        }
    }
}

const useHandleError = () => {
    const alert = useErrorAlert()
    return (error: EvtErrorResponse, onOk?: () => void) => {
        const message = error?.response?.data?.message || error?.message
        if (message) {
            alert({
                message: message,
                onOk: onOk,
            })
        }
    }
}
