import { FC } from 'react'
import { Theme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import EvtStepper from '../elements/EvtStepper'
import useDeviceType from '../hooks/useDeviceType'
import { EVT } from '../utils/evt'

interface Props {
    stepNames: Array<string>
    activeStepIndex: number
}

const StepperHeader: FC<Props> = ({ stepNames, activeStepIndex }) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    return (
        <EvtBox sx={stepperStyles} className="stepper-header">
            <EvtStepper
                steps={stepNames}
                activeStepIndex={activeStepIndex}
                alternativeLabel={isMobile ? true : false}
            />
        </EvtBox>
    )
}

export default StepperHeader

const stepperStyles = (theme: Theme) => ({
    backgroundColor: theme.palette.stepper.bgColor,
    color: 'red',
    paddingY: '25px',
    paddingX: '10%',
    position: 'fixed',
    zIndex: 1199,
    width: `calc(100% - ${theme.navbar.drawer.closeWidth})`,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        width: '100%',
        marginLeft: 0,
        paddingX: '10px',
    },
    '.MuiStepLabel-iconContainer > svg': {
        '&.Mui-active, &.Mui-completed': {
            color: theme.palette.stepper.lableComplete,
        },
        '&.Mui-completed': {
            backgroundColor: 'white',
            borderRadius: '25px',
        },
    },
    '.MuiStepLabel-labelContainer': {
        '.Mui-completed': {
            color: '#ffffff',
        },
        '.Mui-active': {
            color: theme.palette.text.secondary,
        },
    },
})
