import { FC } from 'react'
import { Theme } from '@mui/material'
import EvtBox from '../../elements/EvtBox'
import { EvtLoader } from '../../elements/EvtLoader'
import { Settings, useSettings } from '../../query/settings'
import { EVT } from '../../utils/evt'

export const Terms = () => {
    return <Base prop={(r) => r.terms} />
}

export const Privacy = () => {
    return <Base prop={(r) => r.private} />
}

export const About = () => {
    return <Base prop={(r) => r.about} />
}

export const FaQ = () => {
    return <Base prop={(r) => r.faq} />
}

const Base: FC<{ prop: (response: Settings) => string | undefined }> = ({ prop }) => {
    const { data: response, isLoading } = useSettings()
    const value = response ? prop(response) : undefined
    return (
        <EvtLoader loading={isLoading}>
            {value && (
                <EvtBox display="flex" flexDirection="column" alignItems="center" paddingY="30px">
                    <EvtBox sx={wrapperStyle} className="term">
                        <EvtBox>{EVT.html(value)}</EvtBox>
                    </EvtBox>
                </EvtBox>
            )}
        </EvtLoader>
    )
}

const wrapperStyle = (theme: Theme) => ({
    p: {
        textAlign: 'left',
    },
    'h1, h2, h3, h4, h5': {
        textAlign: 'center',
    },
    width: '60%',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        width: '90%',
    },
})
