import React, { FC, useEffect } from 'react'
import { EvtLoader } from '../elements/EvtLoader'
import { useEvtNavigate } from '../hooks/useEvtNavigate'
import { useUser } from '../query/users'
import userUrls from '../urls/userUrls'
import Page from './Page'

interface Props {
    children: React.ReactElement
    roles: string[] | undefined
    pageTitle?: string
}

const AuthorisedPage: FC<Props> = ({ pageTitle, children }) => {
    const navigate = useEvtNavigate()
    const { data: user, isLoading } = useUser()

    useEffect(() => {
        if (!isLoading && !user) {
            navigate(userUrls.pages.login)
        }
    }, [user, isLoading, navigate])

    return (
        <Page pageTitle={pageTitle}>
            <EvtLoader loading={isLoading || !user}>{user && children}</EvtLoader>
        </Page>
    )
}

export default AuthorisedPage
