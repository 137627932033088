import { FC, useEffect, useState } from 'react'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import { useTheme } from '@mui/material'
import FormComponent from '../../components/FormComponent'
import GoogleMapInput from '../../components/GoogleMapInput'
import StepContainerUI, { StepContinueButtonSubmit } from '../../components/StepContainerUI'
import { Countries, States, Titles, isAu } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtCheckbox } from '../../elements/EvtCheckbox'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtFormSelect from '../../elements/EvtFormSelect'
import EvtGrid from '../../elements/EvtGrid'
import EvtPostcodeFormInput from '../../elements/EvtPostcodeFormInput'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { Address } from '../../types/orders/OrderCheckoutResponse'
import { EVT } from '../../utils/evt'
import { evtValidator } from '../../utils/validation'
import { PaymentMethodFormProps } from './PaymentMethodForm'

interface Props {
    id: string
    steps: Array<{ text: string; step: number }>
    myStep: number
    activeStep: number
    hasPhysical: boolean
    savedAddress?: Address | undefined
    billingAddress: BillingAddressProps | undefined
    isSkipShipping: boolean
    isFree: boolean
    onPayment: (values: PaymentMethodFormProps) => void
    onNext: (values: BillingAddressProps) => void
    onBack: (step: number) => void
    onShippingChange: (isSkipShipping: boolean) => void
}

const INITAL_FORM_VALUES = {
    title: Titles[0].value,
    firstName: '',
    lastName: '',
    companyName: '',
    address1: '',
    address2: '',
    suburb: '',
    state: States[0].value,
    postcode: '',
    country: Countries[0].value,
    mobile: '',
    ref: '',
    city: '',
}

export const BillingAddressForm: FC<Props> = ({
    id,
    steps,
    myStep,
    activeStep,
    hasPhysical,
    savedAddress,
    billingAddress,
    isSkipShipping,
    isFree,
    onPayment,
    onNext,
    onBack,
    onShippingChange,
}) => {
    const index = steps.findIndex((s) => s.step === myStep)
    const isActive = activeStep === myStep
    const isDone = activeStep > myStep
    const title = steps[index].text
    const nextTitle = steps[index + 1].text

    const formValidation = evtValidator(formInitialValues)
    const [formValues, setFormValues] = useState<BillingAddressProps>(INITAL_FORM_VALUES)

    useEffect(() => {
        if (billingAddress) {
            billingAddress.ref = billingAddress.ref || ''
            setFormValues(billingAddress)
        }
    }, [billingAddress])

    const toggleStoredAddress = () => {
        formValues.id ? setFormValues(INITAL_FORM_VALUES) : setFormValues(savedAddress as BillingAddressProps)
    }

    const toggleShippingAddress = () => {
        onShippingChange(!isSkipShipping)
    }

    return (
        <StepContainerUI
            id={id}
            index={index}
            title={title}
            isActive={isActive}
            isDone={isDone}
            onBack={() => onBack(myStep)}
        >
            {isDone && billingAddress && <AddressDescription address={billingAddress} />}
            <FormComponent
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={formValidation}
                onSubmit={isFree ? onPayment : onNext}
            >
                {isActive && (
                    <EvtGrid container spacing={5}>
                        {savedAddress && (
                            <EvtGrid item xs={12}>
                                <EvtBox display="flex" alignItems="center">
                                    <EvtCheckbox onChange={toggleStoredAddress} checked={!!formValues.id} />
                                    <EvtTypography>Use stored address?</EvtTypography>
                                </EvtBox>
                                {hasPhysical && (
                                    <EvtBox display="flex" alignItems="center">
                                        <EvtCheckbox onChange={toggleShippingAddress} checked={isSkipShipping} />
                                        <EvtTypography>
                                            My billing address and shipping address are the same
                                        </EvtTypography>
                                    </EvtBox>
                                )}

                                {formValues.id && <AddressDescription address={savedAddress} />}
                            </EvtGrid>
                        )}

                        {!formValues.id && (
                            <>
                                <>
                                    <GridSelectField size={2} label="Title" name="title" options={Titles} />
                                    <GridInputField size={5} label="First Name" name="firstName" />
                                    <GridInputField size={5} label="Last Name" name="lastName" />
                                </>
                                <GridInputField size={6} label="Company Name" name="companyName" />
                                <GridInputField size={6} label="Phone number" name="mobile" />
                                <EvtGrid item xs={12}>
                                    <GoogleMapInput
                                        label="Address"
                                        properties={{
                                            address: 'address1',
                                        }}
                                    />
                                </EvtGrid>
                                <>
                                    <GridInputField size={4} label="Suburb" name="suburb" />
                                    {isAu ? (
                                        <GridSelectField size={4} label="State" name="state" options={States} />
                                    ) : (
                                        <GridInputField size={4} label="City" name="city" />
                                    )}
                                    <GridSelectField size={4} label="Country" name="country" options={Countries} />
                                </>
                                <EvtGrid item xs={12} sm={3}>
                                    <EvtPostcodeFormInput name="postcode" label="Postcode" />
                                </EvtGrid>
                                <br />
                            </>
                        )}
                        <GridInputField size={5} label="Purchase Order / Ref Number" name="ref" />
                        <StepContinueButtonSubmit
                            text={
                                isFree ? (
                                    <>
                                        <ShoppingBagIcon sx={{ mr: '7px' }} />
                                        Place Order
                                    </>
                                ) : (
                                    `Countinue to ${nextTitle}`
                                )
                            }
                        />
                    </EvtGrid>
                )}
            </FormComponent>
        </StepContainerUI>
    )
}

export type BillingAddressProps = Address & {
    ref?: string
}

interface GridInputFieldProps {
    size: number
    name: string
    label: string
}

interface GridSelectFieldProps {
    size: number
    name: string
    label: string
    options: Array<{ value: string; text: string }>
}

const GridInputField: FC<GridInputFieldProps> = ({ size, name, label }) => {
    return (
        <EvtGrid item xs={12} sm={size}>
            <EvtFormInputField label={label} name={name} isRoundBorder />
        </EvtGrid>
    )
}

const GridSelectField: FC<GridSelectFieldProps> = ({ size, name, label, options }) => {
    return (
        <EvtGrid item xs={12} sm={size}>
            <EvtFormSelect options={options} name={name} label={label} fullWidth isRoundBorder isCustom={false} />
        </EvtGrid>
    )
}

const AddressDescription: FC<{ address: Address | undefined }> = ({ address }) => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const theme = useTheme()
    const textColor = theme.palette.stepContainerUI.border
    if (!address) return <></>
    const addy = [address.address1, address.suburb, `${address.state} ${address.country} ${address.postcode}`]
    const bob = addy.filter((a) => a).join(', ')

    return (
        <>
            <EvtTypography
                color={textColor.secondary}
                paddingTop={isDesktop ? '0px' : '20px'}
            >{`${address.title} ${address.firstName} ${address.lastName}`}</EvtTypography>
            <EvtTypography color={textColor.secondary}>{address.companyName}</EvtTypography>
            <EvtTypography color={textColor.secondary}>{address.mobile}</EvtTypography>
            <EvtTypography color={textColor.secondary}>{bob}</EvtTypography>
        </>
    )
}

const formInitialValues: BillingAddressProps = {
    title: Titles[0].value,
    companyName: '',
    firstName: '',
    lastName: '',
    address1: '',
    suburb: '',
    state: States[0].value,
    country: Countries[0].value,
    postcode: '',
    ref: '',
    mobile: '',
}
