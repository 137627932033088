import { FC, ReactNode, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import { styled } from '@mui/material/styles'
import StepContainerUI from '../../components/StepContainerUI'
import { BraintreeHostedFields } from '../../components/braintree/BraintreeHostedFields'
import { BraintreePaypal } from '../../components/braintree/BraintreePaypal'
import { PaymentType } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtButton, EvtButtonPill } from '../../elements/EvtButton'
import EvtCollapse from '../../elements/EvtCollapse'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { BraintreeProvider } from '../../providers/BraintreeProvider'
import { OrderCheckoutResponse } from '../../types/orders/OrderCheckoutResponse'
import { EVT } from '../../utils/evt'

interface Props {
    id: string
    steps: Array<{ text: string; step: number }>
    myStep: number
    activeStep: number
    orderResponse: OrderCheckoutResponse
    shipping?: number
    onNext: (values: PaymentMethodFormProps) => void
    onBack: (step: number) => void
}

export const PaymentMethodForm: FC<Props> = ({
    id,
    steps,
    myStep,
    activeStep,
    orderResponse,
    shipping,
    onNext,
    onBack,
}) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    const index = steps.findIndex((s) => s.step === myStep)
    const isActive = activeStep === myStep
    const isDone = activeStep > myStep
    const title = steps[index].text
    const paymentTypeIds = orderResponse.paymentTypeIds

    const [activePaymentType, setActivePaymentType] = useState<number>(0)
    const handleMethodSwitch = (paymentType: number) => {
        setActivePaymentType(paymentType)
    }
    return (
        <StepContainerUI
            id={id}
            index={index}
            title={title}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={isMobile ? 430 : 360}
            onBack={() => onBack(myStep)}
        >
            {(isActive || isDone) && (
                <PaymentProviderWrapper orderResponse={orderResponse}>
                    <EvtBox display="flex" flexDirection="column" gap="10px">
                        {paymentTypeIds.map((typeId) => {
                            switch (typeId) {
                                case PaymentType.paypal.id: {
                                    return (
                                        <CollapseWrapper
                                            key={typeId}
                                            text={PaymentType.paypal.text}
                                            isActive={activePaymentType === PaymentType.paypal.id}
                                            onClick={() => handleMethodSwitch(PaymentType.paypal.id)}
                                        >
                                            <BraintreePaypal
                                                orderResponse={orderResponse}
                                                shipping={shipping}
                                                onNext={onNext}
                                            />
                                        </CollapseWrapper>
                                    )
                                }
                                case PaymentType.apple.id: {
                                    return (
                                        <TabButton
                                            key={typeId}
                                            onClick={() => {
                                                handleMethodSwitch(PaymentType.apple.id)
                                                onNext({ paymentTypeId: PaymentType.apple.id })
                                            }}
                                        >
                                            {PaymentType.apple.text}
                                        </TabButton>
                                    )
                                }
                                case PaymentType.eft.id: {
                                    return (
                                        <CollapseWrapper
                                            key={typeId}
                                            text={PaymentType.eft.text}
                                            isActive={activePaymentType === PaymentType.eft.id}
                                            onClick={() => handleMethodSwitch(PaymentType.eft.id)}
                                        >
                                            <EFTMethod onNext={onNext} />
                                        </CollapseWrapper>
                                    )
                                }
                                default:
                                    return (
                                        <CollapseWrapper
                                            key={typeId}
                                            text={PaymentType.creditCard.text}
                                            isActive={activePaymentType === PaymentType.creditCard.id}
                                            onClick={() => handleMethodSwitch(PaymentType.creditCard.id)}
                                        >
                                            <BraintreeHostedFields onNext={onNext} />
                                        </CollapseWrapper>
                                    )
                            }
                        })}
                    </EvtBox>
                </PaymentProviderWrapper>
            )}
        </StepContainerUI>
    )
}

const PaymentProviderWrapper: FC<{ orderResponse: OrderCheckoutResponse; children: ReactNode }> = ({ children }) => {
    return (
        <EvtBox>
            <BraintreeProvider>{children}</BraintreeProvider>
        </EvtBox>
    )
}

const EFTMethod: FC<{ onNext: (values: PaymentMethodFormProps) => void }> = ({ onNext }) => {
    return (
        <>
            <EvtBox paddingBottom="25px">
                <EvtTypography fontWeight="bold" paddingBottom="10px">
                    Thanks for selecting Invoice.
                </EvtTypography>
                <EvtTypography>What happens next?</EvtTypography>
                <EvtTypography>
                    Once you place your order, your invoice will be returned. On receipt of full payment,
                </EvtTypography>
                <EvtTypography>
                    your vouchers will be despatched or your eVouchers will be available for sending.
                </EvtTypography>
            </EvtBox>
            <EvtBox width="100%">
                <EvtButtonPill
                    sx={{ padding: '10px 20px', width: 'fit-content', minWidth: '150px' }}
                    onClick={() => onNext({ paymentTypeId: PaymentType.eft.id })}
                >
                    <ShoppingBagIcon sx={{ mr: '7px' }} />
                    Place Order
                </EvtButtonPill>
            </EvtBox>
        </>
    )
}

interface CollapseWrapperProps {
    children?: ReactNode
    text: string
    isActive?: boolean
    onClick: () => void
}

const CollapseWrapper: FC<CollapseWrapperProps> = ({ children, text, isActive, onClick }) => {
    return (
        <EvtBox>
            <TabButton onClick={onClick}>
                {text} <ArrowIconStyles fontSize="small" className={isActive ? 'arrow-down' : ''} />
            </TabButton>

            <EvtCollapse in={isActive}>
                <EvtBox paddingY="20px">{children}</EvtBox>
            </EvtCollapse>
        </EvtBox>
    )
}

interface TabButtonProps {
    children: ReactNode
    onClick: () => void
}
const TabButton: FC<TabButtonProps> = ({ children, onClick }) => {
    return (
        <EvtButton variant="contained" sx={buttonStyle} onClick={onClick}>
            {children}
        </EvtButton>
    )
}

const buttonStyle = () => ({
    width: '100%',
    maxWidth: '300px',
    paddingY: '15px',
    justifyContent: 'space-between',
    backgroundColor: '#2c2c2c',
    border: '1px solid #5c5c5c',
    borderRadius: '5px',
    ':hover': {
        backgroundColor: '#5c5c5c',
    },
})

const ArrowIconStyles = styled(ArrowForwardIosIcon)(({ theme }) => ({
    transition: 'all 0.1s linear',
    '&.arrow-down': {
        transform: 'rotate(90deg)',
    },
}))

export interface PaymentMethodFormProps {
    braintreeNonce?: string
    braintreeDeviceData?: string
    paymentTypeId: number
    paymentProviderId?: number
}
