import { FC, KeyboardEvent, useContext, useEffect, useState } from 'react'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { Autocomplete, Theme } from '@mui/material'
import { useField } from 'formik'
import { theme as customTheme } from '../consts'
import { GetThemeStateContext } from '../providers/CustomThemeProvider'
import EvtBox from './EvtBox'
import { EvtFormInputField } from './EvtFormInputField'

interface Props {
    options: Array<{ text: string; value: string }>
    label: string
    name: string
}

const EvtStateFormInput: FC<Props> = ({ options, label, name }) => {
    const [field] = useField(name)
    const [open, setOpen] = useState(false)
    const isLight = useContext(GetThemeStateContext).theme === customTheme.LIGHT
    const [value, setValue] = useState<string>(field.value)
    const [inputValue, setInputValue] = useState<string>(field.value)

    const setSelectorValue = () => {
        const filterOptions = options.filter((option) => option.text.toLowerCase().includes(value.toLowerCase()))
        setValue(filterOptions.length > 0 && value.length > 0 ? filterOptions[0].text : '')
    }

    useEffect(() => {
        setValue(field.value)
    }, [field.value])

    useEffect(() => {
        const typingTimer = setTimeout(setSelectorValue, 800)
        return () => clearTimeout(typingTimer)
    }, [value])

    return (
        <Autocomplete
            fullWidth
            freeSolo
            value={value}
            open={open}
            disableClearable
            options={options.map((option) => option.text)}
            onFocus={() => setValue('')}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            sx={(t: Theme) => SelectStyle(t, isLight)}
            onInputChange={(e: React.SyntheticEvent, value: string) => setValue(value)}
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => setInputValue((e.target as HTMLInputElement).value)}
            filterOptions={(options, state) =>
                options.filter((option) => option.toLocaleLowerCase().includes(inputValue))
            }
            renderInput={(params) => {
                const InputProps = { ...params.InputProps }
                InputProps.endAdornment = <ArrowDropDownOutlinedIcon className="evt-autoselect-icon" />
                return (
                    <EvtBox onClick={(e: any) => e.target.localName !== 'input' && setOpen((s) => !s)}>
                        <EvtFormInputField
                            isRoundBorder
                            {...params}
                            InputProps={InputProps}
                            label={label}
                            name={name}
                        />
                    </EvtBox>
                )
            }}
        />
    )
}

export default EvtStateFormInput

const SelectStyle = (theme: Theme, isLight: boolean) => ({
    position: 'relative',
    '.MuiInputBase-root': {
        input: { textAlign: 'center' },
        paddingRight: '45px !important',
    },
    '.evt-autoselect-icon': {
        userSelect: 'none',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fill: 'currentColor',
        WebkitSlexShrink: 0,
        MsFlexNegative: 0,
        flexShrink: 0,
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontSize: '1.5rem',
        position: 'absolute',
        right: '7px',
        top: 'calc(50% - 0.5em)',
        pointerEvents: 'none',
        color: isLight ? 'rgba(0, 0, 0, 0.54)' : 'white',
        zIndex: 9,
    },
    input: {
        zIndex: 9,
    },
})
