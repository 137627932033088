import { FC, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import LoginIcon from '@mui/icons-material/Login'
import MenuIcon from '@mui/icons-material/Menu'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Theme } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import EvtAppBar from '../../elements/EvtAppBar'
import EvtAvatar from '../../elements/EvtAvatar'
import EvtBadge from '../../elements/EvtBadge'
import EvtBox from '../../elements/EvtBox'
import { EvtButton } from '../../elements/EvtButton'
import EvtIconButton from '../../elements/EvtIconButton'
import { EvtLinkTo } from '../../elements/EvtLinkTo'
import { EvtLoader } from '../../elements/EvtLoader'
import EvtToolbar from '../../elements/EvtToolbar'
import EvtTypography from '../../elements/EvtTypography'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { GetCartStateContext } from '../../providers/CartProvider'
import { useUser } from '../../query/users'
import { Product } from '../../types/products/Product'
import { LoggedInUser } from '../../types/users/User'
import homeUrls from '../../urls/homeUrls'
import userUrls from '../../urls/userUrls'
import { EVT } from '../../utils/evt'
import { EvtCorpLogo } from '../EvtCorpLogo'
import CartMenu from './CartMenu'
import MobileMenu from './MobileMenu'
import UserMenu from './UserMenu'

interface Props {
    open: boolean
    handleDrawerToggle: (isOpen: boolean) => void
}

const TopNavbar: FC<Props> = ({ open = false, handleDrawerToggle }) => {
    const theme = useTheme()
    const {
        palette: { nav: navTheme },
    } = theme
    const { data: user, isLoading: userLoading } = useUser()
    const location = useLocation()
    const isCheckoutPage = location.pathname.startsWith('/checkout/')

    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)
    const [cartMenuAnchorEl, setCartMenuAnchorEl] = useState<null | HTMLElement>(null)

    const userMenuId = 'evt-user-menu'
    const mobileMenuId = 'evt-menu-mobile'
    const cartMenuId = 'evt-cart-menu'
    const isUserMenuOpen = Boolean(userMenuAnchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
    const isCartMenuOpen = Boolean(cartMenuAnchorEl)

    const toggleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        userMenuAnchorEl ? setUserMenuAnchorEl(null) : setUserMenuAnchorEl(event.currentTarget)
    }

    const toggleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
        mobileMoreAnchorEl ? setMobileMoreAnchorEl(null) : setMobileMoreAnchorEl(event.currentTarget)
    }

    const toggleCartMenu = (event: React.MouseEvent<HTMLElement>) => {
        cartMenuAnchorEl ? setCartMenuAnchorEl(null) : setCartMenuAnchorEl(event.currentTarget)
    }

    const handelCartMenuClose = () => {
        setCartMenuAnchorEl(null)
    }

    return (
        <EvtBox>
            <EvtAppBarStyles position="fixed" open={open} sx={{ height: theme.navbar.topNav.topNavHeight }}>
                <EvtToolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <EvtButton
                        aria-label="open drawer"
                        onClick={() => handleDrawerToggle(true)}
                        sx={{ width: theme.navbar.drawer.closeWidth, position: 'absolute', left: 0 }}
                    >
                        <MenuIcon
                            sx={{
                                color: navTheme.sideTitleTx,
                                fontSize: 'xx-large',
                            }}
                        />
                    </EvtButton>
                    <EvtBox width="100%" display="flex" justifyContent="center">
                        <EvtLinkTo to={homeUrls.pages.index} sx={logoLinkStyle}>
                            <EvtCorpLogo />
                        </EvtLinkTo>
                    </EvtBox>

                    <EvtBox pr={theme.spacing(3)} sx={{ position: 'absolute', right: 0 }}>
                        <EvtLoader loading={userLoading}>
                            {user ? (
                                <EvtBox display="flex" gap={theme.spacing(4)}>
                                    <UserAvatar user={user} userMenuId={userMenuId} toggleUserMenu={toggleUserMenu} />

                                    {!isCheckoutPage && (
                                        <ShoppingCartButton userMenuId={userMenuId} toggleCartMenu={toggleCartMenu} />
                                    )}
                                </EvtBox>
                            ) : (
                                <LoginButton />
                            )}
                        </EvtLoader>
                    </EvtBox>
                </EvtToolbar>
            </EvtAppBarStyles>

            <MobileMenu
                anchorEl={mobileMoreAnchorEl}
                menuId={mobileMenuId}
                isMenuOpen={isMobileMenuOpen}
                handleMenuClose={toggleMobileMenu}
            />
            <UserMenu
                anchorEl={userMenuAnchorEl}
                menuId={userMenuId}
                isMenuOpen={isUserMenuOpen}
                handleMenuClose={toggleUserMenu}
            />
            <CartMenu
                anchorEl={cartMenuAnchorEl}
                menuId={cartMenuId}
                isMenuOpen={isCartMenuOpen}
                handleMenuClose={toggleCartMenu}
                forceMenuClose={handelCartMenuClose}
            />
        </EvtBox>
    )
}

export default TopNavbar

const EvtAppBarStyles = styled(EvtAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
    backgroundColor: theme.palette.nav.topBgColor,
    '.MuiToolbar-root': {
        padding: 0,
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const LoginButton = () => {
    const navigate = useEvtNavigate()
    const theme = useTheme()
    const {
        palette: { nav: navTheme },
    } = theme
    return (
        <EvtButton
            onClick={() => {
                return navigate(userUrls.pages.login)
            }}
        >
            <LoginIcon
                sx={{
                    color: navTheme.sideTitleTx,
                    marginRight: theme.spacing(2),
                    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
                        fontSize: 'large',
                    },
                }}
            />
            <EvtTypography
                sx={{
                    color: navTheme.sideTitleTx,
                    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
                        fontSize: '0.9rem',
                    },
                }}
            >
                Login
            </EvtTypography>
        </EvtButton>
    )
}

interface ShoopingCartProps {
    userMenuId: string
    toggleCartMenu: (event: React.MouseEvent<HTMLElement>) => void
}
const ShoppingCartButton: FC<ShoopingCartProps> = ({ userMenuId, toggleCartMenu }) => {
    const theme = useTheme()
    const cart = useContext(GetCartStateContext)
    // @ts-ignore
    const totalQuantity = cart.items?.reduce((quantity: number, item: Product) => quantity + item.quantity, 0) || 0

    return (
        <EvtIconButton
            size="large"
            aria-controls={userMenuId}
            aria-haspopup="true"
            color="inherit"
            onClick={toggleCartMenu}
            sx={{
                [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                    padding: theme.spacing(1),
                },
            }}
        >
            <EvtBadge badgeContent={totalQuantity} color="error">
                <ShoppingCartIcon sx={{ color: theme.palette.nav.cartIcon }} />
            </EvtBadge>
        </EvtIconButton>
    )
}

interface UserAvatarProps {
    user: LoggedInUser
    userMenuId: string
    toggleUserMenu: (event: React.MouseEvent<HTMLElement>) => void
}
const UserAvatar: FC<UserAvatarProps> = ({ user, userMenuId, toggleUserMenu }) => {
    const theme = useTheme()
    return (
        <EvtIconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={userMenuId}
            aria-haspopup="true"
            onClick={toggleUserMenu}
            sx={{
                height: '46px',
                padding: theme.spacing(2),
                [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                    padding: theme.spacing(1),
                },
            }}
        >
            <EvtAvatar
                alt={EVT.getUsersName(user)}
                sx={{
                    width: 30,
                    height: 30,
                    fontSize: theme.spacing(2.5),
                }}
            >
                {EVT.getUsersInitial(user)}
            </EvtAvatar>
        </EvtIconButton>
    )
}

const logoLinkStyle = (theme: Theme) => ({
    width: '400px',
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
        marginLeft: theme.navbar.drawer.closeWidth,
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: '300px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: '220px',
    },
    [theme.breakpoints.down(450)]: {
        width: '180px',
    },
})
