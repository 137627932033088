import { Theme } from '@mui/material/styles'
import EvtBox from '../elements/EvtBox'
import { useSettings } from '../query/settings'
import { EVT } from '../utils/evt'

const Footer = () => {
    const { data: settings } = useSettings()
    return (
        <EvtBox sx={wrapperStyle} className="evt-footer">
            <EvtBox sx={contentWrapperStyle}>
                <EvtBox className="footer-image"></EvtBox>
            </EvtBox>

            <EvtBox sx={contentWrapperStyle}>
                <p className="evt-copyright">© {EVT.format(new Date(), 'yyyy')} Event Cinemas</p>
                {settings && EVT.html(settings.footer)}
                <img className="part-of-evt-img desktop" src={require('./../icons/evt-logo.svg').default} alt="Logo" />
            </EvtBox>
            <img className="part-of-evt-img mobile" src={require('./../icons/evt-logo.svg').default} alt="Logo" />
        </EvtBox>
    )
}

export default Footer

const wrapperStyle = (theme: Theme) => ({
    textAlign: 'center',
    padding: `40px 0 20px ${theme.navbar.drawer.closeWidth}`,
    bgcolor: theme.palette.footer.bgColor,
    width: '100%',
    '> div': {
        color: '#ffffff',
    },
    '.evt-copyright:after': {
        content: '"|"',
        margin: '0 5px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        fontSize: '10px',
        padding: '20px',
    },
    a: {
        textDecoration: 'none',
        textDecorationColor: '#ffffff',
        color: '#ffffff !important',
        '&:visited': {
            color: '#ffffff',
        },
    },
    '.part-of-evt-img': {
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            '&.desktop': {
                display: 'none',
            },
            '&.mobile': {
                display: 'inline',
            },
        },
        display: 'none',
        padding: '8px 12px',
        background: '#FFFFFF',
        height: '32px',
    },
})

const contentWrapperStyle = (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '.footer-image': {
        backgroundImage: `url(${theme.palette.footerUrl})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '120px',
        width: '100%',
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            height: '80px',
        },
    },
    '.part-of-evt-img': {
        display: 'block',
        position: 'absolute',
        right: 0,
    },
})
