import { Fragment, ReactNode, useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AlertModal } from './components/AlertModal'
import AnimationMask from './components/AnimationMask'
import Navbar from './components/AppBar/Navbar'
import AuthorisedPage from './components/AuthorisedPage'
import ErrorBoundaryComponent from './components/ErrorBoundaryComponent'
import Footer from './components/Footer'
import { GlobalModal } from './components/GlobalModal'
import LoaderSpinner from './components/LoaderSpinner'
import MessageAlert from './components/MessageAlert'
import Page from './components/Page'
import { ViewAsWrapper } from './components/ViewAsWrapper'
import { theme } from './consts'
import EvtScrollToTop from './elements/EvtScrollToTop'
import UnExpectPage from './pages/UnExpectPage'
import ShoppingCart from './pages/cart/ShoppingCart'
import Checkout from './pages/checkout/Checkout'
import DigitalHub from './pages/digitalHub/DigitalHub'
import Evoucher from './pages/evoucher/Evoucher'
import { Home } from './pages/home/Home'
import Assign from './pages/order/Assign'
import OrderDetail from './pages/order/OrderDetail'
import Orders from './pages/order/Orders'
import ProductDetail from './pages/products/ProductDetail'
import Products from './pages/products/Products'
import { ContactUs } from './pages/settings/ContactUs'
import { About, FaQ, Privacy, Terms } from './pages/settings/Settings'
import { Login } from './pages/user/Login'
import PasswordRest from './pages/user/PasswordRest'
import Profile from './pages/user/Profile'
import { ViewAs } from './pages/user/ViewAs'
import { AnimationMaskProvider } from './providers/AnimationMaskProvider'
import { CartProvider } from './providers/CartProvider'
import { CustomThemeProvider, GetThemeStateContext } from './providers/CustomThemeProvider'
import { LoaderProvider } from './providers/LoaderProvider'
import { MessageAlertProvider } from './providers/MessageAlertProvider'
import { AlertModalProvider } from './providers/modals/AlertModalProvider'
import { GlobalModalProvider } from './providers/modals/GlobalModalProvider'
import Theme_Basic from './theme/theme.basic'
import Palette_Dark, { MuiComponents_Dark } from './theme/theme.palette.dark'
import Palette_Light, { MuiComponents_Light } from './theme/theme.palette.light'
import { PageURL } from './urls'
import cartUrls from './urls/cartUrls'
import checkoutUrls from './urls/checkoutUrls'
import { digitalHubUrls } from './urls/digitalHubUrls'
import eVoucherUrls from './urls/eVoucherUrls'
import homeUrls from './urls/homeUrls'
import orderUrls from './urls/orderUrls'
import productUrls from './urls/productUrls'
import userUrls from './urls/userUrls'

const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const Rounters = () => {
    // @ts-ignore
    const errorBoundaryCallback = ({ resetErrorBoundary }) => (
        <ErrorBoundaryComponent resetErrorBoundary={resetErrorBoundary} />
    )
    return (
        <BrowserRouter>
            <EvtScrollToTop />
            <CssBaseline />
            <GlobalModal />
            <AlertModal />
            <LoaderSpinner />
            <AnimationMask />
            <Navbar />
            <MessageAlert />

            <ErrorBoundary FallbackComponent={errorBoundaryCallback}>
                <Routes>
                    {authorisedRoute(digitalHubUrls.pages.index, <DigitalHub />)}
                    {authorisedRoute(cartUrls.pages.cart, <ShoppingCart />)}
                    {authorisedRoute(checkoutUrls.pages.checkout, <Checkout />)}
                    {authorisedRoute(
                        checkoutUrls.pages.confirm,
                        <OrderDetail title="Confirmation" showTickAnimation />,
                    )}
                    {authorisedRoute(productUrls.pages.index, <Products />)}
                    {authorisedRoute(productUrls.pages.details, <ProductDetail />)}

                    {authorisedRoute(orderUrls.pages.index, <Orders />)}
                    {authorisedRoute(orderUrls.pages.details, <OrderDetail title="Order Detail" />)}
                    {authorisedRoute(orderUrls.pages.assign, <Assign />)}
                    {authorisedRoute(userUrls.pages.profile, <Profile />)}
                    {route(userUrls.pages.viewAs, <ViewAs />)}

                    {route(userUrls.pages.passwordReset, <PasswordRest />)}
                    {route(userUrls.pages.login, <Login />)}
                    {route(homeUrls.pages.index, <Home />)}
                    {route(eVoucherUrls.pages.details, <Evoucher />)}
                    {route(homeUrls.pages.terms, <Terms />)}
                    {route(homeUrls.pages.privacy, <Privacy />)}
                    {route(homeUrls.pages.about, <About />)}
                    {route(homeUrls.pages.faq, <FaQ />)}
                    {route(homeUrls.pages.contactUs, <ContactUs />)}

                    <Route
                        path="*"
                        element={
                            <Page pageTitle={'Error'}>
                                <UnExpectPage />
                            </Page>
                        }
                    />
                </Routes>
            </ErrorBoundary>

            <Footer />
            <ViewAsWrapper />
        </BrowserRouter>
    )
}

const CustomProviders = (props: any) => {
    const providerArray = [
        AlertModalProvider,
        GlobalModalProvider,
        MessageAlertProvider,
        CartProvider,
        LoaderProvider,
        AnimationMaskProvider,
    ]

    const providerGenerator = (providerArray: Array<(props: { children: ReactNode }) => ReactJSXElement>) => {
        const Wrapper = providerArray[0]
        let Child = null

        if (providerArray.length === 0) {
            return props.children
        } else {
            Child = providerGenerator(providerArray.slice(1))
        }

        return <Wrapper>{Child}</Wrapper>
    }

    return (
        <CustomThemeProvider>
            <ThemeContextProvider>
                {providerGenerator(providerArray)}
                <ReactQueryDevtools initialIsOpen={true} />
            </ThemeContextProvider>
        </CustomThemeProvider>
    )
}

const ThemeContextProvider = (props: any) => {
    const theme_dark = createTheme({
        ...Theme_Basic,
        ...MuiComponents_Dark,
        palette: { ...Palette_Dark, mode: theme.DARK as 'dark' },
    })
    const theme_light = createTheme({
        ...Theme_Basic,
        ...MuiComponents_Light,
        palette: { ...Palette_Light, mode: theme.LIGHT as 'light' },
    })

    const themeCtx = useContext(GetThemeStateContext)
    return (
        <ThemeProvider theme={themeCtx.theme === theme.LIGHT ? theme_light : theme_dark}>
            {props.children}
        </ThemeProvider>
    )
}

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: false,
                staleTime: twentyFourHoursInMs,
            },
        },
    })
    return (
        <QueryClientProvider client={queryClient} contextSharing={true}>
            <CustomProviders>
                <Rounters />
            </CustomProviders>
        </QueryClientProvider>
    )
}

export default App

const route = (page: PageURL, element: JSX.Element) => {
    return registerOptionalParams(page.url, <Page pageTitle={page.text}>{element}</Page>)
}

const authorisedRoute = (page: PageURL, element: JSX.Element) => {
    return registerOptionalParams(
        page.url,
        <AuthorisedPage roles={page.roles} pageTitle={page.text}>
            {element}
        </AuthorisedPage>,
    )
}

const registerOptionalParamRoute = (optionalParams: string[], element: JSX.Element) => {
    if (optionalParams.length === 0) return <Fragment />

    const param = optionalParams[0]
    optionalParams.splice(0, 1)

    return (
        <Route path={param} element={element}>
            {registerOptionalParamRoute(optionalParams, element)}
        </Route>
    )
}

const registerOptionalParams = (path: string, element: JSX.Element) => {
    let basePath = path
    let optionalParams = []
    if (basePath.includes('?')) {
        basePath = ''
        const params = path.split('/')

        for (let i = 0; i < params.length; i++) {
            if (params[i] === '') continue

            if (!params[i].includes('?')) basePath += '/' + params[i]
            else optionalParams.push(params[i].substr(0, params[i].length - 1))
        }
    }

    return (
        <Route path={basePath} key={basePath} element={element}>
            {optionalParams.length > 0 && registerOptionalParamRoute(optionalParams, element)}
        </Route>
    )
}
