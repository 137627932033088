import { FC, useContext, useState } from 'react'
import Dropzone from 'react-dropzone'
import DoneIcon from '@mui/icons-material/Done'
import DownloadIcon from '@mui/icons-material/Download'
import InfoIcon from '@mui/icons-material/Info'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Theme } from '@mui/material'
import Papa from 'papaparse'
import { StepContinueButton } from '../../../components/StepContainerUI'
import { theme } from '../../../consts'
import EvtBox from '../../../elements/EvtBox'
import { EvtButton } from '../../../elements/EvtButton'
import EvtLink from '../../../elements/EvtLink'
import EvtTooltip from '../../../elements/EvtTooltip'
import EvtTypography from '../../../elements/EvtTypography'
import useDeviceType from '../../../hooks/useDeviceType'
import { GetThemeStateContext } from '../../../providers/CustomThemeProvider'
import { useSettings } from '../../../query/settings'
import { EVT } from '../../../utils/evt'
import { Recipient } from '../AssignRecipient'

export interface RecipientCSV {
    value: { file: File | null; recipients: Array<Recipient> }
    isActive: boolean
}

interface Props {
    data: RecipientCSV
    onChange: (value: RecipientCSV | null) => void
    onNext: (value: RecipientCSV) => void
}

const AssignAddCSV: FC<Props> = ({ data, onChange, onNext }) => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const isLight = useContext(GetThemeStateContext).theme === theme.LIGHT
    const { data: settings } = useSettings()
    const [csvFile, setCsvFile] = useState<File | null>(data.value.file)
    const handleNext = () => {
        if (csvFile) {
            const reader = new FileReader()
            reader.readAsBinaryString(csvFile)
            const recipients: Array<Recipient> = []
            reader.onload = () => {
                let result = reader.result as string
                const topLine = result.substring(0, result.indexOf('\r\n')).toLowerCase()
                if (topLine.indexOf('email') !== 0) {
                    result = 'Email,Name\r\n' + result
                }
                Papa.parse(result, {
                    header: true,
                    complete: (results) => {
                        results.data.forEach((recipient: any, i: number) => {
                            const r = { id: i, email: recipient.email || recipient.Email } as Recipient
                            if (r.email) recipients.push(r)
                        })
                        onNext({ ...data, value: { file: csvFile, recipients }, isActive: true })
                    },
                })
            }
        }
    }
    const fileUpload = (files: Array<File>) => {
        setCsvFile(files[0])
        onChange({ ...data, value: { file: csvFile, recipients: [] }, isActive: true })
    }
    const downloadCSVTemplate = () => {
        window.open(
            'https://cdn.eventcinemas.com.au/cdn/resources/contentupload/giftshop/corporate/csvtemplate/corporate_csvtemplate.csv',
        )
    }

    return (
        <>
            <EvtBox sx={docUploadContainer}>
                <EvtBox display="flex" alignItems="center">
                    <Dropzone accept={{ 'text/csv': ['.csv'] }} onDrop={fileUpload} maxFiles={1} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <EvtButton variant="contained" sx={uploadBtnStyle}>
                                    <UploadFileIcon sx={{ pr: '5px', fontSize: '28px' }} />
                                    <EvtTypography fontSize="15px">
                                        {isDesktop ? 'Upload from computer' : 'Upload'}
                                    </EvtTypography>
                                </EvtButton>
                            </div>
                        )}
                    </Dropzone>

                    <EvtBox sx={docInputnStyle}>
                        <EvtTypography fontSize="15px" minWidth={isDesktop ? '220px' : '130px'}>
                            {csvFile?.name || 'No File Seleted'}
                        </EvtTypography>
                    </EvtBox>
                </EvtBox>
                {csvFile && (
                    <EvtBox display="flex" alignItems="center">
                        <DoneIcon fontSize="medium" sx={{ mr: '15px' }} color="success" />
                        <EvtLink
                            sx={{ cursor: 'pointer' }}
                            color="error"
                            onClick={() => {
                                setCsvFile(null)
                                onChange({ ...data, value: { file: null, recipients: [] }, isActive: true })
                            }}
                        >
                            Remove
                        </EvtLink>
                    </EvtBox>
                )}
            </EvtBox>

            <EvtBox sx={downloadBtn}>
                <EvtButton variant={isLight ? 'contained' : 'outlined'} onClick={downloadCSVTemplate}>
                    <DownloadIcon />
                    Download CSV Template
                </EvtButton>
                <EvtTooltip title={EVT.html(settings?.contentCopy.corporate_Assign_CSVInfo || '')}>
                    <InfoIcon />
                </EvtTooltip>
            </EvtBox>

            <StepContinueButton disabled={csvFile === null} onClick={handleNext} text="Next" />
        </>
    )
}

export default AssignAddCSV

const downloadBtn = (theme: Theme) => ({
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    button: {
        borderRadius: '20px',
        border: 'unset',
        fontWeight: 'normal',
    },
    svg: {
        marginLeft: '5px',
        fontSize: '13px',
    },
})

const docUploadContainer = (theme: Theme) => ({
    display: 'flex',
    alignItems: 'cetner',
    gap: '15px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        flexDirection: 'column',
    },
})

const uploadBtnStyle = (theme: Theme) => ({
    height: '56px',
    bgcolor: theme.palette.assignAddCSV.uploadBtnBg,
    border: `1px solid ${theme.palette.assignAddCSV.border}`,
    borderRadius: '35px 0 0 35px',
})
const docInputnStyle = (theme: Theme) => ({
    padding: '12px 25px',
    borderRadius: '0px 30px 30px 0',
    borderTop: `1px solid ${theme.palette.assignAddCSV.border}`,
    borderBottom: `1px solid ${theme.palette.assignAddCSV.border}`,
    borderRight: `1px solid ${theme.palette.assignAddCSV.border}`,
    bgcolor: theme.palette.assignAddCSV.docInputBg,
    color: 'red',
})
