import React, { FC } from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import EvtBadge from '../../elements/EvtBadge'
import EvtIconButton from '../../elements/EvtIconButton'
import EvtMenu from '../../elements/EvtMenu'
import EvtMenuItem from '../../elements/EvtMenuItem'

interface Props {
    anchorEl: null | HTMLElement
    menuId: string
    isMenuOpen: boolean
    handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void
}

const MobileMenu: FC<Props> = ({ anchorEl, menuId, isMenuOpen, handleMenuClose }) => {
    return (
        <EvtMenu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <EvtMenuItem>
                <EvtIconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <EvtBadge badgeContent={4} color="error">
                        <ShoppingCartIcon />
                    </EvtBadge>
                </EvtIconButton>
                <p>Messages</p>
            </EvtMenuItem>
            <EvtMenuItem>
                <EvtIconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    {/* <AccountCircle /> */}
                </EvtIconButton>
                <p>Profile</p>
            </EvtMenuItem>
        </EvtMenu>
    )
}

export default MobileMenu
