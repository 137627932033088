import { FC, ReactNode, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { styled, useTheme } from '@mui/material'
import StepContainerUI from '../../components/StepContainerUI'
import EvtBox from '../../elements/EvtBox'
import { EvtCheckbox } from '../../elements/EvtCheckbox'
import EvtCollapse from '../../elements/EvtCollapse'
import EvtDivider from '../../elements/EvtDivider'
import EvtTypography from '../../elements/EvtTypography'
import { RecipientSectionDataProps, STEP } from './Assign'
import AssignAddCSV, { RecipientCSV } from './assignTypes/AssignAddCSV'
import AssignManual, { RecipientManual } from './assignTypes/AssignManual'
import AssignMyself, { RecipientMyself } from './assignTypes/AssignMyself'

interface Props {
    activeStepIndex: number
    recipientStep: STEP
    remaining: number
    recipientSectionData: RecipientSectionDataProps
    onChange: (data: RecipientSectionDataProps) => void
    onNext: (data: RecipientSectionDataProps) => void
    onBack: () => void
}

interface SelectType {
    text: string
    value: number
    isDoneTxt: string
}

const TYPE_CSV = { text: 'Upload CSV', value: 1, isDoneTxt: '' }
const TYPE_MANUAL = { text: 'Assign Manually', value: 2, isDoneTxt: '' }
const TYPE_MYSELF = { text: 'Send to Myself', value: 3, isDoneTxt: '' }
const DEFAULT_TYPE = { text: '', value: 0, isDoneTxt: '' }

const AssignRecipient: FC<Props> = ({
    activeStepIndex,
    recipientStep,
    remaining,
    recipientSectionData,
    onChange,
    onNext,
    onBack,
}) => {
    const isActive = activeStepIndex === recipientStep.index
    const isDone = activeStepIndex > recipientStep.index
    const theme = useTheme()
    const [selectedType, setSelectedType] = useState<SelectType>(DEFAULT_TYPE)
    const handleTypeClick = (type: SelectType) => {
        setSelectedType(type.value === selectedType.value ? DEFAULT_TYPE : type)
    }

    const handleChange = (
        data: RecipientCSV | RecipientManual | RecipientMyself | null,
        dataType: 'csv' | 'manual' | 'myself',
        actionType: 'next' | 'change',
    ) => {
        recipientSectionData.csv.isActive = false
        recipientSectionData.manual.isActive = false
        recipientSectionData.myself.isActive = false
        if (dataType === 'csv') {
            recipientSectionData.csv = data as RecipientCSV
        } else if (dataType === 'manual') {
            recipientSectionData.manual = data as RecipientManual
        } else {
            recipientSectionData.myself = data as RecipientMyself
        }
        actionType === 'next' ? onNext(recipientSectionData) : onChange(recipientSectionData)
    }

    return (
        <StepContainerUI
            id={recipientStep.id}
            index={recipientStep.index}
            title="Assign Recipients"
            isAssign={true}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={450}
            onBack={onBack}
        >
            {isActive && (
                <>
                    <EvtTypography color={theme.palette.stepContainerUI.border.default}>Select one</EvtTypography>
                    <EvtBox pt="10px">
                        <AssignTab selectedType={selectedType} type={TYPE_CSV} onClick={handleTypeClick}>
                            <AssignAddCSV
                                data={recipientSectionData.csv}
                                onChange={(data) => handleChange(data, 'csv', 'change')}
                                onNext={(data) => handleChange(data, 'csv', 'next')}
                            />
                        </AssignTab>
                        <EvtDivider />

                        <AssignTab selectedType={selectedType} type={TYPE_MANUAL} onClick={handleTypeClick}>
                            <AssignManual
                                remaining={remaining}
                                data={recipientSectionData.manual}
                                onChange={(data) => handleChange(data, 'manual', 'change')}
                                onNext={(data) => handleChange(data, 'manual', 'next')}
                            />
                        </AssignTab>
                        <EvtDivider />

                        <AssignTab selectedType={selectedType} type={TYPE_MYSELF} onClick={handleTypeClick}>
                            <AssignMyself
                                remaining={remaining}
                                data={recipientSectionData.myself}
                                onChange={(data) => handleChange(data, 'myself', 'change')}
                                onNext={(data) => handleChange(data, 'myself', 'next')}
                            />
                        </AssignTab>
                    </EvtBox>
                </>
            )}
            {isDone && <DoneSection recipientSectionData={recipientSectionData} />}
        </StepContainerUI>
    )
}

export default AssignRecipient

const DoneSection: FC<{ recipientSectionData: RecipientSectionDataProps }> = ({ recipientSectionData }) => {
    const { csv, manual } = recipientSectionData
    const doneTxt = csv.isActive
        ? `${csv.value.file?.name} is Uploaded`
        : `${manual.value.length} emails has been added`

    return <EvtBox>{doneTxt}</EvtBox>
}

interface AssignTabProps {
    children: ReactNode
    selectedType: SelectType
    type: SelectType
    onClick: (value: SelectType) => void
}
const AssignTab: FC<AssignTabProps> = ({ children, selectedType, type, onClick }) => {
    const theme = useTheme()
    const textColor = theme.palette.stepContainerUI.border
    let color = textColor.secondary
    const isActive = selectedType.value === type.value
    if (isActive || !selectedType.value) {
        color = textColor.default
    }
    return (
        <>
            <EvtBox sx={assignTabStyle} onClick={() => onClick(type)}>
                <EvtBox display="flex" alignItems="center">
                    <EvtCheckbox checked={isActive} sx={{ color: color }} />
                    <EvtTypography color={color}>{type.text}</EvtTypography>
                </EvtBox>
                <ArrowIconStyles fontSize="small" sx={{ color: color }} className={isActive ? 'arrow-down' : ''} />
            </EvtBox>

            <EvtCollapse in={selectedType.value === type.value}>
                <EvtBox sx={contentWrapperStyle}>{children}</EvtBox>
            </EvtCollapse>
        </>
    )
}

const contentWrapperStyle = () => ({
    padding: '20px 0',
})

const assignTabStyle = () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
})

const ArrowIconStyles = styled(ArrowForwardIosIcon)(() => ({
    fontSize: 'small',
    transition: 'all 0.1s linear',
    '&.arrow-down': {
        transform: 'rotate(90deg)',
    },
}))

export interface Recipient {
    id: number
    email: string
    qty: number
}
