import { FC } from 'react'
import { Theme } from '@mui/material'
import FormComponent from '../../components/FormComponent'
import GoogleMapInput from '../../components/GoogleMapInput'
import { Countries, States, Titles, isAu } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtSubmitButton } from '../../elements/EvtButton'
import { EvtFormCheckbox } from '../../elements/EvtFormCheckbox'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtFormSelect from '../../elements/EvtFormSelect'
import EvtGrid from '../../elements/EvtGrid'
import EvtLink from '../../elements/EvtLink'
import EvtPostcodeFormInput from '../../elements/EvtPostcodeFormInput'
import EvtStateFormInput from '../../elements/EvtStateFormInput'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { useJoinUser } from '../../query/users'
import { Join } from '../../types/users/User'
import homeUrls from '../../urls/homeUrls'
import { EVT } from '../../utils/evt'
import { evtValidator } from '../../utils/validation'

const INITIAL_FORM_STATE: Join = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    addressLine2: '',
    state: '',
    country: Countries[0].value,
    companyName: '',
    title: '',
    confirmEmail: '',
    confirmPassword: '',
    optin: false,
    password: '',
    termsOfService: false,
    city: '',
    suburb: '',
    postCode: '',
    phoneNumber: '',
}

const FORM_VALIDATION = evtValidator(INITIAL_FORM_STATE)

const SignUpForm = () => {
    const navigate = useEvtNavigate()
    const deviceType = useDeviceType()

    const isMobile = EVT.isMobile(deviceType)
    const { mutate: createUser } = useJoinUser()

    const onSubmit = (values: Join) => {
        createUser(values)
    }

    return (
        <FormComponent initialValues={INITIAL_FORM_STATE} validationSchema={FORM_VALIDATION} onSubmit={onSubmit}>
            <EvtBox sx={wrapperStyle}>
                <EvtTypography fontSize="25px">New Customer / Create Account</EvtTypography>
                <EvtGrid container spacing="20px">
                    <EvtGrid item xs={isMobile ? 12 : 3}>
                        <EvtFormSelect
                            options={Titles}
                            name="title"
                            fullWidth
                            isRoundBorder
                            isCustom={false}
                            label="Title"
                        />
                    </EvtGrid>

                    <GridInputField size={5} label="First Name" name="firstName" isMobile={isMobile} />
                    <GridInputField size={4} label="Last Name" name="lastName" isMobile={isMobile} />
                    <GridInputField size={6} label="Company Name" name="companyName" isMobile={isMobile} />
                    <GridInputField size={6} label="Phone Number" name="phoneNumber" isMobile={isMobile} />

                    <EvtGrid item xs={12}>
                        <GoogleMapInput
                            label="Address"
                            properties={{
                                postcode: 'postCode',
                            }}
                        />
                    </EvtGrid>

                    <GridInputField name="suburb" label="Suburb" isMobile={isMobile} />
                    <EvtGrid item xs={isMobile ? 12 : 6}>
                        <EvtPostcodeFormInput name="postCode" label="Postcode" />
                    </EvtGrid>
                    {isAu ? (
                        <>
                            <EvtGrid item xs={isMobile ? 12 : 6}>
                                <EvtStateFormInput name="state" label="State" options={States} />
                            </EvtGrid>
                        </>
                    ) : (
                        <GridInputField size={6} label="City" name="city" isMobile={isMobile} />
                    )}
                    <EvtGrid item xs={isMobile ? 12 : 6}>
                        <EvtFormSelect
                            options={Countries}
                            isRoundBorder
                            name="country"
                            isCustom={false}
                            label="Country"
                            disabled
                        />
                    </EvtGrid>

                    <GridInputField label="Email" name="email" isMobile={isMobile} />
                    <GridInputField label="Confirm Email" name="confirmEmail" isMobile={isMobile} />
                    <GridInputField label="Password" name="password" isPassword isMobile={isMobile} />
                    <GridInputField label="Confirm Password" name="confirmPassword" isPassword isMobile={isMobile} />

                    <EvtGrid item xs={12}>
                        <EvtBox display="flex" flexDirection="column" width="100%" gap="10px">
                            <EvtFormCheckbox
                                name="termsOfService"
                                sx={{ padding: '0 5px 0 0' }}
                                description={
                                    <EvtTypography textAlign="left">
                                        I agree to these
                                        <EvtLink
                                            sx={{ cursor: 'pointer', ml: '5px' }}
                                            onClick={() => window.open(homeUrls.pages.terms.url, '_blank')}
                                        >
                                            Terms &amp; Conditions
                                        </EvtLink>
                                    </EvtTypography>
                                }
                            />

                            <EvtFormCheckbox
                                name="optin"
                                sx={{ padding: '0 5px 0 0' }}
                                description={
                                    <EvtTypography textAlign="left">
                                        I agree to receive conmunications from Event Corporate
                                    </EvtTypography>
                                }
                            />
                        </EvtBox>
                    </EvtGrid>
                </EvtGrid>

                <EvtSubmitButton variant="contained" sx={{ borderRadius: '35px', height: '56px', width: '50%' }}>
                    Sign Up
                </EvtSubmitButton>
            </EvtBox>
        </FormComponent>
    )
}

export default SignUpForm

interface GridInputFieldProps {
    size?: number
    name: string
    label: string
    isPassword?: boolean
    isMobile: boolean
}

const GridInputField: FC<GridInputFieldProps> = ({ size = 6, name, label, isPassword = false, isMobile }) => {
    let props = {
        label,
        name,
        isRoundBorder: true,
    }

    return (
        <EvtGrid item xs={isMobile ? 12 : size}>
            <EvtFormInputField {...props} type={isPassword ? 'password' : ''} />
        </EvtGrid>
    )
}

const wrapperStyle = (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    gap: '40px',
    width: '50%',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: '60%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: '80%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: '100%',
    },
})
