import React, { FC } from 'react'
import { InputAdornment, InputAdornmentProps } from '@mui/material'

interface Props {
    position: 'start' | 'end'
}

const EvtInputAdornment: FC<InputAdornmentProps & Props> = ({ children, position, ...props }) => {
    return (
        <InputAdornment {...props} position={position}>
            {children}
        </InputAdornment>
    )
}

export default EvtInputAdornment
