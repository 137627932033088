import React from 'react'
import EvtBox from '../../elements/EvtBox'
import NavDrawer from './NavDrawer'
import TopNavbar from './TopNavbar'

const Navbar = () => {
    const [open, setOpen] = React.useState<boolean>(false)

    return (
        <EvtBox sx={{ display: 'flex' }}>
            <TopNavbar open={open} handleDrawerToggle={(isOpen: boolean) => setOpen(isOpen)} />
            <NavDrawer open={open} handleDrawerToggle={(isOpen: boolean) => setOpen(isOpen)} />
        </EvtBox>
    )
}

export default Navbar
