import { useContext } from 'react'
import { useTheme } from '@mui/material'
import CarouselUI from '../../components/CarouselUI'
import ProductCard, { ProductCardSkeleton } from '../../components/ProductCard'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtTypography, { EvtTitleTypography } from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { GetThemeStateContext } from '../../providers/CustomThemeProvider'
import { useProducts } from '../../query/products'
import { useSettings } from '../../query/settings'
import productUrls from '../../urls/productUrls'
import { EVT } from '../../utils/evt'
import { theme as themeColor } from './../../consts'

const MyProductSection = () => {
    const theme = useTheme()
    const deviceType = useDeviceType()
    const { data: productRes, isLoading: productsLoading } = useProducts()
    const isHasShortDes = productRes?.products.some((p) => p.shortDescription) || false
    const itemWidth = EVT.isMobile(deviceType) ? theme.productCard.widthMobile : theme.productCard.width
    const btnVariant = useContext(GetThemeStateContext).theme === themeColor.LIGHT ? 'contained' : 'outlined'
    const { data: settings } = useSettings()

    let parentWidthRatio = 0.667
    if (EVT.isTablet(deviceType)) {
        parentWidthRatio = 0.8
    }
    if (EVT.isMobile(deviceType)) {
        parentWidthRatio = 0.9
    }

    return (
        <>
            <EvtTitleTypography mb="60px">My Products</EvtTitleTypography>
            <EvtBox>
                {productRes && (
                    <>
                        {productRes.products.length > 1 ? (
                            <CarouselUI
                                itemGap={30}
                                itemWidth={itemWidth}
                                renderDotsOutside={true}
                                infinite={false}
                                parentWidthRatio={parentWidthRatio}
                                itemPaddingBottom
                                items={
                                    productsLoading || !productRes
                                        ? skeleton
                                        : productRes.products.map((p) => (
                                              <ProductCard
                                                  product={p}
                                                  key={p.id}
                                                  isHasShortDes={isHasShortDes}
                                                  mobile={EVT.isMobile(deviceType)}
                                              />
                                          ))
                                }
                            />
                        ) : productRes.products.length > 0 ? (
                            <ProductCard
                                product={productRes.products[0]}
                                key={productRes.products[0].id}
                                isHasShortDes={isHasShortDes}
                                mobile={EVT.isMobile(deviceType)}
                            />
                        ) : (
                            <EvtBox>
                                <EvtTypography>
                                    {settings?.contentCopy.corporate_Products_NoneAssigned ||
                                        'There are no products available, please contact us if you continue to see this message.'}
                                </EvtTypography>
                            </EvtBox>
                        )}
                        {productRes.products.length > 0 && (
                            <EvtButtonPill
                                variant={btnVariant}
                                sx={{ padding: '15px 50px', mt: '80px' }}
                                to={productUrls.pages.index}
                            >
                                View All
                            </EvtButtonPill>
                        )}
                    </>
                )}
            </EvtBox>
        </>
    )
}

const skeleton = [<ProductCardSkeleton />, <ProductCardSkeleton />, <ProductCardSkeleton />]

export default MyProductSection
