import { FC, ReactNode, useEffect } from 'react'
import { Theme, useTheme } from '@mui/material'
import EvtBox from '../../elements/EvtBox'
import useDeviceType from '../../hooks/useDeviceType'
import { useAlert } from '../../providers/modals/AlertModalProvider'
import { useImages } from '../../query/images'
import { useSettings } from '../../query/settings'
import { useUser } from '../../query/users'
import { EVT } from '../../utils/evt'
import BannerSection from './BannerSection'
import { BottomOffersSection, CorporateOffersSection } from './CorporateOffersSection'
import JoinSection from './JoinSection'
import MyProductSection from './MyProductSection'
import MyProductUnLoignSection from './MyProductUnLoignSection'
import RecentOrdersSection from './RecentOrdersSection'
import ValuedClientsSection from './ValuedClientsSection'

export const Home = () => {
    const { data: user } = useUser()
    const { data: settings } = useSettings()
    const { data: images } = useImages()
    const alert = useAlert()

    // Remove hash key from redirect, and show the welcome message
    useEffect(() => {
        if (window.location.href.includes('#redirect=1') && settings) {
            const cleanedUrl = window.location.href.split('#')[0]
            window.history.replaceState(null, '', cleanedUrl)
            alert({
                title: 'Welcome to Event | Corporate',
                message: (
                    <EvtBox textAlign="center">
                        {EVT.html(settings?.contentCopy.corporate_RedirectFromEt_Message)}
                    </EvtBox>
                ),
            })
        }
    }, [settings])

    const clientImages = images?.home.clients.images || []
    const sectionList = user
        ? [<MyProductSection />, <CorporateOffersSection />, <RecentOrdersSection />, <BottomOffersSection />]
        : [<JoinSection />, <CorporateOffersSection />, <MyProductUnLoignSection />, <BottomOffersSection />]
    return (
        <EvtBox sx={contentWrapperStyle}>
            <BannerSection />
            {sectionList.map((section, index) => (
                <SectionWrapper key={index} index={index}>
                    {section}
                </SectionWrapper>
            ))}
            {clientImages?.length > 0 && (
                <SectionWrapper className="valued-client-section" index={sectionList.length}>
                    <ValuedClientsSection images={clientImages} />
                </SectionWrapper>
            )}
        </EvtBox>
    )
}

const SectionWrapper: FC<{ children: ReactNode; index: number; className?: string }> = ({
    children,
    index,
    className,
}) => {
    const theme = useTheme()
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    const isRight = index % 2 === 0

    return (
        <EvtBox sx={sectionWrapperStyle} className={className}>
            <EvtBox className="section-content">{children}</EvtBox>
            <img
                className={`side-stripes ${isRight ? 'right' : 'left'} ${isMobile ? 'mobile' : ''}`}
                src={isRight ? theme.palette.homeRightStripesUrl : theme.palette.homeLeftStripesUrl}
            />
        </EvtBox>
    )
}

const contentWrapperStyle = (theme: Theme) => ({
    '> div': {
        '&:nth-of-type(2n+1)': {
            backgroundColor: theme.palette.background.secondary,
        },
        '&:nth-of-type(2n)': {
            backgroundColor: theme.palette.background.default,
        },
        '&.valued-client-section': {
            backgroundColor: '#ffffff',
            '.side-stripes': {
                display: 'none',
            },
        },
    },
})
const sectionWrapperStyle = (theme: Theme) => ({
    position: 'relative',
    padding: '80px 0 120px 0',
    display: 'flex',
    justifyContent: 'center',
    '.section-content': {
        width: '80%',
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            width: '90%',
        },
    },
    '.side-stripes': {
        position: 'absolute',
        width: '180px',
        height: '300px',
        bottom: 0,
        '&.right': {
            right: 0,
        },
        '&.left': {
            left: 0,
        },
        '&.mobile': {
            width: '100px',
            height: '170px',
        },
    },
})
