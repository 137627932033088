import { useEffect, useState } from 'react'
import { Theme, useTheme } from '@mui/material'
import { DeviceType } from '../types/DeviceType'

const getDeviceType = (theme: Theme) => {
    const { desktop, tablet } = theme.breakpoints.values
    const width = window.innerWidth
    if (width < tablet) return DeviceType.Mobile
    if (width < desktop) return DeviceType.Tablet
    return DeviceType.Desktop
}

export default function useDeviceType() {
    const theme = useTheme()
    const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType(theme))

    useEffect(() => {
        const onResize = () => {
            setDeviceType(getDeviceType(theme))
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [theme])

    return deviceType
}
