import { FC } from 'react'
import Lottie from './Lottie'
import cross from './animations/cross.json'
import tick from './animations/tick.json'

export const TickAnimation: FC<{ width?: number; height?: number }> = ({ width = 50, height = 50 }) => {
    return <Lottie animationData={tick} width={width} height={height} />
}

export const CrossAnimation: FC<{ width?: number; height?: number }> = ({ width = 50, height = 50 }) => {
    return <Lottie animationData={cross} width={width} height={height} />
}
