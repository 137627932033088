import React, { FC } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DoneIcon from '@mui/icons-material/Done'
import { styled, useTheme } from '@mui/material/styles'
import EvtImageListItem from '../elements/EvtImageListItem'
import EvtSkeleton from '../elements/EvtSkeleton'
import { eVoucherImage } from '../query/images'

interface Props {
    height?: string
    width?: string
    isSelectForDelete?: boolean
    isactive: boolean
    image: eVoucherImage
    imageFitType?: 'fill' | 'contain' | 'cover'
    onClick: (image: eVoucherImage) => void
}

export const ImageItem: FC<Props> = ({
    height = '100%',
    width = 'auto',
    isSelectForDelete = false,
    image,
    isactive,
    imageFitType = 'cover',
    onClick,
}) => {
    const theme = useTheme()
    const isselectable = isSelectForDelete && image.isDefault ? 0 : 1
    return (
        <ImageListItemStyles
            key={image.id}
            isactive={isactive ? 1 : 0}
            isselectable={isselectable}
            width={width}
            onClick={() => {
                if (isselectable) {
                    onClick(image)
                }
            }}
        >
            {isSelectForDelete ? (
                <DeleteForeverIcon color="error" />
            ) : (
                <DoneIcon sx={{ color: theme.palette.stepper.lableComplete }} />
            )}

            <img src={image.imageUrl} alt="Select" loading="lazy" style={{ height, objectFit: imageFitType }} />
        </ImageListItemStyles>
    )
}

interface ImageListItemStylesProps {
    isactive?: number
    isselectable: number
    width: string
}
const ImageListItemStyles = styled(EvtImageListItem)<ImageListItemStylesProps>(
    ({ theme, isactive, isselectable, width }) => ({
        width,
        cursor: isselectable ? 'pointer' : '',
        img: isselectable
            ? {
                  WebkitFilter: `brightness(${isactive ? 30 : 100}%)`,
                  '&:hover': {
                      WebkitFilter: 'brightness(30%)',
                      transition: 'all 0.5s ease',
                  },
              }
            : {},

        svg: {
            zIndex: 2,
            display: isactive ? 'block' : 'none',
            WebkitFilter: '100% !important',
            position: 'absolute',
            fontSize: '50px',
            bottom: 'calc(50% - 25px)',
            left: 'calc(50% - 25px)',
        },
    }),
)

export const ImageItemSkeleton = () => {
    return (
        <EvtImageListItem>
            <EvtSkeleton variant="rectangular" width="247px" height="200px" />
        </EvtImageListItem>
    )
}
