import { createPageUrl } from '../urls'

const checkoutUrls = {
    pages: {
        checkout: createPageUrl({
            url: '/checkout/:id',
            text: 'Checkout',
        }),
        confirm: createPageUrl({
            url: '/confirm/:id',
            text: 'Confirm',
        }),
    },
}

export default checkoutUrls
