import { Product, ProductType } from '../types/products/Product'
import productUrls from '../urls/productUrls'
import { useEvtQuery } from './evtQuery'

export function useProducts() {
    return useEvtQuery<ProductsResponse>(productUrls.api.getAll)
}

export type ProductsResponse = {
    products: Array<Product>
    productTypes: Array<ProductType>
}
