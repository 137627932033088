import { useEffect, useState } from 'react'
import EvtBox from '../elements/EvtBox'
import { useUser } from '../query/users'

export const ViewAsWrapper = () => {
    const { data: user } = useUser()
    const [viewAs, setViewAs] = useState<boolean>(false)

    useEffect(() => {
        setViewAs(!!user?.view_as)
    }, [user?.view_as])

    return viewAs ? <EvtBox sx={style}>VIEWING AS {user?.unique_name}</EvtBox> : <></>
}

const style = {
    width: '100%',
    position: 'sticky',
    bottom: '0',
    background: 'red',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
    zIndex: 1,
}
