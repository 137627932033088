const Theme_Basic = {
    spacing: 4,
    navbar: {
        drawer: {
            openWidth: '220px',
            closeWidth: '72px',
            closeWidthNum: 72,
        },
        topNav: {
            topNavHeight: '60px',
        },
    },
    image: {
        height: 96,
        width: 250,
    },
    productCard: {
        width: 300,
        widthMobile: 220,
    },
    carousel: {
        arrowBtn: {
            width: 44,
        },
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 770,
            desktop: 1200,
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
}

export default Theme_Basic
