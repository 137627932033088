import { ChangeEvent, FC, useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import * as yup from 'yup'
import { StepContinueButton } from '../../../components/StepContainerUI'
import EvtBox from '../../../elements/EvtBox'
import EvtCustomSelect from '../../../elements/EvtCustomSelect'
import EvtGrid from '../../../elements/EvtGrid'
import { EvtInputField } from '../../../elements/EvtInputField'
import EvtTypography from '../../../elements/EvtTypography'
import { useUserProfile } from '../../../query/users'
import { EVT } from '../../../utils/evt'
import { Recipient } from '../AssignRecipient'

export interface RecipientMyself {
    value: Array<Recipient>
    isActive: boolean
}

interface Props {
    remaining: number
    data: RecipientMyself
    onChange: (value: RecipientMyself) => void
    onNext: (value: RecipientMyself) => void
}

const maxEmailLength = 60

const AssignMyself: FC<Props> = ({ data, remaining, onChange, onNext }) => {
    const { data: userProfile } = useUserProfile()

    const [recipient, setRecipient] = useState<Recipient>(
        data.value.length > 0
            ? {
                  ...data.value[0],
              }
            : {
                  id: 0,
                  qty: 1,
                  email: '',
              },
    )

    const qtyOptions = EVT.range(1, Math.max(remaining + 1, 2)).map((i) => ({ text: `${i}`, value: i }))

    useEffect(() => {
        onChange({ ...data, value: [{ ...recipient }] })
    }, [recipient, data, onChange])

    useEffect(() => {
        if (userProfile && recipient.email === '') {
            setRecipient({
                ...recipient,
                email: userProfile.email,
            })
        }
    }, [userProfile, recipient])

    return (
        <EvtBox>
            <EvtBox sx={assignCounterStyle}>
                <EvtTypography fontSize="25px">
                    {recipient.qty}/{remaining}
                </EvtTypography>
                <EvtTypography fontSize="12px">Assigned</EvtTypography>
            </EvtBox>
            <EvtGrid container mt="-50px" spacing={5}>
                <EvtGrid item xs={12} md={5}>
                    <EvtInputField
                        name="email"
                        label="Email"
                        maxLength={maxEmailLength}
                        value={recipient.email}
                        isRoundBorder
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setRecipient({ ...recipient, email: e.currentTarget.value as string })
                        }}
                    />
                </EvtGrid>
                <EvtGrid item xs={12} md={5}>
                    <EvtCustomSelect
                        options={qtyOptions}
                        label="Assign"
                        fullWidth
                        value={recipient.qty}
                        onChange={(e: SelectChangeEvent<unknown>) =>
                            setRecipient({ ...recipient, qty: e.target.value as number })
                        }
                        isRoundBorder
                        sx={selectStyle}
                    />
                </EvtGrid>
            </EvtGrid>
            <StepContinueButton
                disabled={
                    recipient.email === '' ||
                    !emailSchema.isValidSync(recipient.email) ||
                    recipient.qty === 0 ||
                    recipient.qty > remaining
                }
                onClick={() => {
                    onNext({
                        ...data,
                        value: Array(recipient.qty).fill({ email: recipient.email }),
                        isActive: true,
                    })
                }}
                text="Send"
            />
        </EvtBox>
    )
}

export default AssignMyself

const selectStyle = () => ({
    width: '120px',
    '.evt-select-icon': {
        svg: {
            paddingLeft: '8px',
        },
    },
})

const assignCounterStyle = () => ({
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    position: 'relative',
    top: '-60px',
    right: '30px',
    justifyContent: 'flex-end',
    width: '100px',
    left: 'calc(100% - 100px - 30px)',
})

const emailSchema = yup.string().max(maxEmailLength, `Email must not exceed ${maxEmailLength} characters`).email()
