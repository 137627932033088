import { FC } from 'react'
import { Helmet } from 'react-helmet'

const EvoHelmet: FC<{
    keywords?: string
    description?: string
    title?: string
    isTopLevel?: boolean
    imageUrl?: string
}> = ({ keywords, description, title, isTopLevel = false, imageUrl }) => {
    return (
        <Helmet>
            <meta
                name="keywords"
                content={`Event Cinemas gift cards, Birch Carroll and Coyle Gift cards, Greater Union gift cards, BCC Cinemas gift cards, gift card, movie gift cards, 
                        theatre gift cards, Gold Class Gift Cards, Cinema Gift Cards, birthday, graduation, Christmas, mother's day, father's day, ${
                            keywords || ''
                        }`}
            />
            <meta
                name="description"
                content={
                    description ||
                    'Give the gift of entertainment with an Event Cinemas Gift Card. Good for everything from movies, Candy Bar items, Vmax and Gold Class premium offerings.'
                }
            />
            {title && <title>{title} - Corporate Giftshop</title>}
            {!title && isTopLevel && <title>Corporate Giftshop</title>}
            {title && <meta property="og:title" content={title} />}
            {imageUrl && <meta property="og:image" content={imageUrl} />}
            {description && <meta property="og:description" content={description} />}
        </Helmet>
    )
}

export default EvoHelmet
