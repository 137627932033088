import { useContext, useEffect, useState } from 'react'
import EvtBox from '../elements/EvtBox'
import { EvtButtonPill } from '../elements/EvtButton'
import Modal from '../elements/EvtModal'
import { AlertModalOpenOptions, GetAlertModalStateContext, useAlert } from '../providers/modals/AlertModalProvider'

export const AlertModal = () => {
    const cont = useContext(GetAlertModalStateContext)
    const [settings, setSettings] = useState<AlertModalOpenOptions | undefined>(undefined)

    const setModalSettings = useAlert()
    const closeAlertModal = () => {
        if (settings && settings.onClose) {
            settings.onClose()
        }

        setModalSettings(undefined)
    }

    const cancelClick = () => {
        if (settings && settings.onCancel) {
            settings.onCancel()
        }

        closeAlertModal()
    }

    const okClick = () => {
        if (settings && settings.onOk) {
            settings.onOk()
        }

        setModalSettings(undefined)
    }

    useEffect(() => {
        if (typeof cont === 'string') {
            setSettings({
                message: cont as string,
            })
        } else {
            setSettings(cont as AlertModalOpenOptions)
        }
    }, [cont])

    return (
        <Modal
            isOpen={!!settings}
            title={settings?.title}
            maxWidth="desktop"
            cancelFunc={cancelClick}
            buttons={
                <>
                    <EvtButtonPill
                        fullWidth={!settings?.confirm}
                        sx={{ padding: '10px', marginX: '10px' }}
                        onClick={() => okClick()}
                    >
                        {settings?.okButtonText || (settings?.confirm ? 'Ok' : 'Close')}
                    </EvtButtonPill>
                    {settings?.confirm && (
                        <EvtButtonPill sx={{ padding: '10px', marginX: '10px' }} onClick={() => cancelClick()}>
                            {settings?.cancelButtonText || 'Cancel'}
                        </EvtButtonPill>
                    )}
                </>
            }
        >
            <EvtBox>{settings && settings.message}</EvtBox>
        </Modal>
    )
}
