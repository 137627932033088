import { FC } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { Theme, styled, useTheme } from '@mui/material'
import EvtAvatar from '../elements/EvtAvatar'
import EvtBox from '../elements/EvtBox'
import { EvtButton } from '../elements/EvtButton'
import EvtDivider from '../elements/EvtDivider'
import EvtLink from '../elements/EvtLink'
import EvtTable from '../elements/EvtTable'
import EvtTypography from '../elements/EvtTypography'
import { Order, OrderProduct } from '../types/orders/Order'
import orderUrls from '../urls/orderUrls'
import { EVT } from '../utils/evt'

interface Props {
    handleEdit?: () => void
    handleCheckout?: () => void
    products?: Array<OrderProduct>
    shipping?: { name: string; price: number }
    order?: Order
    isShowButton?: boolean
    isShowItems?: boolean
}

const OrderSummary: FC<Props> = ({
    order,
    products,
    shipping,
    isShowButton = false,
    isShowItems = false,
    handleCheckout = () => {},
    handleEdit,
}) => {
    products = products || []

    return (
        <EvtBox className="evt-order-summary" maxWidth="500px">
            {isShowItems && <OrderItemsTable products={products} handleEdit={handleEdit} />}
            <OrderSummaryTable
                products={products}
                isShowButton={isShowButton}
                shipping={shipping}
                isShowItems={isShowItems}
                order={order}
                handleCheckout={handleCheckout}
            />
        </EvtBox>
    )
}

export default OrderSummary

interface OrderSummaryTableProps {
    order?: Order
    shipping?: { name: string; price: number }
    products: Array<OrderProduct>
    isShowItems: boolean
    isShowButton: boolean
    handleCheckout: () => void
}
const OrderSummaryTable: FC<OrderSummaryTableProps> = ({
    order,
    shipping,
    products,
    isShowButton,
    isShowItems,
    handleCheckout,
}) => {
    const theme = useTheme()
    const summaryTxtColor = theme.palette.summary.text
    const subTotal = EVT.sum(products, (p) => p.price * p.quantity)
    let total = subTotal
    if (shipping) {
        total += shipping.price
    }
    return (
        <EvtBox sx={(t) => summaryTableStyle(isShowItems, t)}>
            <EvtBox paddingBottom="20px" textAlign="left">
                <EvtTypography fontSize="18px">Order Summary</EvtTypography>
                {order && <EvtTypography fontSize="13px">Order #{order.orderNumber}</EvtTypography>}
            </EvtBox>

            <FeeOption title="Subtotal" amount={subTotal} />
            {shipping && <FeeOption title={`Shipping - ${shipping.name}`} amount={shipping.price} />}
            <EvtDivider sx={{ pt: '15px', borderColor: theme.palette.border.secondary }} />
            <FeeOption title="Total" amount={total} />
            {order && (
                <EvtBox sx={summaryDetailStyle}>
                    <EvtTypography fontSize="13px" color={summaryTxtColor}>
                        Order Date: {EVT.displayDate(order.created)}
                    </EvtTypography>
                    <EvtTypography fontSize="13px" color={summaryTxtColor}>
                        {order.paymentMethod && `Payment Method: ${order.paymentMethod}`}
                    </EvtTypography>
                    <EvtTypography fontSize="13px" color={summaryTxtColor}>
                        {order.postalOrderReference && `Reference: ${order.postalOrderReference}`}
                    </EvtTypography>
                    <EvtBox display="flex" alignItems="flex-start">
                        <TextSnippetIcon sx={{ color: summaryTxtColor }} />
                        <StyledLink href={`${orderUrls.api.invoice.url}/${order?.orderId}`} target="_blank" download>
                            Download Tax Invoice
                        </StyledLink>
                    </EvtBox>
                </EvtBox>
            )}

            {isShowButton && (
                <EvtButton sx={checkoutStyle} variant="contained" onClick={handleCheckout}>
                    Checkout
                </EvtButton>
            )}
        </EvtBox>
    )
}

const OrderItemsTable: FC<{ products: Array<OrderProduct>; handleEdit?: () => void }> = ({ products, handleEdit }) => {
    const rows = products.map((item) => [
        <EvtAvatar alt={item.name} src={item.cardImageUrl} />,
        <EvtTypography fontSize="12px">{item.name}</EvtTypography>,
        <EvtTypography fontSize="15px" minWidth="50px">
            Qty: {item.quantity}
        </EvtTypography>,
        <EvtTypography fontSize="15px">{EVT.toCurrency(item.price * item.quantity)}</EvtTypography>,
    ])
    return (
        <EvtBox sx={itemContainerStyle}>
            <EvtBox className="item-container-header">
                <EvtTypography fontSize="18px" textAlign="left">
                    {products.length} Items in this order
                </EvtTypography>

                {handleEdit && (
                    <EvtButton onClick={handleEdit}>
                        <EditIcon fontSize="small" sx={{ mr: '5px' }} color="primary" />
                        <EvtLink fontSize="medium">Edit Cart</EvtLink>
                    </EvtButton>
                )}
            </EvtBox>

            <EvtTable rows={rows} paddingSize="small" />
        </EvtBox>
    )
}

interface FeeOptionProps {
    title: string
    amount: number
}

const FeeOption: FC<FeeOptionProps> = ({ title, amount }) => (
    <EvtBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px', pt: '15px' }}>
        <EvtTypography sx={{ textAlign: 'left' }}>{title}</EvtTypography>
        <EvtTypography>{EVT.toCurrency(amount)}</EvtTypography>
    </EvtBox>
)

const itemContainerStyle = (theme: Theme) => ({
    padding: '20px',
    borderRadius: '15px 15px 0 0 ',
    borderTop: `1px solid ${theme.palette.border.default}`,
    borderLeft: `1px solid ${theme.palette.border.default}`,
    borderRight: `1px solid ${theme.palette.border.default}`,
    '.item-container-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: '10px',
    },
})

const StyledLink = styled('a')(({ theme }) => ({
    cursor: 'pointer',
    fontSize: '11px',
    paddingLeft: '5px',
    paddingTop: '6px',
    color: theme.palette.link.primary,
}))

const checkoutStyle = (theme: Theme) => ({
    marginTop: '15px',
    borderRadius: '35px',
    paddingY: '10px',
    paddingX: '50px',
    backgroundColor: theme.palette.orderSummary.checkoutBtnBg,
})

const summaryDetailStyle = (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '20px',
    marginBottom: '10px',
    alignItems: 'flex-start',
})

const summaryTableStyle = (isShowItems: boolean, theme: Theme) => ({
    backgroundColor: theme.palette.orderSummary.summaryBg,
    padding: '20px',
    borderRadius: isShowItems ? '0 0 15px 15px' : '15px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
})
