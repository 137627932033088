import { FC } from 'react'
import FormComponent from '../../components/FormComponent'
import EvtBox, { EvtBoxColumn } from '../../elements/EvtBox'
import { EvtSubmitButtonPill } from '../../elements/EvtButton'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtFormSelect from '../../elements/EvtFormSelect'
import { EvtLoader } from '../../elements/EvtLoader'
import { useFormikRef } from '../../hooks/useFormikRef'
import { useSuccessAlert } from '../../providers/modals/AlertModalProvider'
import { ContactUs as CU, useContactUs, useSettings } from '../../query/settings'
import { EVT } from '../../utils/evt'
import { evtValidator } from '../../utils/validation'

export const ContactUs: FC<{}> = () => {
    const { data: response, isLoading } = useSettings()
    const innerRef = useFormikRef()
    const success = useSuccessAlert()
    const { mutate: contactUs } = useContactUs()
    const formValidation = evtValidator(contactUsValues)

    const onSubmit = (values: CU) => {
        contactUs(values, {
            onSuccess: () => {
                success('Your query has been succesfully sent')
                innerRef.current?.resetForm()
            },
        })
    }

    return (
        <EvtLoader loading={isLoading}>
            <EvtBoxColumn alignItems="center" paddingY="30px" width="100%">
                {response?.contactUs && <EvtBox>{EVT.html(response.contactUs)}</EvtBox>}
                <FormComponent
                    initialValues={contactUsValues}
                    onSubmit={onSubmit}
                    recaptcha
                    innerRef={innerRef}
                    validationSchema={formValidation}
                    sx={{ width: '100%', maxWidth: '350px' }}
                >
                    <EvtBoxColumn gap="20px" width={'100%'}>
                        <EvtFormInputField name="firstName" label="First Name*" isRoundBorder />
                        <EvtFormInputField name="lastName" label="Last Name*" isRoundBorder />
                        <EvtFormInputField name="email" label="Email Address*" isRoundBorder />
                        <EvtFormInputField name="company" label="Company/Position" isRoundBorder />
                        <EvtFormInputField name="contactNumber" label="Contact Number" isRoundBorder />
                        <EvtFormSelect
                            name="products"
                            label="Products"
                            options={products}
                            multiple
                            isRoundBorder
                            isCheckbox
                            // @ts-ignore
                            renderValue={(selected) => selected.join(', ')}
                            isDoneBtn
                        />
                        <MessageInputField />
                        <EvtSubmitButtonPill sx={{ minHeight: '56px' }}>Submit</EvtSubmitButtonPill>
                    </EvtBoxColumn>
                </FormComponent>
            </EvtBoxColumn>
        </EvtLoader>
    )
}

const MessageInputField: FC<{}> = () => (
    <EvtFormInputField
        label="Message"
        name="message"
        maxLength={1000}
        isRoundBorder
        rows={6}
        multiline
        FormHelperTextProps={{ sx: messageCounterStyles }}
    />
)

const messageCounterStyles = () => ({
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    bottom: '-23px',
})

const contactUsValues = {
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    contactNumber: '',
    preferredMethod: '',
    products: [],
} as CU

const products = [
    { value: 'Movie', text: 'Movie' },
    { value: 'GoldClass', text: 'Gold Class' },
    { value: 'eVouchers', text: 'eVouchers' },
    { value: 'GiftCard', text: 'Gift Card' },
    { value: 'StandardVouchers', text: 'Standard Vouchers' },
    { value: 'GroupBookings', text: 'Group Bookings' },
]
