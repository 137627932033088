import React, { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ResendIcon: FC<SvgIconProps> = ({ ...props }) => {
    return (
        <SvgIcon {...props}>
            <path
                id="share-all-solid"
                d="M15.852,38.608,9.507,33.161a.866.866,0,0,0-1.431.655V36.8C3.42,37.138,0,38.648,0,43.382a7.366,7.366,0,0,0,3,5.555.642.642,0,0,0,1.012-.671c-1.387-4.438.148-6.1,4.059-6.558v3.03a.866.866,0,0,0,1.431.655l6.345-5.447A.894.894,0,0,0,15.852,38.608Zm4.614,0-6.345-5.447a.866.866,0,0,0-1.431.655v.536l5.685,4.91-5.685,4.91v.571a.866.866,0,0,0,1.431.655l6.345-5.447A.9.9,0,0,0,20.466,38.608Z"
                transform="translate(0 -32.95)"
            />
        </SvgIcon>
    )
}

export default ResendIcon
