import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Theme, Typography, styled, useTheme } from '@mui/material'
import AddToCartSection from '../../components/AddToCartSection'
import CarouselUI from '../../components/CarouselUI'
import EvoHelmet from '../../components/EvoHelmet'
import ProductCard, { ProductCardSkeleton } from '../../components/ProductCard'
import EvtBox from '../../elements/EvtBox'
import EvtDivider from '../../elements/EvtDivider'
import EvtLink from '../../elements/EvtLink'
import { EvtLoader } from '../../elements/EvtLoader'
import EvtTypography, { EvtTitleTypography } from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useModal } from '../../providers/modals/GlobalModalProvider'
import { useProducts } from '../../query/products'
import { EVT } from '../../utils/evt'

const ProductDetail = () => {
    const { id } = useParams()
    const theme = useTheme()
    const deviceType = useDeviceType()
    const { data: productRes, isLoading: isLoadProducts } = useProducts()
    const product = productRes?.products.filter((p) => p.url === id)[0]
    const isHasShortDes = productRes?.products.some((p) => p.shortDescription) || false
    const itemWidth = EVT.isMobile(deviceType) ? theme.productCard.widthMobile : theme.productCard.width

    let parentWidthRatio = 0.667
    if (EVT.isTablet(deviceType)) {
        parentWidthRatio = 0.8
    }
    if (EVT.isMobile(deviceType)) {
        parentWidthRatio = 0.9
    }

    return (
        <>
            <EvoHelmet
                keywords={product?.metaKeywords}
                title={product?.name}
                description={product?.metaDescription}
                imageUrl={product?.cardImageUrl}
            />
            <EvtLoader loading={isLoadProducts}>
                <EvtBox>
                    <EvtBox display="flex" flexDirection="column" alignItems="center" padding="50px 0">
                        <ImageStyles src={product?.cardImageUrl} alt="Product" />
                        <EvtTitleTypography padding="20px" letterSpacing="0.1em" textTransform="uppercase">
                            {product?.name}
                        </EvtTitleTypography>
                        <EvtBox sx={actionsBoxStyle}>
                            {product && <AddToCartSection product={product} type="page" />}
                        </EvtBox>
                        {product?.description && (
                            <EvtBox sx={desContainerStyle}>{EVT.html(product.description)}</EvtBox>
                        )}
                        <HaveReceived content={product?.youHaveReceived} title={product?.name} />
                        <TermAndCondition termAndCondition={product?.termAndCondition} />
                    </EvtBox>

                    <EvtDivider />

                    <EvtBox sx={relatedProductContainerStyle}>
                        <EvtTitleTypography padding="20px" letterSpacing="0.1em">
                            RELATED PRODUCTS
                        </EvtTitleTypography>
                        <CarouselUI
                            itemGap={30}
                            itemWidth={itemWidth}
                            renderDotsOutside={true}
                            infinite={false}
                            parentWidthRatio={parentWidthRatio}
                            items={
                                isLoadProducts || !productRes
                                    ? skeleton
                                    : productRes.products
                                          .filter((p) => p.id.toString() !== id)
                                          .map((p) => (
                                              <ProductCard
                                                  product={p}
                                                  key={p.id}
                                                  isHasShortDes={isHasShortDes}
                                                  mobile={EVT.isMobile(deviceType)}
                                              />
                                          ))
                            }
                        />
                    </EvtBox>
                </EvtBox>
            </EvtLoader>
        </>
    )
}

export default ProductDetail

const skeleton = [<ProductCardSkeleton />, <ProductCardSkeleton />, <ProductCardSkeleton />]

const HaveReceived: FC<{ content: string | undefined; title: string | undefined }> = ({ content, title }) => {
    return content ? (
        <EvtBox sx={HaveReceivedStyle}>
            <EvtTypography className="title">{title} includes:</EvtTypography>
            <EvtBox className="content">{EVT.html(content)}</EvtBox>
        </EvtBox>
    ) : (
        <></>
    )
}

const TermAndCondition: FC<{ termAndCondition: string | undefined }> = ({ termAndCondition }) => {
    const { openModal } = useModal()
    const openTermModal = () =>
        openModal({
            content: modalContent,
        })
    const modalContent = (
        <EvtBox sx={TermModalStyle}>
            <Typography className="title">Term & Conditions</Typography>
            <EvtBox className="content">{termAndCondition ? EVT.html(termAndCondition) : ''}</EvtBox>
        </EvtBox>
    )
    return termAndCondition ? (
        <EvtLink sx={TermAndConditionStyle} onClick={openTermModal}>
            Full Terms & Conditions
        </EvtLink>
    ) : (
        <></>
    )
}

const TermModalStyle = (theme: Theme) => ({
    '.title': {
        textAlign: 'center',
        fontSize: '1.5em',
    },
    '.content': {
        textAlign: 'center',
        fontSize: '0.8em',
    },
})

const TermAndConditionStyle = (theme: Theme) => ({
    color: theme.palette.termCondition,
    fontSize: '0.9em',
    marginTop: '40px',
    cursor: 'pointer',
})

const HaveReceivedStyle = (theme: Theme) => ({
    marginTop: '40px',
    width: '60%',
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        width: '80%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        width: '80%',
    },
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: '20px',
    minHeight: '150px',
    padding: '20px',
    '.title': {
        color: '#c9a249',
        fontSize: '1.1em',
    },
    '.content': {
        fontSize: '0.9em',
        li: {
            listStyleType: 'none',
            marginBottom: '8px',
            color: theme.palette.border.secondary,
            '&:before': {
                display: 'inline-block',
                content: '"-"',
                width: '1em',
                marginLeft: '-1em',
            },
        },
    },
})

const ImageStyles = styled('img')(() => ({
    maxWidth: '500px',
    width: '90%',
}))

const actionsBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
}

const desContainerStyle = (theme: Theme) => ({
    width: '50%',
    '> p': {
        backgroundColor: `${theme.palette.background.default} !important`,
        color: `${theme.palette.text.secondary} !important`,
    },
})

const relatedProductContainerStyle = (theme: Theme) => ({
    width: '66.7%',
    margin: 'auto',
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        width: '80%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        width: '95%',
    },
    paddingBottom: '60px',
})
