import { QueryClient } from 'react-query'
import axios from 'axios'
import parse from 'html-react-parser'
import moment from 'moment'
import { DeviceType } from '../types/DeviceType'
import { LoggedInUser } from '../types/users/User'
import { API } from '../urls'
import { evtuser } from './evtuser'

export const EVT = {
    get: <T>(evtUrl: API, settings?: any) => {
        return EVT.axiosBase(evtUrl, settings, (s) => {
            return axios.get(evtUrl.url, s).then((res) => {
                return (res.data as Response<T>).data
            })
        })
    },

    post: <T>(evtUrl: API, data: any, settings?: any) => {
        if (data.recaptchaToken) {
            settings = Object.assign(settings || {}, {
                headers: {
                    'g-recaptcha-response': data.recaptchaToken,
                },
            })

            delete data.recaptchaToken
        }
        return EVT.axiosBase(evtUrl, settings, (s) => {
            return axios.post(evtUrl.url, data, s).then((res) => {
                const retVal = res.data as Response<T>
                if (retVal.redirect) window.location.href = retVal.redirect
                else if (retVal.redirectInNewWindow) window.open(retVal.redirectInNewWindow, '_blank')
                return retVal.data
            })
        })
    },

    put: <T>(evtUrl: API, data: any, settings?: any) => {
        return EVT.axiosBase(evtUrl, settings, (s) => {
            return axios.put(evtUrl.url, data, s).then((res) => {
                return (res.data as Response<T>).data
            })
        })
    },

    axiosBase: <T>(evtUrl: API, settings: any, method: (s: any) => Promise<T>) => {
        settings = Object.assign(
            {
                withCredentials: true,
            },
            settings || {},
        )

        if (evtUrl.requiresAuth) {
            // Do auth checking
            return evtuser.getKey().then((k) => {
                settings.headers = settings.headers || {}
                settings.headers.Authorization = `Bearer ${k}`
                return method(settings)
            })
        }

        return method(settings)
    },

    any: <T>(array: Array<T> | undefined): boolean => {
        if (array && array.length > 0) {
            return true
        }
        return false
    },

    parseDate: (value?: Date | string | undefined) => {
        if (!value) return undefined

        if (Object.prototype.toString.call(value) === '[object Date]') {
            return value as Date // already a date
        }

        const strDate = value as string

        var arr = strDate.split(/[- :T]/).map((a) => parseInt(a))
        while (arr.length < 6) {
            arr.push(0)
        }
        var retVal = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
        var hours = arr[3]
        var diff = (24 + hours - retVal.getHours()) % 24
        if (diff > 0) {
            retVal.setTime(retVal.getTime() + diff * 60 * 60 * 1000)
        }

        return retVal
    },

    format: (date: Date | string | undefined, dateFormat: string) => {
        const actualDate = EVT.parseDate(date)
        if (!actualDate) return ''
        return moment(actualDate).format(dateFormat)
    },

    range: (start: number, end: number) => {
        return Array.from({ length: end - start }, (v, k) => k + start)
    },

    html: (html: string) => {
        return html ? parse(html) : ''
    },

    getUsersName: (user: LoggedInUser) => {
        if (!user) {
            return ''
        }

        if (user.given_name) {
            if (user.family_name) {
                return `${user.given_name} ${user.family_name}`
            }

            return user.given_name
        }

        return user.unique_name
    },

    getUsersInitial: (user: LoggedInUser) => {
        if (!user) {
            return ''
        }

        if (user.given_name) {
            if (user.family_name) {
                return `${user.given_name[0]}${user.family_name[0]}`.toUpperCase()
            }

            return user.given_name[0].toUpperCase()
        }

        return user.unique_name[0].toUpperCase()
    },

    toCurrency: (amount: number) => {
        amount = amount || 0
        return `$${amount.toFixed(2)}`.replace('.00', '')
    },

    log: (message: any, ...params: any[]) => {
        console.log(message, params)
    },

    error: (message: any, ...params: any[]) => {
        console.error(message, params)
    },

    sum: <T>(items: Array<T>, func: (item: T) => number) => {
        return items.reduce((sum, c) => sum + func(c), 0)
    },

    displayDate: (date: Date | string | undefined, includeTime?: boolean) => {
        if (includeTime) {
            return EVT.format(date, 'DD/MM/yyyy HH:mm')
        }

        return EVT.format(date, 'DD/MM/yyyy')
    },
    displayTime: (date: Date | string | undefined) => {
        return EVT.format(date, 'HH:mm')
    },
    getRelatedCacheKeys: (queryClient: QueryClient, targetKey: string) => {
        return queryClient
            .getQueryCache()
            .getAll()
            .map((query) => query.queryKey)
            .filter((key) => (Array.isArray(key) ? key.includes(targetKey) : key === targetKey))[0]
    },
    isMobile: (deviceType: DeviceType) => {
        return deviceType === DeviceType.Mobile
    },
    isTablet: (deviceType: DeviceType) => {
        return deviceType === DeviceType.Tablet
    },
    isDesktop: (deviceType: DeviceType) => {
        return deviceType === DeviceType.Desktop
    },

    logNewRelic: (text: string) => {
        const nr = (window as any).newrelic
        if (typeof nr !== 'undefined' && typeof nr.noticeError === 'function') {
            nr.noticeError(text)
        }
    },
}

export type Response<T> = {
    success: boolean
    data: T
    message?: string
    redirect?: string
    redirectInNewWindow?: string
}
