import { useParams } from 'react-router-dom'
import EVoucherTemplate from '../../components/EVoucherTemplate'
import EvtBox from '../../elements/EvtBox'
import { EvtLoader } from '../../elements/EvtLoader'
import { useEvoucher } from '../../query/eVoucher'

const Evoucher = () => {
    const params = useParams()
    const id = params['*']
    const { data: evoucher, isLoading } = useEvoucher(id)

    return (
        <EvtLoader loading={isLoading}>
            <EvtBox sx={{ width: '100%', paddingY: '50px', display: 'flex', justifyContent: 'center' }}>
                <EVoucherTemplate
                    name={evoucher?.name}
                    sender={evoucher?.from}
                    greeting={evoucher?.greeting}
                    message={evoucher?.message}
                    bannerImageUrl={evoucher?.bannerImageUrl}
                    items={evoucher?.items}
                    description={evoucher?.terms || evoucher?.items[0]?.terms}
                    youHaveReceived={evoucher?.youHaveReceived || evoucher?.items[0]?.youHaveReceived}
                />
            </EvtBox>
        </EvtLoader>
    )
}

export default Evoucher
