import { ReactNode } from 'react'
import {
    Pagination,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableProps,
    TableRow,
    Theme,
} from '@mui/material'
import { PaginationResponse } from '../types/pagintation/PaginationResponse'
import { EVT } from '../utils/evt'
import EvtSkeleton from './EvtSkeleton'

interface Props<T> {
    columns: Array<Column<T>>
    items?: Array<T> | undefined
    pagination?: PaginationResponse<T>
    skeletonRows?: number
    showBorder?: boolean
    paddingSize?: 'small' | 'normal'
    onPageChange?: (page: number) => void
    isLoading?: boolean
    showHeader?: boolean
    emptyNode?: ReactNode
}

export enum AlignType {
    Left,
    Right,
    Center,
}

const EvtTableDetail = <T extends unknown>({
    showHeader = true,
    showBorder = true,
    skeletonRows = 5,
    paddingSize = 'normal',
    columns,
    items,
    pagination,
    isLoading,
    emptyNode,
    onPageChange,
    ...props
}: Props<T> & TableProps) => {
    items = items || pagination?.items
    return (
        <>
            <TableContainer {...props}>
                <Table sx={(t) => headerStyle(t, showBorder, paddingSize)}>
                    {showHeader && (
                        <TableHead>
                            <TableRow>
                                {columns.map(
                                    (col, index) =>
                                        col.enabled !== false && (
                                            <TableCell
                                                key={`table-header-${index}`}
                                                sx={{
                                                    ...col.sx,
                                                    textAlign:
                                                        col.align === AlignType.Center
                                                            ? 'center'
                                                            : col.align === AlignType.Right
                                                            ? 'right'
                                                            : null,
                                                }}
                                            >
                                                {col.title}
                                            </TableCell>
                                        ),
                                )}
                            </TableRow>
                        </TableHead>
                    )}

                    <TableBody>
                        {items &&
                            items.length > 0 &&
                            items.map((row, rowIndex) => (
                                <TableRow key={`row-${rowIndex}`}>
                                    {columns.map(
                                        (col, colIndex) =>
                                            col.enabled !== false && (
                                                <TableCell
                                                    key={`cell-${rowIndex}-${colIndex}`}
                                                    sx={{
                                                        ...col.sx,
                                                        textAlign:
                                                            col.align === AlignType.Center
                                                                ? 'center'
                                                                : col.align === AlignType.Right
                                                                ? 'right'
                                                                : null,
                                                    }}
                                                >
                                                    {col.descriptor(row)}
                                                </TableCell>
                                            ),
                                    )}
                                </TableRow>
                            ))}
                        {isLoading &&
                            EVT.range(0, skeletonRows).map((row, rowIndex) => (
                                <TableRow key={`row-${rowIndex}`}>
                                    {columns.map((col, colIndex) => (
                                        <TableCell key={`cell-${rowIndex}-${colIndex}`}>
                                            <EvtSkeleton />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {items && items.length === 0 && !isLoading && emptyNode && emptyNode}
            </TableContainer>

            {pagination && pagination.totalPages > 1 && (
                <Pagination
                    count={pagination.totalPages}
                    page={pagination.currPage}
                    onChange={(e, p) => onPageChange?.(p)}
                />
            )}
        </>
    )
}

export default EvtTableDetail

export type Column<T> = {
    title?: string
    descriptor: (item: T) => string | number | ReactNode
    sx?: SxProps<Theme>
    enabled?: boolean
    align?: AlignType
}

const headerStyle = (theme: Theme, showBorder: boolean, paddingSize: string) => ({
    'thead tr th': {
        backgroundColor: theme.palette.table.headerBg,
        fontWeight: 'bold',
    },
    'thead tr > th:first-of-type': {
        borderRadius: '5px 0 0 5px',
    },
    'thead tr > th:last-of-type': {
        borderRadius: '0 5px 5px 0',
    },
    'th, td': {
        border: 'unset',
        color: theme.palette.text.primary,
        padding: paddingSize === 'small' ? '8px' : '16px',
    },
    'tbody tr:not(:last-of-type)': {
        td: {
            borderBottom: showBorder ? `1px solid ${theme.palette.border.default}` : 'unset',
        },
    },
    'tbody tr: last-of-type': {
        td: {
            borderBottom: 'unset !important',
        },
    },
})
