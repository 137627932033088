import { useTheme } from '@mui/material'
import EvtBox from '../elements/EvtBox'

export const EvtCorpLogo = () => {
    const theme = useTheme()
    return (
        <EvtBox sx={{ paddingX: '20px' }}>
            <img src={theme.palette.logoUrl} alt="logo" style={{ width: '100%', paddingTop: '5px' }} />
        </EvtBox>
    )
}
