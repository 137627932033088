import { FC, useRef } from 'react'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import { Theme, useTheme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import braintree from 'braintree-web'
import { PaymentProviders, PaymentType } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtInputLabel from '../../elements/EvtInputLabel'
import { PaymentMethodFormProps } from '../../pages/checkout/PaymentMethodForm'
import { useBraintreeInstance } from '../../providers/BraintreeProvider'
import { EVT } from '../../utils/evt'

export const BraintreeHostedFields: FC<{ onNext?: (values: PaymentMethodFormProps) => void }> = ({ onNext }) => {
    const clientInstance = useBraintreeInstance()
    const theme = useTheme()
    const hostedFieldsInstance = useRef<braintree.HostedFields | undefined>(undefined)
    if (clientInstance && !hostedFieldsInstance.current) {
        braintree.hostedFields.create(
            {
                client: clientInstance,
                fields: {
                    number: {
                        selector: '#CardNumber',
                    },
                    cvv: {
                        selector: '#CardSecurityCode',
                    },
                    expirationDate: {
                        selector: '#CardExpiry',
                    },
                },
                styles: {
                    input: {
                        'font-size': '16px',
                        color: theme.palette.text.primary,
                    },
                },
            },
            function (err, hfi) {
                if (err) {
                    EVT.error(err)
                    return
                }
                hostedFieldsInstance.current = hfi
            },
        )
    }

    interface ErrorObj {
        key: string
        id: string
        infoId: string
        errorTxt: string
    }

    const onSubmit = (e: any) => {
        const containerErrorClass = 'braintree-field-invalid'
        const infoErrorClass: string = 'error-info'
        const fieldError: { number: ErrorObj; cvv: ErrorObj; expiryDate: ErrorObj } = {
            number: {
                key: 'number',
                id: 'CardNumber',
                infoId: 'card-number-error-info',
                errorTxt: 'Card number is invalid',
            },
            cvv: {
                key: 'cvv',
                id: 'CardSecurityCode',
                infoId: 'card-cvv-error-info',
                errorTxt: 'CVV is invalid',
            },
            expiryDate: {
                key: 'expirationDate',
                id: 'CardExpiry',
                infoId: 'card-date-error-info',
                errorTxt: 'Expiry date is invalid',
            },
        }
        const showErrorInfo = ($parent: any, errorObj: ErrorObj) => {
            if (!$parent.classList.contains(containerErrorClass)) {
                $parent?.classList.add(containerErrorClass)
                const tag = document.createElement('p')
                tag.setAttribute('id', errorObj.infoId)
                tag.setAttribute('class', infoErrorClass)
                const text = document.createTextNode(errorObj.errorTxt)
                tag.appendChild(text)
                $parent?.appendChild(tag)
            }
        }
        const hideErrorInfo = ($parent: any, errorObj: ErrorObj) => {
            $parent?.classList.remove(containerErrorClass)
            document.getElementById(errorObj.infoId)?.remove()
        }

        hostedFieldsInstance.current?.tokenize(function (err: any, payload: any) {
            const $cardNumber = document.getElementById('CardNumber')
            const $cvv = document.getElementById('CardSecurityCode')
            const $cardExpiry = document.getElementById('CardExpiry')

            if (err) {
                EVT.error(err)
                const invalidFieldKeys = err.details.invalidFieldKeys ? err.details.invalidFieldKeys : []

                invalidFieldKeys.includes(fieldError.number.key)
                    ? showErrorInfo($cardNumber, fieldError.number)
                    : hideErrorInfo($cardNumber, fieldError.number)
                invalidFieldKeys.includes('cvv')
                    ? showErrorInfo($cvv, fieldError.cvv)
                    : hideErrorInfo($cvv, fieldError.cvv)
                invalidFieldKeys.includes('expirationDate')
                    ? showErrorInfo($cardExpiry, fieldError.expiryDate)
                    : hideErrorInfo($cardExpiry, fieldError.expiryDate)

                return
            }

            const data: PaymentMethodFormProps = {
                paymentTypeId: PaymentType.creditCard.id,
                paymentProviderId: PaymentProviders.braintree,
                braintreeDeviceData: clientInstance.deviceData,
                braintreeNonce: payload.nonce,
            }

            onNext?.(data)
        })
    }

    return (
        <EvtBox display="flex" flexDirection="column" gap="35px">
            <EvtBox id="CardNumber" sx={inputFieldStyles}>
                <EvtInputLabel sx={{ color: theme.palette.border.default }}>Card Number</EvtInputLabel>
            </EvtBox>
            <EvtBox id="CardExpiry" sx={inputFieldStyles}>
                <EvtInputLabel sx={{ color: theme.palette.border.default }}>Expiry Date</EvtInputLabel>
            </EvtBox>
            <EvtBox id="CardSecurityCode" sx={inputFieldStyles}>
                <EvtInputLabel sx={{ color: theme.palette.border.default }}>CVV</EvtInputLabel>
            </EvtBox>

            <EvtButtonPill sx={{ padding: '10px 20px', width: 'fit-content', minWidth: '150px' }} onClick={onSubmit}>
                <ShoppingBagIcon sx={{ mr: '7px' }} />
                Place Order
            </EvtButtonPill>
        </EvtBox>
    )
}

const inputFieldStyles = (theme: Theme): SystemStyleObject<Theme> => ({
    width: '300px',
    height: '54px',
    borderRadius: '35px',
    border: `1px solid ${theme.palette.border.default}`,
    position: 'relative',
    paddingX: '15px',
    label: {
        width: 'fit-content',
        position: 'absolute',
        top: '-10px',
        left: '20px',
        paddingX: '5px',
        backgroundColor: theme.palette.background.default,
    },
    '&.braintree-field-invalid': {
        border: `1px solid ${theme.palette.error.main}`,
        label: {
            color: theme.palette.error.main,
        },
    },
    '.error-info': {
        margin: 0,
        color: theme.palette.error.main,
        paddingBottom: '20px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: '200px',
    },
})
