import { FC } from 'react'
import { Button, ButtonProps, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormikContext } from 'formik'
import { PageURL } from '../urls'
import { EvtLinkTo } from './EvtLinkTo'

export const EvtButton: FC<ButtonProps & EvtButtonProps> = ({
    color = 'btnPrimary',
    to,
    children,
    toId,
    sx,
    ...props
}) => {
    const theme = useTheme()
    if (typeof sx === 'function') {
        sx = { ...sx(theme), textTransform: 'capitalize', fontWeight: 'bold' }
    } else {
        sx = { ...sx, textTransform: 'capitalize', fontWeight: 'bold' }
    }

    return to ? (
        <EvtLinkTo to={to} toId={toId}>
            <Button color={color} sx={sx} {...props}>
                {children}
            </Button>
        </EvtLinkTo>
    ) : (
        <Button color={color} sx={sx} {...props}>
            {children}
        </Button>
    )
}

export const EvtButtonPill: FC<ButtonProps & EvtButtonProps> = ({
    sx,
    variant = 'contained',
    children,
    to,
    toId,
    search,
    ...props
}) => {
    const theme = useTheme()
    if (typeof sx === 'function') {
        sx = { ...sx(theme), borderRadius: '50px' }
    } else {
        sx = { ...sx, borderRadius: '50px' }
    }
    return to ? (
        <EvtLinkTo to={to} toId={toId} search={search}>
            <EvtButton sx={sx} variant={variant} {...props}>
                {children}
            </EvtButton>
        </EvtLinkTo>
    ) : (
        <EvtButton sx={sx} variant={variant} {...props}>
            {children}
        </EvtButton>
    )
}

export const EvtSubmitButton: FC<ButtonProps> = (props) => {
    const { submitForm } = useFormikContext()
    return <EvtButton {...props} onClick={() => submitForm()} />
}

export const EvtSubmitButtonPill = styled(EvtSubmitButton)(({ theme }) => ({
    borderRadius: '50px',
}))

EvtSubmitButtonPill.defaultProps = {
    variant: 'contained',
}

type EvtButtonProps = {
    to?: PageURL
    toId?: string
    search?: { [key: string]: string | number }
}
