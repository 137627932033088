import { config } from '../config'
import { API, createPageUrl } from '../urls'

const productUrls = {
    api: {
        getAll: {
            url: config.baseUrl + '/api/products',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_PRODUCTS',
        } as API,
    },
    pages: {
        index: createPageUrl({
            url: '/products',
            text: 'Products',
        }),
        details: createPageUrl({
            url: '/product/:id',
            text: 'Product',
        }),
    },
}

export default productUrls
