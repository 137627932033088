import React, { useState } from 'react'

const SetStateContext = React.createContext((state: MessageAlerProps | undefined) => {})
const GetMessageAlertStateContext = React.createContext<MessageAlerProps | undefined>(undefined)

const MessageAlertProvider = (props: { children: React.ReactNode }) => {
    const [messageAlert, setMessageAlert] = useState<MessageAlerProps | undefined>()
    return (
        <SetStateContext.Provider value={setMessageAlert}>
            <GetMessageAlertStateContext.Provider value={messageAlert}>
                {props.children}
            </GetMessageAlertStateContext.Provider>
        </SetStateContext.Provider>
    )
}

const useMessageAlert = () => {
    const context = React.useContext(SetStateContext)
    if (context === undefined) {
        throw new Error('useMessageAlert must be used within a MessageAlertProvider')
    }

    return context
}

export { MessageAlertProvider, useMessageAlert, GetMessageAlertStateContext }

export type MessageAlerProps = {
    severity: 'success' | 'error'
    content: string
    timer: number
}
