import { FC } from 'react'
import { useParams } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import FormComponent from '../../components/FormComponent'
import EvtBox from '../../elements/EvtBox'
import { EvtButton, EvtSubmitButton } from '../../elements/EvtButton'
import EvtCollapse from '../../elements/EvtCollapse'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtTypography from '../../elements/EvtTypography'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { usePasswordRest, usePasswordRestTokenCheck } from '../../query/users'
import homeUrls from '../../urls/homeUrls'
import { evtValidator } from '../../utils/validation'

const PasswordRest = () => {
    const navigate = useEvtNavigate()
    const { token } = useParams()
    const {
        data: tokenCheckResponse,
        isLoading: isTokenCheck,
        isSuccess: isTokenCheckSuccess,
    } = usePasswordRestTokenCheck(token, {
        onOk: () => {
            navigate(homeUrls.pages.index)
        },
    })
    const { mutate: passwordRest, isSuccess: isPasswordRestSuccess } = usePasswordRest()
    const formValues = { password: '', confirmPassword: '' }

    const onSubmit = (values: FormValues) => {
        if (tokenCheckResponse) {
            passwordRest({
                token: tokenCheckResponse.token,
                newPassword: values.password,
                confirmPassword: values.confirmPassword,
            })
        }
    }

    return (
        <EvtBox sx={boxContainerStyle()}>
            <Loading isShow={isTokenCheck} />
            <PasswordRestFrom
                isShow={isTokenCheckSuccess && !isPasswordRestSuccess}
                formValues={formValues}
                onSubmit={onSubmit}
            />
            <PasswordResetSuccess isShow={isPasswordRestSuccess} />
        </EvtBox>
    )
}

export default PasswordRest

const Loading: FC<{ isShow: boolean }> = ({ isShow }) => {
    return (
        <EvtCollapse in={isShow}>
            <EvtBox textAlign="center">
                <CircularProgress />
                <EvtTypography marginTop="20px">Token checking..., please wait for a moment</EvtTypography>
            </EvtBox>
        </EvtCollapse>
    )
}

const PasswordRestFrom: FC<PasswordRestFromProps> = ({ isShow, formValues, onSubmit }) => {
    const theme = useTheme()
    return (
        <EvtCollapse in={isShow}>
            <EvtTypography fontSize="30px">Enter New Password</EvtTypography>
            <EvtTypography>Please enter your new password below</EvtTypography>

            <EvtBox textAlign="left" sx={{ mb: '15px' }}>
                <EvtTypography fontSize="12px" color={theme.palette.info.light}>
                    A mixture of both uppercase and lowercase letters
                </EvtTypography>
                <EvtTypography fontSize="12px" color={theme.palette.info.light}>
                    A mixture of letters and numbers Inclusion of at least
                </EvtTypography>
                <EvtTypography fontSize="12px" color={theme.palette.info.light}>
                    one special character, e.g., ! @ # ?
                </EvtTypography>
            </EvtBox>

            <FormComponent initialValues={formValues} validationSchema={evtValidator(formValues)} onSubmit={onSubmit}>
                <EvtBox display="flex" flexDirection="column" alignItems="center" gap="20px" width="300px">
                    <EvtFormInputField label="New Password" name="password" fullWidth isRoundBorder type="password" />
                    <EvtFormInputField
                        label="Confirm New Password"
                        name="confirmPassword"
                        fullWidth
                        isRoundBorder
                        type="password"
                    />
                    <EvtSubmitButton
                        size="large"
                        variant="contained"
                        sx={{ borderRadius: '35px', padding: '15px 20px' }}
                        fullWidth
                    >
                        Reset Password
                    </EvtSubmitButton>
                </EvtBox>
            </FormComponent>
        </EvtCollapse>
    )
}

const PasswordResetSuccess: FC<{ isShow: boolean }> = ({ isShow }) => {
    const navigate = useEvtNavigate()

    return (
        <EvtCollapse in={isShow}>
            <EvtBox textAlign="center">
                <CheckCircleIcon color="success" sx={{ fontSize: '50px', mb: '20px' }} />
                <EvtTypography>Your password reset successfully</EvtTypography>
                <EvtButton
                    variant="contained"
                    sx={{ mt: '30px', borderRadius: '35px', padding: '15px 20px' }}
                    onClick={() => {
                        navigate(homeUrls.pages.index)
                    }}
                >
                    Back to home page
                </EvtButton>
            </EvtBox>
        </EvtCollapse>
    )
}

interface FormValues {
    password: string
    confirmPassword: string
}

interface PasswordRestFromProps {
    isShow: boolean
    formValues: FormValues
    onSubmit: (values: FormValues) => void
}

const boxContainerStyle = () => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingX: '20%',
    pt: '100px',
    gap: '10px',
    alignItems: 'center',
})
