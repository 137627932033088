import eVoucherUrls from '../urls/eVoucherUrls'
import { useEvtGetWithQuery } from './evtQuery'

export function useEvoucher(code: string | undefined) {
    return useEvtGetWithQuery<Retrieve>(eVoucherUrls.api.retrieve, {
        code: code,
    })
}

export type Retrieve = {
    items: EvoucherItem[]
    email: string
    name?: string
    from?: string
    message?: string
    greeting?: string
    terms?: string
    youHaveReceived?: string
    bannerImageUrl: string
}

export type EvoucherItem = {
    barcode?: string
    productName?: string
    expiry?: string
    redeem?: string
    terms?: string
    youHaveReceived?: string
    productImageUrl?: string
    pin?: string
    barcodeUrl?: string
}
