import React, { FC } from 'react'
import { Box, BoxProps } from '@mui/material'
import styled from 'styled-components'

const EvtBox: FC<BoxProps> = ({ children, ...props }) => {
    return <Box {...props}>{children}</Box>
}

export default EvtBox

export const EvtBoxRow = styled(EvtBox)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
}))

export const EvtBoxColumn = styled(EvtBox)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}))
