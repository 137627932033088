import { FC, ReactNode, useEffect, useState } from 'react'
import EvtBox from '../elements/EvtBox'
import EvtSkeleton from '../elements/EvtSkeleton'
import useDeviceType from '../hooks/useDeviceType'
import { Images } from '../query/images'
import { EVT } from '../utils/evt'
import CarouselUI from './CarouselUI'

export const BannerImageCarousel: FC<{ images: Images | undefined }> = ({ images }) => {
    const [banners, setBanners] = useState<Array<ReactNode>>([])
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)

    useEffect(() => {
        if (images) {
            const actualImages = isMobile ? images.mobileImages : images.images
            if (actualImages) {
                setBanners(
                    actualImages.map((i) => (
                        <img
                            key={i.imageUrl}
                            draggable={false}
                            alt={i.Text + i.imageUrl}
                            src={i.imageUrl}
                            width="100%"
                        />
                    )),
                )
            }
        }
    }, [images, isMobile])

    return (
        <>
            {banners && (
                <EvtBox position="relative" className="banner-section">
                    <EvtBox sx={{ width: '100%', height: '100%' }}>
                        {!EVT.any(banners) ? (
                            <BannerSkeleton />
                        ) : banners.length > 1 ? (
                            <CarouselUI
                                renderDotsOutside={false}
                                items={banners}
                                infinite={true}
                                isArrowOutside={false}
                            />
                        ) : (
                            banners[0]
                        )}
                    </EvtBox>
                </EvtBox>
            )}
        </>
    )
}

const BannerSkeleton = () => {
    return <EvtSkeleton variant="rectangular" width="100%" height="300px" />
}
