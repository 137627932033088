import EvtBox from '../elements/EvtBox'
import { EvtButton } from '../elements/EvtButton'
import EvtTypography from '../elements/EvtTypography'
import { useEvtNavigate } from '../hooks/useEvtNavigate'
import homeUrls from '../urls/homeUrls'
import { EVT } from '../utils/evt'

const UnExpectPage = () => {
    const navigate = useEvtNavigate()
    EVT.logNewRelic(`404: ${window.location.href.replace(window.location.origin, '')}`)
    return (
        <EvtBox>
            <EvtTypography sx={{ pt: '200px', pb: '50px' }}>The Page doesn't exist.</EvtTypography>
            <EvtButton
                variant="contained"
                onClick={() => {
                    navigate(homeUrls.pages.index)
                }}
            >
                Back to home page
            </EvtButton>
        </EvtBox>
    )
}

export default UnExpectPage
