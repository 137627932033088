import { FC } from 'react'
import { useTheme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import { EvtTitleTypography } from '../elements/EvtTypography'
import { PageURL } from '../urls'

export const PageBanner: FC<{ page: PageURL; text?: string }> = ({ page, text }) => {
    const theme = useTheme()
    const index = page.url.indexOf('/:')
    const pageUrl = index === -1 ? page.url : page.url.substring(0, index)
    const url = `https://cdn.eventcinemas.com.au/cdn/resources/contentupload/giftshop/corporate/headerpages${pageUrl}.jpg`

    return (
        <EvtBox
            width="100%"
            sx={{
                height: '240px',
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                backgroundColor: theme.palette.pageBanner.bgColor,
                backgroundPosition: 'center',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {text && (
                <EvtTitleTypography
                    sx={{
                        textTransform: 'uppercase',
                        paddingTop: '50px',
                        color: '#ffffff',
                    }}
                >
                    {text}
                </EvtTitleTypography>
            )}
        </EvtBox>
    )
}
