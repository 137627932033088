import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { PageBanner } from '../../components/PageBanner'
import { hubPages } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import EvtDivider from '../../elements/EvtDivider'
import EvtTypography from '../../elements/EvtTypography'
import { HubPage, useEvtNavigate } from '../../hooks/useEvtNavigate'
import { digitalHubUrls, pages } from '../../urls/digitalHubUrls'
import DigitalImage from './DigitalImage'
import DigitalOrder from './DigitalOrder'
import DigitalResend from './DigitalResend'

const DigitalHub = () => {
    const { id: tab = pages.order.url } = useParams()
    let activePage = hubPages.order
    if (tab === pages.image.url) {
        activePage = hubPages.image
    }
    if (tab === pages.resend.url) {
        activePage = hubPages.resend
    }

    return (
        <EvtBox display="flex" flexDirection="column" alignItems="center">
            <PageBanner page={digitalHubUrls.pages.index} text="eVoucher Hub" />
            <EvtBox display="flex" flexDirection="column" width="80%" paddingY="50px">
                <EvtBox display="flex" gap="50px" mb="50px">
                    <Title
                        activePage={activePage}
                        text="Digital Orders"
                        index={hubPages.order}
                        path={pages.order.url}
                    />
                    <Title
                        activePage={activePage}
                        text="Digital Images"
                        index={hubPages.image}
                        path={pages.image.url}
                    />
                    <Title
                        activePage={activePage}
                        text="Sent History"
                        index={hubPages.resend}
                        path={pages.resend.url}
                    />
                </EvtBox>
                <EvtBox>
                    {activePage === hubPages.order && <DigitalOrder />}
                    {activePage === hubPages.image && <DigitalImage />}
                    {activePage === hubPages.resend && <DigitalResend />}
                </EvtBox>
            </EvtBox>
        </EvtBox>
    )
}

export default DigitalHub

interface TitleProps {
    text: string
    index: HubPage
    activePage: HubPage | null
    path: string
}
const Title: FC<TitleProps> = ({ text, index, activePage, path }) => {
    const navigate = useEvtNavigate()
    return (
        <EvtBox
            sx={TitleStyles(activePage === index)}
            onClick={() => {
                navigate(digitalHubUrls.pages.index, path)
            }}
        >
            <EvtTypography>{text}</EvtTypography>
            <EvtDivider />
        </EvtBox>
    )
}

const TitleStyles = (isActive: boolean) => {
    const theme = useTheme()

    return {
        cursor: 'pointer',
        '> hr': isActive
            ? {
                  marginX: '5%',
                  mt: '5px',
                  borderColor: theme.palette.info.main,
                  borderBottomWidth: 'medium',
              }
            : {
                  display: 'none',
              },
    }
}
