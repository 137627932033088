import React, { FC, ReactNode } from 'react'
import ArticleIcon from '@mui/icons-material/Article'
import LoginIcon from '@mui/icons-material/Login'
import PersonIcon from '@mui/icons-material/Person'
import { useTheme } from '@mui/material'
import EvtAvatar from '../../elements/EvtAvatar'
import EvtBox from '../../elements/EvtBox'
import EvtIconButton from '../../elements/EvtIconButton'
import EvtMenuItem from '../../elements/EvtMenuItem'
import EvtTypography from '../../elements/EvtTypography'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { useLogoutUser, useUser } from '../../query/users'
import { PageURL } from '../../urls'
import orderUrls from '../../urls/orderUrls'
import userUrls from '../../urls/userUrls'
import { EVT } from '../../utils/evt'
import MenuUI from '../MenuUI'

interface Props {
    anchorEl: null | HTMLElement
    menuId: string
    isMenuOpen: boolean
    handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void
}

const UserMenu: FC<Props> = ({ anchorEl, menuId, isMenuOpen, handleMenuClose }) => {
    const navigate = useEvtNavigate()
    const theme = useTheme()
    const {
        palette: { nav: navTheme },
    } = theme
    const { mutate: logoutUser } = useLogoutUser()

    const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
        handleMenuClose(e)
        logoutUser()
    }
    const { data: user } = useUser()

    const menuItemGenerator: FC<{
        icon: ReactNode
        text: string
        to?: PageURL
        onClick: (event: React.MouseEvent<HTMLElement>) => void
    }> = ({ icon, text, to, onClick }) => {
        return (
            <EvtMenuItem onClick={onClick}>
                {icon}
                <EvtTypography color={navTheme.topTx} sx={{ marginLeft: theme.spacing(3) }}>
                    {text}
                </EvtTypography>
            </EvtMenuItem>
        )
    }

    const header = user && (
        <EvtMenuItem sx={{ display: 'flex' }}>
            <EvtIconButton
                edge="end"
                sx={{
                    padding: 0,
                    marginRight: theme.spacing(3),
                }}
            >
                <EvtAvatar
                    alt={EVT.getUsersName(user)}
                    sx={{
                        width: 50,
                        height: 50,
                        fontSize: theme.spacing(2.5),
                    }}
                >
                    {EVT.getUsersInitial(user)}
                </EvtAvatar>
            </EvtIconButton>
            <EvtBox display="flex" flexDirection="column" gap="5px">
                <EvtTypography color={navTheme.topTx} sx={{ lineHeight: 1 }} variant="subtitle1">
                    {EVT.getUsersName(user)}
                </EvtTypography>
                {user.company_name && (
                    <EvtTypography fontSize="12px" color={navTheme.topTx} sx={{ lineHeight: 1 }} variant="body2">
                        {user.company_name}
                    </EvtTypography>
                )}
            </EvtBox>
        </EvtMenuItem>
    )
    const footer = menuItemGenerator({
        icon: <LoginIcon sx={{ color: navTheme.navSideIcon }} />,
        text: 'Logout',
        onClick: handleLogout,
    })

    return (
        <MenuUI
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.5)' } }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            header={header}
            footer={footer}
            sx={{ padding: '20px' }}
        >
            {menuItemGenerator({
                icon: <PersonIcon sx={{ color: navTheme.navSideIcon }} />,
                text: 'Profile',
                to: userUrls.pages.profile,
                onClick: (e) => {
                    handleMenuClose(e)
                    navigate(userUrls.pages.profile)
                },
            })}
            {menuItemGenerator({
                icon: <ArticleIcon sx={{ color: navTheme.navSideIcon }} />,
                text: 'Order History',
                to: orderUrls.pages.index,
                onClick: (e) => {
                    handleMenuClose(e)
                    navigate(orderUrls.pages.index)
                },
            })}
        </MenuUI>
    )
}

export default UserMenu
