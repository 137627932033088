import imageUrls from '../urls/imageUrls'
import userUrls from '../urls/userUrls'
import { useEvtPostQuery, useEvtQuery } from './evtQuery'

export function useImages() {
    return useEvtQuery<ImagesResponse>(imageUrls.api.all)
}

export function useImageUpload() {
    return useEvtPostQuery<FormData, { userEvoucherImages: Array<eVoucherImage> }>(userUrls.api.imageUpload, {
        onSuccess: (res, qc) => {
            const key = imageUrls.api.evouchers.url
            const eVoucherImages: EvoucherImagesResponse | undefined = qc.getQueryData(key)

            if (eVoucherImages) {
                eVoucherImages.userEvoucherImages = res.userEvoucherImages
                qc.setQueryData(imageUrls.api.evouchers.url, eVoucherImages)
            }
        },
        skipCache: true,
    })
}

export function useImageDelete() {
    return useEvtPostQuery<{ id: string }, { userEvoucherImages: Array<eVoucherImage> }>(userUrls.api.imageDelete, {
        onSuccess: (res, qc) => {
            const key = imageUrls.api.evouchers.url
            const eVoucherImages: EvoucherImagesResponse | undefined = qc.getQueryData(key)

            if (eVoucherImages) {
                eVoucherImages.userEvoucherImages = res.userEvoucherImages
                qc.setQueryData(imageUrls.api.evouchers.url, eVoucherImages)
            }
        },
    })
}

export function useEvoucherImages() {
    const res = useEvtQuery<EvoucherImagesResponse>(imageUrls.api.evouchers)
    res.data?.evoucherImages.forEach((image) => (image.isDefault = true))
    res.data?.userEvoucherImages.forEach((image) => (image.isDefault = false))
    return res
}

export type ImageRequest = {
    contentLength: number
    contentType: string
    fileName: string
    inputStream: any
}

export type ImagesResponse = {
    footer: Images
    home: HomeImages
}

export type Images = {
    images: Array<Image>
    mobileImages?: Array<Image>
}

export type Image = {
    hrefUrl: string
    imageUrl: string
    Content?: string
    Title?: string
    Text?: string
    Name?: string
    ShortDescription?: string
}

type HomeImages = {
    banners: LoggedInOut
    clients: Images
    products: Images
    whyJoin: Images
}

type LoggedInOut = {
    loggedIn: Images
    loggedOut: Images
}

type EvoucherImagesResponse = {
    evoucherImages: Array<eVoucherImage>
    userEvoucherImages: Array<eVoucherImage>
}

export type eVoucherImage = {
    id: string
    hrefUrl: string
    imageUrl: string
    Category?: string
    isDefault?: boolean
}
