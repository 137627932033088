import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { getLocalStorageItem, setLocalStorageItem } from '../hooks/useLocalStorage'
import { useUser } from '../query/users'
import { Product } from '../types/products/Product'
import { GetMessageAlertStateContext, useMessageAlert } from './MessageAlertProvider'
import { useErrorAlert } from './modals/AlertModalProvider'

export enum TYPE {
    'ADD_TO_CART',
    'REMOVE_FROM_CART',
    'CLEAR_CART',
    'SET',
}

interface Cart {
    items: Product[] | []
    initialized: boolean
}

interface CartFuncProviderProps {
    removeFromCart: (item: Product) => void
    addToCart: (product: Product, qty: number) => void
    clearCart: () => void
    setCart: (products: Array<Product>) => void
}

const CartContext = createContext<CartFuncProviderProps>({
    addToCart: () => {},
    clearCart: () => {},
    removeFromCart: () => {},
    setCart: () => {},
})

const initalState = { items: [], initialized: false }

const GetCartStateContext = React.createContext<Cart>(initalState)
const cartKey = (user: string | undefined) => `_evt_cart_${user}`

const CartProvider = (props: { children: React.ReactNode }) => {
    const errorAlert = useErrorAlert()
    const messageAlert = useMessageAlert()
    const message = useContext(GetMessageAlertStateContext)
    const timer = message?.timer || 0
    const { data: user } = useUser()
    const initValues = getLocalStorageItem<Array<Product>>(cartKey(user?.unique_name), [])
    const [state, dispatch] = useReducer(store.reducer, initalState)

    useEffect(() => {
        if (initValues.length > 0 && !state.initialized) {
            // Reset initial values
            dispatch({
                type: TYPE.SET,
                products: initValues,
            })
        }
    }, [initValues, state.initialized])

    useEffect(() => {
        // Sync local storage with state
        if (user?.unique_name) setLocalStorageItem(cartKey(user?.unique_name), state.items)
    }, [state.items, user?.unique_name])

    const addToCart = useCallback(
        (item: Product, qty: number) => {
            dispatch({
                type: TYPE.ADD_TO_CART,
                item,
                qty,
                errorAlert,
                messageAlert,
                timer,
            })
        },
        [errorAlert, messageAlert, timer],
    )

    const removeFromCart = useCallback(
        (item: Product) =>
            dispatch({
                type: TYPE.REMOVE_FROM_CART,
                item,
                messageAlert,
                timer,
            }),
        [messageAlert, timer],
    )

    const clearCart = useCallback(
        () =>
            dispatch({
                type: TYPE.CLEAR_CART,
            }),
        [],
    )

    const setCart = useCallback(
        (products: Array<Product>) =>
            dispatch({
                type: TYPE.SET,
                products: products,
            }),
        [],
    )

    const value = useMemo(() => {
        return {
            addToCart,
            removeFromCart,
            clearCart,
            setCart,
        }
    }, [addToCart, removeFromCart, clearCart, setCart])

    return (
        <CartContext.Provider value={value}>
            <GetCartStateContext.Provider value={state}>{props.children}</GetCartStateContext.Provider>
        </CartContext.Provider>
    )
}

const useCart = () => {
    const context = React.useContext(CartContext)
    if (context === undefined) {
        throw new Error('useCart must be used within a CartProvider')
    }

    return context
}

type Action =
    | {
          type: TYPE.ADD_TO_CART
          item: Product
          qty: number
          errorAlert: any
          messageAlert: any
          timer: number
      }
    | {
          type: TYPE.REMOVE_FROM_CART
          item: Product
          messageAlert: any
          timer: number
      }
    | { type: TYPE.CLEAR_CART }
    | { type: TYPE.SET; products: Array<Product> }

const store = {
    initialState: {
        items: [],
    },
    dispatches: {
        addToCart: (
            dispatch: React.Dispatch<Action>,
            item: Product,
            qty: number,
            errorAlert: any,
            messageAlert: any,
            timer: number,
        ) => {
            return dispatch({
                type: TYPE.ADD_TO_CART,
                item,
                qty,
                errorAlert,
                messageAlert,
                timer,
            })
        },
        removeFromCart: (dispatch: React.Dispatch<Action>, item: Product, messageAlert: any, timer: number) => {
            return dispatch({
                type: TYPE.REMOVE_FROM_CART,
                item,
                messageAlert,
                timer,
            })
        },
        clearCart: (dispatch: React.Dispatch<Action>) => {
            return dispatch({
                type: TYPE.CLEAR_CART,
            })
        },
        setCart: (dispatch: React.Dispatch<Action>, products: Array<Product>) => {
            return dispatch({
                type: TYPE.SET,
                products: products,
            })
        },
    },
    reducer: (state: Cart, action: Action) => {
        switch (action.type) {
            case TYPE.ADD_TO_CART: {
                const selectedItem = action.item
                const errorAlert = action.errorAlert
                const messageAlert = action.messageAlert
                const timer = action.timer
                let qty = action.qty
                let existing = false
                let isExceedMaxQty = false

                let cartItems = state.items.map((item) => {
                    if (item.id === selectedItem.id && item.price === selectedItem.price) {
                        existing = true
                        if (item.quantity + qty > item.maximumQuantity) {
                            isExceedMaxQty = true
                            qty = item.maximumQuantity - item.quantity
                        }

                        item.quantity += qty
                    }

                    return item
                })

                if (!existing) {
                    if (qty > selectedItem.maximumQuantity) {
                        isExceedMaxQty = true
                        qty = selectedItem.maximumQuantity
                    }

                    selectedItem.quantity = qty
                    cartItems.push(selectedItem)
                }

                cartItems = cartItems.filter((item) => item.quantity > 0)

                if (isExceedMaxQty) {
                    setTimeout(() => {
                        // alert({ title: 'Warning', message: `Exceed the max quantity ${selectedItem.maximumQuantity}` })
                        errorAlert({
                            message: `Exceed the max quantity ${selectedItem.maximumQuantity}`,
                        })
                    }, 0)
                } else {
                    clearTimeout(timer)
                    const newMessageTimer = setTimeout(() => {
                        messageAlert(undefined)
                    }, 1000)
                    if (qty > 0) {
                        setTimeout(() => {
                            messageAlert({
                                severity: 'success',
                                content: 'Added to Cart',
                                timer: newMessageTimer,
                            })
                        }, 0)
                    } else {
                        setTimeout(() => {
                            messageAlert({
                                severity: 'error',
                                content: 'Removed from Cart',
                                timer: newMessageTimer,
                            })
                        }, 0)
                    }
                }

                return {
                    ...state,
                    items: cartItems,
                }
            }

            case TYPE.REMOVE_FROM_CART: {
                const selectedItem = action.item
                const messageAlert = action.messageAlert
                const timer = action.timer

                clearTimeout(timer)
                const newMessageTimer = setTimeout(() => {
                    messageAlert(undefined)
                }, 1000)

                setTimeout(() => {
                    messageAlert({ severity: 'error', content: 'Removed from Cart', timer: newMessageTimer })
                }, 0)
                return {
                    ...state,
                    items: state.items.filter(
                        (item) => item.id !== selectedItem.id || item.price !== selectedItem.price,
                    ),
                }
            }
            case TYPE.CLEAR_CART: {
                return {
                    ...state,
                    ...store.initialState,
                }
            }
            case TYPE.SET: {
                return {
                    ...state,
                    items: action.products,
                    initialized: true,
                }
            }
            default:
                throw new Error(`Unknown action: ${JSON.stringify(action)}`)
        }
    },
}

export { CartProvider, GetCartStateContext, useCart }
