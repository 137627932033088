import { FC, useState } from 'react'
import { Tab, Tabs, Theme } from '@mui/material'
import EvtBox from '../elements/EvtBox'

interface Props {
    options: Array<{ text: string; value: string | number }>
    onClick: (value: string | number) => void
    selectedCategory: string | number
}

const ScrollCategoryTab: FC<Props> = ({ options, selectedCategory, onClick }) => {
    const [value, setValue] = useState(options.findIndex((option) => option.value === selectedCategory))
    const handleClick = (index: number) => {
        setValue(index)
        onClick(options[index].value)
    }

    return (
        <EvtBox>
            <Tabs value={value} variant="scrollable" scrollButtons allowScrollButtonsMobile sx={tabsStyles}>
                {options.map((option, index) => (
                    <EvtBox key={index}>
                        <Tab label={option.text} onClick={() => handleClick(index)} />
                    </EvtBox>
                ))}
            </Tabs>
        </EvtBox>
    )
}

export default ScrollCategoryTab

const tabsStyles = (theme: Theme) => {
    return {
        '.MuiTabScrollButton-root': {
            width: '30px',
            height: '40px',
            svg: { fontSize: '1.7rem' },
        },
        '.MuiTabs-flexContainer button': {
            width: 'max-content',
            textTransform: 'inherit',
            fontSize: '1rem',
            padding: '0px 12px',
            minWidth: 'unset',
            letterSpacing: '0.05em',
            minHeight: '40px',
            opacity: 1,
        },
    }
}
