import React, { FC } from 'react'
import { Link, LinkProps } from '@mui/material'
import EvtTooltip from './EvtTooltip'

const EvtLink: FC<{ title?: string } & LinkProps> = ({ title, children, ...props }) => {
    return (
        <>
            {title ? (
                <EvtTooltip title={title}>
                    <Link {...props}>{children}</Link>
                </EvtTooltip>
            ) : (
                <Link {...props}> {children}</Link>
            )}
        </>
    )
}

export default EvtLink
