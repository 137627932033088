import { useParams } from 'react-router-dom'
import { EvtLoader } from '../../elements/EvtLoader'
import { useViewAs } from '../../query/users'

export const ViewAs = () => {
    const { id } = useParams()
    useViewAs(id)

    return (
        <EvtLoader loading={true}>
            <div></div>
        </EvtLoader>
    )
}
