import React, { FC } from 'react'
import { Step, StepLabel, Stepper, StepperProps } from '@mui/material'

interface Props {
    steps: Array<string>
    activeStepIndex: number
}

const EvtStepper: FC<Props & StepperProps> = ({ steps, activeStepIndex, ...props }) => {
    return (
        <Stepper {...props} activeStep={activeStepIndex}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default EvtStepper
