import React, { FC } from 'react'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtTypography, { EvtTitleTypography } from '../../elements/EvtTypography'
import { Image } from '../../query/images'
import { useUser } from '../../query/users'
import userUrls from '../../urls/userUrls'

interface Props {
    images: Array<Image>
}

const ValuedClientsSection: FC<Props> = ({ images }) => {
    const { data: user } = useUser()
    return (
        <>
            <EvtTitleTypography mb="40px" mt="40px" color="#000000">
                some of our valued clients
            </EvtTitleTypography>

            <EvtBox>
                <EvtBox display="flex" justifyContent="center" gap="50px" flexWrap="wrap">
                    {images.map((image, index) => (
                        <ImageItem key={index} image={image} />
                    ))}
                </EvtBox>
                <EvtBox paddingTop="50px">
                    <EvtTypography color="#000000">
                        Join in with some of Australia's leading companies rewading their clients and staff a like by
                        giving the gift of cinema
                    </EvtTypography>
                    <EvtTypography color="#000000"></EvtTypography>
                </EvtBox>
                {user === null ? (
                    <EvtButtonPill to={userUrls.pages.login} sx={{ padding: '15px 35px', mt: '40px' }}>
                        Login / Join Now
                    </EvtButtonPill>
                ) : (
                    <></>
                )}
            </EvtBox>
        </>
    )
}

export default ValuedClientsSection

const ImageItem: FC<{ image: Image }> = ({ image }) => {
    const { imageUrl } = image
    return <img alt="valued client" src={imageUrl} height="100px" width="auto" />
}
