export const storage = {
    get: (key: string) => {
        let result
        if (storage.isLocalStorageEnabled()) {
            // Code for localStorage/sessionStorage.
            storage.removeExpired()
            result = localStorage.getItem(key)
        }

        if (result) {
            return JSON.parse(result)
        }

        return result
    },

    set: (key: string, object: any, expires: Date | number) => {
        const stringified = object ? JSON.stringify(object) : '{}'
        if (storage.isLocalStorageEnabled()) {
            localStorage.setItem(key, stringified)
            if (expires) {
                let valid = true
                if (typeof expires === 'number') {
                    // in minutes
                    expires = new Date().getTime() + 1000 * 60 * expires
                } else if (Object.prototype.toString.call(expires) === '[object Date]') {
                    expires = expires.getTime()
                } else {
                    valid = false
                }

                if (valid) {
                    const expired = JSON.parse(localStorage.getItem('_evt_expirations') || '{}')
                    expired[key] = expires
                    localStorage.setItem('_evt_expirations', JSON.stringify(expired))
                }
            }
        }
    },

    remove: (key: string) => {
        if (storage.isLocalStorageEnabled()) {
            localStorage.removeItem(key)
        }
    },

    removeExpired: function () {
        const expired = JSON.parse(localStorage.getItem('_evt_expirations') || '{}')
        const now = new Date().getTime()
        Object.entries(expired).forEach(([key, value]) => {
            if ((value as number) < now) {
                localStorage.removeItem(key)
            }
        })
    },

    removeAllMatching: function (key: string) {
        const allKeys = Object.keys(localStorage)
        allKeys.forEach((k) => {
            if (k.indexOf(key) !== -1) {
                storage.remove(k)
            }
        })
    },

    isLocalStorageEnabled: function () {
        if (typeof Storage !== 'undefined') {
            try {
                localStorage.getItem('bob')
                return true
            } catch (e) {}
        }

        return false
    },
}
