import { FC, useEffect, useRef } from 'react'
import lottie, {
    AnimationDirection,
    AnimationEventName,
    AnimationItem,
    AnimationSegment,
} from 'lottie-web/build/player/lottie_light'

const Lottie: FC<Props> = ({
    animationData,
    segments,
    forceSegments = false,
    direction = 1,
    isStopped = false,
    isPaused = false,
    speed = 1,
    width,
    loop = false,
    autoplay = true,
    height,
    style,
    eventListeners = [],
    ariaRole = 'button',
    ariaLabel = 'animation',
    title = '',
    tabIndex = 0,
}) => {
    const animationContainer = useRef<HTMLDivElement>(null)
    const anim = useRef<AnimationItem>()

    useEffect(() => {
        if (animationContainer.current) {
            anim.current = lottie.loadAnimation({
                container: animationContainer.current,
                loop: loop,
                autoplay: autoplay,
                animationData: animationData,
            })

            eventListeners.forEach((eventListener) => {
                anim.current?.addEventListener(eventListener.eventName, eventListener.callback)
            })

            return () => {
                eventListeners.forEach((eventListener) => {
                    anim.current?.removeEventListener(eventListener.eventName, eventListener.callback)
                })
                anim.current?.destroy()
            }
        }
    }, [animationData, loop, autoplay, eventListeners])

    useEffect(() => {
        if (anim.current) {
            anim.current.setSpeed(speed)
            anim.current.setDirection(direction)

            if (isStopped) {
                anim.current.stop()
            } else if (segments) {
                anim.current.playSegments(segments, forceSegments)
            } else {
                anim.current.play()
            }
        }
    }, [isStopped, segments, speed, direction, forceSegments])

    useEffect(() => {
        if (anim.current) {
            if (isPaused && !anim.current.isPaused) {
                anim.current.pause()
            } else if (!isPaused && anim.current.isPaused) {
                anim.current.pause()
            }
        }
    }, [isPaused])

    const lottieStyles = {
        width: getSize(width),
        height: getSize(height),
        overflow: 'hidden',
        margin: '0 auto',
        outline: 'none',
        ...style,
    }

    return (
        <div
            ref={animationContainer}
            style={lottieStyles}
            title={title}
            role={ariaRole}
            aria-label={ariaLabel}
            tabIndex={tabIndex}
        ></div>
    )
}

export default Lottie

const getSize = (initial: string | number | undefined) => {
    let size

    if (typeof initial === 'number') {
        size = `${initial}px`
    } else {
        size = initial || '100%'
    }

    return size
}

type Props = {
    animationData: any
    eventListeners?: Array<{
        eventName: AnimationEventName
        callback: (args: any) => void
    }>
    height?: string | number
    width?: string | number
    isStopped?: boolean
    isPaused?: boolean
    speed?: number
    segments?: Array<AnimationSegment>
    forceSegments?: boolean
    direction?: AnimationDirection
    ariaRole?: string
    ariaLabel?: string
    title?: string
    loop?: boolean
    style?: React.CSSProperties
    tabIndex?: number
    autoplay?: boolean
}
