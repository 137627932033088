import { FC } from 'react'
import { Link, createSearchParams } from 'react-router-dom'
import { SxProps, Theme, styled } from '@mui/material'
import { PageURL } from '../urls'
import EvtBox from './EvtBox'
import EvtTooltip from './EvtTooltip'

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    ':visited': { color: theme.palette.text.primary },
    textDecoration: 'none',
}))

export const EvtLinkTo: FC<{
    className?: string
    to: PageURL
    toId?: number | string | undefined
    sx?: SxProps<Theme>
    linkSx?: SxProps<Theme>
    children: React.ReactNode
    title?: string
    search?: { [key: string]: string | number }
    isOpenInNew?: boolean
}> = ({ className, to, toId, sx, children, title, search, linkSx, isOpenInNew = false, ...props }) => {
    let toUrl = to.idUrl(toId)
    let openNewTab = {}
    if (search) {
        // @ts-ignore
        toUrl += `?${createSearchParams(search)}`
    }
    if (isOpenInNew) {
        openNewTab = {
            target: '_blank',
            rel: 'noopener noreferrer',
        }
    }

    return (
        <EvtBox sx={sx} {...props} className={className}>
            {title ? (
                <EvtTooltip title={title}>
                    <StyledLink sx={linkSx} to={toUrl} {...openNewTab}>
                        {children}
                    </StyledLink>
                </EvtTooltip>
            ) : (
                <StyledLink sx={linkSx} to={toUrl} {...openNewTab}>
                    {children}
                </StyledLink>
            )}
        </EvtBox>
    )
}
