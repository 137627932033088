import { useEffect } from 'react'
import EvtBox from '../../elements/EvtBox'
import EvtDivider from '../../elements/EvtDivider'
import EvtTypography from '../../elements/EvtTypography'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { useUser } from '../../query/users'
import homeUrls from '../../urls/homeUrls'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'

export const Login = () => {
    const { data: user } = useUser()
    const navigate = useEvtNavigate()
    useEffect(() => {
        if (user) {
            navigate(homeUrls.pages.index)
        }
    })

    return (
        <EvtBox
            className="evt-login-container"
            sx={{
                margin: 'auto',
                width: '90%',
                padding: '40px 5%',
            }}
        >
            <SignInForm />

            <EvtDivider sx={{ marginY: '50px' }}>
                <EvtTypography paddingX="20px" fontSize="0.9rem">
                    Or
                </EvtTypography>
            </EvtDivider>

            <SignUpForm />
        </EvtBox>
    )
}
