import { FC } from 'react'
import { Typography, TypographyProps, useTheme } from '@mui/material'

interface Props {
    color?: string
    component?: any
}

const EvtTypography: FC<Props & TypographyProps> = ({ children, color, component, ...props }) => {
    const theme = useTheme()
    //@ts-ignore
    if (!color && !props?.sx?.color) {
        color = theme.palette.text.primary
    }
    return (
        <Typography {...props} color={color} component={component}>
            {children}
        </Typography>
    )
}

export const EvtTitleTypography: FC<Props & TypographyProps> = ({ children, ...props }) => {
    return (
        <EvtTypography
            {...props}
            fontFamily={'TTBluescreens-Regular'}
            fontSize={'3em'}
            textTransform="uppercase"
            letterSpacing="5px"
        >
            {children}
        </EvtTypography>
    )
}

export default EvtTypography
