import { MouseEvent, useContext } from 'react'
import Modal from '../elements/EvtModal'
import { GetModalStateContext, useModal } from '../providers/modals/GlobalModalProvider'

export const GlobalModal = () => {
    const settings = useContext(GetModalStateContext)
    const { forceClose } = useModal()

    const closeGlobalModal = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (settings && settings.onClose) {
            settings.onClose(e)
        }

        forceClose()
    }

    const cancelClick = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (settings && settings.onCancel) {
            settings.onCancel(e)
        }

        closeGlobalModal(e)
    }

    const okClick = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (settings && settings.onOk) {
            settings.onOk(e)
        }

        if (!e?.defaultPrevented) {
            forceClose()
        }
    }

    return (
        <Modal
            isOpen={!!settings}
            title={settings?.title}
            maxWidth="desktop"
            confirmFunc={okClick}
            cancelFunc={cancelClick}
            buttons={settings?.buttons}
        >
            <div>{settings?.content}</div>
        </Modal>
    )
}
