import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import EvtBox from '../elements/EvtBox'

interface Props<T> {
    initialValue: T
    children: (props: FuncProps<T>) => JSX.Element
    onSubmit: (model: T) => void
}

interface FuncProps<T> {
    handleClear: (key: string, value?: any) => void
    setFieldValue: (key: string, value: any) => void
    filterState: T
    submit: () => void
}

export const EvtFilterWrapper = <T extends unknown>({ children, onSubmit, initialValue }: Props<T>) => {
    const location = useLocation()
    const [filterState, setFilterState] = useState<T>(initialValue)
    useEffect(() => {
        setFilterState(initialValue)
    }, [location])

    const handleClear = (key: string, value: any = '') => {
        const newState = setFieldValue(key, value || '')
        onSubmit(newState)
    }

    const submit = () => {
        onSubmit(filterState)
    }

    const setFieldValue = (key: string, value: any) => {
        // @ts-ignore
        const newFilter: T = { ...filterState }
        // @ts-ignore
        newFilter[key] = value
        setFilterState(newFilter)
        return newFilter
    }

    return <EvtBox>{children({ handleClear, filterState, setFieldValue, submit } as FuncProps<T>)}</EvtBox>
}
