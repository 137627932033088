import { ChangeEvent, FC, useState } from 'react'
import { Theme } from '@mui/material'
import * as yup from 'yup'
import FormComponent from '../../components/FormComponent'
import StepContainerUI, { StepContinueButtonSubmit } from '../../components/StepContainerUI'
import EvtBox from '../../elements/EvtBox'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtGrid from '../../elements/EvtGrid'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { EVT } from '../../utils/evt'
import { evtValidator } from '../../utils/validation'
import { STEP } from './Assign'

export interface Message {
    from: string
    greeting: string
    message: string
}

interface Props {
    updatedFormValues: Message
    activeStepIndex: number
    messageStep: STEP
    onNext: (message: any) => void
    onBack: () => void
    onChange: (message: any) => void
}

const maxMessageLength = 100
const maxGreetingLength = 50
const maxFromLength = 180

const AssignAddMessage: FC<Props> = ({ updatedFormValues, activeStepIndex, messageStep, onNext, onBack, onChange }) => {
    const isActive = activeStepIndex === messageStep.index
    const isDone = activeStepIndex > messageStep.index
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const [message, setMessage] = useState<Message>(updatedFormValues)
    const handleFieldUpdate = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, attr: string) => {
        setMessage({
            ...message,
            [attr]: e.target.value,
        })
        onChange(message)
    }
    return (
        <StepContainerUI
            id={messageStep.id}
            index={messageStep.index}
            title="Add Your Message"
            isAssign={true}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={isDesktop ? 500 : 900}
            onBack={onBack}
        >
            {isActive && (
                <FormComponent
                    enableReinitialize={true}
                    initialValues={message}
                    validationSchema={formValidation}
                    onSubmit={onNext}
                >
                    <EvtGrid container spacing={5}>
                        <EvtGrid item xs={12}>
                            <EvtFormInputField
                                label="From"
                                name="from"
                                maxLength={maxFromLength}
                                value={message.from}
                                onChange={(e) => handleFieldUpdate(e, 'from')}
                                isRoundBorder
                            />
                        </EvtGrid>
                        <EvtGrid item xs={12}>
                            <EvtFormInputField
                                label="Greeting"
                                name="greeting"
                                maxLength={maxGreetingLength}
                                value={message.greeting}
                                onChange={(e) => handleFieldUpdate(e, 'greeting')}
                                isRoundBorder
                            />
                        </EvtGrid>
                        <EvtGrid item xs={12}>
                            <EvtFormInputField
                                label="Message"
                                name="message"
                                maxLength={maxMessageLength}
                                value={message.message}
                                onChange={(e) => handleFieldUpdate(e, 'message')}
                                isRoundBorder
                                rows={8}
                                multiline
                                helperText={`${message.message.length}/${maxMessageLength}`}
                                FormHelperTextProps={{ sx: messageCounterStyles }}
                            />
                        </EvtGrid>
                    </EvtGrid>
                    <StepContinueButtonSubmit text="Continue to Assign Recipients" />
                </FormComponent>
            )}
            {isDone && (
                <EvtBox sx={(t) => descriptionStyle(t)}>
                    <EvtTypography className="title" color="inherit">
                        From
                    </EvtTypography>
                    <EvtTypography color="inherit">{updatedFormValues.from}</EvtTypography>
                    <EvtTypography className="title" color="inherit">
                        Greeting
                    </EvtTypography>
                    <EvtTypography color="inherit">{updatedFormValues.greeting}</EvtTypography>
                    <EvtTypography className="title" color="inherit">
                        Message
                    </EvtTypography>
                    <EvtTypography color="inherit">{updatedFormValues.message}</EvtTypography>
                </EvtBox>
            )}
        </StepContainerUI>
    )
}

export default AssignAddMessage

const descriptionStyle = (theme: Theme) => ({
    color: theme.palette.stepContainerUI.border.secondary,
    '.title': {
        fontWeight: 'bold',
        fontSize: '12px',
        paddingBottom: '4px',
        paddingTop: '15px',
    },
})

const messageCounterStyles = () => ({
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    bottom: '-23px',
})

export const defaultMessage: Message = {
    from: '',
    greeting: '',
    message: '',
}

const formValidation = evtValidator(defaultMessage, {
    from: yup.string().max(maxFromLength, `From must not exceed ${maxFromLength} characters`).required('Required'),
    greeting: yup
        .string()
        .max(maxGreetingLength, `Greeting must not exceed ${maxGreetingLength} characters`)
        .required('Required'),
    message: yup.string().max(maxMessageLength, `Message must not exceed ${maxMessageLength} characters`),
})
