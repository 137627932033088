import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Theme } from '@mui/material'
import EvtContainer from '../elements/EvtContainer'
import { MetaTag, useSettings } from '../query/settings'
import EvoHelmet from './EvoHelmet'

interface Props {
    children: React.ReactElement
    pageTitle?: string
}

const Page: FC<Props> = ({ children, pageTitle }) => {
    const { data: { metaTags } = {} } = useSettings()
    const { pathname } = useLocation()
    const [tag, setTag] = useState<MetaTag | undefined>(undefined)

    useEffect(() => {
        let applicableTag: MetaTag | undefined = undefined
        if (metaTags) {
            applicableTag = metaTags.filter((t) => t.url === pathname)[0]
        }

        setTag(applicableTag)
    }, [metaTags, pathname])

    return (
        <>
            <EvoHelmet
                title={pageTitle}
                isTopLevel={true}
                description={tag?.metaDescription}
                keywords={tag?.metaKeywords}
            />
            <EvtContainer className="evt-page-container" disableGutters={true} sx={containerStyle}>
                {children}
            </EvtContainer>
        </>
    )
}

export default Page

const containerStyle = (theme: Theme) => ({
    marginTop: theme.navbar.topNav.topNavHeight,
    marginLeft: theme.navbar.drawer.closeWidth,
    textAlign: 'center',
    width: `calc(100% - ${theme.navbar.drawer.closeWidth})`,
    maxWidth: `calc(100% - ${theme.navbar.drawer.closeWidth}) !important`,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        width: '100%',
        maxWidth: '100% !important',
        marginLeft: 0,
    },
    backgroundColor: theme.palette.background.default,
    minHeight: `calc(100% - ${theme.navbar.topNav.topNavHeight} - 64px)`,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
})
