import { useContext, useEffect, useState } from 'react'
import { SelectChangeEvent, Theme } from '@mui/material'
import { EvtFilterWrapper } from '../../components/EvtFilterWrapper'
import { Download, Resend, View } from '../../components/OrderAndProductActions'
import OrderAndProductActionsMobile from '../../components/OrderAndProductActionsMobile'
import { OrderCta } from '../../components/OrderAndProductCta'
import { PageBanner } from '../../components/PageBanner'
import { ProgressWithLabel } from '../../components/ProgressWithLabel'
import TableEmptyContent from '../../components/TableEmptyContent'
import { theme } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtButton } from '../../elements/EvtButton'
import EvtDateRangePicker, { DateFilterProps } from '../../elements/EvtDateRangePicker'
import EvtGrid from '../../elements/EvtGrid'
import { EvtLinkTo } from '../../elements/EvtLinkTo'
import EvtSearchField from '../../elements/EvtSearchField'
import { EvtSelect } from '../../elements/EvtSelect'
import EvtTableDetail, { AlignType, Column } from '../../elements/EvtTableDetail'
import useDeviceType from '../../hooks/useDeviceType'
import { GetThemeStateContext } from '../../providers/CustomThemeProvider'
import { useOrders } from '../../query/orders'
import { Order } from '../../types/orders/Order'
import orderUrls from '../../urls/orderUrls'
import productUrls from '../../urls/productUrls'
import { EVT } from '../../utils/evt'

const INITIAL_FILTER = {
    query: '',
    productType: 0,
    dateRange: { fromDate: null, toDate: null },
    pageSize: 10,
}

const Orders = () => {
    const isLight = useContext(GetThemeStateContext).theme === theme.LIGHT
    const deviceType = useDeviceType()
    const [page, setPage] = useState<number>(1)

    const [filter, setFilter] = useState<Filter>({ ...INITIAL_FILTER })
    const [categoryOptions, setCategoryOptions] = useState([{ value: '0', text: 'All' }])

    const { data: pagination, isLoading } = useOrders({
        query: filter.query,
        page: page,
        pageSize: filter.pageSize,
        productType: filter.productType === 0 ? undefined : filter.productType,
        fromDate: filter.dateRange.fromDate?.toDate(),
        toDate: filter.dateRange.toDate?.toDate(),
    })

    useEffect(() => {
        if (categoryOptions.length === 1 && pagination?.productTypes) {
            setCategoryOptions([
                ...categoryOptions,
                ...(pagination?.productTypes.map((p) => ({ value: p.value.toString(), text: p.text })) || []),
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    return (
        <>
            <PageBanner page={orderUrls.pages.index} text="Orders" />
            <EvtBox
                sx={{
                    paddingY: '50px',
                    paddingX: {
                        mobile: '20px',
                        tablet: '50px',
                        desktop: '10%',
                    },
                }}
            >
                <EvtFilterWrapper
                    initialValue={INITIAL_FILTER}
                    onSubmit={(filter) => {
                        setPage(1)
                        setFilter(filter)
                    }}
                >
                    {({ filterState, handleClear, setFieldValue, submit }) => (
                        <EvtGrid container spacing={4} mt="20px" mb="20px">
                            <EvtGrid item xs={12} sm={6} lg={2}>
                                <EvtSelect
                                    label="Category"
                                    fullWidth
                                    options={categoryOptions}
                                    value={filterState.productType || 0}
                                    handleKeyDown={submit}
                                    onChange={(e: SelectChangeEvent<unknown>) => {
                                        setFieldValue('productType', parseInt(e.target.value as string))
                                    }}
                                    sx={{ color: (t) => t.palette.text.secondary }}
                                />
                            </EvtGrid>
                            <EvtGrid item xs={12} sm={6} lg={3}>
                                <EvtSearchField
                                    fullWidth
                                    value={filterState.query}
                                    onChange={(value) => setFieldValue('query', value as string)}
                                    onClear={() => handleClear('query')}
                                    onEnterToSearch={submit}
                                />
                            </EvtGrid>
                            <EvtGrid item xs={9} lg={5}>
                                <EvtDateRangePicker
                                    dateFilter={filterState.dateRange}
                                    onChange={(date) => setFieldValue('dateRange', date)}
                                    onClear={(date) => handleClear('dateRange', date)}
                                    onEnterToSearch={submit}
                                />
                            </EvtGrid>
                            <EvtGrid item xs={3} lg={2}>
                                <EvtButton fullWidth variant="contained" sx={{ height: '100%' }} onClick={submit}>
                                    Search
                                </EvtButton>
                            </EvtGrid>
                        </EvtGrid>
                    )}
                </EvtFilterWrapper>

                <EvtTableDetail
                    columns={
                        EVT.isMobile(deviceType)
                            ? orderColumnsMobile(pagination?.orders?.items)
                            : orderColumns(pagination?.orders?.items)
                    }
                    isLoading={isLoading}
                    pagination={pagination?.orders}
                    onPageChange={setPage}
                    skeletonRows={filter.pageSize}
                    sx={(t) => tableStyle(t, isLight)}
                    emptyNode={
                        <TableEmptyContent
                            text="No orders to show"
                            btnTxt="Purchase products"
                            btnLinkTo={productUrls.pages.index}
                        />
                    }
                />
            </EvtBox>
        </>
    )
}

export default Orders

interface Filter {
    dateRange: DateFilterProps
    productType?: number
    query: string
    pageSize: number
}

export const orderColumns = (orders: Array<Order> | undefined) => {
    const maxLength =
        orders?.reduce((p, c) => {
            return Math.max(p, c.totalSendQuantity || 0).toString().length
        }, 0) || 0

    const retVal: Array<Column<Order>> = [
        {
            title: 'Order',
            descriptor: (o) => (
                <EvtLinkTo to={orderUrls.pages.details} toId={o.orderId} className="order-link">
                    {o.orderNumber}
                </EvtLinkTo>
            ),
        },
        { title: 'Order Date', descriptor: (o) => EVT.displayDate(o.created), align: AlignType.Center },
        { title: 'Ref. Name', descriptor: (o) => o.postalOrderReference, align: AlignType.Center },
        { title: 'Total', descriptor: (o) => EVT.toCurrency(o.orderTotal), align: AlignType.Center },
        {
            title: 'Quantity Assigned/Total',
            descriptor: (o) =>
                o.hasElectronic ? (
                    <ProgressWithLabel
                        current={o.totalSent || 0}
                        total={o.totalSendQuantity || 0}
                        maxNumberLength={maxLength}
                    />
                ) : (
                    '-'
                ),
            align: AlignType.Center,
        },
        {
            title: 'Payment',
            descriptor: (o) => o.paymentMethod || (o.orderTotal === 0 ? 'N/A' : 'Awaiting Payment'),
            align: AlignType.Center,
        },
        {
            title: 'Actions',
            descriptor: (o) => (
                <EvtBox display="flex" justifyContent="center" gap="10px">
                    {(o.totalSent || 0) > 0 && <Download orderId={o.orderId} />}
                    <View orderId={o.orderId} />
                    <Resend disabled={!o.canResend || false} orderNumber={o.orderNumber} />
                </EvtBox>
            ),
            align: AlignType.Center,
        },
        { title: '', descriptor: (o) => <OrderCta order={o} />, align: AlignType.Center },
    ]

    return retVal
}

export const orderColumnsMobile = (orders: Array<Order> | undefined) => {
    const retVal: Array<Column<Order>> = [
        { title: 'Order', descriptor: (o) => o.orderNumber },
        { title: 'Total', descriptor: (o) => EVT.toCurrency(o.orderTotal), align: AlignType.Center },
        {
            title: '',
            descriptor: (o) => (
                <OrderAndProductActionsMobile
                    listItems={[
                        <OrderCta order={o} isMobile />,
                        (o.totalSent || 0) > 0 ? <Download orderId={o.orderId} /> : null,
                        <View orderId={o.orderId} />,
                        <Resend disabled={!o.canResend || false} orderNumber={o.orderNumber} />,
                    ]}
                />
            ),
            align: AlignType.Center,
        },
    ]

    return retVal
}

const tableStyle = (theme: Theme, isLight: boolean) => ({
    '.MuiTableCell-root': {
        color: isLight ? theme.palette.text.primary : theme.palette.text.secondary,
        '.order-link': {
            textDecoration: 'underline',
            a: { color: theme.palette.text.secondary },
        },
        '.progress-text': {
            color: `${theme.palette.text.secondary} !important`,
        },
    },
})
