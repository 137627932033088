import { FC } from 'react'
import EVoucherTemplate from '../../components/EVoucherTemplate'
import EvtBox from '../../elements/EvtBox'
import EvtTypography from '../../elements/EvtTypography'
import { eVoucherImage } from '../../query/images'
import { OrderProduct } from '../../types/orders/Order'
import { Message } from './AssignAddMessage'

interface Props {
    product: OrderProduct | null
    image: eVoucherImage | null
    message: Message | null
}
const MessagePreview: FC<Props> = ({ message, product, image }) => {
    const productName = product?.name || '#Product Name'
    const greeting = message?.greeting || '#Greeting'
    const sender = message?.from || '#Sender'
    const orderMessage = message?.message || '#Message'

    return (
        <EvtBox display="flex" alignItems="center">
            <EvtTypography sx={{ writingMode: 'vertical-rl', textOrientation: 'mixed', transform: 'scale(-1, -1)' }}>
                Live Preview
            </EvtTypography>
            <EVoucherTemplate
                greeting={greeting}
                sender={sender}
                message={orderMessage}
                bannerImageUrl={image?.imageUrl}
                isShowButton={false}
                items={[
                    {
                        productName: productName,
                    },
                ]}
            />
            <EvtTypography sx={{ writingMode: 'vertical-rl', textOrientation: 'mixed', transform: 'scale(1, 1)' }}>
                Live Preview
            </EvtTypography>
        </EvtBox>
    )
}

export default MessagePreview
