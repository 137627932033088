import { config } from '../config'
import { API } from '../urls'

const imageUrls = {
    api: {
        all: {
            url: config.baseUrl + '/api/images',
            requiresAuth: true,
            method: 'GET',
        } as API,
        evouchers: {
            url: config.baseUrl + '/api/images/evouchers',
            requiresAuth: true,
            method: 'GET',
        } as API,
    },
}

export default imageUrls
