import { FC } from 'react'
import { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import EvtBox from '../elements/EvtBox'
import { EvtButtonPill } from '../elements/EvtButton'
import EvtSkeleton from '../elements/EvtSkeleton'
import EvtTypography from '../elements/EvtTypography'
import { useEvtNavigate } from '../hooks/useEvtNavigate'
import AusPostIcon from '../icons/AusPostIcon'
import { Image } from '../query/images'
import { Product } from '../types/products/Product'
import productUrls from '../urls/productUrls'
import userUrls from '../urls/userUrls'
import AddToCartSection from './AddToCartSection'

interface Props {
    product?: Product
    image?: Image
    isHasShortDes: boolean
    mobile?: boolean
}

const ProductCard: FC<Props> = ({ product, image, isHasShortDes, mobile }) => {
    const navigate = useEvtNavigate()
    let name = ''
    let shortDescription = ''
    let imageUrl = ''
    if (image) {
        name = image.Name || ''
        shortDescription = image.ShortDescription || ''
        imageUrl = image.imageUrl
    }
    if (product) {
        name = product.name
        shortDescription = product.shortDescription
        imageUrl = product.cardImageUrl
    }

    return (
        <EvtBox className="product-card" sx={(t) => outerBoxStyle(t, mobile)}>
            <EvtBox
                sx={(t) => imageBoxStyle(t, imageUrl)}
                onClick={() => {
                    product && navigate(productUrls.pages.details, product.url)
                }}
            />

            <EvtBox sx={contentBoxStyle}>
                {product?.isPhysical && <AusPostIcon sx={AusPostIconStyle} />}
                <EvtBox sx={(t) => titleStyle(t, product?.isPhysical || false)}>
                    <TitleStyles height={isHasShortDes ? '24px' : '0px'}>
                        {shortDescription && shortDescription}
                    </TitleStyles>
                    {name && (
                        <TitleStyles>
                            <b>{name}</b>
                        </TitleStyles>
                    )}
                </EvtBox>
                {image && (
                    <EvtButtonPill fullWidth sx={addToCartStyle} to={userUrls.pages.login}>
                        login / sign up now
                    </EvtButtonPill>
                )}
                {product && <AddToCartSection product={product} mobile={mobile} />}
            </EvtBox>
        </EvtBox>
    )
}

export const ProductCardSkeleton: FC<{ mobile?: boolean }> = ({ mobile }) => {
    return (
        <EvtBox sx={(t) => outerBoxStyle(t, mobile)}>
            <EvtSkeleton sx={imageBoxStyle} variant="rectangular" />
            <EvtBox sx={contentBoxStyle}>
                <EvtBox sx={(t) => titleStyle(t, false)}>
                    <EvtSkeleton width="100%" />
                    <EvtSkeleton width="50%" />
                </EvtBox>
                <EvtBox sx={actionsBoxStyle}>
                    <EvtBox sx={priceOptionsStyle}>
                        <EvtSkeleton
                            sx={{ borderRadius: '35px', width: '100%', height: '50px' }}
                            variant="rectangular"
                        />
                        <EvtSkeleton
                            sx={{ borderRadius: '35px', width: '100%', height: '50px' }}
                            variant="rectangular"
                        />
                    </EvtBox>
                    <EvtBox>
                        <EvtSkeleton sx={addToCartStyle} variant="rectangular" />
                    </EvtBox>
                </EvtBox>
            </EvtBox>
        </EvtBox>
    )
}

export default ProductCard

const TitleStyles = styled(EvtTypography)(() => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'left',
}))

const outerBoxStyle = (theme: Theme, mobile?: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '15px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    width: mobile ? theme.productCard.widthMobile : theme.productCard.width,
    margin: 'auto',
})

const imageBoxStyle = (theme: Theme, imageUrl?: string) => ({
    textAlign: 'right',
    borderRadius: '15px 15px 0 0',
    height: 0,
    width: '100%',
    backgroundSize: 'cover',
    bgcolor: theme.palette.productCard.bgColor,
    backgroundImage: `url(${imageUrl})`,
    cursor: 'pointer',
    paddingBottom: '59.5%',
})

const AusPostIconStyle = () => ({
    position: 'relative',
    top: '-10px',
})

const contentBoxStyle = (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    bgcolor: theme.palette.productCard.bgColor,
    borderRadius: '0 0 15px 15px',
    paddingBottom: theme.spacing(7.5),
    paddingX: theme.spacing(7.5),
    flexGrow: 1,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        paddingX: theme.spacing(2.5),
    },
})

const titleStyle = (theme: Theme, isPhysical: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '15px',
    marginTop: isPhysical ? '0' : '20px',
    gap: '5px',
    flexGrow: 1,
})

const actionsBoxStyle = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const priceOptionsStyle = () => ({ display: 'flex', gap: '10px', alignItems: 'center' })

const addToCartStyle = (theme: Theme) => ({
    borderRadius: '30px',
    height: '50px',
})
