import { FC, useContext } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Theme, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import OrderSummary from '../../components/OrderSummary'
import QtyInputField from '../../components/QtyInputField'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtDivider from '../../elements/EvtDivider'
import EvtGrid from '../../elements/EvtGrid'
import EvtIconButton from '../../elements/EvtIconButton'
import EvtTableDetail, { Column } from '../../elements/EvtTableDetail'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { GetCartStateContext, useCart } from '../../providers/CartProvider'
import { OrderCreateProdcutProps, OrderCreateRequestProps, useOrderCreate } from '../../query/orders'
import { OrderProduct } from '../../types/orders/Order'
import { Product } from '../../types/products/Product'
import checkoutUrls from '../../urls/checkoutUrls'
import productUrls from '../../urls/productUrls'
import { EVT } from '../../utils/evt'

const ShoppingCart = () => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const navigate = useEvtNavigate()
    const cart = useContext(GetCartStateContext)

    const { mutate: createOrder } = useOrderCreate()

    const onHandleCheckout = () => {
        const reqeustProducts: OrderCreateRequestProps = {
            products: cart.items.map(
                (item) =>
                    ({
                        id: item.id,
                        name: item.name,
                        quantity: item.quantity,
                        price: item.price,
                    } as OrderCreateProdcutProps),
            ),
        }
        createOrder(reqeustProducts, {
            onSuccess: (res) => {
                navigate(checkoutUrls.pages.checkout, res.id)
            },
        })
    }

    const checkoutProducts = cart.items.map(
        (i) =>
            ({
                id: '',
                productId: i.id,
                name: i.name,
                quantity: i.quantity,
                price: i.price,
                priceExclTax: i.price,
                cardImageUrl: i.cardImageUrl,
            } as OrderProduct),
    )

    return (
        <EvtBox
            sx={{
                paddingY: '30px',
                paddingX: {
                    xs: '20px',
                    md: '100px',
                    lg: '150px',
                },
            }}
        >
            <EvtTypography fontSize="25px" pb="40px">
                Shopping Cart
            </EvtTypography>
            {cart.items.length > 0 ? (
                !isDesktop ? (
                    <CartMobile
                        products={cart.items}
                        checkoutProducts={checkoutProducts}
                        onHandleCheckout={onHandleCheckout}
                    />
                ) : (
                    <CartDesktop
                        products={cart.items}
                        checkoutProducts={checkoutProducts}
                        onHandleCheckout={onHandleCheckout}
                    />
                )
            ) : (
                <EmptyCart />
            )}
        </EvtBox>
    )
}

export default ShoppingCart

const EmptyCart = () => {
    const navigate = useEvtNavigate()
    return (
        <EvtBox>
            <EvtTypography>Cart is empty, please select some procuts.</EvtTypography>
            <EvtButtonPill
                sx={{ padding: '10px 20px', mt: '20px' }}
                onClick={() => {
                    navigate(productUrls.pages.index)
                }}
            >
                Shopping
            </EvtButtonPill>
        </EvtBox>
    )
}

interface CartProps {
    products: Product[] | []
    checkoutProducts: OrderProduct[]
    onHandleCheckout: () => void
}

const CartDesktop: FC<CartProps> = ({ products, checkoutProducts, onHandleCheckout }) => {
    const theme = useTheme()
    const { removeFromCart } = useCart()

    const cartItems: Array<Column<Product>> = [
        {
            title: 'Product',
            descriptor: (item) => (
                <EvtBox sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <ImageStyles alt={item.name} src={item.cardImageUrl} />
                    <EvtBox>
                        <EvtTypography>{item.name}</EvtTypography>
                        {item.shortDescription && (
                            <EvtTypography fontSize="small" color={theme.palette.text.secondary}>
                                {item.shortDescription}
                            </EvtTypography>
                        )}
                        {item.isPhysical && <EvtTypography sx={postageStyle}>POST</EvtTypography>}
                    </EvtBox>
                </EvtBox>
            ),
        },
        {
            title: 'Price',
            descriptor: (i) => EVT.toCurrency(i.price),
            sx: { minWidth: 'unset', maxWidth: '160px' },
        },
        { title: 'Quantity', descriptor: (i) => <QtyInputField product={i} />, sx: { width: '100px' } },
        {
            title: 'Total Price',
            descriptor: (item) => EVT.toCurrency(item.price * item.quantity),
            sx: { minWidth: '80px' },
        },
        {
            title: '',
            descriptor: (item) => (
                <EvtIconButton onClick={() => removeFromCart(item)}>
                    <DeleteOutlineIcon sx={{ color: theme.palette.text.primary }} />
                </EvtIconButton>
            ),
        },
    ]

    return (
        <EvtGrid container spacing={5}>
            <EvtGrid item xs={9}>
                <EvtTableDetail sx={tableStyle} columns={cartItems} items={products} />
            </EvtGrid>
            <EvtGrid item xs={3}>
                <OrderSummary isShowButton handleCheckout={onHandleCheckout} products={checkoutProducts} />
            </EvtGrid>
        </EvtGrid>
    )
}

const CartMobile: FC<CartProps> = ({ products, checkoutProducts, onHandleCheckout }) => {
    return (
        <>
            {products.map((product, index) => (
                <ProductItem product={product} key={product.id} index={index + 1} total={products.length} />
            ))}
            <EvtDivider sx={{ mb: '30px' }} />
            <OrderSummary isShowButton handleCheckout={onHandleCheckout} products={checkoutProducts} />
        </>
    )
}

interface ProductItemProps {
    product: Product
    index: number
    total: number
}
const ProductItem: FC<ProductItemProps> = ({ product, index, total }) => {
    const { removeFromCart } = useCart()
    const theme = useTheme()

    return (
        <EvtBox sx={productItemStyle}>
            <EvtBox className="product-item-header">
                <EvtTypography>Product</EvtTypography>
                <EvtTypography>{`${index}/${total}`}</EvtTypography>
            </EvtBox>
            <EvtBox className="product-item-content">
                <EvtBox className="title">
                    <img alt={product.cardImageUrl} src={product.cardImageUrl} width="30%" />
                    <EvtTypography>{product.name}</EvtTypography>
                </EvtBox>
                <EvtBox className="contents">
                    <EvtBox className="left">
                        <EvtTypography>Price: {EVT.toCurrency(product.price)}</EvtTypography>
                        <EvtTypography>Total: {EVT.toCurrency(product.price * product.quantity)}</EvtTypography>
                    </EvtBox>
                    <EvtBox className="right">
                        <EvtIconButton onClick={() => removeFromCart(product)}>
                            <DeleteOutlineIcon sx={{ color: theme.palette.text.primary }} />
                        </EvtIconButton>
                        <QtyInputField product={product} />
                    </EvtBox>
                </EvtBox>
            </EvtBox>
        </EvtBox>
    )
}

const productItemStyle = (theme: Theme) => ({
    '.product-item-header': {
        display: 'flex',
        backgroundColor: theme.palette.shoppingCart.itemHeader,
        borderRadius: '10px',
        padding: '15px 20px',
        justifyContent: 'space-between',
    },
    '.product-item-content': {
        padding: '30px 10px',
        '.title': {
            display: 'flex',
            gap: '20px',
            paddingBottom: '20px',
            textAlign: 'left',
            '> p': {
                fontSize: '20px',
            },
        },
        '.contents': {
            display: 'flex',
            gap: '20px',
            justifyContent: 'space-between',
            '.left': {
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            },
            '.right': {
                display: 'flex',
                gap: '10px',
                alignItems: 'flex-end',
                '.MuiFormControl-root': { maxWidth: '50px' },
            },
        },
    },
})

const tableStyle = () => ({
    'td:nth-of-type(n+2), th:nth-of-type(n+2)': {
        textAlign: 'center',
    },
})

const ImageStyles = styled('img')(({ theme }) => ({
    height: '100px',
}))

const postageStyle = (theme: Theme) => ({
    borderRadius: '3px',
    padding: '3px 5px',
    width: 'fit-content',
    backgroundColor: theme.palette.productCard.postBg,
    fontSize: 'xx-small',
    fontWeight: 'bold',
    marginTop: '5px',
    lineHeight: 'normal',
})
