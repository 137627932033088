import { FC } from 'react'
import { TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import EvtFormControl from './EvtFormControl'
import EvtFormHelperText from './EvtFormHelperText'
import { EvtInputField, EvtInputFieldProps } from './EvtInputField'

interface Props {
    name: string
    type?: string
    bgColor?: string
}

export const EvtFormInputField: FC<Props & TextFieldProps & EvtInputFieldProps> = ({
    name,
    bgColor,
    type,
    ...props
}) => {
    const [field, meta] = useField(name)
    const configTextfield = {
        ...field,
        ...props,
        error: false,
    }

    if (meta && meta.touched && meta.error) {
        configTextfield.error = true
    }

    return (
        <EvtFormControl fullWidth>
            <EvtInputField
                bgColor={bgColor}
                {...configTextfield}
                isError={configTextfield.error}
                variant="outlined"
                type={type}
                fullWidth
            />
            {configTextfield.error && <EvtFormHelperText error={configTextfield.error}>{meta.error}</EvtFormHelperText>}
        </EvtFormControl>
    )
}
