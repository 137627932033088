import { FC, useCallback, useEffect, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone, { FileRejection } from 'react-dropzone'
import ImageIcon from '@mui/icons-material/Image'
import { styled, useTheme } from '@mui/material'
import EvtBox from '../elements/EvtBox'
import { EvtButtonPill } from '../elements/EvtButton'
import EvtSlider from '../elements/EvtSlider'
import EvtTypography from '../elements/EvtTypography'
import useDeviceType from '../hooks/useDeviceType'
import { useModal } from '../providers/modals/GlobalModalProvider'
import { useImageUpload } from '../query/images'
import { EVT } from '../utils/evt'

let editorRef: any = null

interface FileUploaderProps {
    width?: string
    height?: string
    maxNameLength?: number
    onUploadSuccess?: () => void
}

const FIleUploader: FC<FileUploaderProps> = ({
    width = '250px',
    height = '250px',
    maxNameLength = 900,
    onUploadSuccess,
}) => {
    let file: File | null = null

    const theme = useTheme()
    const [isUploaded, setUploaded] = useState(false)
    const { openModal, forceClose } = useModal()
    const { mutate: imageUpload, isSuccess } = useImageUpload()

    const handleClick = useCallback(() => {
        if (onUploadSuccess) {
            onUploadSuccess()
        }
    }, [onUploadSuccess])

    useEffect(() => {
        setUploaded(isSuccess)
    }, [isSuccess])

    useEffect(() => {
        if (isUploaded) {
            handleClick()
            setUploaded(false)
        }
    }, [isUploaded, handleClick])

    const setEditorRef = (ref: any) => {
        editorRef = ref
    }

    const handleCancel = () => {
        file = null
        editorRef = null
        forceClose()
    }

    const handleSave = async () => {
        if (editorRef) {
            const canvasScaled = editorRef.getImageScaledToCanvas()
            const croppedImg = canvasScaled.toDataURL()
            const blob = await (await fetch(croppedImg)).blob()
            let data = new FormData()
            data.append('imageFile', blob, file?.name)
            imageUpload(data)
            handleCancel()
        }
    }

    const handleChange = (acceptedFiles: Array<File>, rejectedFiles: FileRejection[]) => {
        if (rejectedFiles.length > 0) {
            openModal({
                title: 'Unable to upload image',
                content: `${rejectedFiles[0].errors[0].message}`,
            })
        } else {
            file = acceptedFiles[0]
            openModal({
                title: 'Resize the uploading image',
                content: (
                    <ImageSizeModal imgUrl={file} setRef={setEditorRef} onSave={handleSave} onCancel={handleCancel} />
                ),
                buttons: <></>,
            })
        }
    }

    const handleFilenameLength = (dropFile: File | DataTransferItem) => {
        let file: File | null
        if (dropFile instanceof DataTransferItem) file = dropFile.getAsFile()
        else file = dropFile

        if (!!file && file.name.length > maxNameLength) {
            return {
                code: 'name-too-large',
                message: `Name is larger than ${maxNameLength} characters`,
            }
        }
        return null
    }

    return (
        <Dropzone
            onDrop={handleChange}
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
            maxFiles={1}
            multiple={false}
            maxSize={7000000}
            validator={handleFilenameLength}
        >
            {({ getRootProps, getInputProps }) => (
                <FileUploaderContainer {...getRootProps()} width={width} height={height}>
                    <input {...getInputProps()} />
                    <div className="evt-img-circle">
                        <div className="evt-img">
                            <ImageIcon
                                sx={{
                                    position: 'absolute',
                                    fontSize: '110px',
                                    color: theme.palette.fileUploader.svgBg,
                                    zIndex: 1,
                                }}
                            />
                            <EvtTypography zIndex={2}>Drag images</EvtTypography>
                            <EvtTypography zIndex={2}>here to upload</EvtTypography>
                        </div>
                    </div>
                    <EvtTypography
                        sx={{
                            marginY: '10px',
                            fontSize: '11px',
                            color: theme.palette.fileUploader.outerTxt,
                        }}
                    >
                        Allowed formats jpg, png and ...
                    </EvtTypography>
                    <EvtButtonPill sx={{ mb: '15px', fontSize: 'small', padding: '8px 16px' }}>
                        Upload from Device
                    </EvtButtonPill>
                </FileUploaderContainer>
            )}
        </Dropzone>
    )
}

export default FIleUploader

interface ImageSizeModalProps {
    imgUrl: File
    setRef: (ref: any) => void
    onSave: () => void
    onCancel: () => void
}
const ImageSizeModal: FC<ImageSizeModalProps> = ({ imgUrl, setRef, onSave, onCancel }) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    const isTablet = EVT.isTablet(deviceType)
    const [zoom, setZoom] = useState<number>(1)
    const handleSlider = (e: any, value: number | number[]) => {
        if (Array.isArray(value)) {
            setZoom(value[0])
        } else {
            setZoom(value)
        }
    }

    const ratio = 680 / 262
    const height = isMobile ? 80 : isTablet ? 100 : 262

    return (
        <EvtBox display="flex" flexDirection="column" gap="20px">
            <AvatarEditor
                ref={setRef}
                image={imgUrl}
                width={height * ratio}
                height={height}
                border={30}
                color={[255, 255, 255, 0.5]}
                rotate={0}
                scale={zoom}
            />
            <EvtBox textAlign="center">
                <EvtSlider
                    sx={{ width: '60%' }}
                    aria-label="raceSlider"
                    value={zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    onChange={handleSlider}
                />
            </EvtBox>

            <EvtBox display="flex" justifyContent="center" gap="50px">
                <EvtButtonPill sx={{ width: '100px', padding: '10px 15px' }} onClick={onSave}>
                    Upload
                </EvtButtonPill>
                <EvtButtonPill sx={{ width: '100px', padding: '10px 15px' }} onClick={onCancel}>
                    Cancel
                </EvtButtonPill>
            </EvtBox>
        </EvtBox>
    )
}

const FileUploaderContainer = styled('div')<{ width: string; height: string }>(
    ({ theme: { palette }, width, height }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height,
        width,
        backgroundColor: palette.fileUploader.bgColor,
        borderRadius: '10px',
        cursor: 'pointer',

        '.evt-file-upload-info': {
            marginBottom: '10px',
        },
        '.evt-img-circle': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15px',
            border: `1px dashed ${palette.border.default}`,
            width: '150px',
            height: '150px',
            borderRadius: '75px',
            '.evt-img': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '130px',
                height: '130px',
                borderRadius: '65px',
                backgroundColor: palette.fileUploader.circleBg,
                color: palette.text.secondary,
                p: {
                    fontSize: '12px',
                },
            },
        },
    }),
)
