import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useTheme } from '@mui/material'
import * as yup from 'yup'
import FormComponent from '../../components/FormComponent'
import EvtBox from '../../elements/EvtBox'
import { EvtSubmitButton } from '../../elements/EvtButton'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtTypography from '../../elements/EvtTypography'
import { useSuccessAlert } from '../../providers/modals/AlertModalProvider'
import { useModal } from '../../providers/modals/GlobalModalProvider'
import { usePasswordChange } from '../../query/users'
import { evtValidator } from '../../utils/validation'

const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
}

interface formValuesProps {
    oldPassword: string
    password: string
    confirmPassword: string
}

const PasswordUpdateForm = () => {
    const theme = useTheme()
    const success = useSuccessAlert()
    const { forceClose } = useModal()
    const { mutate: passwordChange } = usePasswordChange()
    const onSubmit = (values: formValuesProps) => {
        const requestValues = {
            oldPassword: values.oldPassword,
            newPassword: values.password,
            confirmPassword: values.confirmPassword,
        }

        passwordChange(requestValues, {
            onSuccess: () => {
                forceClose()
                success({
                    message: 'Password updated successfully',
                })
            },
        })
    }
    return (
        <FormComponent
            initialValues={initialValues}
            validationSchema={evtValidator(initialValues, {
                oldPassword: yup.string().required('Required'),
            })}
            onSubmit={onSubmit}
        >
            <EvtBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="20px"
                width={{
                    sm: '250px',
                    md: '300px',
                }}
            >
                <EvtTypography align="center" fontSize="1.5rem">
                    Manage Password
                </EvtTypography>
                <LockOutlinedIcon sx={{ fontSize: '2rem' }} />

                <EvtFormInputField
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    bgColor={theme.palette.modal.inputBgColor}
                    isRoundBorder
                />
                <EvtFormInputField
                    label="New Password"
                    name="password"
                    type="password"
                    bgColor={theme.palette.modal.inputBgColor}
                    isRoundBorder
                />
                <EvtFormInputField
                    label="Confirm New Password"
                    name="confirmPassword"
                    type="password"
                    bgColor={theme.palette.modal.inputBgColor}
                    isRoundBorder
                />

                <EvtSubmitButton size="large" variant="contained" sx={{ borderRadius: '25px', width: '200px' }}>
                    Update
                </EvtSubmitButton>
            </EvtBox>
        </FormComponent>
    )
}

export default PasswordUpdateForm
