import React, { FC } from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const AusPostIcon: FC<SvgIconProps> = ({ ...props }) => {
    const theme = useTheme()
    if (!props.viewBox) props.viewBox = '0 0 55 22'

    if (typeof props.sx === 'function') {
        props.sx = {
            ...props.sx(theme),
        }
    } else {
        props.sx = {
            ...props.sx,
        }
    }

    props.sx = {
        ...props.sx,
        // @ts-ignore
        width: props.sx.width || '55px !important',
    }

    return (
        <SvgIcon {...props}>
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_2751" data-name="Rectangle 2751" width="39.552" height="10.91" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_4189" data-name="Group 4189" transform="translate(-2839 -1477.172)">
                <rect
                    id="Rectangle_2752"
                    data-name="Rectangle 2752"
                    width="55"
                    height="22"
                    rx="11"
                    transform="translate(2839 1477.172)"
                    fill="#d81332"
                />
                <g id="Group_4165" data-name="Group 4165" transform="translate(2846.959 1482.685)">
                    <g id="Group_4163" data-name="Group 4163" transform="translate(0 0)" clipPath="url(#clip-path)">
                        <path
                            id="Path_3234"
                            data-name="Path 3234"
                            d="M4.391,5.134c1.18,0,1.741-.08,1.741-.986,0-.95-.576-1.065-1.741-1.065L0,3.085V.486H4.26a6.533,6.533,0,0,1,3.612.69A3.23,3.23,0,0,1,9.3,4.083c0,2.3-1.4,3.584-3.986,3.584H3.326v3.166H0v-5.7Z"
                            transform="translate(0 -0.21)"
                            fill="#fff"
                        />
                        <path
                            id="Path_3235"
                            data-name="Path 3235"
                            d="M22.366,8.306c1.409,0,2.014-.907,2.014-2.851s-.6-2.849-2.015-2.849-2.028.907-2.028,2.85.62,2.85,2.029,2.85m0-8.3c3.324,0,5.368,2.086,5.368,5.453s-2.043,5.455-5.367,5.455-5.383-2.086-5.384-5.454S19.04,0,22.366,0"
                            transform="translate(-7.328 -0.001)"
                            fill="#fff"
                        />
                        <path
                            id="Path_3236"
                            data-name="Path 3236"
                            d="M40.213,7.2c.086.907.642,1.252,1.549,1.252.806,0,1.252-.3,1.252-.749,0-.648-.6-.7-1.828-1.021a10.4,10.4,0,0,1-2.806-.993,2.754,2.754,0,0,1-1.2-2.388c0-2,1.583-3.3,4.345-3.3,2.678,0,4.343,1.2,4.45,3.453H42.827c-.009-.807-.549-1.109-1.355-1.109-.7,0-1.051.231-1.051.706,0,.547.533.648,1.54.906a10.577,10.577,0,0,1,2.965.978,2.681,2.681,0,0,1,1.324,2.417c0,2.245-1.612,3.555-4.633,3.555C38.839,10.909,37.1,9.594,37,7.2Z"
                            transform="translate(-15.966 0)"
                            fill="#fff"
                        />
                        <path
                            id="Path_3237"
                            data-name="Path 3237"
                            d="M56.325,3.2H53.346V.479H62.57V3.2H59.649v7.628H56.326Z"
                            transform="translate(-23.018 -0.206)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export default AusPostIcon
