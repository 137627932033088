import React, { FC } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { SelectProps, useTheme } from '@mui/material'
import EvtBox from './EvtBox'
import { EvtSelect, EvtSelectProps } from './EvtSelect'

interface Props extends EvtSelectProps {
    widthOfSelectButton?: string
}

const EvtCustomSelect: FC<Props> = ({ widthOfSelectButton, options, children, ...props }) => {
    const theme = useTheme()
    widthOfSelectButton = widthOfSelectButton || theme.spacing(20)

    return (
        <EvtSelect
            {...props}
            IconComponent={(props: SelectProps) => (
                <EvtBox
                    className={`evt-select-icon ${props.className}`}
                    sx={{
                        width: widthOfSelectButton,
                    }}
                >
                    <KeyboardArrowDownIcon />
                </EvtBox>
            )}
            options={options}
        />
    )
}

export default EvtCustomSelect
