import { FC, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Theme, useTheme } from '@mui/material'
import * as yup from 'yup'
import StepContainerUI, { StepContinueButton } from '../../components/StepContainerUI'
import EvtBox from '../../elements/EvtBox'
import { EvtButton } from '../../elements/EvtButton'
import { EvtCheckbox } from '../../elements/EvtCheckbox'
import EvtTableDetail, { Column } from '../../elements/EvtTableDetail'
import EvtTypography from '../../elements/EvtTypography'
import { OrderProduct } from '../../types/orders/Order'
import { RecipientSectionDataProps, STEP } from './Assign'
import { Recipient } from './AssignRecipient'

interface Props {
    product: OrderProduct | null
    recipientSectionData: RecipientSectionDataProps
    activeStepIndex: number
    sendStep: STEP
    onNext: (recipients: Array<Recipient>) => void
    onBack: () => void
}

const AssignSend: FC<Props> = ({ product, recipientSectionData, activeStepIndex, sendStep, onBack, onNext }) => {
    const theme = useTheme()
    const isActive = activeStepIndex === sendStep.index
    const isDone = activeStepIndex > sendStep.index

    let rawRecipients: Array<Recipient> = []
    if (recipientSectionData.csv.isActive) {
        rawRecipients = recipientSectionData.csv.value.recipients
    } else if (recipientSectionData.manual.isActive) {
        rawRecipients = recipientSectionData.manual.value
    } else {
        rawRecipients = recipientSectionData.myself.value
    }
    rawRecipients = rawRecipients.filter((r) => r.email.replace(/\s/g, '').length > 0)

    const [recipients, setRecipients] = useState<Array<Recipient> | []>([])
    const availableQty = (product?.quantity || 0) - (product?.totalSent || 0)
    const columns: Array<Column<Recipient>> = [
        {
            title: '',
            descriptor: (r) => {
                const isChecked = recipients.some((rs) => rs.id === r.id)
                const isDisabled = !isChecked && recipients.length === availableQty
                const isValid = emailSchema.isValidSync(r.email)
                return isValid ? (
                    <EvtCheckbox checked={isChecked} onClick={() => handleRecipientSelect(r)} disabled={isDisabled} />
                ) : (
                    <ClearIcon color="error" sx={{ pl: '8px' }} />
                )
            },
        },
        {
            title: 'Email',
            descriptor: (r) => r.email,
        },
    ]

    const handleRecipientSelect = (recipient: Recipient) => {
        let newRecipients = [...recipients]
        if (recipients.some((r) => r.id === recipient.id)) {
            newRecipients = recipients.filter((r) => r.id !== recipient.id)
        } else {
            newRecipients.push(recipient)
        }
        setRecipients(newRecipients)
    }

    const handleNext = () => {
        onNext(recipients)
    }

    const toggleSelectAll = () => {
        setRecipients(recipients.length > 0 ? [] : rawRecipients.filter((r) => emailSchema.isValidSync(r.email)))
    }

    return (
        <StepContainerUI
            id={sendStep.id}
            index={sendStep.index}
            title="Send Your eVouchers"
            isActive={isActive}
            isDone={isDone}
            onBack={onBack}
        >
            {isActive && (
                <>
                    <EvtBox sx={(t) => assignTtileStyle(t, recipients.length > availableQty)}>
                        <EvtTypography fontSize="30px" color="inherit">
                            {recipients.length}/{availableQty}
                        </EvtTypography>
                        <EvtTypography fontSize="18px" color="inherit">
                            Assigned
                        </EvtTypography>
                    </EvtBox>

                    {rawRecipients.length > 0 && (
                        <EvtTableDetail
                            columns={columns}
                            items={rawRecipients}
                            sx={{ mb: '20px', maxHeight: '500px' }}
                        />
                    )}
                    <EvtBox display="flex" gap="20px">
                        <EvtButton
                            variant="outlined"
                            sx={{
                                borderRadius: '25px',
                                padding: '10px 25px',
                                color: theme.palette.background.reverse,
                            }}
                            onClick={toggleSelectAll}
                        >
                            {recipients.length > 0 ? 'Unselected All' : 'Select All'}
                        </EvtButton>

                        <EvtBox display="flex" alignItems="center" gap="5px">
                            <ClearIcon color="error" sx={{ svg: { fontSize: 'large' } }} />
                            <EvtTypography pb="8px">.</EvtTypography>
                            <EvtTypography color="error" fontSize="small">
                                {rawRecipients.filter((r) => !emailSchema.isValidSync(r.email)).length} emails invalid
                            </EvtTypography>
                        </EvtBox>
                    </EvtBox>

                    <StepContinueButton
                        disabled={recipients.length === 0 || recipients.length > availableQty ? true : false}
                        onClick={handleNext}
                        text="Send to selected"
                    />
                </>
            )}
        </StepContainerUI>
    )
}

export default AssignSend

const assignTtileStyle = (theme: Theme, isExceed: boolean) => ({
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '-22px',
    right: '50px',
    backgroundColor: theme.palette.background.default,
    paddingX: '10px',
    color: isExceed ? theme.palette.error.main : 'inherit',
})

const maxEmailLength = 60
const emailSchema = yup.string().max(maxEmailLength, `Email must not exceed ${maxEmailLength} characters`).email()
