import React, { useEffect, useState } from 'react'
import braintree from 'braintree-web'
import { EvtLoader } from '../elements/EvtLoader'
import { useBraintreeClientToken } from '../query/orders'

const GetBraintreeStateContext = React.createContext<any>(null)
const BraintreeProvider = (props: { children: React.ReactNode }) => {
    const { data: braintreeResponse, isLoading } = useBraintreeClientToken()
    const [clientInstance, setClientInstance] = useState<any>()
    useEffect(() => {
        if (braintreeResponse) {
            braintree.client.create(
                {
                    authorization: braintreeResponse.clientToken,
                },
                function (err, ci) {
                    if (err) {
                        console.error(err)
                        return
                    }

                    braintree.dataCollector.create(
                        {
                            client: ci,
                            paypal: true,
                            kount: true,
                        },
                        function (err, dataCollectorInstance) {
                            if (err) {
                                setClientInstance(ci)
                                return
                            }

                            ci.deviceData = dataCollectorInstance?.deviceData
                            setClientInstance(ci)
                        },
                    )
                },
            )
        }
    }, [braintreeResponse])

    return (
        <GetBraintreeStateContext.Provider value={clientInstance}>
            <EvtLoader loading={isLoading || !clientInstance}>{props.children}</EvtLoader>
        </GetBraintreeStateContext.Provider>
    )
}

const useBraintreeInstance = () => {
    const context = React.useContext(GetBraintreeStateContext)
    if (context === undefined) {
        throw new Error('useBraintree must be used within a BraintreeProvider')
    }

    return context
}

export { BraintreeProvider, useBraintreeInstance, GetBraintreeStateContext }
