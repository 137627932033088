import { FC } from 'react'
import { useTheme } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { useField, useFormikContext } from 'formik'
import EvtCustomSelect from './EvtCustomSelect'
import EvtFormControl from './EvtFormControl'
import EvtFormHelperText from './EvtFormHelperText'
import { EvtSelect, EvtSelectProps } from './EvtSelect'

interface Props extends EvtSelectProps {
    name: string
    isCustom?: boolean
}

const EvtFormSelect: FC<Props> = ({ name, options, isCustom = true, ...props }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)
    const theme = useTheme()

    const handleChange = (e: SelectChangeEvent<unknown>) => {
        setFieldValue(name, e.target.value)
    }

    const configSelect = {
        ...field,
        ...props,
        error: false,
        onChange: handleChange,
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true
    }

    return (
        <EvtFormControl
            fullWidth
            sx={{
                '.MuiInputLabel-root': {
                    color: configSelect.error ? theme.palette.formInput.border.error : 'inherent',
                },
            }}
        >
            {isCustom ? (
                <EvtCustomSelect {...configSelect} name={name} options={options} onChange={handleChange} />
            ) : (
                <EvtSelect {...configSelect} name={name} options={options} onChange={handleChange} />
            )}
            {configSelect.error && <EvtFormHelperText error={configSelect.error}>{meta.error}</EvtFormHelperText>}
        </EvtFormControl>
    )
}

export default EvtFormSelect
