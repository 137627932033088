import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import StepContainerUI, { StepContinueButton } from '../../components/StepContainerUI'
import EvtBox from '../../elements/EvtBox'
import { EvtCheckbox } from '../../elements/EvtCheckbox'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { EVT } from '../../utils/evt'
import { STEP_SHIPPING } from './Checkout'

interface Props {
    id: string
    steps: Array<{ text: string; step: number }>
    myStep: number
    activeStep: number
    shippingMethods: Array<ShippingMethodFormProps>
    shippingMethod: ShippingMethodFormProps | undefined
    onNext: (values: ShippingMethodFormProps) => void
    onBack: (step: number) => void
}
export const ShippingMethodForm: FC<Props> = ({
    id,
    steps,
    myStep,
    activeStep,
    shippingMethods,
    shippingMethod,
    onNext,
    onBack,
}) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    const isDesktop = EVT.isDesktop(deviceType)
    const index = steps.findIndex((s) => s.step === myStep)
    const isActive = activeStep === myStep
    const isDone = activeStep > myStep
    const title = steps[index].text
    const nextTitle = steps[myStep + 1].text
    const [formValues, setFormValues] = useState(shippingMethods[0])
    const element = document.getElementById(STEP_SHIPPING.id) as HTMLElement
    const theme = useTheme()
    const textColor = theme.palette.stepContainerUI.border
    return (
        <StepContainerUI
            id={id}
            index={index}
            title={title}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={element ? (isMobile ? 810 : 500) : 450}
            onBack={() => onBack(myStep)}
        >
            {isActive && (
                <>
                    {shippingMethods.map((method) => (
                        <EvtBox display="flex" alignItems="center" key={method.id}>
                            <EvtCheckbox
                                sx={{ pl: '0px' }}
                                checked={formValues.id === method.id}
                                onClick={() => setFormValues(method)}
                            />
                            <EvtTypography>
                                {method.name} ${method.price}
                            </EvtTypography>
                        </EvtBox>
                    ))}
                    <StepContinueButton text={nextTitle} onClick={() => onNext(formValues)} />
                </>
            )}

            {isDone && shippingMethod && (
                <EvtTypography color={textColor.secondary} sx={{ pt: isDesktop ? 0 : '15px' }}>
                    {shippingMethod.name} ${shippingMethod.price}
                </EvtTypography>
            )}
        </StepContainerUI>
    )
}

export interface ShippingMethodFormProps {
    description: string
    id: number
    name: string
    price: number
}
