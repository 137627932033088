import globalUrls from '../urls/globalUrls'
import { useEvtQuery } from './evtQuery'

export function usePromoTiles() {
    return useEvtQuery<PromoTilesResponse>(globalUrls.api.promoTiles)
}

export type PromoTilesResponse = {
    offerTiles: Array<PromoTile>
    experienceTiles: Array<PromoTile>
}

export type PromoTile = {
    content: string
    button1Text?: string
    button1Url?: string
    button2Text?: string
    button2Url?: string
    bannerUrl: string
}
