import React, { FC, MouseEvent, ReactNode } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Grow, Theme, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import EvtIconButton from './EvtIconButton'

interface Props {
    maxWidth?: 'mobile' | 'tablet' | 'desktop'
    isOpen: boolean
    title?: string | ReactNode
    children?: string | ReactNode
    cancelText?: string
    confirmText?: string
    buttons?: React.ReactNode
    cancelFunc: (e: MouseEvent<HTMLButtonElement> | undefined) => void
    confirmFunc?: (e: MouseEvent<HTMLButtonElement> | undefined) => void
}

const Modal: FC<Props> = ({ isOpen, maxWidth = 'mobile', title, children, buttons, cancelFunc }) => {
    const theme = useTheme()
    return (
        <Dialog
            maxWidth={maxWidth}
            open={isOpen}
            TransitionComponent={GrowTransition}
            onClose={() => cancelFunc(undefined)}
            aria-describedby="alert-dialog-slide-description"
            sx={dialogStyles(theme)}
            className="evt-dialog"
        >
            <EvtIconButton className="evt-dialog-close-btn" onClick={(e) => cancelFunc && cancelFunc(e)}>
                <ClearIcon />
            </EvtIconButton>
            {title && <DialogTitle align="center">{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            {buttons && (
                <DialogActions sx={{ justifyContent: 'center', pb: '20px', paddingX: '30px' }}>{buttons}</DialogActions>
            )}
        </Dialog>
    )
}

export default Modal

export const SlideUpTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const GrowTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Grow ref={ref} {...props} />
})

const dialogStyles = (theme: Theme) => ({
    '.MuiDialog-container': {
        '> .MuiPaper-root': {
            overflow: 'initial',
            borderRadius: '25px',
            backgroundColor: theme.palette.modal.bgColor,
        },
        '.evt-dialog-close-btn': {
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            bgcolor: theme.palette.modal.closeBtn.bgColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            right: '-15px',
            top: '-15px',
            cursor: 'pointer',
            '&:hover': {
                bgcolor: theme.palette.modal.closeBtn.bgColor,
            },
            border: `1px solid ${theme.palette.modal.closeBtn.borderColor}`,
        },
    },
})
