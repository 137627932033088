import { FC, ReactNode } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import { ButtonProps, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import EvtBox from '../elements/EvtBox'
import { EvtButton, EvtButtonPill, EvtSubmitButton } from '../elements/EvtButton'
import EvtGrid from '../elements/EvtGrid'
import EvtLink from '../elements/EvtLink'
import EvtTypography from '../elements/EvtTypography'
import useDeviceType from '../hooks/useDeviceType'
import { BasicProps } from '../types/BasicProps'
import { EVT } from '../utils/evt'

interface Props {
    id?: string
    children: ReactNode
    isActive: boolean
    isDone: boolean
    index: number
    title: string
    isAssign?: boolean
    scrollMarginTop?: number
    onBack?: () => void
}

const StepContainerUI: FC<Props> = ({
    id,
    children,
    index,
    title,
    isActive,
    isDone,
    isAssign = false,
    scrollMarginTop,
    onBack,
}) => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const isMobile = EVT.isMobile(deviceType)
    const isEditBackLink = isDone && onBack

    return (
        <EvtBox
            sx={(t) => stepContainerStyle(t, isDesktop, isActive, isDone, scrollMarginTop)}
            id={id}
            className="stepper-container"
        >
            <EvtBox sx={(t) => titleContainerStyle(t, isDesktop, isActive)}>
                <EvtTypography className="index-box">{index + 1}</EvtTypography>
                <EvtTypography className="title-text">{title}</EvtTypography>
                {isDone && (
                    <EvtBox className="done-icon">
                        <DoneIcon fontSize="medium" sx={{ pt: '5px', path: { color: 'white' } }} />
                    </EvtBox>
                )}
            </EvtBox>

            <EvtBox sx={(t) => bodyContainerStyle(t, isDesktop, isMobile, isActive, isAssign)}>
                <EvtGrid container spacing={10}>
                    <EvtGrid item xs={isEditBackLink ? 10 : 12} md={isEditBackLink ? 11 : 12}>
                        {children}
                    </EvtGrid>
                    {isEditBackLink && (
                        <EvtGrid item xs={2} md={1} sx={editBackLinkStyle}>
                            {
                                <EvtButton onClick={onBack}>
                                    <EditIcon />
                                    <EvtLink>Edit</EvtLink>
                                </EvtButton>
                            }
                        </EvtGrid>
                    )}
                </EvtGrid>
            </EvtBox>
        </EvtBox>
    )
}

export default StepContainerUI

export const StepContinueButtonSubmit: FC<{ text: string | ReactNode } & ButtonProps & BasicProps> = ({
    text,
    ...props
}) => {
    return (
        <EvtSubmitButton variant="contained" sx={(t) => buttonStyle(t, '-28px', '15px 25px', '35px')} {...props}>
            {text}
        </EvtSubmitButton>
    )
}

export const StepContinueButton: FC<{ text: string } & ButtonProps & BasicProps> = ({ text, ...props }) => {
    return (
        <EvtButtonPill variant="contained" sx={(t) => buttonStyle(t, '-28px', '15px 25px')} {...props}>
            {text}
        </EvtButtonPill>
    )
}

const titleContainerStyle = (theme: Theme, isDesktop: boolean, isActive: boolean): SystemStyleObject<Theme> => ({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    position: 'absolute',
    top: '-22px',
    left: '5px',
    paddingRight: '10px',
    width: 'fit-content',
    backgroundColor: theme.palette.background.default,

    '> p': {
        fontSize: '22px',
    },

    '.index-box': {
        fontWeight: 'bold',
        border: `1px solid ${
            isActive ? theme.palette.stepContainerUI.border.default : theme.palette.stepContainerUI.border.secondary
        }`,
        color: isActive ? theme.palette.stepContainerUI.border.default : theme.palette.stepContainerUI.border.secondary,
        borderRadius: '25px',
        padding: '5px 15px',
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            padding: '2px 12px',
        },
    },
    '.title-text': {
        fontWeight: 'bold',
        color: isActive ? theme.palette.stepContainerUI.border.default : theme.palette.stepContainerUI.border.secondary,
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            fontSize: '19px',
        },
    },
    '.done-icon': {
        width: '30px',
        height: '30px',
        backgroundColor: theme.palette.stepper.lableComplete,
        borderRadius: '25px',
        textAlign: 'center',
    },
})

const stepContainerStyle = (
    theme: Theme,
    isDesktop: boolean,
    isActive: boolean,
    isDone: boolean,
    scrollMarginTop: number | undefined,
): SystemStyleObject<Theme> => {
    const border = theme.palette.stepContainerUI.border
    const styles = isDesktop
        ? {
              border: `1px solid ${isActive ? border.default : border.secondary}`,
              borderRadius: '20px',
          }
        : {
              borderTop: `1px solid ${isActive ? border.default : border.secondary}`,
              borderBottom: isActive
                  ? `1px solid ${border.default}`
                  : isDone
                  ? `1px solid ${border.secondary}`
                  : 'unset',
          }
    return {
        ...styles,
        textAlign: 'left',
        position: 'relative',
        scrollMarginBlockStart: `${scrollMarginTop}px`,
    }
}

const bodyContainerStyle = (
    theme: Theme,
    isDesktop: boolean,
    isMobile: boolean,
    isActive: boolean,
    isAssign: boolean,
): SystemStyleObject<Theme> => ({
    paddingY: isActive || isDesktop ? '40px' : '20px',
    paddingX: isDesktop ? '40px' : isMobile ? (isAssign ? 0 : '30px') : '20px',
    color: isActive ? theme.palette.border.default : theme.palette.border.secondary,
})

const buttonStyle = (
    theme: Theme,
    bottom: string,
    padding: string,
    borderRadius?: string,
): SystemStyleObject<Theme> => ({
    left: '30px',
    bottom,
    position: 'absolute',
    padding,
    borderRadius,
    '&.Mui-disabled': {
        backgroundColor: theme.palette.stepContainerUI.border.secondary,
    },
})

const editBackLinkStyle = (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    button: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        color: theme.palette.text.primary,
    },
    svg: {
        fontSize: 'small',
        marginRight: '5px',
        color: 'inherit',
    },
    a: {
        fontSize: 'small',
        color: 'inherit',
    },
})
