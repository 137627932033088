import React, { FC, ReactNode } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableProps, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { EVT } from '../utils/evt'
import EvtSkeleton from './EvtSkeleton'

interface Props {
    headerBgColor?: string
    header?: Array<string | number | ReactNode>
    rows: Array<Array<string | number | ReactNode>>
    paddingSize?: 'small' | 'normal'
    isEnableBorder?: boolean
    skeletonRows?: number
}

interface TableHeadStylesProps {
    headerbgcolor: string | undefined
}

interface TableStylesProps {
    paddingsize: string
    isenableborder: number
}
const TableStyles = styled(Table)<TableStylesProps>(({ theme, paddingsize, isenableborder }) => ({
    'th, td': {
        border: 'unset',
        color: theme.palette.text.primary,
        padding: paddingsize,
    },
    'tr:not(:last-of-type)': {
        td: {
            borderBottom: isenableborder > 0 ? `1px solid ${theme.palette.border.default}` : 'unset',
        },
    },
    'tr: last-of-type': {
        td: {
            borderBottom: 'unset !important',
        },
    },
}))

const TableHeadStyles = styled(TableHead)<TableHeadStylesProps>(({ theme, headerbgcolor }) => ({
    th: {
        backgroundColor: headerbgcolor || theme.palette.table.headerBg,
    },
    'tr > th:first-of-type': {
        borderRadius: '5px 0 0 5px',
    },

    'tr > th:last-of-type': {
        borderRadius: '0 5px 5px 0',
    },
}))

const EvtTable: FC<Props & TableProps> = ({
    header,
    paddingSize = 'normal',
    isEnableBorder = true,
    skeletonRows = 5,
    rows,
    headerBgColor,
    children,
    ...props
}) => {
    let padding = '16px'
    if (paddingSize === 'small') {
        padding = '8px'
    }

    rows =
        rows && rows.length > 0
            ? rows
            : EVT.range(0, skeletonRows).map(() => EVT.range(0, header?.length || 1).map(() => <EvtSkeleton />))

    return (
        <TableContainer {...props}>
            <TableStyles paddingsize={padding} isenableborder={isEnableBorder ? 1 : 0}>
                <TableHeader header={header} headerbgcolor={headerBgColor} />
                <TableBody>
                    {rows.map((row, rowIndex) => (
                        <TableRow key={`row-${rowIndex}`}>
                            {row.map((cell, cellIndex) => (
                                <TableCell key={`cell-${cellIndex}`}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </TableStyles>
        </TableContainer>
    )
}

const TableHeader: FC<{ headerbgcolor?: string; header?: Array<string | number | ReactNode> }> = ({
    header,
    headerbgcolor,
}) => (
    <>
        {header && (
            <TableHeadStyles headerbgcolor={headerbgcolor}>
                <TableRow>
                    {header.map((cell, index) => (
                        <TableCell key={index}>{cell}</TableCell>
                    ))}
                </TableRow>
            </TableHeadStyles>
        )}
    </>
)

export default EvtTable
