import React, { ReactNode, useState } from 'react'

const SetStateContext = React.createContext((state: AnimationMaskProps | null) => {})
const GetAnimationMaskStateContext = React.createContext<AnimationMaskProps | null>(null)

const AnimationMaskProvider = (props: { children: React.ReactNode }) => {
    const [animationMask, setAnimationMask] = useState<AnimationMaskProps | null>(null)

    return (
        <SetStateContext.Provider value={(node: AnimationMaskProps | null) => setAnimationMask(node)}>
            <GetAnimationMaskStateContext.Provider value={animationMask}>
                {props.children}
            </GetAnimationMaskStateContext.Provider>
        </SetStateContext.Provider>
    )
}

const useAnimationMask = () => {
    const context = React.useContext(SetStateContext)
    if (context === undefined) {
        throw new Error('useAnimationMask must be used within a AnimationMaskProvider')
    }

    return context
}

export enum AnimationMaskValue {
    success,
    error,
}

type AnimationMaskProps = {
    item: ReactNode | AnimationMaskValue
    text: string
}

export { AnimationMaskProvider, useAnimationMask, GetAnimationMaskStateContext }
