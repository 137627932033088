import React, { FC, KeyboardEvent } from 'react'
import { Select, SelectProps, Theme, useTheme } from '@mui/material'
import EvtBox from './EvtBox'
import { EvtButtonPill } from './EvtButton'
import { EvtCheckbox } from './EvtCheckbox'
import EvtFormControl from './EvtFormControl'
import EvtFormHelperText from './EvtFormHelperText'
import EvtInputLabel from './EvtInputLabel'
import EvtMenuItem from './EvtMenuItem'

export interface EvtSelectProps extends SelectProps {
    options: Array<{ value: string | number; text: string | React.ReactNode }> | undefined
    isCheckbox?: boolean
    isRoundBorder?: boolean
    isDoneBtn?: boolean
    handleKeyDown?: (e: KeyboardEvent) => void
}

export const EvtSelect: FC<EvtSelectProps> = ({
    isRoundBorder = false,
    isCheckbox = false,
    isDoneBtn = false,
    options,
    children,
    handleKeyDown = () => {},
    ...props
}) => {
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)
    const borderColor = props.error ? theme.palette.formInput.border.error : theme.palette.border.default
    props.sx = {
        ...props.sx,
        '&.Mui-focused': {
            '.evt-select-icon': {
                borderLeft: `2px solid ${borderColor} !important`,
            },
            fieldset: {
                border: `2px solid ${borderColor} !important`,
            },
        },
        '&:hover': {
            '.evt-select-icon': {
                borderWidth: '2px',
            },
            fieldset: {
                border: `2px solid ${borderColor} !important`,
            },
        },
        '.MuiInputLabel-root': {
            color: borderColor,
        },
        fieldset: {
            borderColor: borderColor,
            color: borderColor,
        },
        '.evt-select-icon': {
            borderLeft: `1px solid ${borderColor}`,
            width: '50px',
            height: 'calc(100% - 1px)',
            right: 0,
            top: 0,
            WebkitTransform: 'unset',
            transform: 'unset',
            svg: {
                height: '100%',
            },
            color: borderColor,
        },
    }

    if (isRoundBorder) {
        props.sx = {
            ...props.sx,
            borderRadius: '35px',
        }
    }

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return (
        <EvtFormControl fullWidth onKeyDown={handleKeyDown}>
            {props.label && (
                <EvtInputLabel sx={{ color: borderColor, '&.Mui-focused': { color: borderColor } }}>
                    {props.label}
                </EvtInputLabel>
            )}
            <Select {...props} fullWidth open={open} onClose={handleClose} onOpen={handleOpen}>
                {options?.map((option, index) => (
                    <EvtMenuItem value={option.value} key={index}>
                        {isCheckbox && Array.isArray(props.value) && (
                            <EvtCheckbox checked={props.value.includes(option.value)} />
                        )}

                        {option.text}
                    </EvtMenuItem>
                ))}
                {isDoneBtn && (
                    <EvtBox sx={doneBtnWrapperStyle}>
                        <EvtButtonPill className="done-btn" onClick={handleClose}>
                            Done
                        </EvtButtonPill>
                    </EvtBox>
                )}
            </Select>
            {props.error && <EvtFormHelperText error={props.error}>{props.error}</EvtFormHelperText>}
        </EvtFormControl>
    )
}

const doneBtnWrapperStyle = (theme: Theme) => ({
    padding: '5px',
    display: 'flex',
    'justify-content': 'center',
    '.done-btn': {
        padding: '10px 50px',
    },
})
