export const queryCacheTime = 5 * 60 * 1000 // 5 mins
export const SiteLocation = {
    AU: 'au',
    NZ: 'nz',
}

export const theme = {
    LIGHT: 'light',
    DARK: 'dark',
}

export const PaymentType = {
    paypal: { id: 1, text: 'Paypal' },
    creditCard: { id: 2, text: 'Credit Card' },
    apple: { id: 3, text: 'Apple' },
    eft: { id: 4, text: 'Invoice (Pay Later)' },
}

export const CreditCardType = {
    visa: { id: 1, text: 'Visa' },
    masterCard: { id: 4, text: 'Mastercard' },
    amex: { id: 2, text: 'AMEX' },
    diners: { id: 6, text: 'Diners' },
}

export const States = [
    { value: 'NSW', text: 'NSW' },
    { value: 'VIC', text: 'VIC' },
    { value: 'ACT', text: 'ACT' },
    { value: 'NT', text: 'NT' },
    { value: 'QLD', text: 'QLD' },
    { value: 'SA', text: 'SA' },
    { value: 'TAS', text: 'TAS' },
    { value: 'WA', text: 'WA' },
]

export const isAu = process.env.REACT_APP_COUNTRY === SiteLocation.AU

export const Countries = isAu
    ? [{ value: 'Australia', text: 'Australia' }]
    : [{ value: 'New Zealand', text: 'New Zealand' }]

export const Titles = [
    { value: 'Mr', text: 'Mr' },
    { value: 'Ms', text: 'Ms' },
    { value: 'Mrs', text: 'Mrs' },
    { value: 'Dr', text: 'Dr' },
]

export const PaymentProviders = {
    braintree: 1,
}

export const orderStatus = {}

export enum hubPages {
    order,
    image,
    resend,
}

export enum PaymentStatus {
    notPaid = 0,
    paid = 1,
    error = 3,
    awaitingPayment = 4,
}
