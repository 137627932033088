import { FC, ReactNode, useContext } from 'react'
import { useParams } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Theme, styled } from '@mui/material'
import { OrderProductCta } from '../../components/OrderAndProductCta'
import OrderExtraInfoBox from '../../components/OrderExtraInfoBox'
import OrderSummary from '../../components/OrderSummary'
import { ProgressWithLabel } from '../../components/ProgressWithLabel'
import { TickAnimation } from '../../components/lottie/TickAnimation'
import { theme } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtDivider from '../../elements/EvtDivider'
import EvtGrid from '../../elements/EvtGrid'
import { EvtLoader } from '../../elements/EvtLoader'
import EvtTableDetail, { AlignType, Column } from '../../elements/EvtTableDetail'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { GetThemeStateContext } from '../../providers/CustomThemeProvider'
import { useOrder } from '../../query/orders'
import { Order, OrderProduct } from '../../types/orders/Order'
import orderUrls from '../../urls/orderUrls'
import { EVT } from '../../utils/evt'

interface Props {
    title: string
    showTickAnimation?: boolean
}
const OrderDetail: FC<Props> = ({ title, showTickAnimation = false }) => {
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const { id } = useParams()
    const { data: order, isLoading } = useOrder(id)
    const maxLength =
        order?.products.reduce((p, c) => {
            return Math.max(p, c.quantity || 0).toString().length
        }, 0) || 0

    const props = {
        title,
        showTickAnimation,
        isLoading,
        order,
        maxLength,
    }

    return <>{isDesktop ? <OrderDetailsDesktop {...props} /> : <OrderDetailsMobile {...props} />}</>
}

export default OrderDetail

interface OrderDetailProps {
    isLoading: boolean
    order: Order | undefined
    maxLength: number
}

const OrderDetailsDesktop: FC<OrderDetailProps & Props> = ({
    title,
    showTickAnimation,
    isLoading,
    order,
    maxLength,
}) => {
    const products: Array<Column<OrderProduct>> = [
        {
            title: 'Product',
            descriptor: (item) => <ImageStyles alt={item.name} src={item.cardImageUrl} />,
        },
        {
            title: 'Order Details',
            descriptor: (item) => item.name,
        },
        {
            title: 'Qty Assigned / Total',
            descriptor: (o) =>
                o.isElectronic ? (
                    <ProgressWithLabel current={o.totalSent || 0} total={o.quantity} maxNumberLength={maxLength} />
                ) : (
                    '-'
                ),
            align: AlignType.Center,
        },
        { title: 'Price', descriptor: (o) => EVT.toCurrency(o.price), align: AlignType.Center },

        { title: 'Total', descriptor: (o) => EVT.toCurrency(o.quantity * o.price), align: AlignType.Center },

        {
            title: '',
            descriptor: (o) => <OrderProductCta orderProduct={o} />,
            align: AlignType.Center,
        },
    ]
    return (
        <EvtBox
            sx={{
                paddingY: '50px',
                paddingX: '10%',
            }}
        >
            <EvtLoader loading={isLoading}>
                <EvtTypography sx={{ fontSize: '25px', pb: showTickAnimation ? '20px' : '40px' }}>
                    {title}
                </EvtTypography>
                {showTickAnimation && (
                    <>
                        <TickAnimation width={80} height={80} />
                        <EvtTypography sx={succssTxtStyle}>Order Successful!</EvtTypography>
                    </>
                )}

                <EvtGrid container spacing="40">
                    <EvtGrid item xs={8}>
                        <EvtTableDetail columns={products} items={order?.products} />
                        <EvtDivider />
                        <EvtBox textAlign="left">
                            <BackToOrdersBtn />
                        </EvtBox>
                    </EvtGrid>

                    <EvtGrid item xs={4}>
                        <OrderSummary
                            products={order?.products}
                            order={order}
                            shipping={
                                order?.orderShippingInclTax
                                    ? { name: order?.shippingMethod || '', price: order.orderShippingInclTax }
                                    : undefined
                            }
                        />
                    </EvtGrid>
                </EvtGrid>

                <EvtGrid container spacing="40">
                    <EvtGrid item xs={8}>
                        <OrderExtraInfoBox order={order} />
                    </EvtGrid>
                </EvtGrid>
            </EvtLoader>
        </EvtBox>
    )
}
const OrderDetailsMobile: FC<OrderDetailProps & Props> = ({ title, showTickAnimation, isLoading, order }) => {
    const productDetails: Array<ProductProp> = [
        { title: 'Quantity', descriptor: (product) => product.quantity },
        {
            title: 'Qty Assigned / Total',
            descriptor: (o) => (
                <EvtTypography fontSize="13px">
                    {o.isElectronic ? `${o.totalSent || 0} / ${o.quantity}` : '-'}
                </EvtTypography>
            ),
        },
        {
            title: 'Price',
            descriptor: (product) => <EvtTypography fontSize="13px">{EVT.toCurrency(product.price)}</EvtTypography>,
        },
        {
            title: 'Total',
            descriptor: (product) => (
                <EvtTypography fontSize="13px">{EVT.toCurrency(product.price * product.quantity)}</EvtTypography>
            ),
        },
    ]
    return (
        <EvtBox
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingY: '20px',
                paddingX: {
                    mobile: '20px',
                    tablet: '50px',
                },
            }}
        >
            <EvtLoader loading={isLoading}>
                <EvtTypography sx={{ fontSize: '25px', pb: showTickAnimation ? '20px' : '40px' }}>
                    {title}
                </EvtTypography>
                {showTickAnimation && (
                    <>
                        <TickAnimation width={50} height={50} />
                        <EvtTypography sx={succssTxtStyle}>Order Successful!</EvtTypography>
                    </>
                )}

                {order?.products.map((product: OrderProduct, index) => (
                    <EvtBox sx={orderProductStyle} key={index}>
                        <EvtBox className="header">
                            <EvtTypography>Product</EvtTypography>
                            <EvtTypography>
                                {index + 1}/{order?.products.length}
                            </EvtTypography>
                        </EvtBox>
                        <EvtBox className="content">
                            <EvtBox className="top">
                                <ImageStyles alt={product.name} src={product.cardImageUrl} />
                                <EvtBox className="title">
                                    <EvtTypography>{product.name}</EvtTypography>
                                    {/* {isShowId && <EvtTypography>ID #{product.productId}</EvtTypography>} */}
                                </EvtBox>
                            </EvtBox>
                            <EvtBox className="bottom">
                                <EvtBox className="left">
                                    {productDetails.map((item, i) => (
                                        <EvtBox key={i} display="flex" alignItems="center" gap="5px">
                                            <EvtTypography fontSize="13px">{item.title} : </EvtTypography>
                                            {item.descriptor(product)}
                                        </EvtBox>
                                    ))}
                                </EvtBox>
                                <EvtBox className="right">
                                    <OrderProductCta orderProduct={product} />
                                </EvtBox>
                            </EvtBox>
                        </EvtBox>
                    </EvtBox>
                ))}

                <EvtDivider sx={{ marginBottom: '30px', width: '100%' }} />
                <EvtBox maxWidth="500px" minWidth="320px">
                    <OrderSummary
                        products={order?.products}
                        order={order}
                        shipping={
                            order?.orderShippingInclTax ? { name: '', price: order.orderShippingInclTax } : undefined
                        }
                    />
                </EvtBox>
                <BackToOrdersBtn />
                <OrderExtraInfoBox order={order} />
            </EvtLoader>
        </EvtBox>
    )
}

const BackToOrdersBtn = () => {
    const isLight = useContext(GetThemeStateContext).theme === theme.LIGHT
    const navigate = useEvtNavigate()
    return (
        <EvtButtonPill
            variant={isLight ? 'contained' : 'outlined'}
            sx={{ padding: '15px 30px', marginTop: '30px' }}
            onClick={() => {
                navigate(orderUrls.pages.index)
            }}
        >
            <KeyboardBackspaceIcon sx={{ marginRight: '10px' }} />
            Back to all orders
        </EvtButtonPill>
    )
}

const succssTxtStyle = (t: Theme) => ({
    marginTop: '30px',
    marginBottom: '30px',
    fontSize: '28px',
    color: t.palette.success.main,
})

const ImageStyles = styled('img')(() => ({
    height: '60px',
}))

const orderProductStyle = (theme: Theme) => ({
    width: '100%',
    '> .header': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 15px',
        backgroundColor: theme.palette.shoppingCart.itemHeader,
        borderRadius: '8px',
        '> p ': {
            color: theme.palette.text.secondary,
            fontSize: '12px',
        },
    },
    '> .content': {
        padding: '20px',
        '> .top': {
            display: 'flex',
            gap: '20px',
            marginBottom: '20px',
            '> .title': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                '> p:nth-of-type(1)': {
                    fontSize: '20px',
                    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                        fontSize: '15px',
                    },
                    textAlign: 'left',
                    width: 'fit-content',
                },
                '> p:nth-of-type(2)': {
                    fontSize: '15px',
                    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                        fontSize: '12px',
                    },
                    width: 'fit-content',
                },
            },
        },
        '> .bottom': {
            display: 'flex',
            justifyContent: 'space-between',
            '> .left': {
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
                '> p': {
                    fontSize: '13px',
                    width: 'fit-content',
                },
            },
            '> .right': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
            },
        },
    },
})

type ProductProp = {
    title?: string
    descriptor: (item: OrderProduct) => string | number | ReactNode
}
