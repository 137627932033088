import { config } from '../config'
import { API, createPageUrl } from '../urls'

const userUrls = {
    api: {
        passwordChange: {
            url: config.baseUrl + '/api/user/changepassword',
            requiresAuth: true,
            method: 'POST',
        } as API,
        passwordReset: {
            url: config.baseUrl + '/api/user/passwordreset',
            requiresAuth: false,
            method: 'POST',
        } as API,
        passwordResetTokenCheck: {
            url: config.baseUrl + '/api/user/passwordreset',
            requiresAuth: false,
            method: 'GET',
        } as API,
        forgottenPassword: {
            url: config.baseUrl + '/api/user/forgottenpassword',
            requiresAuth: false,
            method: 'POST',
        } as API,
        login: {
            url: config.baseUrl + '/api/user/login',
            requiresAuth: false,
            method: 'POST',
        } as API,
        refresh: {
            url: config.baseUrl + '/api/user/refresh',
            requiresAuth: false,
            method: 'GET',
        } as API,
        logout: {
            url: config.baseUrl + '/api/user/logout',
            requiresAuth: false,
            method: 'GET',
        } as API,
        join: {
            url: config.baseUrl + '/api/user/join',
            requiresAuth: false,
            method: 'POST',
        } as API,
        update: {
            url: config.baseUrl + '/api/user/update',
            requiresAuth: true,
            method: 'POST',
        } as API,
        profile: {
            url: config.baseUrl + '/api/user/details',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_USER_DETAIL',
        } as API,
        imageUpload: {
            url: config.baseUrl + '/api/user/evoucherimage',
            requiresAuth: true,
            method: 'POST',
        } as API,
        imageDelete: {
            url: config.baseUrl + '/api/user/evoucherimagedelete',
            requiresAuth: true,
            method: 'POST',
        } as API,
        viewAs: {
            url: config.baseUrl + '/api/user/viewAs',
            method: 'GET',
        } as API,
    },
    pages: {
        passwordReset: createPageUrl({
            url: `/resetpassword/:token`,
            text: `Password Reset`,
        }),
        login: createPageUrl({
            url: `/user/login`,
            text: `Login`,
        }),
        profile: createPageUrl({
            url: `/user/profile`,
            text: `Profile`,
        }),
        viewAs: createPageUrl({
            url: `/viewas/:id`,
            text: `View As`,
        }),
    },
}

export default userUrls
