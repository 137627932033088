import { useTheme } from '@mui/material'
import CarouselUI from '../../components/CarouselUI'
import ProductCard, { ProductCardSkeleton } from '../../components/ProductCard'
import EvtBox from '../../elements/EvtBox'
import { EvtTitleTypography } from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useImages } from '../../query/images'
import { EVT } from '../../utils/evt'

const MyProductUnLoignSection = () => {
    const theme = useTheme()
    const deviceType = useDeviceType()
    const { data: images, isLoading } = useImages()
    const productImages = images?.home.products.images || []
    const isHasShortDes = productImages.some((p) => p.ShortDescription) || false
    const itemWidth = EVT.isMobile(deviceType) ? theme.productCard.widthMobile : theme.productCard.width

    return (
        <>
            <EvtTitleTypography>Products</EvtTitleTypography>
            <EvtBox marginTop="20px">
                <CarouselUI
                    itemGap={30}
                    itemWidth={itemWidth}
                    renderDotsOutside={true}
                    infinite={false}
                    items={
                        isLoading || !productImages
                            ? skeleton
                            : productImages.map((p, index) => (
                                  <ProductCard
                                      image={p}
                                      key={index}
                                      isHasShortDes={isHasShortDes}
                                      mobile={EVT.isMobile(deviceType)}
                                  />
                              ))
                    }
                />
            </EvtBox>
        </>
    )
}

const skeleton = [<ProductCardSkeleton />, <ProductCardSkeleton />, <ProductCardSkeleton />]

export default MyProductUnLoignSection
