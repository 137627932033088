import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { theme } from '../consts'
import { getLocalStorageItem, setLocalStorageItem } from '../hooks/useLocalStorage'
import { useUser } from '../query/users'

export enum TYPE {
    'SWITCH_THEME',
    'SET',
}

interface CustomeTheme {
    theme: string
    initialized: boolean
}

interface ThemeFuncProviderProps {
    switchTheme: () => void
    theme: string
}

const initalState = { theme: '', initialized: false }
const ThemeContext = React.createContext<ThemeFuncProviderProps>({
    switchTheme: () => {},
    theme: initalState.theme,
})
const GetThemeStateContext = React.createContext(initalState)
const themeKey = (user: string | undefined) => `_evt_theme_${user}`

const CustomThemeProvider = (props: { children: React.ReactNode }) => {
    const { data: user } = useUser()
    const initValues = getLocalStorageItem<string>(themeKey(user?.unique_name), theme.DARK)
    const [state, dispatch] = useReducer(store.reducer, initalState)

    useEffect(() => {
        if (initValues.length > 0 && !state.initialized) {
            // Reset initial values
            dispatch({
                type: TYPE.SET,
                theme: initValues,
            })
        }
    }, [initValues, state.initialized])

    useEffect(() => {
        // Sync local storage with state
        if (user?.unique_name) setLocalStorageItem(themeKey(user?.unique_name), state.theme)
    }, [state.theme, user?.unique_name])

    const switchTheme = useCallback(
        () =>
            dispatch({
                type: TYPE.SWITCH_THEME,
            }),
        [],
    )

    const value = useMemo(() => {
        const theme = state.theme
        return {
            switchTheme,
            theme,
        }
    }, [switchTheme, state.theme])

    return (
        <ThemeContext.Provider value={value}>
            <GetThemeStateContext.Provider value={state}>{props.children}</GetThemeStateContext.Provider>
        </ThemeContext.Provider>
    )
}

const useCustomTheme = () => {
    const context = React.useContext(ThemeContext)
    if (context === undefined) {
        throw new Error('useCustomTheme must be used within a CustomThemeProvider')
    }

    return context
}

type Action =
    | {
          type: TYPE.SWITCH_THEME
      }
    | { type: TYPE.SET; theme: string }

const store = {
    initialState: {
        theme: '',
    },
    dispatches: {
        switchTheme: (dispatch: React.Dispatch<Action>) => {
            return dispatch({ type: TYPE.SWITCH_THEME })
        },
    },
    reducer: (state: CustomeTheme, action: Action) => {
        switch (action.type) {
            case TYPE.SWITCH_THEME: {
                return {
                    ...state,
                    theme: state.theme === theme.LIGHT ? theme.DARK : theme.LIGHT,
                }
            }

            case TYPE.SET: {
                return {
                    ...state,
                    theme: action.theme,
                    initialized: true,
                }
            }

            default:
                throw new Error(`Unknown action: ${JSON.stringify(action)}`)
        }
    },
}

export { CustomThemeProvider, GetThemeStateContext, useCustomTheme }
