import { config } from '../config'
import { API } from '../urls'

const globalUrls = {
    api: {
        promoTiles: {
            url: config.baseUrl + '/api/promotiles',
            requiresAuth: false,
            method: 'GET',
        } as API,
    },
}

export default globalUrls
