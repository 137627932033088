import { FC } from 'react'
import { TextField, TextFieldProps, useTheme } from '@mui/material'

export interface EvtInputFieldProps {
    bgColor?: string
    isRoundBorder?: boolean
    isError?: boolean
    maxLength?: number
    enterKeyHint?: 'enter' | 'done' | 'search' | 'next'
}

const EvtInputField: FC<EvtInputFieldProps & TextFieldProps> = ({
    isError,
    isRoundBorder = false,
    maxLength = 1000,
    bgColor,
    enterKeyHint,
    ...props
}) => {
    const theme = useTheme()
    const borderColor = props.error ? theme.palette.formInput.border.error : theme.palette.border.default
    bgColor = bgColor ? bgColor : theme.palette.background.default
    props.sx = {
        ...props.sx,
        '& .MuiOutlinedInput-root': {
            borderRadius: isRoundBorder ? '35px' : '4px',
            'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,': {
                WebkitTextFillColor: theme.palette.text.primary,
                WebkitBoxShadow: `0 0 0px 1000px ${bgColor} inset`,
                transition: 'background-color 5000s ease-in-out 0s',
            },
        },
        fieldset: {
            borderColor: `${borderColor} !important`,
        },
        '&:hover': {
            fieldset: {
                border: `2px solid ${borderColor} !important`,
            },
        },
        '.MuiInputLabel-root': {
            color: borderColor,
            '&.Mui-focused': { color: borderColor },
        },
    }
    props.inputProps = {
        ...props.inputProps,
        maxLength: maxLength,
        enterKeyHint,
    }

    return <TextField {...props} variant="outlined" />
}

export { EvtInputField }
