import { useContext, useState } from 'react'
import TableEmptyContent from '../../components/TableEmptyContent'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtTableDetail from '../../elements/EvtTableDetail'
import { EvtTitleTypography } from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { GetThemeStateContext } from '../../providers/CustomThemeProvider'
import { useOrders } from '../../query/orders'
import orderUrls from '../../urls/orderUrls'
import productUrls from '../../urls/productUrls'
import { EVT } from '../../utils/evt'
import { orderColumns, orderColumnsMobile } from '../order/Orders'
import { theme as themeColor } from './../../consts'

const RecentOrdersSection = () => {
    const deviceType = useDeviceType()
    const [page, setPage] = useState<number>(1)
    const { data: pagination, isLoading } = useOrders({ page: page, pageSize: 5 })

    const nextPage = () => {
        if (pagination?.orders.nextPage) {
            setPage(pagination.orders.nextPage)
        }
    }

    const isLight = useContext(GetThemeStateContext).theme === themeColor.LIGHT

    return (
        <>
            <EvtTitleTypography mb="60px" mt="30px" onClick={() => nextPage()}>
                Your Recent Orders
            </EvtTitleTypography>
            <EvtTableDetail
                sx={{
                    width: '100%',
                }}
                isLoading={isLoading}
                items={pagination?.orders?.items}
                columns={
                    EVT.isMobile(deviceType)
                        ? orderColumnsMobile(pagination?.orders?.items)
                        : orderColumns(pagination?.orders?.items)
                }
                emptyNode={
                    <TableEmptyContent
                        text="No orders to show"
                        btnTxt="Purchase products"
                        btnLinkTo={productUrls.pages.index}
                    />
                }
            />

            {!isLoading && pagination?.orders?.items && pagination?.orders?.items?.length > 0 && (
                <EvtButtonPill
                    variant={isLight ? 'contained' : 'outlined'}
                    sx={{ padding: '15px 50px', mt: '40px' }}
                    to={orderUrls.pages.index}
                >
                    View All
                </EvtButtonPill>
            )}
        </>
    )
}

export default RecentOrdersSection
