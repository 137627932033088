import { ChangeEvent, FC, KeyboardEvent } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { TextFieldProps, useTheme } from '@mui/material'
import EvtInputAdornment from './EvtInputAdornment'
import { EvtInputField } from './EvtInputField'

export interface EvtSearchFieldProps {
    onClear: () => void
    onChange: (value: string) => void
    onEnterToSearch: () => void
    value: string
}

const EvtSearchField: FC<EvtSearchFieldProps & TextFieldProps> = ({
    value,
    onChange,
    onClear,
    onEnterToSearch,
    ...props
}) => {
    const theme = useTheme()
    const handleEnterToSearch = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onEnterToSearch()
        }
    }
    return (
        <EvtInputField
            {...props}
            value={value}
            label={props.label || 'Search'}
            enterKeyHint="search"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value)
            }}
            onKeyDown={handleEnterToSearch}
            InputProps={{
                endAdornment:
                    value.length > 0 ? (
                        <EvtInputAdornment position="end">
                            <ClearIcon
                                sx={{ color: theme.palette.text.primary, cursor: 'pointer' }}
                                onClick={onClear}
                            />
                        </EvtInputAdornment>
                    ) : (
                        <></>
                    ),
            }}
        />
    )
}

export default EvtSearchField
