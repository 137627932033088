import { FC, useEffect, useState } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import { Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import FormComponent from '../../../components/FormComponent'
import { StepContinueButton } from '../../../components/StepContainerUI'
import EvtBox from '../../../elements/EvtBox'
import { EvtSubmitButton } from '../../../elements/EvtButton'
import EvtDivider from '../../../elements/EvtDivider'
import { EvtFormInputField } from '../../../elements/EvtFormInputField'
import EvtGrid from '../../../elements/EvtGrid'
import EvtLink from '../../../elements/EvtLink'
import EvtTypography from '../../../elements/EvtTypography'
import useDeviceType from '../../../hooks/useDeviceType'
import { EVT } from '../../../utils/evt'
import { evtValidator } from '../../../utils/validation'
import { Recipient } from '../AssignRecipient'

export interface RecipientManual {
    value: Array<Recipient>
    isActive: boolean
}

interface Props {
    remaining: number
    data: RecipientManual
    onChange: (value: RecipientManual) => void
    onNext: (value: RecipientManual) => void
}

const maxEmailLength = 60

const INITIAL_FORM_STATE = {
    email: '',
}

const FORM_VALIDATION = evtValidator(INITIAL_FORM_STATE)
const DEFAULT_RECIPIENT = { id: 0, email: '', qty: 1, isActive: true }

const AssignManual: FC<Props> = ({ data, remaining, onChange, onNext }) => {
    const deviceType = useDeviceType()
    const [recipients, setRecipients] = useState<Array<FormValue>>([])
    const totalAssigned = recipients.filter((r) => !r.isActive).length
    const isDesktop = EVT.isDesktop(deviceType)
    const isMobile = EVT.isMobile(deviceType)

    useEffect(() => {
        if (data.value.length > 0) {
            const formatRecipients = data.value.map((d) => ({ ...d, isActive: false }))
            if (data.value.length < remaining) {
                formatRecipients.push({ ...DEFAULT_RECIPIENT, id: data.value.length })
            }
            setRecipients(formatRecipients)
        } else {
            setRecipients([DEFAULT_RECIPIENT])
        }
    }, [remaining, data])

    useEffect(() => {
        onChange({
            ...data,
            value: recipients.filter((r) => r.email !== ''),
        })
    }, [recipients, data, onChange])

    const handleAdd = (value: FormValue) => {
        value.isActive = false
        recipients.forEach((r, i) => (recipients[i] = r.id === value.id ? value : r))

        const newRecipient = {
            ...DEFAULT_RECIPIENT,
            id: recipients.filter((r) => r.email !== '').length,
        }

        if (recipients.length === remaining || recipients.filter((r) => r.isActive).length > 0) {
            setRecipients([...recipients])
        } else {
            setRecipients([...recipients, newRecipient])
        }
    }
    const handleEdit = (value: FormValue) => {
        value.isActive = true
        recipients.forEach((r, i) => (recipients[i] = r.id === value.id ? value : r))
        setRecipients([...recipients])
    }

    // 1. more than 1 active
    // 2. there is a filling field
    // 3. inital values
    const isInvalidate = () => {
        const isMore = recipients.filter((r) => r.isActive).length > 1
        let isFilling = false
        if (recipients.filter((r) => r.isActive).length === 1) {
            const activeRecipient = recipients.filter((r) => r.isActive).pop()
            isFilling = activeRecipient?.email !== ''
        }
        let isEmpty = false
        if (recipients.length === 1 && recipients[0].email === '') isEmpty = true
        return isFilling || isMore || isEmpty
    }

    const handleRemove = (value: FormValue) => {
        const updatedRecipients = recipients.filter((r) => r.id !== value.id)
        setRecipients(updatedRecipients.length === 0 ? [DEFAULT_RECIPIENT] : [...updatedRecipients])
    }

    return (
        <EvtBox>
            <EvtBox sx={(t) => assignCounterStyle(t, isMobile)}>
                <EvtTypography fontSize={isMobile ? '20px' : '25px'}>
                    {totalAssigned}/{remaining}
                </EvtTypography>
                <EvtTypography fontSize="12px">Assigned</EvtTypography>
            </EvtBox>

            <EvtBox sx={(t) => containerStyle(t, isDesktop)} className="recipient-container">
                {recipients.map((r, i) => {
                    return isDesktop ? (
                        <RecipientFormDesktop
                            key={i}
                            formValues={r}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onRemove={handleRemove}
                        />
                    ) : (
                        <RecipientFormMobile
                            key={i}
                            formValues={r}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onRemove={handleRemove}
                        />
                    )
                })}
            </EvtBox>
            <StepContinueButton
                disabled={isInvalidate()}
                onClick={() => {
                    onNext({
                        ...data,
                        value: recipients
                            .filter((r) => r.email !== '')
                            .map((r) => ({ id: r.id, email: r.email, qty: 1 })),
                        isActive: true,
                    })
                }}
                text="Next"
            />
        </EvtBox>
    )
}
export default AssignManual

interface RecipientFormProps {
    formValues: FormValue
    onAdd: (value: FormValue) => void
    onEdit: (value: FormValue) => void
    onRemove: (value: FormValue) => void
}

const RecipientFormDesktop: FC<RecipientFormProps> = ({ formValues, onAdd, onEdit, onRemove }) => {
    return (
        <FormComponent
            enableReinitialize={true}
            initialValues={formValues}
            validationSchema={FORM_VALIDATION}
            onSubmit={onAdd}
        >
            <EvtGrid container className="recipient-row" spacing="10px">
                <EvtGrid item xs={8}>
                    <EvtFormInputField
                        label="Email"
                        name="email"
                        maxLength={maxEmailLength}
                        isRoundBorder
                        disabled={!formValues.isActive}
                    />
                </EvtGrid>
                <EvtGrid item xs={4}>
                    {formValues.isActive ? (
                        <EvtSubmitButton
                            variant="contained"
                            fullWidth
                            sx={{ borderRadius: '35px', height: '56px' }}
                            color="success"
                        >
                            Add
                        </EvtSubmitButton>
                    ) : (
                        <EvtBox
                            display="flex"
                            gap="10px"
                            alignItems="center"
                            height="100%"
                            justifyContent="space-around"
                        >
                            <DoneIcon color="success" />
                            <EvtLink
                                onClick={() => onEdit(formValues)}
                                sx={{ color: (t) => t.palette.border.secondary, cursor: 'pointer' }}
                            >
                                Edit
                            </EvtLink>
                            <EvtDivider orientation="vertical" sx={{ height: '50%' }} />
                            <EvtLink onClick={() => onRemove(formValues)} color="error" sx={{ cursor: 'pointer' }}>
                                Remove
                            </EvtLink>
                        </EvtBox>
                    )}
                </EvtGrid>
            </EvtGrid>
        </FormComponent>
    )
}

const RecipientFormMobile: FC<RecipientFormProps> = ({ formValues, onAdd, onEdit, onRemove }) => {
    return (
        <FormComponent
            enableReinitialize={true}
            initialValues={formValues}
            validationSchema={FORM_VALIDATION}
            onSubmit={onAdd}
        >
            <EvtBox className="recipient-row" sx={recipientRowMobileStyle}>
                <EvtFormInputField
                    label="Email"
                    name="email"
                    maxLength={maxEmailLength}
                    isRoundBorder
                    disabled={!formValues.isActive}
                    InputProps={{
                        endAdornment: !formValues.isActive ? <DoneIcon color="success" /> : <></>,
                    }}
                />
                <EvtBox display="flex" justifyContent="left" width="90%">
                    {formValues.isActive ? (
                        <EvtSubmitButton
                            variant="contained"
                            fullWidth
                            sx={{ borderRadius: '35px', height: '56px', width: '150px' }}
                            color="success"
                        >
                            Add
                        </EvtSubmitButton>
                    ) : (
                        <EvtBox
                            display="flex"
                            gap="10px"
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <EvtLink
                                onClick={() => onEdit(formValues)}
                                sx={{ color: (t) => t.palette.border.secondary, cursor: 'pointer' }}
                            >
                                Edit
                            </EvtLink>
                            <EvtDivider orientation="vertical" sx={{ height: '50%' }} />
                            <EvtLink onClick={() => onRemove(formValues)} color="error" sx={{ cursor: 'pointer' }}>
                                Remove
                            </EvtLink>
                        </EvtBox>
                    )}
                </EvtBox>
            </EvtBox>
        </FormComponent>
    )
}

const containerStyle = (theme: Theme, isDesktop: boolean): SystemStyleObject<Theme> => {
    const formStyle = isDesktop
        ? {}
        : {
              'form:not(:last-of-type)': {
                  paddingBottom: '20px',
                  borderBottom: `1px solid ${theme.palette.border.default}`,
                  borderBottomStyle: 'dashed',
              },
          }
    return {
        mt: '-50px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        ...formStyle,
    }
}

const recipientRowMobileStyle = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    '> .MuiFormControl-root': { width: '90%' },
})

const assignCounterStyle = (theme: Theme, isMobile: boolean) => ({
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    position: 'relative',
    top: isMobile ? '-55px' : '-60px',
    right: '30px',
    justifyContent: 'flex-end',
    width: '100px',
    left: 'calc(100% - 100px - 30px)',
})

interface FormValue extends Recipient {
    isActive: boolean
}
