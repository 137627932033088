import { FC } from 'react'
import EvtBox from '../elements/EvtBox'
import { EvtButton } from '../elements/EvtButton'
import EvtTypography from '../elements/EvtTypography'
import { useEvtNavigate } from '../hooks/useEvtNavigate'
import homeUrls from '../urls/homeUrls'

interface Props {
    resetErrorBoundary: () => void
}

const ErrorBoundaryComponent: FC<Props> = ({ resetErrorBoundary }) => {
    const navigate = useEvtNavigate()
    return (
        <EvtBox
            sx={{
                height: 'calc(100% - 64px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
            }}
        >
            <EvtTypography>Sorry... there was an error</EvtTypography>
            <EvtButton
                variant="contained"
                onClick={() => {
                    navigate(homeUrls.pages.index)
                    resetErrorBoundary()
                }}
            >
                Back to Home
            </EvtButton>
        </EvtBox>
    )
}

export default ErrorBoundaryComponent
