import { FC, ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router'
import { AlternateEmail, AutoAwesomeMotion, Settings } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import LoginIcon from '@mui/icons-material/Login'
import SendIcon from '@mui/icons-material/Send'
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive'
import WorkIcon from '@mui/icons-material/Work'
import { Divider, useTheme } from '@mui/material'
import { Theme, styled } from '@mui/material/styles'
import { theme as themeConst } from '../../consts'
import EvtAvatar from '../../elements/EvtAvatar'
import EvtBox, { EvtBoxColumn } from '../../elements/EvtBox'
import EvtDrawer from '../../elements/EvtDrawer'
import EvtIconButton from '../../elements/EvtIconButton'
import { EvtLinkTo } from '../../elements/EvtLinkTo'
import EvtList from '../../elements/EvtList'
import EvtListItem from '../../elements/EvtListItem'
import EvtListItemButton from '../../elements/EvtListItemButton'
import EvtListItemIcon from '../../elements/EvtListItemIcon'
import EvtSwitch from '../../elements/EvtSwitch'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { GetThemeStateContext, useCustomTheme } from '../../providers/CustomThemeProvider'
import { useUser } from '../../query/users'
import { LoggedInUser } from '../../types/users/User'
import { PageURL } from '../../urls'
import { digitalHubUrls, pages } from '../../urls/digitalHubUrls'
import homeUrls from '../../urls/homeUrls'
import orderUrls from '../../urls/orderUrls'
import productUrls from '../../urls/productUrls'
import userUrls from '../../urls/userUrls'
import { EVT } from '../../utils/evt'

interface Props {
    open: boolean
    handleDrawerToggle: (isOpen: boolean) => void
}

const NavDrawer: FC<Props> = ({ open, handleDrawerToggle }) => {
    const theme = useTheme()
    const { data: user } = useUser()
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)

    const sections: Array<SectionProps> = []
    if (user) {
        sections.push(
            ...[userLoggedInSection(user, open, theme), myCoporateSection, eVoucherSection, sectionsWithoutLogin],
        )
    } else {
        sections.push(...[userLoggedOutSection, sectionsWithoutLogin])
    }

    const onDrawerClose = () => {
        handleDrawerToggle(false)
    }

    const onDrawerOpen = () => {
        handleDrawerToggle(true)
    }

    return (
        <EvtDrawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={open}
            sx={(t) => drawerStyles(t, open)}
            onMouseOver={onDrawerOpen}
            onMouseOut={onDrawerClose}
            ModalProps={{
                onBackdropClick: onDrawerClose,
            }}
        >
            <DrawerHeader>
                {open && (
                    <EvtIconButton sx={{ marginLeft: '16px' }} onClick={onDrawerClose}>
                        <CloseIcon sx={{ color: theme.palette.nav.sideTitleTx }} />
                    </EvtIconButton>
                )}
            </DrawerHeader>

            <EvtList sx={{ padding: theme.spacing(3) }}>
                {sections.map((section, index) => {
                    const { title, items } = section
                    return (
                        <EvtBox key={`nav-side-section-${index}`} className="nav-side-group">
                            {title && <TitleSection title={title} open={open} />}
                            {items.map((item, subIndex) => (
                                <ItemSection
                                    key={`nav-side-item-${index}-${subIndex}`}
                                    open={open}
                                    item={item}
                                    onDrawerClose={onDrawerClose}
                                />
                            ))}
                            {title === 'General' && <ThemeSwitcherWrapper open={open} />}
                        </EvtBox>
                    )
                })}
            </EvtList>
        </EvtDrawer>
    )
}

export default NavDrawer

interface SwitcherWrapperProps {
    open: boolean
}

const ThemeSwitcherWrapper: FC<SwitcherWrapperProps> = ({ open }) => {
    const { switchTheme } = useCustomTheme()
    const themeCtx = useContext(GetThemeStateContext)
    const isDarkMode = themeCtx.theme === themeConst.DARK
    return (
        <EvtListItem
            disablePadding
            sx={{
                display: 'block',
            }}
        >
            <EvtListItemIcon className="item-icon">
                <EvtSwitch onClick={() => switchTheme()} checked={isDarkMode} />
            </EvtListItemIcon>
            <EvtTypography className="item-title" variant="body2" sx={(t) => ThemeTextStyles(t, open)}>
                Change to {isDarkMode ? 'light' : 'dark'} mode
            </EvtTypography>
        </EvtListItem>
    )
}

const TitleSection: FC<{ title: string; open: boolean }> = ({ title, open }) => {
    const theme = useTheme()

    return !open ? (
        <Divider sx={{ margin: '19px 10px 20px 10px' }} />
    ) : (
        <EvtListItem
            key={title}
            disablePadding
            sx={{
                display: 'block',
                height: theme.spacing(10),
            }}
        >
            <EvtTypography
                variant="body1"
                fontWeight="bold"
                sx={{
                    paddingX: theme.spacing(2.5),
                    color: theme.palette.nav.sideTitleTx,
                }}
            >
                {title}
            </EvtTypography>
        </EvtListItem>
    )
}

const ItemSection: FC<{ open: boolean; item: ItemProps; onDrawerClose: () => void }> = ({
    open,
    item,
    onDrawerClose,
}) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const deviceType = useDeviceType()
    return (
        <EvtListItem
            disablePadding
            sx={{
                display: 'block',
            }}
            onClick={() => {
                item.to && navigate(item.to.idUrl(item.toId))
                if (!EVT.isDesktop(deviceType)) setTimeout(() => onDrawerClose(), 200)
            }}
        >
            <EvtListItemButton sx={(t) => itemButtonStyles(t, open)}>
                {item.icon && <EvtListItemIcon className="item-icon">{item.icon}</EvtListItemIcon>}
                {item.contents || (
                    <EvtTypography
                        className="item-title"
                        variant="body2"
                        color={theme.palette.nav.sideItemTx}
                        height="20px"
                    >
                        {item.name}
                    </EvtTypography>
                )}
            </EvtListItemButton>
        </EvtListItem>
    )
}

const userLoggedInSection = (user: LoggedInUser, open: boolean, theme: Theme) =>
    ({
        items: [
            {
                icon: (
                    <EvtAvatar
                        alt={EVT.getUsersName(user)}
                        sx={{
                            width: 30,
                            height: 30,
                            fontSize: theme.spacing(2.5),
                            bgcolor: theme.palette.nav.navSideIcon,
                        }}
                    >
                        {EVT.getUsersInitial(user)}
                    </EvtAvatar>
                ),
                contents: (
                    <EvtBox sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
                        <EvtLinkTo to={userUrls.pages.profile} className="item-title">
                            <EvtBoxColumn gap="5px" width="100px">
                                <EvtTypography
                                    noWrap
                                    color={theme.palette.nav.sideTitleTx}
                                    sx={{ lineHeight: 1, fontSize: theme.spacing(3.5) }}
                                >
                                    {EVT.getUsersName(user)}
                                </EvtTypography>
                                {user.company_name && (
                                    <EvtTypography
                                        noWrap
                                        color={theme.palette.nav.sideItemTx}
                                        sx={{ lineHeight: 1, fontSize: theme.spacing(2.5) }}
                                    >
                                        {user.company_name}
                                    </EvtTypography>
                                )}
                            </EvtBoxColumn>
                        </EvtLinkTo>
                        {open && <Settings sx={{ color: theme.palette.nav.navUserIcon }} />}
                    </EvtBox>
                ),
                to: userUrls.pages.profile,
            },
        ],
    } as SectionProps)

const userLoggedOutSection: SectionProps = {
    items: [
        {
            icon: <LoginIcon />,
            name: 'Login',
            to: userUrls.pages.login,
        },
    ],
}
const myCoporateSection: SectionProps = {
    title: 'My Corporate',
    items: [
        {
            icon: <WorkIcon />,
            name: 'Purchase Products',
            to: productUrls.pages.index,
        },
        {
            icon: <AutoAwesomeMotion />,
            name: 'My Order History',
            to: orderUrls.pages.index,
        },
    ],
}
const sectionsWithoutLogin: SectionProps = {
    title: 'General',
    items: [
        {
            icon: <AlternateEmail />,
            name: 'Contact us',
            to: homeUrls.pages.contactUs,
        },
        {
            icon: <WorkIcon />,
            name: 'About us',
            to: homeUrls.pages.about,
        },
    ],
}
const eVoucherSection: SectionProps = {
    title: 'Digital Hub',
    items: [
        {
            icon: <SendIcon />,
            name: 'Send eVouchers',
            to: digitalHubUrls.pages.index,
            toId: pages.order.url,
        },
        {
            icon: <FileUploadIcon />,
            name: 'Add images',
            to: digitalHubUrls.pages.index,
            toId: pages.image.url,
        },
        {
            icon: <SendAndArchiveIcon />,
            name: 'Resend eVouchers',
            to: digitalHubUrls.pages.index,
            toId: pages.resend.url,
        },
    ],
}

interface ItemProps {
    icon: ReactNode
    name: string
    to?: PageURL
    contents?: ReactNode
    toId?: number | string | undefined
}
interface SectionProps {
    title?: string
    items: Array<ItemProps>
}

const ThemeTextStyles = (theme: Theme, open: boolean) => ({
    height: '20px',
    color: theme.palette.nav.sideItemTx,
    display: open ? 'block' : 'none',
})

const openedMixin = (theme: Theme) => ({
    width: theme.navbar.drawer.openWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.navbar.drawer.closeWidth} + 1px)`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}))

const itemButtonStyles = (theme: Theme, open: boolean) => ({
    width: 'fit-content',
    '&:hover': {
        backgroundColor: theme.palette.nav.btnHover,
        cursor: 'pointer',
    },
    minHeight: 28,
    justifyContent: open ? 'initial' : 'center',
    paddingY: theme.spacing(1),
    paddingX: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
    '.item-icon': {
        minWidth: 0,
        mr: open ? 3 : 'auto',
        color: theme.palette.nav.sideItemTx,
    },
    '.item-title': {
        display: open ? 'block' : 'none',
        color: theme.palette.nav.sideItemTx,
    },
})

const drawerStyles = (theme: Theme, open: boolean) => ({
    width: theme.navbar.drawer.openWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '.MuiPaper-root': { backgroundColor: theme.palette.nav.sideBgColor, borderRight: 'unset' },
    '.nav-side-group': {
        li: {
            height: '40px',
            display: 'flex',
            alignItems: 'center',
        },
        '&:first-of-type': {
            li: {
                height: '80px',
                '.MuiButtonBase-root': {
                    borderRadius: '15px',
                    padding: '10px 8px',
                },
            },
        },
    },
})
