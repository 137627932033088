import React, { FC } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { styled, useTheme } from '@mui/material/styles'
import EvtIconButton from '../elements/EvtIconButton'
import EvtInputAdornment from '../elements/EvtInputAdornment'
import { EvtInputField } from '../elements/EvtInputField'
import { useCart } from '../providers/CartProvider'
import { Product } from '../types/products/Product'

interface Props {
    product: Product
}

const QtyInputField: FC<Props> = ({ product }) => {
    const { addToCart } = useCart()
    const theme = useTheme()

    const handleAdd = () => {
        addToCart(product, 1)
    }
    const handleMinus = () => {
        addToCart(product, -1)
    }

    return (
        <EvtInputFieldStyles
            fullWidth
            isRoundBorder
            disabled
            value={product.quantity}
            bgColor={theme.palette.menu.bgColor}
            InputProps={{
                startAdornment: (
                    <EvtInputAdornment position="start" className="qty-minus">
                        <EvtIconButton sx={{ padding: 0 }} onClick={handleMinus}>
                            <RemoveIcon sx={{ color: theme.palette.text.primary }} fontSize="small" />
                        </EvtIconButton>
                    </EvtInputAdornment>
                ),
                endAdornment: (
                    <EvtInputAdornment position="end" className="qty-plus">
                        <EvtIconButton sx={{ padding: 0 }} onClick={handleAdd}>
                            <AddIcon sx={{ color: theme.palette.text.primary }} fontSize="small" />
                        </EvtIconButton>
                    </EvtInputAdornment>
                ),
            }}
        />
    )
}

export default QtyInputField

const EvtInputFieldStyles = styled(EvtInputField)(({ theme }) => ({
    minWidth: '100px',
    '.MuiOutlinedInput-root': {
        padding: '0 5px',
        height: '40px',
    },
    input: {
        textAlign: 'center',
        WebkitTextFillColor: `${theme.palette.text.primary} !important`,
    },
}))
