import { PromoTileCarousel, PromoTileImagePosition } from '../../components/PromoTileCarousel'
import EvtBox from '../../elements/EvtBox'
import { EvtTitleTypography } from '../../elements/EvtTypography'
import { usePromoTiles } from '../../query/promotiles'

export const CorporateOffersSection = () => {
    const { data: banners, isLoading } = usePromoTiles()
    return (
        <>
            <EvtTitleTypography mb="60px" mt="30px">
                Corporate Offers
            </EvtTitleTypography>
            <PromoTileCarousel tiles={banners?.offerTiles} loading={isLoading} />
        </>
    )
}

export const BottomOffersSection = () => {
    const { data: banners, isLoading } = usePromoTiles()
    return (
        <EvtBox sx={{ mt: '50px' }}>
            <PromoTileCarousel
                tiles={banners?.experienceTiles}
                imagePosition={PromoTileImagePosition.Right}
                loading={isLoading}
            />
        </EvtBox>
    )
}
