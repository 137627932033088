import { hubPages } from '../consts'
import { createPageUrl } from '../urls'

export const digitalHubUrls = {
    pages: {
        index: createPageUrl({
            url: '/digitalhub/:id',
            text: 'Digital Hub',
        }),
    },
}

export const pages = {
    order: {
        key: hubPages.order,
        url: 'order',
    },
    image: {
        key: hubPages.image,
        url: 'image',
    },
    resend: {
        key: hubPages.resend,
        url: 'resend',
    },
}
