import { useEffect, useState } from 'react'
import { BannerImageCarousel } from '../../components/BannerImageCarousel'
import { Images, useImages } from '../../query/images'
import { useUser } from '../../query/users'

const Banner = () => {
    const { data: images } = useImages()
    const { data: user, isLoading: userLoading } = useUser()
    const [banners, setBanners] = useState<Images | undefined>(undefined)

    useEffect(() => {
        if (images && images.home && images.home.banners && !userLoading) {
            const actualImages = user ? images.home.banners.loggedIn : images.home.banners.loggedOut
            setBanners(actualImages)
        }
    }, [user, images, userLoading])

    return <BannerImageCarousel images={banners} />
}

export default Banner
