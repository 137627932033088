import { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Theme, styled } from '@mui/material'
import { useFormikContext } from 'formik'
import moment from 'moment'
import * as yup from 'yup'
import { EvtFilterWrapper } from '../../components/EvtFilterWrapper'
import FormComponent from '../../components/FormComponent'
import { Edit, Resend, ViewEvoucher } from '../../components/OrderAndProductActions'
import OrderAndProductActionsMobile from '../../components/OrderAndProductActionsMobile'
import TableEmptyContent from '../../components/TableEmptyContent'
import EvtBox from '../../elements/EvtBox'
import { EvtButton, EvtButtonPill } from '../../elements/EvtButton'
import EvtChip from '../../elements/EvtChip'
import EvtDateRangePicker, { DateFilterProps } from '../../elements/EvtDateRangePicker'
import { EvtFormInputField } from '../../elements/EvtFormInputField'
import EvtGrid from '../../elements/EvtGrid'
import EvtSearchField from '../../elements/EvtSearchField'
import EvtTableDetail, { AlignType, Column } from '../../elements/EvtTableDetail'
import useDeviceType from '../../hooks/useDeviceType'
import { useSuccessAlert } from '../../providers/modals/AlertModalProvider'
import { useModal } from '../../providers/modals/GlobalModalProvider'
import {
    RecipientModel,
    RecipientUpdateParams,
    useOrderProductResend,
    useOrderRecipients,
    useRecipientUpdate,
} from '../../query/orders'
import productUrls from '../../urls/productUrls'
import { EVT } from '../../utils/evt'
import { evtValidator } from '../../utils/validation'

interface FilterProps {
    pageSize: number
    productName?: string
    recipientName?: string
    emailedTo?: string
    dateRange: DateFilterProps
    orderNumber?: string
    orderProductId?: string
}

const maxEmailLength = 60
const maxMessageLength = 100
const maxGreetingLength = 50
const maxFromLength = 180

const DigitalResend = () => {
    const navigate = useNavigate()
    const success = useSuccessAlert()
    const { mutate: orderProductResend } = useOrderProductResend()
    const { openModal } = useModal()
    const [page, setPage] = useState<number>(1)
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const [searchParams, setSearchParams] = useSearchParams()
    const orderNumber = searchParams.get('orderNumber')
    const productName = searchParams.get('productName')
    const recipientName = searchParams.get('recipientName')
    const emailedTo = searchParams.get('emailedTo')
    const fromDate = searchParams.get('fromDate')
    const toDate = searchParams.get('toDate')
    const orderProductId = searchParams.get('orderProductId')

    const initialFilter = {
        pageSize: 10,
        dateRange: {
            fromDate: fromDate ? moment(parseInt(fromDate)) : null,
            toDate: toDate ? moment(parseInt(toDate)) : null,
        },
        orderNumber: orderNumber || '',
        productName: productName || '',
        recipientName: recipientName || '',
        emailedTo: emailedTo || '',
        orderProductId: orderProductId || '',
    }

    const { data: recipResponse, isLoading } = useOrderRecipients({
        page: page,
        pageSize: initialFilter.pageSize,
        productName: initialFilter.productName,
        recipientName: initialFilter.recipientName,
        emailedTo: initialFilter.emailedTo,
        fromDate: initialFilter.dateRange.fromDate?.toDate(),
        toDate: initialFilter.dateRange.toDate?.toDate(),
        orderNumber: initialFilter.orderNumber,
        orderProductId: initialFilter.orderProductId,
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [page])

    const handleSearch = (filter: FilterProps) => {
        let params: { [key: string]: string } = {}
        let paramArray = []
        if (filter.orderProductId) {
            params.orderProductId = filter.orderProductId
            paramArray.push(`orderProductId=${orderProductId}`)
        }

        if (filter.orderNumber) {
            params.orderNumber = filter.orderNumber
            paramArray.push(`orderNumber=${filter.orderNumber}`)
        }
        if (filter.productName) {
            params.productName = filter.productName
            paramArray.push(`productName=${filter.productName}`)
        }
        if (filter.recipientName) {
            params.recipientName = filter.recipientName
            paramArray.push(`recipientName=${filter.recipientName}`)
        }
        if (filter.emailedTo) {
            params.emailedTo = filter.emailedTo
            paramArray.push(`emailedTo=${filter.emailedTo}`)
        }
        if (filter.dateRange?.fromDate) {
            params.fromDate = filter.dateRange.fromDate.toDate().getTime().toString()
            paramArray.push(`fromDate=${params.fromDate}`)
        }
        if (filter.dateRange?.toDate) {
            params.toDate = filter.dateRange.toDate.toDate().getTime().toString()
            paramArray.push(`toDate=${params.toDate}`)
        }

        navigate({ search: `?${paramArray.join('&')}` })
    }

    const showResendRecipient = (item: RecipientModel) => {
        openModal({
            title: 'Update Recipient Info',
            content: (
                <ResendRecipientUpdateModal
                    email={item.email}
                    orderProductItemId={item.id.toString()}
                    from={item.from}
                    message={item.message}
                    greeting={item.greeting}
                    send={false}
                />
            ),
        })
    }

    const resend = (item: RecipientModel) => {
        orderProductResend(
            { OrderProductItemId: item.id },
            {
                onSuccess: () => {
                    success('Products emailed to recipient/s successfully')
                },
            },
        )
    }

    return (
        <>
            <EvtFilterWrapper
                initialValue={initialFilter}
                onSubmit={(filter) => {
                    setPage(1)
                    handleSearch(filter)
                }}
            >
                {({ filterState, handleClear, setFieldValue, submit }) => (
                    <EvtGrid container spacing={4} mb="40px">
                        <GridInputField
                            label="Product Name"
                            propsName="productName"
                            filter={filterState}
                            onChange={setFieldValue}
                            onClear={handleClear}
                            onSearch={submit}
                        />
                        <GridInputField
                            label="Recipient Name"
                            propsName="recipientName"
                            filter={filterState}
                            onChange={setFieldValue}
                            onClear={handleClear}
                            onSearch={submit}
                        />
                        <GridInputField
                            label="Email To"
                            propsName="emailedTo"
                            filter={filterState}
                            onChange={setFieldValue}
                            onClear={handleClear}
                            onSearch={submit}
                        />

                        <GridInputField
                            label="Order Number"
                            propsName="orderNumber"
                            filter={filterState}
                            onChange={setFieldValue}
                            onClear={handleClear}
                            onSearch={submit}
                        />
                        <EvtGrid item lg={6} sm={9} xs={8}>
                            <EvtDateRangePicker
                                dateFilter={filterState.dateRange}
                                onChange={(date) => setFieldValue('dateRange', date)}
                                onClear={(date) => handleClear('dateRange', date)}
                                onEnterToSearch={submit}
                            />
                        </EvtGrid>

                        <EvtGrid item lg={2} sm={3} xs={4}>
                            <EvtButton fullWidth sx={{ height: '100%' }} onClick={submit} variant="contained">
                                Search
                            </EvtButton>
                        </EvtGrid>

                        {orderProductId && (
                            <EvtGrid item xs={12} textAlign="left">
                                <EvtBox display="flex">
                                    <EvtChip
                                        label={`Product ID: ${orderProductId}`}
                                        onDelete={() => {
                                            setFieldValue('orderProductId', undefined)
                                            handleSearch({ ...initialFilter, orderProductId: undefined })
                                        }}
                                    />
                                </EvtBox>
                            </EvtGrid>
                        )}
                    </EvtGrid>
                )}
            </EvtFilterWrapper>

            <EvtTableDetail
                isLoading={isLoading}
                columns={
                    isDesktop
                        ? recipientColumns(showResendRecipient, resend)
                        : recipientColumnsMobile(showResendRecipient, resend)
                }
                pagination={recipResponse?.recipients}
                onPageChange={setPage}
                skeletonRows={initialFilter.pageSize}
                emptyNode={
                    <TableEmptyContent
                        text="No products to show"
                        btnLinkTo={productUrls.pages.index}
                        btnTxt="Purchase products"
                    />
                }
            />
        </>
    )
}

export default DigitalResend

interface GridInputFieldProps {
    filter: FilterProps
    label: string
    propsName: string
    onChange: (propsName: string, value: string) => void
    onSearch: (forceRemoveProductId?: boolean) => void
    onClear: (propsName: string, value?: any) => void
}
const GridInputField: FC<GridInputFieldProps> = ({ filter, label, propsName, onChange, onSearch, onClear }) => {
    return (
        <EvtGrid item xs={12} sm={6} lg={4}>
            <EvtSearchField
                fullWidth
                label={label}
                // @ts-ignore
                value={filter[propsName]}
                onChange={(value) => onChange(propsName, value as string)}
                onClear={() => onClear(propsName)}
                onEnterToSearch={onSearch}
            />
        </EvtGrid>
    )
}

const recipientColumns = (
    onShowEditModal: (item: RecipientModel) => void,
    onResend: (item: RecipientModel) => void,
) => {
    const columns: Array<Column<RecipientModel>> = [
        {
            title: '',
            descriptor: (item) => <ImageStyles alt={item.name} src={item.productImageUrl} />,
        },
        { title: 'Product Name', descriptor: (item) => item.productName },
        { title: 'Order', descriptor: (item) => item.orderNumber },
        {
            title: 'Send Date',
            descriptor: (o) =>
                o.sent ? (
                    <>
                        {EVT.displayDate(o.sent)}
                        <br />
                        {EVT.displayTime(o.sent)}
                    </>
                ) : (
                    '-'
                ),
            align: AlignType.Left,
        },
        { title: 'Email', descriptor: (item) => item.email },
        {
            title: 'Actions',
            descriptor: (o) => {
                return (
                    <EvtBox display="flex" justifyContent="center" gap="10px">
                        <Edit onClick={() => onShowEditModal(o)} />
                        <ViewEvoucher id={o.id} />
                        <Resend disabled={false} onResend={() => onResend(o)} />
                    </EvtBox>
                )
            },
            align: AlignType.Center,
        },
    ]
    return columns
}

const recipientColumnsMobile = (
    onShowEditModal: (item: RecipientModel) => void,
    onResend: (item: RecipientModel) => void,
) => {
    const columns: Array<Column<RecipientModel>> = [
        { title: 'Product Name', descriptor: (item) => item.productName },
        {
            title: '',
            descriptor: (o) => (
                <OrderAndProductActionsMobile
                    listItems={[
                        <Edit onClick={() => onShowEditModal(o)} />,
                        <ViewEvoucher id={o.id} />,
                        <Resend disabled={false} onResend={() => onResend(o)} />,
                    ]}
                />
            ),
            align: AlignType.Center,
        },
    ]

    return columns
}

const ResendRecipientUpdateModal: FC<RecipientUpdateParams> = (props) => {
    const success = useSuccessAlert()
    const { forceClose } = useModal()
    const [settings, setSettings] = useState<RecipientUpdateParams>(props)
    const { mutate: recipientUpdate } = useRecipientUpdate()

    const onSubmit = () => {
        recipientUpdate(settings, {
            onSuccess: () => {
                success({
                    message: `Recipient info updated successfully ${
                        settings?.send ? ' and new Email has been sent' : ''
                    }`,
                    onOk: () => {
                        forceClose()
                    },
                })
            },
        })
    }
    return (
        <FormComponent
            initialValues={settings}
            validationSchema={evtValidator(settings, extraValidation)}
            onSubmit={onSubmit}
        >
            <EvtGrid container spacing={6} sx={gridContainerStyle}>
                <EvtGrid item xs={12}>
                    <EvtFormInputField label="Email" name="email" maxLength={maxEmailLength} isRoundBorder />
                </EvtGrid>
                <EvtGrid item xs={12}>
                    <EvtFormInputField label="From" name="from" maxLength={maxFromLength} isRoundBorder />
                </EvtGrid>
                <EvtGrid item xs={12}>
                    <EvtFormInputField label="Greeting" name="greeting" maxLength={maxGreetingLength} isRoundBorder />
                </EvtGrid>
                <EvtGrid item xs={12}>
                    <MessageInputField />
                </EvtGrid>
                <EvtGrid item container spacing={5}>
                    <EvtGrid item xs={12} md={6} display="flex" justifyContent="center">
                        <SubmitButton text="Update Only" send={false} setSettings={setSettings} />
                    </EvtGrid>
                    <EvtGrid item xs={12} md={6} display="flex" justifyContent="center">
                        <SubmitButton text="Update &amp; Resend" send={true} setSettings={setSettings} />
                    </EvtGrid>
                </EvtGrid>
            </EvtGrid>
        </FormComponent>
    )
}

const MessageInputField: FC<{}> = () => {
    const { values } = useFormikContext<RecipientUpdateParams>()
    return (
        <EvtFormInputField
            label="Message"
            name="message"
            maxLength={maxMessageLength}
            isRoundBorder
            rows={6}
            multiline
            helperText={`${(values.message || '').length}/${maxMessageLength}`}
            FormHelperTextProps={{ sx: messageCounterStyles }}
        />
    )
}

const SubmitButton: FC<SubmitButtonProps> = ({ text, send, setSettings }) => {
    const { values, submitForm } = useFormikContext<RecipientUpdateParams>()
    return (
        <EvtButtonPill
            sx={{ padding: '15px 35px' }}
            onClick={() => {
                setSettings({ ...values, send })
                submitForm()
            }}
        >
            {text}
        </EvtButtonPill>
    )
}

interface SubmitButtonProps {
    send: boolean
    text: string
    setSettings: (settings: RecipientUpdateParams) => void
}

const ImageStyles = styled('img')(() => ({
    height: '60px',
}))

const gridContainerStyle = (theme: Theme) => ({
    padding: '20px 30px 0px 30px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: { padding: '20px 0px 0px 0px' },
})

const messageCounterStyles = () => ({
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    bottom: '-23px',
})

const extraValidation = {
    from: yup.string().max(maxFromLength, `From must not exceed ${maxFromLength} characters`).required('Required'),
    greeting: yup
        .string()
        .max(maxGreetingLength, `Greeting must not exceed ${maxGreetingLength} characters`)
        .required('Required'),
    message: yup
        .string()
        .max(maxMessageLength, `Message must not exceed ${maxMessageLength} characters`)
        .required('Required'),
}
